import { Component } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

@Component({
  selector: 'app-step-verify',
  templateUrl: './verify.component.html',
  styleUrls:['./verify.component.less'],
})
export class StepVerifyComponent {
  constructor(
    private router: Router,
    public msg: NzMessageService,
    private activatedRoute: ActivatedRoute,
  ) {}

  guid:string;

  ngOnInit() {
    this.guid = this.activatedRoute.params['_value']['id'] == null ? "":this.activatedRoute.params['_value']['id'];
   }


   navito() {
    let url = 'sites/templatelist';
    this.router.navigateByUrl(url + '/' + this.guid);
  }
}
