// Custom icon static resources

import {
  InfoOutline,
  BulbOutline,
  ProfileOutline,
  ExceptionOutline,
  LinkOutline,
  HomeOutline,
} from '@ant-design/icons-angular/icons';

export const ICONS = [InfoOutline, BulbOutline, ProfileOutline, ExceptionOutline, LinkOutline,HomeOutline];
