import { Component, OnInit, ViewChild } from '@angular/core';
import { NzMessageService,NzDrawerRef, NzModalControlService } from 'ng-zorro-antd';
import { _HttpClient,ModalHelper } from '@delon/theme';
import { ContentImagesComponent } from '../../../content/images/images.component';
import { environment } from '@env/environment';
import { CookieService } from 'ngx-cookie-service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OrderDeliverylistComponent } from 'app/routes/order/deliverylist/deliverylist.component';

@Component({
  selector: 'app-product-productbrand-edit',
  templateUrl: './edit.component.html',
})
export class ProductProductbrandEditComponent implements OnInit {
  validateForm: FormGroup;
  addObj: any;
  editObj: any;
  loading = false;
  form: any;
  initForm = {
    name: "",
    b_url: "",
    cat_id: "",
    logo: "",
    pic_url: "",
    list_order: "",
    is_hot:true,
    desc: "",
  }
  catelist: any;

  get sid() {
    return this.cookies.get("sid")
  }
  constructor(
    private drawerRef: NzDrawerRef,
    private msgSrv: NzMessageService,
    public http: _HttpClient,
    private cookies: CookieService,
    private fb: FormBuilder,
    private modal: ModalHelper,
  ) { }

  ngOnInit(): void {

    this.getCate()
    if (this.addObj) {
      console.log(this.addObj)
      this.form = this.initForm

    } else {
      console.log(this.editObj);
      this.form = this.editObj.item;
    }
    console.log("hot",this.form.is_hot)
    //表单验证
    this.validateForm = this.fb.group({
      brandName: [null, [Validators.required]],
      brandLink: [null],
      brandCate: [null, [Validators.required]],
      sort: [null],
      hot:[null],
      brandDes: [null],
    })
  }
  add(form) {
    this.http.post(environment.SPAPPS_URL + "/backend/web/v1/sp-goods-brand", form).subscribe(res => {
      if (res.code !== 1) {
        this.msgSrv.error(res.message)
        return;
      }
      this.msgSrv.success(res.message)
      this.drawerRef.close()
    })
  }
  edit(form) {
    // form['id'] = this.form.id
    this.http.put(environment.SPAPPS_URL + "/backend/web/v1/sp-goods-brand/"+this.form.id, form).subscribe(res => {
      if (res.code !== 1) {
        this.msgSrv.error(res.message)
      }
      this.msgSrv.success(res.message);
      this.drawerRef.close()
    })
  }
  save(value: any) {
    this.loading = true;
    console.log(this.validateForm.valid)
    if (!this.validateForm.valid) {
      for (const i in this.validateForm.controls) {
        this.validateForm.controls[i].markAsDirty();
        this.validateForm.controls[i].updateValueAndValidity();
      }
    } else {
      let form = {
        "sid":this.sid,
        "name":this.form.name,
        "logo": this.form.logo,
        "desc": this.form.desc,
        "url": this.form.url,
        "list_order": this.form.list_order,
        "parent_cat_id" :this.form.parent_cat_id,
        "is_hot":this.form.is_hot/1,

      }
      if (this.addObj) {
        this.add(form)
      } else {
        this.edit(form)
      }
    }
  }

  close() {
    this.drawerRef.close();
  }
  checkImages() {
    this.modal.createStatic(ContentImagesComponent, { size: 'md' }).subscribe((res: any) => {
      console.log(res.url)
      this.form.pic_url = res.url;
      this.form.logo = res.media_id;
    });
  }
  blur(e) {
    console.log("e",e)
  }
  getCate() {
    this.http.get(environment.SPAPPS_URL + "/backend/web/v1/sp-goods-brand/goods-cate").subscribe(res => {
      console.log(res)
      if (res.code == 1) {
        this.catelist=res.data
      }
    })
  }
}
