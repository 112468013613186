import { Component, OnInit, ViewChild } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { STColumn, STComponent } from '@delon/abc';
import { SFSchema } from '@delon/form';
import { MemberMemberlistEditComponent } from "./edit/edit.component";
import { environment } from '@env/environment';
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'app-member-memberlist',
  templateUrl: './memberlist.component.html',
})
export class MemberMemberlistComponent implements OnInit {
  isLogin = false;
  content = "";
  nickname = "";
  datalist = [{
    id: 1,
    nickname: "羡羡",
    drage:"未知",
    email: "401147684@qq.com",
    phone: "18895693367",
    integral: "10",
    data:"1970-01-01 08:00:00",
  }]
  pageNumber = 1;
  pageSize = 10;
  total: any;
  @ViewChild('st') st: STComponent;
  columns: STColumn[] = [
    { title: '编号', index: 'id' },
    { title: '会员昵称',  index: 'nickname' },
    { title: '会员等级',  index: 'drage' },
    { title: '邮件地址',  index: 'mail' },
    { title: '手机号码',  index: 'phone' },
    { title: '积分',  index: 'integral' },
    { title: '注册日期', type: 'date', index: 'data' },
    {
      title: '操作',
      buttons: [
        { text: '查看', click: (item: any) => this.view(item) },
        // { text: '编辑', type: 'static', component: FormEditComponent, click: 'reload' },
      ]
    }
  ];

  constructor(
    private http: _HttpClient,
    private modal: ModalHelper,
    private cookies:CookieService
  ) { }

  ngOnInit() {
    this.getData()
   }
  get sid() {
    return this.cookies.get('sid');
   }
  add() {
    this.modal
      .createStatic(MemberMemberlistEditComponent, { i: { id: 0 } })
      .subscribe(() => this.st.reload());
  }
  view(item) {
    this.modal.createStatic(MemberMemberlistEditComponent, { view: { item: item } }).subscribe(() => {
      this.st.reload()
    })
  }
  getData() {
    this.http.post(environment.SPAPPS_URL + "/backend/web/v1/sp-goods-member/member-list", {
      "user_login":"",
      "user_nickname":"",
    }).subscribe(res => {
      console.log(res)
    })
  }
  getRest() {
    this.content = "";
    this.nickname = "";
    this.getData();
  }

}
