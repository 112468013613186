import { Component, OnInit, ViewChild } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { STColumn, STComponent, STReq, STRes  } from '@delon/abc';
import { SFSchema } from '@delon/form';
import { environment } from '@env/environment';
import { STConfig } from '@delon/abc';
@Component({
  selector: 'app-demo-list',
  templateUrl: './list.component.html',
})


export class DemoListComponent implements OnInit {
  url = environment.SPAPPS_URL+'/backend/web/v1/templates?tpl=1';
  // url = environment.SPAPPS_URL+'/backend/web/v1/demo?all=1';
  // url = 'https://api.spapps.cn/backend/web/v1/menu/menu';


  // https://api.spapps.cn/backend/web/v1/menu/menu

  // https://api.spapps.cn/backend/web/v1/menu/menu



  // params: any = {
  //   "par-page" : 500
  // };


  searchSchema: SFSchema = {
    properties: {
      no: {
        type: 'string',
        title: '编号'
      }
    }
  };
  @ViewChild('st') st: STComponent;
  columns: STColumn[] = [
    { title: '编号', index: 'demo_id' },
    { title: '名称', index: 'name' },
    { title: '调用次数', type: 'number', index: 'call_no' },
    { title: '头像', type: 'img', width: '50px', index: 'avatar' },
    { title: '时间', type: 'date', index: 'updated_at' },
    {
      title: '',
      buttons: [
        { text: '查看', click: (item: any) => environment.SPAPPS_URL+'/backend/web/v1/demo/${item.demo_id}' },
        // { text: '编辑', type: 'static', component: DemoEditComponent, click: 'reload' },
      ]
    }
  ];

  constructor(private http: _HttpClient, private modal: ModalHelper) { }

  ngOnInit() { }

  add() {
    // this.modal
    //   .createStatic(FormEditComponent, { i: { id: 0 } })
    //   .subscribe(() => this.st.reload());
  }

}
