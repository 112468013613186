import { _HttpClient, ModalHelper } from '@delon/theme';
import { environment } from '@env/environment';
import { ContentImglistCategorylistEditComponent } from './edit/edit.component';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { NzModalRef, NzMessageService, NzModalService } from 'ng-zorro-antd';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CookieService } from "ngx-cookie-service";
export interface TreeNodeInterface {
  key: number;
  name: string;
  expand: boolean;
  children?: TreeNodeInterface[];
}

@Component({
  selector: 'app-content-imglist-categorylist',
  templateUrl: './categorylist.component.html',
})
export class ContentImglistCategorylistComponent implements OnInit {
  nodes: any[] = [];
  validateForm: FormGroup;
  editData: any = {};
  avatarUrl: string;
  loading = false;
  isVisible = false;

  constructor(
    private cookies: CookieService,
    private http: _HttpClient,
    private modal: ModalHelper,
    private modalService: NzModalService,
    private message: NzMessageService,
    private cdr: ChangeDetectorRef,
  ) {}

  get sid(){
    return this.cookies.get('sid');
  }

  mapOfExpandedData: { [key: string]: TreeNodeInterface[] } = {};
  collapse(array: TreeNodeInterface[], data: TreeNodeInterface, $event: boolean): void {
    if ($event === false) {
      if (data.children) {
        data.children.forEach(d => {
          const target = array.find(a => a.key === d.key)!;
          target.expand = true;
          this.collapse(array, target, false);
        });
      } else {
        return;
      }
    }
  }
  convertTreeToList(root: object): TreeNodeInterface[] {
    const stack: any[] = [];
    const array: any[] = [];
    const hashMap = {};
    stack.push({ ...root, level: 0, expand: true });

    while (stack.length !== 0) {
      const node = stack.pop();
      this.visitNode(node, hashMap, array);
      if (node.children) {
        for (let i = node.children.length - 1; i >= 0; i--) {
          stack.push({ ...node.children[i], level: node.level + 1, expand: true, parent: node });
        }
      }
    }
    return array;
  }
  visitNode(node: TreeNodeInterface, hashMap: { [key: string]: any }, array: TreeNodeInterface[]): void {
    if (!hashMap[node.key]) {
      hashMap[node.key] = true;
      array.push(node);
    }
  }

  ngOnInit() {
    this.getTreeData();
  }

  deleteClick(id) {
    this.modalService.confirm({
      nzTitle: '<b>是否确定删除该图片分类?</b>',
      nzContent: '<p>删除以后无法恢复，删除前请慎重考虑!</p>',
      nzOnOk: () => {
        this.http
          .delete(environment.SPAPPS_URL + '/backend/web/v1/categories/'+id+'?sid='+this.sid,)
          .subscribe((res: any) => {
            console.log(res);
            if (res.code === 1) {
              this.message.success('删除成功');
              this.getTreeData();
            } else {
              this.message.error(res.message);
            }
          });
      },
    });
  }

  add() {
    this.modal
      .createStatic(ContentImglistCategorylistEditComponent,).subscribe(() => {
        this.getTreeData(); 
      });
  }

  edit(record:any){
    this.modal
    .createStatic(ContentImglistCategorylistEditComponent,{ record }).subscribe(() => {
      this.getTreeData(); 
    });
  }


  
  getTreeData() {
    this.loading = true;
    this.http.get(environment.SPAPPS_URL + '/backend/web/v1/categories?symbol=pic_cate&sid='+this.sid).subscribe(res => {
      this.nodes = res.data;
      console.log(this.nodes);
      this.nodes.forEach(item => {
        this.mapOfExpandedData[item.key] = this.convertTreeToList(item);
      });
      this.loading = false;
      this.cdr.detectChanges();
    });
  }



}
