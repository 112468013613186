import { Component, OnInit, ViewChild } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { STColumn, STComponent } from '@delon/abc';
import { SFSchema } from '@delon/form';
import { CookieService } from "ngx-cookie-service";
import { NzMessageService,UploadFile, NzFormatEmitEvent,NzModalService, NzTreeNode } from 'ng-zorro-antd';
import { environment } from '@env/environment';
import { NzModalRef } from 'ng-zorro-antd';
import { Observable, Observer } from 'rxjs/index';

@Component({
  selector: 'app-content-images',
  templateUrl: './images.component.html',
  styleUrls:['./images.component.less'],
})
export class ContentImagesComponent implements OnInit {
  loading = false;
  activedNode: NzTreeNode;
  picsize:string;
  title:string;
  nodes:any[] = [];
  list:any[]= [];
  count:any = 0;
  visible = false;
  uploading = false;
  fileList: UploadFile[] = [];
  categoryid = '0';
  searchKey = '';
  isVisible1 = false;
  showImgName:string;
  showImgUrl:string;
  modalWidth = 520;
  categoryName ='全部分类';
  picUrl:string;

  get sid(){
    return this.cookies.get('sid');
  }

  constructor(private http: _HttpClient,
    private modal: NzModalRef,
    private msg: NzMessageService,
    private cookies: CookieService,) { }

  ngOnInit() {
    this.picUrl = environment.SPAPPS_URL + '/backend/web/v1/commons/upload-pic?sid=' + this.sid;
    this.getTreeData();
    this.getPicList(1);
   }


  // 获取左侧图片分类
  getTreeData() {
    this.http.get(environment.SPAPPS_URL + '/backend/web/v1/categories?symbol=pic_cate&sid='+this.sid).subscribe(res => {
      this.nodes = res.data;
    });
  }

  getPicList(page: number){
    this.loading = true;
      this.http.get(environment.SPAPPS_URL+'/backend/web/v1/medias?sid='+this.sid
      +'&category_id='+this.categoryid+'&name='+this.searchKey+'&page='+page+'&per-page=12'
      ).subscribe((res:any)=>{
          this.list = res.data.list;
          this.count = res.data.pagination.total;
          this.picsize = res.data.total_size;
          this.title = this.categoryName +" ("+ this.count +")";

      });
      this.loading = false;
      //this.cdr.detectChanges();
  }

  ///点击分类，进行查询
  activeNode(data: NzFormatEmitEvent){
    this.activedNode = data.node!;
    this.categoryid = this.activedNode.key;
    this.categoryName = this.activedNode.origin.name;
    this.getPicList(1);
  }

  searchList(){
      this.getPicList(1);
  }


// 图片上传前处理
beforeUpload = (file: File) => {
  return new Observable((observer: Observer<boolean>) => {
    // const isJPG = file.type === 'image/jpeg';
    const isJPG =
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'image/jpg' ||
      file.type === 'image/bmp';
    if (!isJPG) {
      this.msg.error('仅支持上传jpg、jpeg、png、bmp 格式图片文件!');
      observer.complete();
      return;
    }
    const isLt2M = file.size / 1024 / 1024  < 1;
    if (!isLt2M) {
      this.msg.error('上传图片文件必须小于1M，请压缩以后再上传 !');
      observer.complete();
      return;
    }
  });
};

handleChange(info: { file: UploadFile }): void {
  console.log(info);
  switch (info.file.status) {
    case 'uploading':
      this.loading = true;
      break;
    case 'done':
      this.getPicList(1);
      break;
    case 'error':
      this.msg.error('图片上传失败，系统未知错误，请联系客服进行反馈!');
      this.loading = false;
      break;
  }
}

checkImage(result:any){
   this.modal.close(result);
}


}
