import { Component, OnInit } from '@angular/core';
import { NzMessageService, NzDrawerRef } from 'ng-zorro-antd';
import { _HttpClient } from '@delon/theme';
@Component({
  selector: 'app-member-memberlevel-edit',
  templateUrl: './edit.component.html',
})
export class MemberMemberlevelEditComponent implements OnInit {
  editObj: any;
  addObj: any;
  constructor(private msgSrv: NzMessageService, public http: _HttpClient, private drawerRef: NzDrawerRef) {}

  ngOnInit(): void {
    console.log(this.addObj);
    console.log(this.editObj);
  }

  save(value: any) {}

  close() {}
}
