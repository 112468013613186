import { Component, OnInit, ViewChild } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { STColumn, STComponent } from '@delon/abc';
import {SFSchema, SFSchemaEnumType} from '@delon/form';
import {map} from "rxjs/operators";
import {environment} from "@env/environment";
import { NzFormatEmitEvent, NzTreeNodeOptions } from 'ng-zorro-antd';
import {any} from "codelyzer/util/function";

@Component({
  selector: 'app-demo-auth-edit',
  templateUrl: './edit.component.html',
})
export class DemoAuthEditComponent implements OnInit {

  nodes:any= [];

  q: any = {
    status: null,
    text: '',
    statusList: [],
  };

  url = `/user`;
  searchSchema: SFSchema = {
    properties: {
      no: {
        type: 'string',
        title: '编号'
      }
    }
  };
  @ViewChild('st') st: STComponent;
  columns: STColumn[] = [
    { title: '菜单名称',  index: 'name'},
    { title: '描述',  index: 'description'},
    { title: '', index: '' }
  ];

  constructor(
    private http: _HttpClient,
    private modal: ModalHelper
  ) { }

  ngOnInit() {
    this.getTreeData();
  }

  add() {

    // this.modal
    //   .createStatic(FormEditComponent, { i: { id: 0 } })
    //   .subscribe(() => this.st.reload());
  }

  getTreeData() {
    // return this.http.get(environment.SPAPPS_URL + '/backend/web/v1/menu/listmenu').pipe(
    //   map((v, i) => {
    //     console.log(v);
    //     const arr: NzTreeNodeOptions[] = [];
    //     (v as any).data.list.forEach(element => {
    //       arr.push(element);
    //       console.log(arr);
    //     });
    //     console.log('1111---------------');
    //     return arr;
    //   })
    // );
    let that = this;
    this.http.get(environment.SPAPPS_URL + '/backend/web/v1/menu/listmenu')
      .subscribe(res => {
        console.log(res);
        console.log(res);
        that.nodes = res.data.list
      })
  }


  nzEvent(event) {
    console.log(event);
  }

}
