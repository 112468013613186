import { Component, OnInit, ViewChild,ChangeDetectorRef } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { STColumn, STComponent } from '@delon/abc';
import { SFSchema } from '@delon/form';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { Inject, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { SettingsService } from '@delon/theme';
import { ActivatedRoute } from '@angular/router';
import { StepFeedbackComponent } from "../feedback/feedback.component";
import { environment } from '@env/environment';
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'app-step-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.less'],
})
export class StepDefaultComponent implements OnInit {
  boxNum: number;
  isShow: boolean;
  userId: any;
  get sid() {
    return this.cookies.get("sid")
  }
  constructor(
    private http: _HttpClient,
    private modal: ModalHelper,
    public settings: SettingsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private cookies: CookieService,
    private cdr:ChangeDetectorRef,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
  ) { }

  ngOnInit() {

    let sid = this.activatedRoute.params['_value']['id'];
    console.log('sid',sid);
    this.getBaseInfo();

    console.log(this.sid)
  }

  goFeedback() {
    this.modal.createStatic(StepFeedbackComponent, { user: { userId:this.userId,sid:this.sid } }).subscribe();
  }
  // 获取用户信息
  getBaseInfo() {
    this.http.get(environment.SPAPPS_URL + '/backend/web/v1/users/profile').subscribe((res: any) => {
      console.log("userinfo",res.data);
      this.userId = res.data.id;
      this.getMessage();
    });
  }

    logout() {
          //清除cookie
          this.cookies.delete('sid','/','smartprogram.spapps.cn');
          this.cookies.delete('sid','/','.spapps.cn');
          this.cookies.delete('sid','/','localhost');
          this.cookies.delete('sid');
      this.tokenService.clear();
      this.router.navigateByUrl(this.tokenService.login_url!);
    }

    //获取消息
  getMessage() {
    this.http.get(environment.SPAPPS_URL + "/backend/web/v1/notice/notice-select?id="+this.userId +"&sid="+this.sid+ "&view=0").subscribe(res => {
      if (res.code === 1) {
        this.boxNum = res.data.list.length;
        this.isShow = this.boxNum === 0 ? false : true;
        this.cdr.detectChanges()
      }
      })
    }
}
