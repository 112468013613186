import { Component, OnInit, ViewChild } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { STColumn, STComponent } from '@delon/abc';
import { SFSchema } from '@delon/form';
import { NzMessageService,UploadFile } from 'ng-zorro-antd';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {environment} from "@env/environment";
import { Observable, Observer } from 'rxjs';
import { match } from 'minimatch';


@Component({
  selector: 'app-step-step1-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.less'],
})
export class StepStep1AddComponent implements OnInit {
  ngOnInit() {
    this.picUrl = environment.SPAPPS_URL + '/backend/web/v1/commons/upload-pic';
    this.validateForm = this.fb.group({
      app_id: [null,[Validators.required,Validators.max]],
      com_name: ['', [Validators.required,Validators.pattern(/^[^\s]*$/)]],
      com_type: [null, [Validators.required]],
      name:[null,[Validators.required,Validators.pattern(/^[^\s]*$/),Validators.maxLength(10)]],
    });
    this.getCompangType();
  }

  current = 0;
  validateForm: FormGroup;
  picUrl = '';
  loading = false;
  avatarUrl: string;
  media_id = 0;
  error = '';
  get app_id() {
    return this.validateForm.controls.app_id;
  }
  get com_name() {
    return this.validateForm.controls.com_name;
  }
  get com_type() {
    return this.validateForm.controls.com_type;
  }
  get name() {
    return this.validateForm.controls.name;
  }

  //行业选择
  nzOptions: any[] | null = null;
  values: any[] | null = null;


  pre(): void {
    this.current -= 1;
    this.changeContent();
  }

  next(): void {
    this.current += 1;
    this.changeContent();
    console.log('下一步', this.validateForm.value)

  }

  // done(): void {
  //   console.log('done');
  // }

  changeContent(): void {
    switch (this.current) {
      case 0: {

        break;
      }
      case 1: {
        if(this.app_id.value == null){
          this.current -= 1;
          this.msg.error('请选择类型');
        } 
        break;
      }
      case 2: {


        //验证小程序名称
        var a = this.name.value;
            var reg = /^[^\s]*$/;
            if(reg.test(a)){
            }else{
              this.current -= 1;
              this.msg.error('小程序名不能为空，不能包含空格，且最多10个字符2');
            }

         if(this.com_type.value == null){
          this.current -= 1;
          this.msg.error('请选择公司类型');
        }else if(this.name.value == null){
          this.current -= 1;
          this.msg.error('小程序名不能为空，不能包含空格，且最多10个字符1');
        }
        else if(this.name.value.length>10){
          this.current -= 1;
          this.msg.error('小程序名不能为空，不能包含空格，且最多10个字符3');
        }

      //验证公司名称
      var b = this.name.value;
          var reg = /^[^\s]*$/;
          if(reg.test(b)){
          }else{
            this.current -= 1;
            this.msg.error('公司名称不能为空，不能包含空格');
          }

       if(this.com_type.value == null){
        this.current -= 1;
        this.msg.error('请选择公司类型');
      }else if(this.name.value == null){
        this.current -= 1;
        this.msg.error('小程序名不能为空，不能包含空格，且最多10个字符1');
      }
      else if(this.name.value.length>10){
        this.current -= 1;
        this.msg.error('小程序名不能为空，不能包含空格，且最多10个字符3');
      }






        break;
      }
      case 3:  {

        if(this.media_id == 0){
          this.msg.error('请上传logo');
        } 


      }
    }
  }


  onChanges(values: any): void {
    console.log(values, this.values);
  }

  //获取行业类型
  getCompangType() {
    let that = this;
    this.http.get(environment.SPAPPS_URL + '/backend/web/v1/instances/com-type')
      .subscribe(res => {
        console.log('行业类型', res.data);
        that.nzOptions = res.data;
      })
  }

  constructor(
    private router: Router,
    private http: _HttpClient,
    private modal: ModalHelper,
    private fb: FormBuilder,
    private msg: NzMessageService
  ) { }




  //图片上传前处理
  beforeUpload = (file: File) => {
    return new Observable((observer: Observer<boolean>) => {
      // const isJPG = file.type === 'image/jpeg';
      const isJPG = (file.type === 'image/jpeg' || file.type === 'image/png' ||file.type === 'image/jpg' || file.type === 'image/bmp')
      if (!isJPG) {
        this.msg.error('仅支持上传jpg、jpeg、png、bmp 格式图片文件!');
        observer.complete();
        return;
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.msg.error('Image must smaller than 2MB!');
        observer.complete();
        return;
      }
      // check height
      this.checkImageDimension(file).then(dimensionRes => {
        if (!dimensionRes) {
          this.msg.error('Image only 256x256 above');
          observer.complete();
          return;
        }

        observer.next(isJPG && isLt2M && dimensionRes);
        observer.complete();
      });
    });
  };

  private getBase64(img: File, callback: (img: string) => void): void {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result!.toString()));
    reader.readAsDataURL(img);
  }


  private checkImageDimension(file: File): Promise<boolean> {
    return new Promise(resolve => {
      const img = new Image(); // create image
      img.src = window.URL.createObjectURL(file);
      img.onload = () => {
        const width = img.naturalWidth;
        const height = img.naturalHeight;
        window.URL.revokeObjectURL(img.src!);
        resolve(width === height && width <= 256);
      };
    });
  }


  //
  handleChange(info: { file: UploadFile }): void {
    console.log(info);
    switch (info.file.status) {
      case 'uploading':
        this.loading = true;
        break;
      case 'done':

        // Get this url from response in real world.
        this.getBase64(info.file!.originFileObj!, (img: string) => {
          this.loading = false;
          this.avatarUrl = img;
          this.media_id = info.file.response.data.media_id;
        });
        break;
      case 'error':
        this.msg.error('Network error');
        this.loading = false;
        break;
    }
  }

  handleData = (file: UploadFile) => {
    return {
       'media_id':this.media_id
    }
  };

  done() {
    this.error = '';
    if(this.media_id == 0){
      // this.current -= 1;
      this.msg.error('请上传logo');
      return;
    } 
    this.http
    .post(environment.SPAPPS_URL+'/backend/web/v1/instances', {
      // type: this.type,
      app_id: this.app_id.value,
      com_name: this.com_name.value,
      com_type: this.com_type.value,
      name: this.name.value,
      media_id: this.media_id,
    })
    .subscribe((res: any) => {
      if (res.code !== 1) {
        this.msg.error(res.message);
        return;
      }
       let url = 'sites/list';
       this.router.navigateByUrl(url);
    });
  }
}
