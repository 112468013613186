import { Component, OnInit, ViewChild, ChangeDetectorRef} from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { STColumn, STComponent } from '@delon/abc';
import { NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';
import { OrderOrderlistViewComponent } from "./view/view.component";
import { OrderOrderlistEditComponent } from "./edit/edit.component";
@Component({
  selector: 'app-order-orderlist',
  templateUrl: './orderlist.component.html',
})
export class OrderOrderlistComponent implements OnInit {
  params= {
    account : "",
    nickName : "",
  }

  datalist = [{
    orderNumber: "201804121012019579",
    consignee: "肖战",
    totalAmount: "777",
    amountPayable: "790",
    orderStatus: "已完成",
    logisticsStatus: "未发货",
    time:"2018-04-12 10:12:01"
  }]

  @ViewChild('st') st: STComponent;
  columns: STColumn[] = [
    { title: '订单号', index: 'orderNumber',width: '10px' },
    { title: '收货人', index: 'consignee',width: '50px' },
    { title: '总金额', type: 'currency', index: 'totalAmount' ,width: '50px'},
    { title: '应付金额', type: 'currency', index: 'amountPayable',width: '50px'},
    { title: '订单状态', index: 'orderStatus',width: '50px'},
    { title: '物流状态', index: 'logisticsStatus' ,width: '50px'},
    { title: '下单时间', type: 'date', index: 'time' ,width: '50px'},
    {
      title: '操作',
      buttons: [
        { text: '查看', click: (item: any) => this.view(item) }
      ],
      width: '50px'
    }
  ];

  constructor(
    private http: _HttpClient,
    private modal: ModalHelper,
    private  drawerService:NzDrawerService,
    private cdr:ChangeDetectorRef,
  ) { }

  ngOnInit() { }

  view(item) {
    this.modal
      .createStatic(OrderOrderlistViewComponent, { view:item })
      .subscribe(() => this.st.reload());
  }
  add() {
    this.drawerService.create<OrderOrderlistEditComponent, { value: any }, string>({
      nzTitle: '添加订单',
      nzContent: OrderOrderlistEditComponent,
      nzWidth: '60%',
      nzContentParams: {
        value: 1
      }
    }).afterOpen.subscribe(() => {
      this.getData();
      this.cdr.detectChanges();
    });
  }

  getData() {

  }

}
