import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { NzMessageService, UploadFile, NzDrawerRef } from 'ng-zorro-antd';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ContentImagesComponent } from '../../../content/images/images.component';
import { environment } from '@env/environment';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-product-productlist-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.less'],
})
export class ProductProductlistEditComponent implements OnInit {
  validateForm: FormGroup;
  scrollTo="";
  loading = false;
  isVisible: boolean;
  imgIconUrl = "";
  addObj: any;
  editObj: any;
  cateList: any;
  brandList: any;
  storeList: any;
  form: any;
  values: any = [];
  goodsId = "";
  dataId: any;//
  //商品信息、、、、、、、、、、、、、、、、、
  initForm = {
    goods_name: '',
    cat_id:"",
    goods_remark: '',
    goods_sn: '',
    brand_id: '',
    shop_price: '',
    market_price: '',
    cost_price: '',
    weight: '',
    volume: '',
    store_count: '',
    keywords: '',
    virtual_sales_sum: '',
    virtual_collect_sum: '',
    is_self: '0',
    self_shop_id: '',
    goods_content: '',
    goods_type:"",
    // 封面图
    goods_img:'',
    original_img: ''
  };
     //模型选择/////////
    modeList: any;
    modeValue: any;
    specList = [];
    statusList = [];
   // 表格渲染
   specFlag: boolean=false;
   arrs: any[];//获取到的数组
  specArr = []//最后的二维数组
  specArrId=[]//二维数组所需的下标
  specNewArr = [];//最后渲染的数组
  specNewArrId = [];
  formData = [];//表格数据

  //积分折扣//////////////
  discount = {
    count: "",
    disPic: "",
    give_integral:"",
    exchange_integral:""
  }
  //添加的价格阶梯
  picArr = [{
    count: "",
    disPic:"",
  }];
  // 图片信息//////////////
  imgList = [];
  image = [];
  editorConfig: any = {};
  content = '';

  // 编辑表格所需数据
  pice = [];
  constructor(
    private msg: NzMessageService,
    private modal: ModalHelper,
    public http: _HttpClient,
    private https: HttpClient,
    private fb: FormBuilder,
    private cookies: CookieService,
    private drawerRef: NzDrawerRef,
    private cdr:ChangeDetectorRef,
  ) {}

  get sid() {
    return this.cookies.get('sid');
  }
  ngOnInit(): void {
    this.getSelect();
    if (this.addObj) {
      console.log(this.addObj);
      this.form = this.initForm;
      this.cateList = this.addObj.cate;
      this.brandList = this.addObj.brand;
    } else {
      console.log(this.editObj);
      this.getData();
      this.form = this.editObj.item;
      this.editObj.item.cate_path.splice(0, 1);
      this.values = this.editObj.item.cate_path;
      console.log(".....",this.values)
      this.cateList = this.editObj.cate;
      this.brandList = this.editObj.brand;
      this.goodsId = this.editObj.item.goods_id;
      const {
        goods_name,
        goods_remark,
        goods_sn,
        brand_id,
        shop_price,
        market_price,
        cost_price,
        weight,
        volume,
        store_count,
        keywords,
        virtual_sales_sum,
        virtual_collect_sum,
        is_self,
        self_shop_id,
        goods_content,
        goods_type,
        goods_img,
        original_img
      }=this.editObj.item
      this.form = {
        goods_name,
        cat_id:"",
        goods_remark,
        goods_sn,
        brand_id,
        shop_price,
        market_price,
        cost_price,
        weight,
        volume,
        store_count,
        keywords,
        virtual_sales_sum,
        virtual_collect_sum,
        is_self,
        self_shop_id,
        goods_content,
        goods_type,
        goods_img,
        original_img
      }

    }

    this.validateForm = this.fb.group({
      name: [null, [Validators.required]],
      cate: [null, [Validators.required]],
      des: [null, [Validators.required]],
      number: [null, [Validators.required]],
      brand: [null, [Validators.required]],
      price: [null, [Validators.required]],
      marketvalue: [null, [Validators.required]],
      costPrice: [null],
      cover: [null],
      weight: [null],
      volume: [null],
      stock: [null],
      keyWord: [null],
      virtualSalesVolume: [null],
      virtualCollectionQuantity: [null],
      selfMention: [null],
      selfMentionLocation: [null],
      detailedDescription: [null],
    });
  }

  add(form) {
    this.http.post(environment.SPAPPS_URL + '/backend/web/v1/sp-goods', form).subscribe(res => {
      console.log(res);
      if (res.code !== 1) {
        this.msg.error(res.message);
        return;
      }
      this.msg.success("商品添加成功");
      this.drawerRef.close();
    });
  }
  edit(form) {
    this.http.put(environment.SPAPPS_URL + '/backend/web/v1/sp-goods/'+this.editObj.item.goods_id,form).subscribe(res => {
      console.log(res);
      if (res.code !== 1) {
        this.msg.error(res.message);
        return;
      }
      this.msg.success("商品修改成功");
      this.drawerRef.close();
    });
  }
  submit() {
    if (this.pice !== []) {
      this.pice.forEach((ele) => {
        this.formData.forEach((el) => {
          for (let key in el) {
            el["item_id"] = ele.item_id;
            el["goods_id"] = ele.goods_id;
         }
       })
     })
    }
    if (this.values.length>0) {
      this.form.cat_id = this.values[this.values.length - 1].toString();
    }
     console.log("分类id",this.form.cate_path)
    this.loading = true;
    console.log(this.validateForm.valid);
    if (!this.validateForm.valid) {
      for (const i in this.validateForm.controls) {
        this.validateForm.controls[i].markAsDirty();
        this.validateForm.controls[i].updateValueAndValidity();
      }
    } else {
      let form = {
        "goods": this.form,
        "image": this.image,
        "price": this.formData,
        "price_key": this.specNewArrId
      };
      if (this.addObj) {
        console.log(form)
        this.add(form);
      } else {
        this.edit(form);
        console.log(form)
      }
    }
  }
  close() {
    this.drawerRef.close();
  }
    //查询单条
    getData() {
      this.http
        .get(environment.SPAPPS_URL + '/backend/web/v1/sp-goods/' + this.editObj.item.goods_id)
        .subscribe(res => {
          console.log("getData",res);
          if (res.code == 1 && res.data.goods_price&&res.data.type) {
            // res.data.type.attr.forEach((ele)=>{
            //   let str = ele.attr_values.replace(/\s*/g, "")
            //   ele.attr_values = str.split(",")
            // })
            this.modeValue = res.data.type.type.id;
            this.selectType(this.modeValue)

            this.imgList = res.data.image;
            this.imgList.forEach((ele) => {
              this.image.push(ele.media_id)
            })
            this.cdr.detectChanges();
            console.log(this.imgList)
            console.log(this.image)
            // 最后显示的表格内容
            //  res.data.goods_price.forEach((ele) => {
            //   let item = {
            //     item_id:ele.item_id,
            //     goods_id:ele.goods_id,
            //   }
            //    this.pice.push(item)
            //  })
            //  this.formData = res.data.goods_price;
             // this.specNewArrId=res.data

                 // 选中的规格key
                //  res.data.goods_price.forEach((ele)=>{
                //   this.specNewArrId.push(ele.key)
                // })
          }
        });
    }
    //模型选择
    selectType(e) {
      console.log(e);
      this.form.goods_type=e
      this.arrs = [];
      this.specNewArr = [];
      this.specNewArrId = [];
      this.formData = [];
      this.specArr = [];
      this.specArrId = [];
      this.http
        .get(environment.SPAPPS_URL +'/backend/web/v1/sp-goods/goods-type?type_id=' +e+"&goods_id="+this.goodsId)
        .subscribe(res => {
          console.log("下拉选择的值",res);
          if (res.code == 1) {

            // res.data.type.attr.forEach((ele) => {
            //   for (let key in ele) {
            //     ele['statusValue'] = "";
            //   }
            //   let str = ele.attr_values.replace(/\s*/g, "")
            //   ele.attr_values = str.split(",")
            // })
            this.specList = res.data.type.spec || [];
                //选中的规格值
            this.specList.forEach((ele, index) => {
              this.dataId = index;
              ele.son.forEach((el) => {
                if (el.checked == true) {
                  const item = {
                    dataId: index,
                    name: el.item,
                    id: el.id,
                    checked: el.checked,
                  }
                  this.arrs.push(item)
                }
              })
            })
            console.log("arrs",this.arrs)
            console.log("模型选择", this.specList);
            // 最后显示的表格内容
            this.formData = res.data.goods_price
            // 选中的key
            res.data.goods_price.forEach((ele) => {
              this.specNewArrId.push(ele.key)
            })
          }
        });
    }

  checkSpec(dataId,name,id,checked) {
    console.log('checkSpec', dataId, name, id, checked)
    // if (dataId == this.specList.length - 1) {
    //   this.specFlag = true;
    //   console.log(this.specFlag)
    // }

    this.arrs.push({ dataId, name, id, checked });
    console.log("反转前",this.arrs)
    let arrs = [];
    arrs=arrs.concat(this.arrs).reverse()
    console.log("反转后", arrs)
    // 数组去重
    let hash = {}
    arrs=arrs.reduce((preVal, curVal) => {
      hash[curVal.id] ? '' : (hash[curVal.id] = true && preVal.push(curVal))
      return preVal
    }, [])
    console.log('去重后', arrs)
    dataId = this.dataId;
    for (let i = 0; i <= dataId; i++){
      this.specArr[i] = [];
      this.specArrId[i] = [];
      arrs.forEach((ele) => {
        if (ele.checked == true &&ele.dataId == i) {
          this.specArr[i].push(ele.name)
          this.specArrId[i].push(ele.id)
        }
      })
    }
    console.log("specArr", this.specArr)
    // 组成数据
    this.specNewArr = this.doExchange(this.specArr);
    this.specNewArrId=this.doExchange(this.specArrId)
    console.log("specNewArr", this.specNewArr)
    console.log("specNewArrId", this.specNewArrId);
    // 表格数据
    let formData = []
      this.specNewArr.forEach((element )=> {
        // if (this.specFlag == true) {
        const item = {
            key_name: element,
            price: "",
            cost_price: "",
            commission: "",
            store_count: "",
            sku: "",
          }
          formData.push(item)
        // }
      });
    this.formData = formData
    console.log(this.formData)
    this.formData.forEach((ele) => {
      ele.key_name=ele.key_name.split("_")
    })

  }


  // 获取下拉框数据
  getSelect() {
    this.http.get(environment.SPAPPS_URL + "/backend/web/v1/sp-goods/select").subscribe(res => {
      console.log("下拉数据",res);
      if (res.code == 1) {
        this.storeList = res.data.store;
        this.modeList = res.data.type_data;

      }
    })
  }


  //三级联动
  ChangesCate(value: any): void {
    // if (value.length>1) {
    //   console.log(value)
    //   this.form.cat_id= value[value.length - 1].toString();
    // }
  }
  plus() {
    console.log("puls")
    this.picArr.push({
      count: "",
      disPic:"",
    });
  }
  minus(i) {
    this.picArr.splice(i, 1);
  }
  checkImages() {
    this.modal.createStatic(ContentImagesComponent, { size: 'md' }).subscribe((res: any) => {
      this.form. goods_img = res.url;
      this.form.original_img = res.media_id;
    });
  }
//上传多个图片

handleChange(): void {
  this.modal.createStatic(ContentImagesComponent, { size: 'md' }).subscribe((res: any) => {
    this.imgList.push({
      goods_img: res.url,
      media_id: res.media_id,
    });
    this.image.push(res.media_id)
  });
}
  lookIcon(item) {
    this.isVisible = true;
    this.imgIconUrl=item.goods_img
  }
  delIcon(item, index) {
    console.log(item, index)
    this.imgList.forEach((ele,id) => {
      if (ele == item) {
        this.imgList.splice(id, 1)
        this.image.splice(id,1)
     }
    })
    console.log(this.imgList)
  }
     //组合数组(二维数组)
     doExchange (doubleArrays) {
      var len = doubleArrays.length;
      if (len >= 2) {
          var arr1 = doubleArrays[0];
          var arr2 = doubleArrays[1];
          var len1 = doubleArrays[0].length;
          var len2 = doubleArrays[1].length;
          var newlen = len1 * len2;
          var temp = new Array(newlen);
          var index = 0;
          for (var i = 0; i < len1; i++) {
            for (var j = 0; j < len2; j++) {
                  temp[index] = arr1[i] + "_" + arr2[j];
                  index++;
              }
          }
          // console.log(temp)//把每一列组合成一个字符串逗号隔开
          var newArray = new Array(len-1);
          newArray[0] = temp;
          if (len > 2) {
              var _count = 1;
            for (var i = 2; i < len; i++) {
                  newArray[_count] = doubleArrays[i];
                  _count++;
              }
          }
        console.log("11", newArray);
        return this.doExchange(newArray);
      }
      else {
          return doubleArrays[0];

      }
  }
}






