import { Component, OnInit, ViewChild } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { STColumn, STComponent } from '@delon/abc';
import { SFSchema } from '@delon/form';
import { environment } from '@env/environment';
import { CookieService } from "ngx-cookie-service";
import { read } from 'fs';
@Component({
  selector: 'app-admin-base',
  templateUrl: './base.component.html',
})
export class AdminBaseComponent implements OnInit {
  avatarUrl = ""
  procedures = {
    title: "",
    keyWord: "",
    des: "",
  }
  info = {
    pro_count:    0,
    content_count: 0,
    comment_count: 0,
    form_count:    0,
  }
  offlineChartData = [
        {x: 1565160400550, y1: 23, y2: 104},
        {x: 1565162200550, y1: 25, y2: 31},
        {x: 1565164000550, y1: 83, y2: 12},
        {x: 1565165800550, y1: 14, y2: 46},
        {x: 1565167600550, y1: 83, y2: 98},
        {x: 1565169400550, y1: 86, y2: 12},
        {x: 1565171200550, y1: 67, y2: 34},
        {x: 1565173000550, y1: 18, y2: 18},
        {x: 1565174800550, y1: 19, y2: 80},
        {x: 1565176600550, y1: 82, y2: 60},
        {x: 1565178400550, y1: 82, y2: 45},
        {x: 1565180200550, y1: 56, y2: 95},
        {x: 1565182000550, y1: 81, y2: 68},
        {x: 1565183800550, y1: 105, y2: 92},
        {x: 1565185600550, y1: 52, y2: 16},
        {x: 1565187400550, y1: 56, y2: 21},
        {x: 1565189200550, y1: 79, y2: 27},
        {x: 1565191000550, y1: 49, y2: 106},
        {x: 1565192800550, y1: 42, y2: 74},
        {x: 1565194600550, y1: 85, y2: 53}
  ];
  constructor(
    private cookies: CookieService,
    private http: _HttpClient,
    private modal: ModalHelper
  ) { }
  get sid() {
    return this.cookies.get('sid');
  }
  ngOnInit() {
    this.getData();
    this.getinfo();
  }
  getData() {
    this.http
      .get(environment.SPAPPS_URL + "/backend/web/v1/app-config/app-list?sid=" + this.sid)
      .subscribe(res => {
        this.procedures.title = res.data.instance_name
        this.procedures.keyWord = res.data.keyword,
          this.procedures.des = res.data.describe
        this.avatarUrl = res.data.media === "" ? "../../../../assets/image/erCode.jpg" : res.data.media
      });
  }
  getinfo() {
      this.http.get(environment.SPAPPS_URL + "/backend/web/v1/homepage/home?sid=" + this.sid).subscribe(res => {
      this.info.pro_count =res.data.pro_count
      this.info.content_count=res.data.content_count
      this.info.comment_count=res.data.comment_count
      this.info.form_count =res.data.form_count
    })
  }
}
