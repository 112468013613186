import { NgModule } from '@angular/core';
import { SharedModule } from '@shared';
import { RouteRoutingModule } from './routes-routing.module';
// dashboard pages
import { DashboardV1Component } from './dashboard/v1/v1.component';
import { DashboardAnalysisComponent } from './dashboard/analysis/analysis.component';
import { DashboardMonitorComponent } from './dashboard/monitor/monitor.component';
import { DashboardWorkplaceComponent } from './dashboard/workplace/workplace.component';
// passport pages
import { UserLoginComponent } from './passport/login/login.component';
import { UserRegisterComponent } from './passport/register/register.component';
import { UserRegisterResultComponent } from './passport/register-result/register-result.component';
// single pages
import { UserLockComponent } from './passport/lock/lock.component';
import { CallbackComponent } from './callback/callback.component';
//demo
import { DemoListComponent } from './demo/list/list.component';
import { DemoDemoComponent } from './demo/demo/demo.component';
import { DemoDemoEditComponent } from './demo/demo/edit/edit.component';
import { DemoAuthComponent } from './demo/auth/auth.component';
import { DemoAuthEditComponent } from './demo/auth/edit/edit.component';

//step
//import {StepStep1Component} from './step/step1/step1.component';
//import {StepStep2Component} from './step/step2/step2.component';

//sites
import { StepDefaultComponent } from './step/default/default.component';
import { StepStep1Component } from './step/step1/step1.component';
import { StepStep1AddComponent } from './step/step1/add/add.component';
import { StepStep1EditComponent } from './step/step1/edit/edit.component';
import { StepTemplatelistComponent } from './step/templatelist/templatelist.component';
import { StepTemplatelistBindsiteComponent } from './step/templatelist/bindsite/bindsite.component';
import { StepSitelistComponent } from './step/sitelist/sitelist.component';
import { StepSitelistEditComponent } from './step/sitelist/edit/edit.component';
import { StepSitelistAddComponent } from './step/sitelist/add/add.component';
import { StepResultComponent } from './step/result/result.component';
import { StepUserinfoBaseComponent } from './step/userinfo/base/base.component';
import { StepUserinfoComponent } from './step/userinfo/userinfo.component';
import { StepUserinfoSecurityComponent } from './step/userinfo/security/security.component';
import { StepVerifyComponent } from './step/verify/verify.component';
import { StepFeedbackComponent } from './step/feedback/feedback.component';
import { StepTemplatesComponent } from './step/templates/templates.component';
import { Exception403Component } from './exception/403.component';
import { Exception404Component } from './exception/404.component';
import { Exception500Component } from './exception/500.component';
import { StepUserinfoNotificationComponent } from './step/userinfo/notification/notification.component';
import { StepAuthorizeresultComponent } from './step/authorizeresult/authorizeresult.component';

//content

import { ContentProductgroupComponent } from './content/productgroup/productgroup.component';
import { ContentCategorylistComponent } from './content/categorylist/categorylist.component';
import { ContentNewslistComponent } from './content/newslist/newslist.component';
import { ContentNewslistEditComponent } from './content/newslist/edit/edit.component';
import { ContentParamslistComponent } from './content/paramslist/paramslist.component';
import { ContentParamslistEditComponent } from './content/paramslist/edit/edit.component';
import { ContentProlistComponent } from './content/prolist/prolist.component';
import { ContentProlistEditComponent } from './content/prolist/edit/edit.component';
import { ContentCategorylistAddComponent } from './content/categorylist/add/add.component';
import { ContentCategorylistEditComponent } from './content/categorylist/edit/edit.component';
import { ContentImagesComponent } from './content/images/images.component';
import { ContentImglistComponent } from './content/imglist/imglist.component';
import { ContentImglistEditComponent } from './content/imglist/edit/edit.component';
import { ContentImglistCategorylistComponent } from './content/imglist/categorylist/categorylist.component';
import { ContentImglistCategorylistEditComponent } from './content/imglist/categorylist/edit/edit.component';
import { ContentProlistCategorylistComponent } from './content/prolist/categorylist/categorylist.component';
import { ContentProlistCategorylistEditComponent } from './content/prolist/categorylist/edit/edit.component';
import { ContentProparamsComponent } from './content/proparams/proparams.component';
import { ContentProparamsEditComponent } from './content/proparams/edit/edit.component';
import { ContentBaseconfigComponent } from './content/baseconfig/baseconfig.component';

//feedback
import { FeedbackMessagelistComponent } from './feedback/messagelist/messagelist.component';
import { FeedbackMessagelistViewComponent } from './feedback/messagelist/view/view.component';
import { FeedbackCommentlistComponent } from './feedback/commentlist/commentlist.component';
import { FeedbackCommentlistViewComponent } from './feedback/commentlist/view/view.component';

// product

// ProductGoodsgroupComponent
import { ProductGoodsgroupComponent } from './product/goodsgroup/goodsgroup.component';
import { ProductProductlistComponent } from './product/productlist/productlist.component';
import { ProductProductlistEditComponent } from './product/productlist/edit/edit.component';
import { ProductProductcateComponent } from './product/productcate/productcate.component';
import { ProductProductcateEditComponent } from './product/productcate/edit/edit.component';
import { ProductProductbrandComponent } from './product/productbrand/productbrand.component';
import { ProductProductbrandEditComponent } from './product/productbrand/edit/edit.component';
import { ProductProductcommentComponent } from './product/productcomment/productcomment.component';
import { ProductProductcommentEditComponent } from './product/productcomment/edit/edit.component';
import { ProductStocklistComponent } from './product/stocklist/stocklist.component';
import { ProductProductmodelComponent } from './product/productmodel/productmodel.component';
import { ProductProductmodelEditComponent } from './product/productmodel/edit/edit.component';
import { ProductProductasklistComponent } from './product/productasklist/productasklist.component';
import { ProductProductasklistViewComponent } from './product/productasklist/view/view.component';
// order
import { OrderOrderlistComponent } from './order/orderlist/orderlist.component';
import { OrderOrderlistViewComponent } from './order/orderlist/view/view.component';
import { OrderOrderlistEditComponent } from './order/orderlist/edit/edit.component';
import { OrderDeliverylistComponent } from './order/deliverylist/deliverylist.component';
import { OrderDeliverylistViewComponent } from './order/deliverylist/view/view.component';
import { OrderRefundorderlistComponent } from './order/refundorderlist/refundorderlist.component';
import { OrderRefundorderlistViewComponent } from './order/refundorderlist/view/view.component';
// member
import { MemberMemberlistComponent } from './member/memberlist/memberlist.component';
import { MemberMemberlistEditComponent } from './member/memberlist/edit/edit.component';
import { MemberMemberlevelComponent } from './member/memberlevel/memberlevel.component';
import { MemberMemberlevelEditComponent } from './member/memberlevel/edit/edit.component';

//store
import { StoreStorelistComponent } from './store/storelist/storelist.component';
import { StoreStorelistEditComponent } from './store/storelist/edit/edit.component';

//tongji
import { StatisticsStatisbaseComponent } from './statistics/statisbase/statisbase.component';

//首页
import { AdminBaseComponent } from './admin/base/base.component';

const COMPONENTS = [
  DashboardV1Component,
  DashboardAnalysisComponent,
  DashboardMonitorComponent,
  DashboardWorkplaceComponent,
  // passport pages
  UserLoginComponent,
  UserRegisterComponent,
  UserRegisterResultComponent,
  // single pages
  UserLockComponent,
  CallbackComponent,
  //demo
  DemoListComponent,
  DemoDemoComponent,
  DemoDemoEditComponent,
  DemoAuthComponent,
  DemoAuthEditComponent,

  //sites
  StepDefaultComponent,
  StepStep1Component,
  StepStep1AddComponent,
  StepStep1EditComponent,
  StepTemplatelistComponent,
  StepTemplatelistBindsiteComponent,
  StepSitelistComponent,
  StepSitelistEditComponent,
  StepSitelistAddComponent,
  StepResultComponent,
  StepUserinfoComponent,
  StepUserinfoBaseComponent,
  StepUserinfoSecurityComponent,
  ContentImglistCategorylistEditComponent,
  AdminBaseComponent,
  StepVerifyComponent,
  StepFeedbackComponent,
  StepTemplatesComponent,
  Exception403Component,
  Exception404Component,
  Exception500Component,
  StepUserinfoNotificationComponent,
  StepAuthorizeresultComponent,
  //step
  //StepStep1Component,
  //StepStep2Component,

  //content
  ContentProductgroupComponent,
  ContentParamslistComponent,
  ContentParamslistEditComponent,
  ContentCategorylistComponent,
  ContentCategorylistAddComponent,
  ContentCategorylistEditComponent,
  ContentNewslistComponent,
  ContentNewslistEditComponent,
  ContentProlistComponent,
  ContentProlistEditComponent,
  ContentImagesComponent,
  ContentImglistComponent,
  ContentImglistEditComponent,
  ContentImglistCategorylistComponent,
  ContentProlistCategorylistComponent,
  ContentProlistCategorylistEditComponent,
  ContentProparamsComponent,
  ContentProparamsEditComponent,
  ContentBaseconfigComponent,

  //feedback
  FeedbackMessagelistComponent,
  FeedbackMessagelistViewComponent,
  FeedbackMessagelistViewComponent,
  FeedbackCommentlistComponent,
  FeedbackCommentlistViewComponent,

  // product
  ProductGoodsgroupComponent,
  ProductProductlistComponent,
  ProductProductlistEditComponent,
  ProductProductcateComponent,
  ProductProductcateEditComponent,
  ProductProductbrandComponent,
  ProductProductbrandEditComponent,
  ProductProductcommentComponent,
  ProductProductcommentEditComponent,
  ProductStocklistComponent,
  ProductProductmodelComponent,
  ProductProductmodelEditComponent,
  ProductProductasklistComponent,
  ProductProductasklistViewComponent,

  //order
  OrderOrderlistComponent,
  OrderOrderlistViewComponent,
  OrderOrderlistEditComponent,
  OrderDeliverylistComponent,
  OrderDeliverylistViewComponent,
  OrderRefundorderlistComponent,
  OrderRefundorderlistViewComponent,

  //member
  MemberMemberlistComponent,
  MemberMemberlistEditComponent,
  MemberMemberlevelComponent,
  MemberMemberlevelEditComponent,

  //store
  StoreStorelistComponent,
  StoreStorelistEditComponent,
  //tongji
  StatisticsStatisbaseComponent,
];
const COMPONENTS_NOROUNT = [];

@NgModule({
  imports: [SharedModule, RouteRoutingModule],
  declarations: [...COMPONENTS, ...COMPONENTS_NOROUNT],
  entryComponents: COMPONENTS_NOROUNT,
})
export class RoutesModule {}
