import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { NzDrawerService, NzModalService } from 'ng-zorro-antd';
import { ProductProductcateEditComponent } from './edit/edit.component';
import { environment } from '@env/environment';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-product-productcate',
  templateUrl: './productcate.component.html',
  styleUrls: ['./productcate.component.less'],
})
export class ProductProductcateComponent implements OnInit {
  islogin = true;
  listOfMapData = [];
  nodes = [];
  pageNumber = 1;
  pageSize = 10;
  total: any;
  searchKey: string = '';

  constructor(
    private http: _HttpClient,
    private modal: ModalHelper,
    private drawerService: NzDrawerService,
    private message: NzModalService,
    private cdr: ChangeDetectorRef,
    private cookies: CookieService,
  ) {}

  ngOnInit() {
    this.getData();
    this.getNode();
  }

  get sid() {
    return this.cookies.get('sid');
  }

  add() {
    this.drawerService
      .create<ProductProductcateEditComponent, { addObj: any }, string>({
        nzTitle: '添加分类',
        nzContent: ProductProductcateEditComponent,
        nzWidth: '60%',
        nzContentParams: {
          addObj: { nodes: this.nodes },
        },
      })
      .afterClose.subscribe(res => {
        this.getData();
        this.cdr.detectChanges();
      });
  }
  edit(data) {
    this.drawerService
      .create<ProductProductcateEditComponent, { editObj: any }, string>({
        nzTitle: '添加分类',
        nzContent: ProductProductcateEditComponent,
        nzWidth: '60%',
        nzContentParams: {
          editObj: { data: data, nodes: this.nodes },
        },
      })
      .afterClose.subscribe(res => {
        this.getData();
        this.cdr.detectChanges();
      });
  }
  delete(id) {
    console.log(id);
    this.message.confirm({
      nzTitle: '是否确认删除该信息',
      nzContent: '删除以后无法恢复，删除前请慎重考虑!',
      nzOnOk: () => {
        this.http.delete(environment.SPAPPS_URL + '/backend/web/v1/sp-goods-category/' + id).subscribe(res => {
          console.log(res);
        });
      },
    });
  }
  // 新增下架分类
  newCate() {}

  searchRest() {
    this. searchKey= '';
    this.getData();
  }

  getData() {
    this.islogin = false;
    this.http
      .get(environment.SPAPPS_URL + '/backend/web/v1/sp-goods-category?sid='+this.sid+'&idword=' + this.searchKey)
      .subscribe(res => {
        console.log('res', res.data.list);
        if (res.code == 1) {
          this.listOfMapData = res.data.list;
          this.listOfMapData.forEach(item => {
            this.mapOfExpandedData[item.id] = this.convertTreeToList(item);
          });
        }
      });
  }
  // 分类
  getNode() {
    this.http.get(environment.SPAPPS_URL + '/backend/web/v1/sp-goods-category/cate-all').subscribe(res => {
      console.log('node', res);
      if (res.code == 1) {
        for (let i = 0; i < 1; i++) {
          console.log('i', i);
          const nodeparent = {
            title: '顶级分类',
            key: 0,
            children: [],
          };
          res.data.forEach(element => {
            const nodeChild = {
              title: element.title,
              key: element.id,
              level: element.level,
              parent_id: element.parent_id,
              children: [],
            };
            if (element.children) {
              element.children.forEach(ele => {
                const son = {
                  title: ele.title,
                  key: ele.id,
                  level: ele.level,
                  parent_id: ele.parent_id,
                };
                nodeChild.children.push(son);
              });
              nodeparent.children.push(nodeChild);
            }
          });
          this.nodes.push(nodeparent);
        }
        console.log('child', this.nodes);
      }
    });
  }


  mapOfExpandedData = {};
  collapse(array, data, $event): void {
    if ($event === false) {
      if (data.children) {
        data.children.forEach(d => {
          const target = array.find(a => a.id === d.id)!;
          target.expand = false;
          this.collapse(array, target, false);
        });
      } else {
        return;
      }
    }
  }

  convertTreeToList(root: object) {
    const stack: any[] = [];
    const array: any[] = [];
    const hashMap = {};
    stack.push({ ...root, expand: false });

    while (stack.length !== 0) {
      const node = stack.pop();
      this.visitNode(node, hashMap, array);
      if (node.children) {
        for (let i = node.children.length - 1; i >= 0; i--) {
          stack.push({ ...node.children[i], expand: false, parent: node });
        }
      }
    }

    return array;
  }

  visitNode(node, hashMap, array): void {
    if (!hashMap[node.id]) {
      hashMap[node.id] = true;
      array.push(node);
    }
  }
}
