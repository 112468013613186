import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd';
import { _HttpClient } from '@delon/theme';
import { NzModalService } from 'ng-zorro-antd/modal';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { environment } from '@env/environment';
import { ChangeDetectorRef } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';


@Component({
  selector: 'app-product-productmodel-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.less'],
})
export class ProductProductmodelEditComponent implements OnInit {
  sendValue = '';
  validateForm: FormGroup;
  flag: boolean = true;
  message: string = "";
  addObj: any;
  editObj: any;
  form: any;
  initForm = {
    name: '',

  };
  //新增规格
  newSpace = [];
  //新增属性
  newStatus = [];
  constructor(
    private msgSrv: NzMessageService,
    public http: _HttpClient,
    private modalService: NzModalService,
    private fb: FormBuilder,
    private drawerRef: NzDrawerRef,
    private cdr: ChangeDetectorRef,
    private cookes: CookieService,
  ) {}
  get sid() {
    return this.cookes.get('sid');
  }
  ngOnInit(): void {
    this.validateForm = this.fb.group({
      modalName: [null, [Validators.required]],
      // name: [null, [Validators.required]],
      // item: [null, [Validators.required]],
      // order: [null, [Validators.required]],
      // attr_name: [null, [Validators.required]],
      // value: [null, [Validators.required]],
      // attr_order:[null, [Validators.required]]
    });
    if (this.addObj) {
      console.log(this.addObj);
      this.form = this.initForm;
    } else {
      this.getData();
      console.log(this.editObj);
      this.form = this.editObj.item;
    }
  }
  // 添加规格
  addSpace(e?: MouseEvent): void {
    if (e) {
      e.preventDefault();
    }
    // const id = this.newSpace.length > 0 ? this.newSpace[this.newSpace.length - 1].del + 1 : 1;
    const id=this.newSpace.length>0?this.newSpace.length+1:0
    console.log(id);
    const control = {
      del: id,
      name: '',
      order: '',
      son: [],
    };
    this.newSpace.push(control);
    this.cdr.detectChanges()
    console.log(this.newSpace)
  }
  deleteSpec(id, index): void {
    console.log(id,index)
    this.modalService.confirm({
      nzTitle: '是否确认删除该信息',
      nzContent: '删除以后无法恢复，删除前请慎重考虑!',
      nzOnOk: () => {
        if (id) {
          this.http.get(environment.SPAPPS_URL + "/backend/web/v1/sp-goods-type/spec-del?id=" + id).subscribe(res => {
            if (res.code == 1) {
              this.msgSrv.success("删除成功")
              this.getData()
            } else {
              this.msgSrv.error(res.message)
            }
          })
        } else {
          this.newSpace.splice(index,1)
        }
      }
    })
  }
  // 标签删除
  onClose(index, itemId, itemDel) {
    //  物理删除
    console.log('onclose', index, itemId, itemDel);
    let arr = this.newSpace[index].son;
    console.log(arr)
    this.newSpace[index].son.forEach((el,id) => {
      if (el.id && el.id == itemId) {
        this.http.get(environment.SPAPPS_URL + "/backend/web/v1/sp-goods-type/item-del?id=" + itemId).subscribe(res => {
          if (res.code == 1) {
            this.msgSrv.success("删除成功")
            this.getData();
          } else {
            this.msgSrv.error(res.message)
          }
        })
      } else {
        if (el.del && el.del === itemDel) {
          this.newSpace[index].son.splice(id,1)
        }
      }
    })
  }

  send(index) {
    console.log(index)
    const del=this.newSpace[index].son.length>0?this.newSpace[index].son.length+1:1
    this.newSpace[index].son.push({
      del:del,
      spec_id: this.newSpace[index].id,
      item: this.sendValue,
    })
    console.log(this.newSpace[index].son)
  }
  // 添加属性
  addStatus(e?: MouseEvent): void {
    if (e) {
      e.preventDefault();
    }
    // const id = this.newStatus.length > 0 ? this.newStatus[this.newStatus.length - 1].del + 1 : 1;
    const id=this.newStatus.length>0?this.newStatus.length+1:0
    console.log(id);
    const control2 = {
      del: id,
      attr_name: "",
      attr_values: "",
      order: "",
    };
    this.newStatus.push(control2);
    console.log(this.newStatus);
  }
  deleteSta(id,index) {
    console.log(id,index)
    this.modalService.confirm({
      nzTitle: '是否确认删除该信息',
      nzContent: '删除以后无法恢复，删除前请慎重考虑!',
      nzOnOk: () => {
        if (id) {
          this.http.get(environment.SPAPPS_URL + "/backend/web/v1/sp-goods-type/attr-del?attr_id=" + id).subscribe(res => {
            if (res.code == 1) {
              this.msgSrv.success("删除成功")
              this.getData()
            } else {
              this.msgSrv.error(res.message)
            }
          })
        } else {
          this.newStatus.splice(index,1)
        }

      }
    })
  }
  add(form) {
    this.http.post(environment.SPAPPS_URL + '/backend/web/v1/sp-goods-type', form).subscribe(res => {
      if (res.code !== 1) {
        this.msgSrv.error(res.message);
        return;
      }
      this.msgSrv.success("添加成功");
      this.drawerRef.close();
    });
  }
  edit(form) {
    this.http.put(environment.SPAPPS_URL + '/backend/web/v1/sp-goods-type/'+this.form.id, form).subscribe(res => {
      if (res.code !== 1) {
        this.msgSrv.error(res.message);
        return;
      }
      this.msgSrv.success(res.message);
      this.drawerRef.close();
    });
  }
  save() {
    this.getInspect()
    console.log(this.validateForm.valid,this.flag);
    if (!this.validateForm.valid) {
      for (const i in this.validateForm.controls) {
        this.validateForm.controls[i].markAsDirty();
        this.validateForm.controls[i].updateValueAndValidity();
      }
    } else if (!this.flag) {
      this.msgSrv.error(this.message);
     }else {
      let form = {
      "type": {
          "name": this.form.name,
          "sid": this.sid,
        },
      "spec":this.newSpace,
      "attr":this.newStatus,
      };
      if (this.addObj) {
        this.add(form);
      } else {
        this.edit(form);
      }
    }
  }

  close() {
    this.drawerRef.close();
  }
  getData() {
    this.http.get(environment.SPAPPS_URL + '/backend/web/v1/sp-goods-type/' + this.editObj.item.id).subscribe(res => {
      console.log(res);
      if (res.code == 1) {
        this.newSpace = res.data.spec;
        this.newStatus = res.data.attr;
      }
    });
  }
  getInspect() {
    this.flag = true;
    this.newSpace.forEach(ele => {
      if (ele.name == "") {
        this.flag = false;
        this.message="请填写规格名称"
        return ;
      } else if (ele.order == "") {
        this.flag = false;
        this.message = "请填写规格排序";
        return;
      }
      ele.son.forEach(el => {
        if (el.item == "") {
          this.flag = false;
          console.log("flag",this.flag)
          this.message="请填写规格值"
          return false;
        }
      })
    })
    this.newStatus.forEach(ele => {
      if (ele.attr_name == "") {
        this.flag = false;
        this.message="请填写属性名称";
        return;
      } else if (ele.order == "") {
        this.flag = false;
        this.message="请填写属性排序名称";
        return;
      }
    })
  }
}
