import { Component, OnInit, ViewChild } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { _HttpClient ,ModalHelper} from '@delon/theme';
import { areasList } from '../../../../../assets/plugins/area-china';
import { log, isArray } from 'util';
import { ContentImagesComponent } from '../../../content/images/images.component';
import { environment } from '@env/environment';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';


@Component({
  selector: 'app-store-storelist-edit',
  templateUrl: './edit.component.html',
})
export class StoreStorelistEditComponent implements OnInit {
  validateForm: FormGroup;
  loading = false;
  form :any;
  initform = {
    name: "",
    phone: "",
    startTime: "",
    endTime:"",
    province:"",
    city: "",
    area: "",
    detail: "",
    des:""
  }
  startTime= "";
  endTime= "";
  avatarUrl="";
  media_id ="";
  // 省市区联动
  areasList = areasList; // 地区总数据
  provinceList: any = [];
  cityList = [];
  areaList = [];
  city: any;
  area: any;
  selectProvinceObject;
  selectCityObject;

  // 多选框
  allChecked = false;
  indeterminate = true;
  checkOptionsOne = [
    { label: '周一', value: '周一', checked: true  },
    { label: '周二', value: '周二', checked: false },
    { label: '周三', value: '周三', checked: false },
    { label: '周四', value: '周四', checked: true  },
    { label: '周五', value: '周五', checked: false },
    { label: '周六', value: '周六', checked: false },
    { label: '周日', value: '周日', checked: false },
  ];
  addObj: any;
  editObj: any;

  constructor(
    private msgSrv: NzMessageService,
    public http: _HttpClient,
    private model:ModalHelper,
    private fb: FormBuilder,
    private drawerRef:NzDrawerRef,
  ) {}

  ngOnInit(): void {
    if (this.addObj) {
      this.form = this.initform;
    } else {
      this.form = this.editObj.item;
      this.avatarUrl=this.editObj.item.avatarUrl;
      this.media_id =this.editObj.item.media_id;
    }
    this.validateForm = this.fb.group({
      name: [null, [Validators.required]],
      phone:[null,[Validators.required]] ,
      startTime: [null,[Validators.required]],
      endTime: [null,[Validators.required]],
      province:[null,[Validators.required]],
      city: [null,[Validators.required]],
      area: [null,[Validators.required]],
      detail:[null,[Validators.required]],
      des:[null],
    })
       //获取所有的省
       this.areasList.map((pro, index) => {
        this.provinceList.push({
          value: pro.value,
          label: pro.label,
        });
      });
  }

  onOk(e) {
    this.form.startTime = this.getNowFormatDate(e[0])
    this.form.endTime = this.getNowFormatDate(e[1])
    console.log(this.startTime,this.endTime);
  }

  add(form) {
    this.http.post(environment.SPAPPS_URL + "", form).subscribe(res => {
      console.log(res)
      if (res.code == 1) {
        this.msgSrv.success("添加成功")
      } else {
        this.msgSrv.error(res.message)
      }
      this.loading = false;
      this.drawerRef.close();
    })
  }
  edit(form) {
    form['id'] = this.editObj.item.id;
    this.http.post(environment.SPAPPS_URL + "", form).subscribe(res => {
      if (res.code == 1) {
        this.msgSrv.success("修改成功")
      } else {
        this.msgSrv.error(res.message)
      }
      this.loading = false;
      this.drawerRef.close()
    })
  }

  save() {
    this.loading = true;
    console.log(this.validateForm.valid)
    if (!this.validateForm.valid) {
      for (const i in this.validateForm.controls) {
        this.validateForm.controls[i].markAsDirty();
        this.validateForm.controls[i].updateValueAndValidity();
      }
    } else {
      let form = {
        "name": this.form.name,
        "phone":this.form.phone,
        "startTime":this.form.startTime,
        "endTime":this.form.endTime,
        "province":this.form.province,
        "city":this.form.city,
        "area":this.form.area,
        "detail":this.form.detail,
        "des":this.form.des,
      }
      if (this.addObj) {
        this.add(form)
      } else {
        this.edit(form)
      }
    }

  }

  close() {
    this.drawerRef.close()
  }
  checkImages() {
    this.model.createStatic(ContentImagesComponent, { size: 'md' }).subscribe((res: any) => {
      console.log(res);
      this.avatarUrl = res.url;
      this.media_id = res.media_id;
    });
  }
  // 选择省
  changeProvince($event, e = 0) {
    this.form.province=$event
    console.log(this.form.province)
    if ($event) {
      this.areasList.map((pro, index) => {
        if (pro.label === $event) {
          this.selectProvinceObject = pro.children;
          this.cityList = [];
          this.areaList = [];
          this.city = null;
          this.area = null;
          if (pro.children.length > 0) {
            pro.children.map(city => {
              this.cityList.push({
                value: city.value,
                label: city.label,
              });
            });
          }
          return;
        }
      });
    }
  }
  // 选择市
  changeCity($event, e = 0) {
    console.log(this.form.city)
    if ($event) {
      this.selectProvinceObject.map((city, index) => {
        if (city.label === $event) {
          this.selectCityObject = city.children;
          this.areaList = [];
          this.area = null;
          if (city.children.length > 0) {
            city.children.map(dis => {
              this.areaList.push({
                value: dis.value,
                label: dis.label,
              });
            });
          }
          return;
        }
      });
    }
  }

  // 选择区县
  changeDistinct($event, e = 0) {
    console.log(this.form.area)
    if ($event) {
      if (isArray(this.selectCityObject)) {
        this.selectCityObject.map((dis, index) => {
          if (dis.label === $event) {
            this.selectCityObject = dis;
            return;
          }
        });
      }
    }
  }
  // 时间转换
  getNowFormatDate(date) {//获取当前时间
    var seperator1 = "-";
    var seperator2 = ":";
    var month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
    var strDate = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    var currentdate = date.getFullYear() + seperator1 + month + seperator1 + strDate
      + " " + date.getHours() + seperator2 + date.getMinutes()
      + seperator2 + date.getSeconds();
    return currentdate;

  }

  // 全选与反选
  updateAllChecked(): void {
    this.indeterminate = false;
    if (this.allChecked) {
      this.checkOptionsOne = this.checkOptionsOne.map(item => {
        return {
          ...item,
          checked: true
        };
      });
    } else {
      this.checkOptionsOne = this.checkOptionsOne.map(item => {
        return {
          ...item,
          checked: false
        };
      });
    }
    // console.log("allChecked", this.allChecked)
    // console.log("checkOptionsOne",this.checkOptionsOne)
  }

  updateSingleChecked(): void {
    if (this.checkOptionsOne.every(item => item.checked === false)) {
      this.allChecked = false;
      this.indeterminate = false;
    } else if (this.checkOptionsOne.every(item => item.checked === true)) {
      this.allChecked = true;
      this.indeterminate = false;
    } else {
      this.indeterminate = true;
    }
    // console.log("allChecked", this.allChecked)
    // console.log("checkOptionsOne",this.checkOptionsOne)
  }
}
