

import { Component, OnInit, ViewChild ,ChangeDetectionStrategy, SimpleChange,ChangeDetectorRef} from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd';
import { _HttpClient } from '@delon/theme';
import { FormBuilder, FormGroup, Validators, FormControl,ValidationErrors } from '@angular/forms';

import { environment } from '@env/environment';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-step-userinfo-security',
  templateUrl: './security.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepUserinfoSecurityComponent implements OnInit {
  info = {
    phone: "--",
    baiduCode: "--",
    qqCode: "--",
    weixinCode:"--"
  }
  phoneCode: any;
  status: any;
  titlename: any;
  showButtonText: any = "获取验证码";
  countDown = false
  num: number = 60;
  clearId: any;
  flag1: boolean = true;
  flag2: boolean = false;
  isVisible = false;
  userinfo: any = {};
  password = {
    oldPassword:"",
    newPassword:"",
    PasswordTwo:"",
  }
  //修改手机号
  phone = {
  phoneNumber: "",
  verification:"",
  }
  validateForm1: FormGroup;
  validateForm2: FormGroup;
  constructor(
    private msgSrv: NzMessageService,
    public http: _HttpClient,
    private formBuilder: FormBuilder,
    private cookie: CookieService,
    private msg: NzMessageService,
    private changeDetectorRef:ChangeDetectorRef,
  ) {}
  get sid() {
    return this.cookie.get('sid');
    }
  ngOnInit(): void {
    this.getBaseInfo();
    // 表单验证
    this.validateForm1 = this.formBuilder.group({
      oldpassword:["", [Validators.required]],
      newpassword: ["", [Validators.required]],
      checkPassword: ["", [Validators.required]],
    });

    this.validateForm2 = this.formBuilder.group({
       phone: [null, [Validators.required, Validators.pattern(/^1\d{10}$/)]],
       ver:[null,[Validators.required]]
    })

  }

  // 获取基本信息
  getBaseInfo() {
    this.http.get(environment.SPAPPS_URL + '/backend/web/v1/users/profile').subscribe((res: any) => {
      if (res.code !== 1) {
        this.msg.error(res.message);
        return;
      }
      console.log(res)
      this.userinfo = res.data;
      this.info.baiduCode=res.data.baidu||"--"
      this.info.qqCode=res.data.qq||"--"
      this.info.weixinCode=res.data.wechat||"--"
      let phone = res.data.phone;
      phone = "" + phone;
      this.phoneCode = phone.replace(phone.substring(3, 7), "****")
      this.changeDetectorRef.detectChanges()
    });
  }
// 修改密码弹框
  changePassword() {
    this.password = {
      oldPassword:"",
      newPassword:"",
      PasswordTwo:"",
    }
    this.status=1
    this.isVisible = true;
    this.titlename = "修改密码"
  }
  //修改密码
  savePassword() {
    console.log(this.validateForm1.valid);
    if (!this.validateForm1.valid) {
      for (const i in this.validateForm1.controls) {
        this.validateForm1.controls[i].markAsDirty();
        this.validateForm1.controls[i].updateValueAndValidity();
      }
    } else {
      this.http.post(environment.SPAPPS_URL + "/backend/web/v1/users/change-password", {
        "sid":this.sid,
        "ChangePassword": {
          "oldPassword": this.password.oldPassword,
          "newPassword": this.password.newPassword,
          "retypePassword":this.password.PasswordTwo,
        }
      }).subscribe(res => {
        if (res.code === 1) {
          this.msgSrv.success(res.message)
          this.validateForm1.reset();
          this.isVisible = false;
        } else {
          this.msgSrv.error(res.message)
          return;
        }
      })
    }
  }
  changePhone() {
    this.phone = {
      phoneNumber: "",
      verification:"",
    }
    this.status=2
    this.isVisible = true;
    this.titlename = "修改手机号"

    this.countDown = false;
    this.num = 60;
    this.showButtonText = "获取验证码"
  }
  // 获取验证码
  saveVerification() {
    this.http.post(environment.SPAPPS_URL + "/backend/web/v1/auth/loginCode", {
      "phone":this.phone.phoneNumber,
	    "sms_type":"sms_alter_config"
    }).subscribe(res => {
      if(res.code!==1){
        this.msgSrv.error(res.message)
      }
    })
  }
  // 修改手机号
  savePhone() {
    console.log("修改手机号", this.validateForm2.valid);
    if (!this.validateForm2.valid) {
      for (const i in this.validateForm2.controls) {
        this.validateForm2.controls[i].markAsDirty();
        this.validateForm2.controls[i].updateValueAndValidity();
      }
    } else {
      this.http.post(environment.SPAPPS_URL + "/backend/web/v1/users/changePhone", {
        "phone":this.phone.phoneNumber,
        "captcha":this.phone.verification
      }).subscribe(res => {
        if (res.code == 1) {
          this.msgSrv.success(res.message)
          this.validateForm1.reset();
          this.isVisible = false;
        }else{
          this.msgSrv.error(res.message)
        }
      })
    }
  }

  getVer() {
    this.saveVerification()
    this.countDown = true;
    this.showButtonText = "验证码已发送(" + this.num + "s)";
    this.clearId = setInterval(() => {
      if (this.num <= 0) {
        clearInterval(this.clearId);
        this.showButtonText = "重新发送";
        this.countDown = false;
        this.num = 60;
      } else {
        this.showButtonText = "验证码已发送(" + this.num + "s)";
        this.num--;
        this.changeDetectorRef.detectChanges()
      }
    },1000)
  }
  handleOk() {
    clearInterval(this.clearId)
    if (this.status == 1) {
        this.savePassword()
    } else if (this.status == 2) {
      this.savePhone()
   }else{
     return
    }
  }
  handleCancel(e) {
    this.isVisible = false
    clearInterval(this.clearId)
    if (this.status == 1) {
    this.validateForm1.reset();
    } else if (this.status == 2) {
      this.validateForm2.reset();
    }
  }

  confirmationValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    } else if (control.value !== this.validateForm1.controls.password.value) {
      return { confirm: true, error: true };
    }
    return {};
  };

  getCaptcha(e: MouseEvent): void {
    e.preventDefault();
  }
}
