import { Component, OnInit, ViewChild } from '@angular/core';
import { NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { _HttpClient } from '@delon/theme';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '@env/environment';


@Component({
  selector: 'app-member-memberlist-edit',
  templateUrl: './edit.component.html',
})
export class MemberMemberlistEditComponent implements OnInit {
  validateForm: FormGroup;
  loading = false;
  form = {
    nickname: "",
    password:"",
    phone: "",
    Email: "",
    QQ: "",
    radioValue:1,
  }

  constructor(
    private modal: NzModalRef,
    private msgSrv: NzMessageService,
    public http: _HttpClient,
    private fb:FormBuilder,
  ) {}

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      nickname: [null,[Validators.required]],
      password:[null,[Validators.required]],
      phone: [null,[Validators.required]],
      Email:[null,[Validators.required]],
      QQ:[null,[Validators.required]],
      value:[null,[Validators.required]],
   })
  }

  save(value: any) {
    this.loading = true;
    let form = {
      "nickname": this.form.nickname,
      "password":this.form.password,
      "phone": this.form.password,
      "Email": this.form.Email,
      "QQ": this.form.QQ,
      "radioValue":this.form.radioValue,
    }
    this.http.post(environment.SPAPPS_URL + "", form).subscribe(res => {
      console.log(res)
      if (res.code !== 1) {
        this.msgSrv.error(res.message)
      }
      this.msgSrv.success(res.message)
      this.loading = false;
      this.modal.close();
   })
  }

  close() {
    this.modal.destroy();
  }
}
