import { Component, OnInit, ViewChild,ChangeDetectorRef,ChangeDetectionStrategy } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { filter } from 'rxjs/operators';
// import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { NzMessageService,UploadFile, NzFormatEmitEvent,NzModalService, NzTreeNode } from 'ng-zorro-antd';
import { HttpClient, HttpRequest, HttpResponse } from '@angular/common/http';
import { environment } from '@env/environment';
import { CookieService } from "ngx-cookie-service";
import {ContentImglistEditComponent} from './edit/edit.component';

@Component({
  selector: 'app-content-imglist',
  templateUrl: './imglist.component.html',
  styleUrls:['./imglist.component.less'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentImglistComponent implements OnInit {
  loading = false;
  activedNode: NzTreeNode;
  picsize:string;
  title:string;
  nodes:any[] = [];
  list:any[]= [];
  count:any = 0;
  visible = false;
  uploading = false;
  fileList: UploadFile[] = [];
  categoryid = '0';
  searchKey = '';
  isVisible1 = false;
  showImgName:string;
  showImgUrl:string;
  modalWidth = 520;
  categoryName ='全部分类';
  

  get sid(){
    return this.cookies.get('sid');
  }

  constructor(
    private cookies: CookieService,
    private http: _HttpClient, 
    private msg: NzMessageService,
    private https: HttpClient,
    private modalService: NzModalService,
    //private cdr: ChangeDetectorRef,
    private modal: ModalHelper) { }

    beforeUpload = (file: UploadFile): boolean => {
      this.fileList = this.fileList.concat(file);
      return false;
    };

    handleUpload(): void {
      const formData = new FormData();
      // tslint:disable-next-line:no-any
      this.fileList.forEach((file: any) => {
        formData.append('image[]', file);
        formData.append('sid', this.sid);
      });
      this.uploading = true;
      // You can use any AJAX library you like
      const req = new HttpRequest('POST', environment.SPAPPS_URL + '/backend/web/v1/commons/upload-img', formData, {
        // reportProgress: true
      });
      this.https.request(req).pipe(filter(e => e instanceof HttpResponse)).subscribe(
          () => {
            this.uploading = false;
            this.fileList = [];
            this.msg.success('所有图片文件已上传成功.');
            this.getPicList(1);
            this.visible = false;
          },
          () => {
            this.uploading = false;
            this.msg.error('文件上传失败，请检查参数配置.');
          }
        );
    }

  ngOnInit() {
    this.getTreeData();
    this.getPicList(1);
    console.log(this.title);
   }

     // 获取左侧图片分类
  getTreeData() {
    this.http.get(environment.SPAPPS_URL + '/backend/web/v1/categories?symbol=pic_cate&sid='+this.sid).subscribe(res => {
      this.nodes = res.data;
    });
  }

  getPicList(page: number){
    this.loading = true;
      this.http.get(environment.SPAPPS_URL+'/backend/web/v1/medias?sid='+this.sid
      +'&category_id='+this.categoryid+'&name='+this.searchKey+'&page='+page+'&per-page=12'
      ).subscribe((res:any)=>{
          this.list = res.data.list;
          this.count = res.data.pagination.total;
          this.picsize = res.data.total_size;
          this.title = this.categoryName +" ("+ this.count +")";
          
      });
      this.loading = false;
      //this.cdr.detectChanges();
  }

  add() {
     this.visible = true;
  }

  edit(record:any){
    this.modal.createStatic(ContentImglistEditComponent,{record},{ size: 'md' }).subscribe((res:any)=>{
        this.getPicList(1);
    });
  }


  ///删除图片
  deleteItem(item:any){
    this.modalService.confirm({
      nzTitle: '<b>是否确定删除该图片?</b>',
      nzContent: '<p>删除该图片会导致使用到的该图片无法被加载，是否任要继续删除?</p>',
      nzOnOk: () => {
        this.http
          .post(environment.SPAPPS_URL + '/backend/web/v1/commons/del-pic',{
            media_id:item.media_id,
            sid:this.sid
          })
          .subscribe((res: any) => {
            console.log(res);
            if (res.code === 1) {
              this.msg.success('删除成功');
              this.getPicList(1);
            } else {
              this.msg.error(res.message);
            }
          });
      },
    });
  }

  ///点击分类，进行查询
  activeNode(data: NzFormatEmitEvent){
    this.activedNode = data.node!;
    this.categoryid = this.activedNode.key;
    this.categoryName = this.activedNode.origin.name;
    this.getPicList(1);
  }

  searchList(){
     this.getPicList(1);
  }

  close(){
    this.fileList = [];
    this.visible = false;
  }

  getImgView(data:any){
    this.showImgName = data.name;
    this.showImgUrl = data.url;
    this.modalWidth = data.width + 48 > 800 ? 800:data.width+48;
    console.log(data);
    this.isVisible1 = true;
  }

  handleAfterClose():void{
    this.showImgName = '';
    this.showImgUrl = '';
  }

  handleCancel(): void {
    this.isVisible1 = false;
  }

  handleData = (file: UploadFile) => {
    return {
      // media_id: this.media_id,
      sid: this.sid,
    };
  };

  // handleChange({ file, fileList }: { [key: string]: any }): void {
  //   const status = file.status;
  //   if (status !== 'uploading') {
  //     console.log(file, fileList);
  //   }
  //   if (status === 'done') {
  //     this.msg.success(`${file.name} 图片文件已上传成功. `);
  //   } else if (status === 'error') {
  //     this.msg.error(`${file.name} 图片文件上传失败.`);
  //   }
  // }

}
