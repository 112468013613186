import {ChangeDetectionStrategy,ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { STColumn, STComponent,STRes,STPage } from '@delon/abc';
import { SFSchema } from '@delon/form';
import {DemoDemoEditComponent} from "./edit/edit.component";
import {forEach} from "@angular/router/src/utils/collection";
import {element} from "@angular/core/src/render3";
import { NzMessageService,NzModalService } from 'ng-zorro-antd';
import {subscribeOn} from "rxjs/internal/operators";
import {environment} from "@env/environment";

import { CookieService } from "ngx-cookie-service";


@Component({
  selector: 'app-demo-demo',
  templateUrl: './demo.component.html',
  styleUrls: ['./demo.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DemoDemoComponent implements OnInit {

  time:number;
  sid:any;
  url ='https://api.spapps.cn/backend/web/v1/demo';
  // searchSchema: SFSchema = {
  //   properties: {
  //     no: {
  //       type: 'string',
  //       title: '菜单名称'
  //     },
  //     status:{
  //       type:'string',
  //       title: '菜单状态',
  //       enum:[
  //         {label:'启用',value:'1'},
  //         {label:'禁用',value:'0'}
  //         ],
  //       ui: {
  //         spanLabelFixed: 200,
  //         // grid: { span: 24 },
  //       },
  //     },
  //   }
  // };
  q: any = {
    status: null,
    text: '',
    statusList: [],
  };
  status = [
    { index: 2, text: '全部', value: '2', type: 'default', checked: false },
    { index: 1, text: '启用', value: '1', type: 'success', checked: false },
    { index: 0, text: '禁用', value: '0', type: 'error', checked: false },
  ];

  data: any[] = [];
  loading = false;


  statusList = this.status.filter(w => w.checked).map(item => item.index);

  //定义返回的参数
  page: STPage = {
     front:false,
     total:'',
     show:false
  };
  @ViewChild('st')
  st: STComponent;
  columns: STColumn[] = [
    { title: '菜单名称', index: 'text_name',width:'50%'},
    // { title: '状态', type: 'number', index: 'status',width:'10%',format:(item:any)=>(item.status == 1 ? '<label class="text-green">启用</label>':'<label class="text-red">禁用</label>') },
    {
      title: '状态',
      index: 'status',
      render: 'status',
      width:'10%',
      filter: {
        menus: this.status,
        fn: (filter: any, record: any) => record.status === filter.index,
      },
    },
    { title: '更新时间', type: 'date',index: 'updated_at', width: '20%' },
    {
      title: '操作',width:'20%',
      buttons: [
        //{ text: '查看', click: (item: any) => `/form/${item.id}` },
        //{ text: '编辑', type: 'static', click: 'reload' },
        { text: '编辑', click: (item: any) => this.edit( item ) },
        { text: '删除', click: (item: any) => this.delete(item.id) },
      ]
    }
  ];

  constructor(
    private cookies: CookieService,
    private http: _HttpClient,
    private modal: ModalHelper,
    private cdr: ChangeDetectorRef,
    public msgSrv: NzMessageService,
    public modalConfirm: NzModalService
  ) { }

  ngOnInit() { 

    // this.time = 2*60*60*1000;// cookie过期时间两个小时 2*60*60*1000
    // this.sid = 454;
    // this.cookies.set("sid",this.sid,new Date(new Date().getTime() + this.time));
  }




  add() {
    this.modal
      .createStatic(DemoDemoEditComponent, { i: { id: 0 } })
      .subscribe(() => this.st.reload());
  }

  //重置
  reset(value) {
    console.log('reset')
  }

  //搜索
  search(value) {
    this.loading = true;
    let that = this;
    console.log('search');
    console.log(this.q);
    this.http.post(environment.SPAPPS_URL + '/backend/web/v1/menu/qmenu', {data: JSON.stringify(this.q)}).subscribe(
      res => {
        console.log(res);
        this.loading = false;
      }
    );

  }


  //新增
  openEdit(record: any = {}) {
    let that = this;
    this.modal.create(DemoDemoEditComponent, { record}, { size: 'md' }).subscribe(res => {
      console.log(res);
      that.st.reload();
    });
  }

  //编辑
  edit(record: any) {
    // console.log(value);
    let that = this;
    that.modal.create(DemoDemoEditComponent, { record }, { size: 'md' }).subscribe(res => {
      that.st.reload();
    });
  }


  //删除
  delete(value: any) {
    console.log('delete', value);
    let that = this;
    this.modalConfirm.confirm({
        nzTitle:'操作提醒',
        nzContent: '是否确定要删除该数据内容，删除以后无法恢复。',
        nzOnOk: function () {
          that.http.post(environment.SPAPPS_URL + '/backend/web/v1/menu/delmenu', {id: value})
            .subscribe((res: any) => {
              if(res.code == 1){
                that.msgSrv.success('删除成功！');
              }else{
                that.msgSrv.error(res.message);
              }
              that.st.reload()
            })
          }
        })
    }
}
