import { _HttpClient, ModalHelper } from '@delon/theme';
import { environment } from '@env/environment';
import { ContentProlistCategorylistEditComponent } from './edit/edit.component';
import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NzMessageService, NzModalService, UploadFile, NzDrawerRef, NzDrawerService } from 'ng-zorro-antd';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
export interface TreeNodeInterface {
  key: number;
  name: string;
  expand: boolean;
  children?: TreeNodeInterface[];
}
@Component({
  selector: 'app-content-prolist-categorylist',
  templateUrl: './categorylist.component.html',
})
export class ContentProlistCategorylistComponent implements OnInit {
  nodes: any = [];

  validateForm: FormGroup;
  editData: any = {};
  avatarUrl: string;
  picUrl = '';
  loading = false;
  media_id = 0;
  constructor(
    private cookies: CookieService,
    private http: _HttpClient,
    private modal: ModalHelper,
    private modalService: NzModalService,
    private message: NzMessageService,
    private msgSrv: NzMessageService,
    private formBuilder: FormBuilder,
    private msg: NzMessageService,
    private cdr: ChangeDetectorRef,
    private drawerService: NzDrawerService, // private drawerRef: NzDrawerRef<string>,
  ) {}

  mapOfExpandedData: { [key: string]: TreeNodeInterface[] } = {};
  collapse(array: TreeNodeInterface[], data: TreeNodeInterface, $event: boolean): void {
    if ($event === false) {
      if (data.children) {
        data.children.forEach(d => {
          const target = array.find(a => a.key === d.key)!;
          target.expand = true;
          this.collapse(array, target, false);
        });
      } else {
        return;
      }
    }
  }
  convertTreeToList(root: object): TreeNodeInterface[] {
    const stack: any[] = [];
    const array: any[] = [];
    const hashMap = {};
    stack.push({ ...root, level: 0, expand: true });

    while (stack.length !== 0) {
      const node = stack.pop();
      this.visitNode(node, hashMap, array);
      if (node.children) {
        for (let i = node.children.length - 1; i >= 0; i--) {
          stack.push({ ...node.children[i], level: node.level + 1, expand: true, parent: node });
        }
      }
    }
    return array;
  }
  visitNode(node: TreeNodeInterface, hashMap: { [key: string]: any }, array: TreeNodeInterface[]): void {
    if (!hashMap[node.key]) {
      hashMap[node.key] = true;
      array.push(node);
    }
  }

  ngOnInit() {
    this.getTreeData();
  }

  get sid() {
    return this.cookies.get('sid');
  }

  add() {
    this.drawerService
      .create<ContentProlistCategorylistEditComponent, { addObj: any }, string>({
        nzTitle: '添加产品分类',
        nzContent: ContentProlistCategorylistEditComponent,
        nzWidth: 800,
        nzContentParams: {
          addObj: {
            cateList: this.nodes,
          },
        },
      })
      .afterClose.subscribe(() => {
        this.getTreeData();
      });
  }

  editClick(item) {
    console.log(item);
    this.drawerService
      .create<ContentProlistCategorylistEditComponent, { editObj: any }, string>({
        nzTitle: '编辑产品分类',
        nzContent: ContentProlistCategorylistEditComponent,
        nzWidth: 600,
        nzContentParams: {
          editObj: {
            item: item,
            cateList: this.nodes,
          },
        },
      })
      .afterClose.subscribe(() => {
        this.getTreeData();
      });
  }

  deleteClick(id) {
    this.modalService.confirm({
      nzTitle: '<b>是否确定删除该分类信息?</b>',
      nzContent: '<p>删除以后无法恢复，删除前请慎重考虑!</p>',
      nzOnOk: () => {
        this.http
          .post(environment.SPAPPS_URL + '/backend/web/v1/productcate/deleteproductcate', { id })
          .subscribe((res: any) => {
            console.log(res);
            if (res.code === 1) {
              this.message.success('删除成功');
              this.getTreeData();
            } else {
              this.message.error(res.message);
            }
          });
      },
    });
  }

  getTreeData() {
    this.http
      .get(environment.SPAPPS_URL + '/backend/web/v1/productcate/listproductcate?sid=' + this.sid)
      .subscribe(res => {
        console.log('gettree', res);
        this.nodes = res.data.list;
        this.nodes.forEach(item => {
          this.mapOfExpandedData[item.key] = this.convertTreeToList(item);
        });
        this.cdr.detectChanges();
      });
  }
}
