import { Component, OnInit, ViewChild } from '@angular/core';
import { NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { _HttpClient } from '@delon/theme';
import { FormGroup, Validators,FormBuilder} from '@angular/forms';
import { environment } from '@env/environment';
import { CookieService } from 'ngx-cookie-service';
import { RouterModule, Routes ,Router} from '@angular/router';

@Component({
  selector: 'app-step-feedback',
  templateUrl: './feedback.component.html',
})
export class StepFeedbackComponent implements OnInit {
  user: any;
  form={
    content: "",
    type:"",
  }
  validateForm: FormGroup;
  constructor(
    private modal: NzModalRef,
    private msgSrv: NzMessageService,
    public http: _HttpClient,
    private cookies: CookieService,
    private formBuilder: FormBuilder,
    private router: Router,
  ) {}

  ngOnInit(): void {
    console.log(this.user)
    this.validateForm = this.formBuilder.group({
      content: [null, [Validators.required]],
      type:[null,[Validators.required]],
    });
  }

  submitForm() {
    // 提交表单要传送的数据
    if (!this.validateForm.valid) {
      for (const i in this.validateForm.controls)
      {
        this.validateForm.controls[i].markAsDirty();
        this.validateForm.controls[i].updateValueAndValidity();
      }
    }
    else {
      // 提交表单要传送的数据
      this.http.post(environment.SPAPPS_URL + "/backend/web/v1/notice/feedback", {
        "id": this.user.userId,
        "sid": this.user.sid,
        "title":this.form.type,
        "content": this.form.content,

      }).subscribe((res: any) => {
        if(res.code==1){
          this.msgSrv.success(res.message)
        } else {
          this.msgSrv.error(res.message)
        }
        this.modal.destroy();
      })
    }
  }

  cacle() {
    this.modal.destroy();
  }
}
