import { Component, OnInit, ViewChild } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NzDrawerRef } from 'ng-zorro-antd';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ContentImagesComponent } from '../../../content/images/images.component';
import { environment } from '@env/environment';
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'app-product-productcate-edit',
  templateUrl: './edit.component.html',
})
export class ProductProductcateEditComponent implements OnInit {
  validateForm: FormGroup;
  loging = false;
  nodes:any;
  level = 0;
  cateImg = '';
  form: any;
  initForm = {
    name: '',
    parent_id: '',
    cateImg: '',
    cateMedia: '',
    list_order: '',
    commission_rate: '',
  };
  addObj: any;
  editObj: any;
  get sid() {
    return this.cookie.get('sid');
  }
  constructor(
    private http: _HttpClient,
    private modal: ModalHelper,
    private fb: FormBuilder,
    private cookie: CookieService,
    private drawerRef: NzDrawerRef,
    private msg: NzMessageService,
  ) {}

  ngOnInit() {
    if (this.addObj) {
      console.log(this.addObj.add);
      this.form = this.initForm;
      this.nodes = this.addObj.nodes;
    } else {
      this.form = this.editObj.data;
      this.nodes = this.editObj.nodes;
      console.log('edit', this.form);
      console.log('nodes', this.nodes);
      this.form.cateImg = this.editObj.data.pic_url;
      this.form.cateMedia = this.editObj.image;
    }
    this.validateForm = this.fb.group({
      catename: [null, [Validators.required]],
      cateparent: [null],
      sort: [null, [Validators.required]],
      proportion: [null, [Validators.required]],
    });
  }
  add(form) {
    this.http.post(environment.SPAPPS_URL + '/backend/web/v1/sp-goods-category', form).subscribe((res: any) => {
      if (res.code !== 1) {
        this.msg.error(res.message);
        return;
      }
      this.loging = false;
      this.msg.success(res.message);
      this.drawerRef.close();
    });
  }
  edit(form) {
    // form['id'] = this.form.id; //修改字段特有的属性
    this.http
      .put(environment.SPAPPS_URL + '/backend/web/v1/sp-goods-category/' + this.form.id, form)
      .subscribe((res: any) => {
        if (res.code !== 1) {
          this.msg.error(res.message);
          return;
        }
        this.loging = false;
        this.msg.success(res.message);
        this.drawerRef.close();
      });
  }
  save() {
    this.loging = true;
    console.log(this.validateForm.valid);
    if (!this.validateForm.valid) {
      for (const i in this.validateForm.controls) {
        this.validateForm.controls[i].markAsDirty();
        this.validateForm.controls[i].updateValueAndValidity();
      }
    } else {
      let form = {
        name: this.form.name,
        mobile_name: this.form.name,
        parent_id: this.form.parent_id,
        level: this.form.level,
        image: this.form.cateMedia,
        commission_rate: this.form.commission_rate,
      };
      if (this.addObj) {
        console.log(form);
        this.add(form);
      } else {
        console.log(form);
        this.edit(form);
      }
    }
  }
  close() {
    this.drawerRef.close();
  }

  checkImages() {
    this.modal.createStatic(ContentImagesComponent, { size: 'md' }).subscribe((res: any) => {
      this.form.cateImg = res.url;
      this.form.cateMedia = res.media_id;
    });
  }
}
