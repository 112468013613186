import { Component, OnInit,ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { STColumn, STComponent } from '@delon/abc';
import { SFSchema } from '@delon/form';
import { NzDrawerService, NzModalService } from 'ng-zorro-antd';
import { environment } from '@env/environment';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-content-productgroup',
  templateUrl: './productgroup.component.html',
})
export class ContentProductgroupComponent implements OnInit {

  url = `/user`;
  loading = true;
  list: any[] = [];
  total = 0; 

  searchSchema: SFSchema = {
    properties: {
      no: {
        type: 'string',
        title: '编号'
      },
      name: {
        type: 'string',
        title: '名称'
      },
      status: {
        type: 'string',
        title: '状态'
      },
    }
  };
  @ViewChild('st') st: STComponent;
  columns: STColumn[] = [
    { title: '编号', index: 'no' },
    { title: '名称', index: 'name' },
    { title: '状态', index: 'status' },
    { title: '排序', type: 'number', index: 'sort' },
    {
      title: '',
      buttons: [
        // { text: '查看', click: (item: any) => `/form/${item.id}` },
        // { text: '编辑', type: 'static', component: ProductGoodsgroupEditComponent, click: 'reload' },
      ]
    }
  ];

  constructor(
    private http: _HttpClient,
     private modal: ModalHelper,
     private message: NzModalService,
     private cookies: CookieService,
     private cdr: ChangeDetectorRef
    ) { }
  get sid() {
    return this.cookies.get('sid');
  }
  add() {

  }
  edit(data) {

  }
  delete(id) {
    console.log(id);
    this.message.confirm({
      nzTitle: '是否确认删除该信息',
      nzContent: '删除以后无法恢复，删除前请慎重考虑!',
      nzOnOk: () => {
        this.http.delete(environment.SPAPPS_URL + '/backend/web/v1/sp-goods-category/' + id).subscribe(res => {
          console.log(res);
        });
      },
    });
  }
  ngOnInit() {
    this.getData();
    // this.getNode();
   }
   getData() {
    this.loading = true;
    this.http.get(environment.SPAPPS_URL+'/backend/web/v1/product-group?sid='+this.sid,{})
      .subscribe((res: any) => {
        this.list = res.data.list;
        this.total = res.data.pagination.total;
       this.loading = false;
       this.cdr.detectChanges();

    });
  }


}
