import { Component, Inject, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { SettingsService } from '@delon/theme';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { CookieService } from "ngx-cookie-service";
@Component({
  selector: 'header-user',
  template: `
    <nz-dropdown nzPlacement="bottomRight">
      <div class="alain-default__nav-item d-flex align-items-center px-sm" nz-dropdown>
        <nz-avatar [nzSrc]="settings.user.avatar" nzSize="small" class="mr-sm"></nz-avatar>
        {{ settings.user.name }}
      </div>
      <div nz-menu class="width-sm">
        <div nz-menu-item routerLink="/sites/setting/base">
          <i nz-icon nzType="user" class="mr-sm"></i>
          {{ 'menu.account.center' | translate }}
        </div>
        <div nz-menu-item routerLink="/sites/list">
          <i nz-icon nzType="setting" class="mr-sm"></i>
           站点管理
        </div>

        <li nz-menu-divider></li>
        <div nz-menu-item (click)="logout()">
          <i nz-icon nzType="logout" class="mr-sm"></i>
          {{ 'menu.account.logout' | translate }}
        </div>
      </div>
    </nz-dropdown>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderUserComponent {
  constructor(
    private cookies: CookieService,
    public settings: SettingsService,
    private router: Router,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
  ) {}

  logout() {
    this.tokenService.clear();
    this.cookies.delete('sid','/','smartprogram.spapps.cn');
    this.cookies.delete('sid','/','.spapps.cn');
    this.cookies.delete('sid','/','localhost');
    this.cookies.delete('sid');
    this.router.navigateByUrl(this.tokenService.login_url!);
  }
}
// <div nz-menu-item routerLink="/sites/list">
// <i nz-icon nzType="close-circle" class="mr-sm"></i>
//  错误日志
// </div>
