import { _HttpClient, ModalHelper } from '@delon/theme';
import { STColumn, STComponent } from '@delon/abc';
import { environment } from '@env/environment';
import { ContentCategorylistAddComponent } from './add/add.component';
import { ContentCategorylistEditComponent } from './edit/edit.component';
import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { SFSchema, SFUISchema } from '@delon/form';
import { Observable, Observer } from 'rxjs/index';
import { NzModalRef, NzMessageService, NzModalService, UploadFile, NzDrawerRef, NzDrawerService } from 'ng-zorro-antd';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
export interface TreeNodeInterface {
  key: number;
  name: string;
  expand: boolean;
  children?: TreeNodeInterface[];
}
@Component({
  selector: 'app-content-categorylist',
  templateUrl: './categorylist.component.html',
})
export class ContentCategorylistComponent implements OnInit {
  nodes: any = [];
  // ---------弹框数据----------
  validateForm: FormGroup;
  editData: any = {};
  avatarUrl: string;
  picUrl = '';
  loading = false;
  media_id = 0;
  isVisible = false;

  constructor(
    private cookies: CookieService,
    private http: _HttpClient,
    private modal: ModalHelper,
    private modalService: NzModalService,
    private message: NzMessageService,
    private msgSrv: NzMessageService,
    private formBuilder: FormBuilder,
    private msg: NzMessageService,
    private cdr: ChangeDetectorRef,
    private drawerService: NzDrawerService, // private drawerRef: NzDrawerRef<string>,
  ) {}

  mapOfExpandedData: { [key: string]: TreeNodeInterface[] } = {};
  collapse(array: TreeNodeInterface[], data: TreeNodeInterface, $event: boolean): void {
    if ($event === false) {
      if (data.children) {
        data.children.forEach(d => {
          const target = array.find(a => a.key === d.key)!;
          target.expand = true;
          this.collapse(array, target, false);
        });
      } else {
        return;
      }
    }
  }
  convertTreeToList(root: object): TreeNodeInterface[] {
    const stack: any[] = [];
    const array: any[] = [];
    const hashMap = {};
    stack.push({ ...root, level: 0, expand: true });

    while (stack.length !== 0) {
      const node = stack.pop();
      this.visitNode(node, hashMap, array);
      if (node.children) {
        for (let i = node.children.length - 1; i >= 0; i--) {
          stack.push({ ...node.children[i], level: node.level + 1, expand: true, parent: node });
        }
      }
    }
    return array;
  }
  visitNode(node: TreeNodeInterface, hashMap: { [key: string]: any }, array: TreeNodeInterface[]): void {
    if (!hashMap[node.key]) {
      hashMap[node.key] = true;
      array.push(node);
    }
  }

  ngOnInit() {
    this.getTreeData();

    // // 上传图片
    // this.picUrl = environment.SPAPPS_URL + '/backend/web/v1/commons/upload-pic';
    // // 表单验证
    // this.validateForm = this.formBuilder.group({
    //   parent: [null, [Validators.required]],
    //   name: [null, [Validators.required]],
    //   second_name: [null],
    //   icon: [null],
    //   link: [null],
    //   status: ['1', [Validators.required]],
    //   type: [null],
    //   sort: [0, [Validators.required]],
    // });
  }

  // 获取表单数据
  get parent() {
    return this.validateForm.controls.parent;
  }
  get name() {
    return this.validateForm.controls.name;
  }
  get second_name() {
    return this.validateForm.controls.name;
  }
  get icon() {
    return this.validateForm.controls.icon;
  }
  get link() {
    return this.validateForm.controls.link;
  }
  get status() {
    return this.validateForm.controls.status;
  }
  get type() {
    return this.validateForm.controls.type;
  }
  get sort() {
    return this.validateForm.controls.sort;
  }
  get sid() {
    return this.cookies.get('sid');
  }

  add() {
    const drawerRef = this.drawerService.create<ContentCategorylistAddComponent, { nodes: any }, string>({
      nzTitle: '添加文章分类',
      nzContent: ContentCategorylistAddComponent,
      nzWidth: 800,
      nzContentParams: {
        nodes: this.nodes,
      },
    });
    drawerRef.afterClose.subscribe(() => {
      this.getTreeData();
    });
  }

  editClick(editData) {
    console.log(editData);
    this.drawerService
      .create<ContentCategorylistEditComponent, { editData: any }, string>({
        nzTitle: '编辑文章分类',
        nzContent: ContentCategorylistEditComponent,
        nzWidth: 800,
        nzContentParams: {
          editData: editData,
        },
      })
      .afterClose.subscribe(() => {
        this.getTreeData();
      });
  }

  deleteClick(id) {
    this.modalService.confirm({
      nzTitle: '<b>是否确定删除该分类信息?</b>',
      nzContent: '<p>删除以后无法恢复，删除前请慎重考虑!</p>',
      nzOnOk: () => {
        this.http
          .post(environment.SPAPPS_URL + '/backend/web/v1/contentcate/deletecontentcate', { id })
          .subscribe((res: any) => {
            console.log(res);
            if (res.code === 1) {
              this.message.success('删除成功');
              this.getTreeData();
            } else {
              this.message.error(res.message);
            }
          });
      },
    });
  }

  getTreeData() {
    this.http
      .get(environment.SPAPPS_URL + '/backend/web/v1/contentcate/listcontentcate?sid=' + this.sid)
      .subscribe(res => {
        // console.log(res);
        this.nodes = res.data.list;
        this.nodes.forEach(item => {
          this.mapOfExpandedData[item.key] = this.convertTreeToList(item);
        });
        this.cdr.detectChanges();
      });
  }

  // 图片上传前处理
  beforeUpload = (file: File) => {
    return new Observable((observer: Observer<boolean>) => {
      // const isJPG = file.type === 'image/jpeg';
      const isJPG =
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/jpg' ||
        file.type === 'image/bmp';
      if (!isJPG) {
        this.msg.error('仅支持上传jpg、jpeg、png、bmp 格式图片文件!');
        observer.complete();
        return;
      }
      const isLt2M = file.size / 1024 / 1024 / 1024 < 200;
      if (!isLt2M) {
        this.msg.error('上传图标文件必须小于20k，请压缩以后再上传 !');
        observer.complete();
        return;
      }
      // check height
      this.checkImageDimension(file).then(dimensionRes => {
        if (!dimensionRes) {
          this.msg.error('上传的封面图文件宽小于200像素，高度小于200像素 !');
          observer.complete();
          return;
        }

        observer.next(isJPG && isLt2M && dimensionRes);
        observer.complete();
      });
    });
  };

  private getBase64(img: File, callback: (img: string) => void): void {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result!.toString()));
    reader.readAsDataURL(img);
  }

  private checkImageDimension(file: File): Promise<boolean> {
    return new Promise(resolve => {
      const img = new Image(); // create image
      img.src = window.URL.createObjectURL(file);
      img.onload = () => {
        const width = img.naturalWidth;
        const height = img.naturalHeight;
        window.URL.revokeObjectURL(img.src!);
        resolve(width <= 200 && height <= 200);
      };
    });
  }

  //
  handleChange(info: { file: UploadFile }): void {
    console.log(info);
    switch (info.file.status) {
      case 'uploading':
        this.loading = true;
        break;
      case 'done':
        this.getBase64(info.file!.originFileObj!, (img: string) => {
          this.loading = false;
          this.avatarUrl = img;
          this.media_id = info.file.response.data.media_id;
        });
        break;
      case 'error':
        this.msg.error('图片上传失败，系统未知错误，请联系客服进行反馈!');
        this.loading = false;
        break;
    }
  }

  handleData = (file: UploadFile) => {
    return {
      media_id: this.media_id,
      sid: this.sid,
    };
  };
}
