import { Component, OnInit } from '@angular/core';
import { NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { _HttpClient } from '@delon/theme';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from "@env/environment";
import { CookieService } from "ngx-cookie-service";

@Component({
  selector: 'app-content-paramslist-edit',
  templateUrl: './edit.component.html',
})
export class ContentParamslistEditComponent implements OnInit {
  record: any = {};
  validateForm:FormGroup
  nodes:any[] = [];


  constructor(
    private modal: NzModalRef,
    private msgSrv: NzMessageService,
    public http: _HttpClient,
    private fb: FormBuilder,
    private cookies: CookieService,
  ) {}

  // 获取表单数据
  get category_id() {
    return this.validateForm.controls.category_id;
  }
  get name() {
    return this.validateForm.controls.name;
  }

  get sid(){
    return this.cookies.get('sid');
  }

  ngOnInit(): void {
      // 表单验证
      this.getTreeData();
      this.validateForm = this.fb.group({
      category_id: [null],
      name: [null, [Validators.required,Validators.pattern(/[\u4e00-\u9fa5]/g)]],
    });
  }

  // 获取父节点
  getTreeData() {
    this.http
      .post(environment.SPAPPS_URL + '/backend/web/v1/contentcate/listcontentcate', {
        sid: this.sid,
      })
      .subscribe(res => {
        this.nodes = res.data.list;
      });
  }

  ///提交
  submitForm():void{
    if (!this.validateForm.valid) {
      for (const i in this.validateForm.controls) {
        this.validateForm.controls[i].markAsDirty();
        this.validateForm.controls[i].updateValueAndValidity();
      }
    }
    else
    {
      this.http
      .put(environment.SPAPPS_URL + '/backend/web/v1/article/addarticlename', {
        id:this.record.id,
        c_id: this.category_id.value,
        name: this.name.value,
        sid: this.sid,
      }).subscribe(res => {
        if (res.code !== 1) {
          this.msgSrv.error(res.message);
          return;
        }
        this.msgSrv.success('成功保存参数信息');
        this.modal.close(true);
      });
    }
  }

}
