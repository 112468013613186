import { Component, OnInit, ViewChild } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { STColumn, STComponent, STColumnBadge } from '@delon/abc';
import { SFSchema } from '@delon/form';
import { environment } from '@env/environment';
import { CookieService } from 'ngx-cookie-service';
import { FeedbackMessagelistViewComponent } from './view/view.component';
import { NzModalService, NzMessageService } from 'ng-zorro-antd';

const BADGE: STColumnBadge = {

  1: { text: '已读', color: 'success' },
  0: { text: '未读', color: 'error' },
};

@Component({
  selector: 'app-feedback-messagelist',
  templateUrl: './messagelist.component.html',
})
export class FeedbackMessagelistComponent implements OnInit {
  isVisible = false;
  lookMessage = "";
  url = environment.SPAPPS_URL + '/backend/web/v1/form/formlist?sid=' + this.sid;
  searchSchema: SFSchema = {
    properties: {
      title: {
        type: 'string',
        title: '',
        ui: {
          placeholder: '手机号/联系人',
          width:200,
        },
      },
      status: {
        type: 'string',
        title: '',
        enum: ['已读', '未读'],
        ui: {
          widget: 'checkbox',
          width: 300,
          span: 6, // 指定每一项 8 个单元的布局
          checkAll: true,
        },
      },
    },
  };

  @ViewChild('st') st: STComponent;
  columns: STColumn[] = [
    { title: '', index: 'id', type: 'checkbox' },
    { title: "联系人",index:"name"},
    { title: "手机号",index:"phone"},
    { title: '留言信息', index: 'message' },
    { title: "联系地址", index:"address"},
    { title: '留言时间', index: 'created_at' },
    { title: "状态",index:"status",type:"badge",badge:BADGE},
    {
      title: '操作',
      buttons: [
        { text: '详情', click: (item: any) => this.detail(item) },
        { text: '删除', click: (item: any) => this.delete(item.id) },
        { text: '查看', click:(item:any)=>this.look(item)},
      ],
    },
  ];

  constructor(
    private http: _HttpClient,
    private modal: ModalHelper,
    private cookies: CookieService,
    private modalSerevice: NzModalService,
    private message: NzMessageService,
  ) {}

  get sid() {
    return this.cookies.get('sid');
  }

  ngOnInit() {
    console.log(this.sid)
  }
  detail(item) {
    console.log(item);
    this.modal.createStatic(FeedbackMessagelistViewComponent, { record: item }).subscribe((res: any) => {
      this.st.reload();
    });
  }
  delete(id) {
    this.modalSerevice.confirm({
      nzTitle: '是否确认删除该信息',
      nzContent: '删除以后无法恢复，删除前请慎重考虑!',
      nzOnOk: () =>
        this.http.post(environment.SPAPPS_URL + '/backend/web/v1/form/formdetail', { id: id }).subscribe((res: any) => {
          if (res.code !== 1) {
            this.message.error(res.message);
          } else {
            this.message.success(res.message);
          }
          this.st.reload();
        }),
    });
  }
  look(item) {
    this.isVisible = true;
    this.lookMessage=item.message
    console.log(item)
    this.http.post(environment.SPAPPS_URL + "/backend/web/v1/form/viewform", {
      id:item.id,
      status:1
    }).subscribe(res => {
      console.log(res)
    })
  }
}
