import { Component, OnInit, ViewChild,ChangeDetectorRef } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { STColumn, STComponent } from '@delon/abc';
import { NzDrawerService,NzModalService,NzMessageService } from 'ng-zorro-antd';
import { ProductProductbrandEditComponent } from "./edit/edit.component";
import { environment } from '@env/environment';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-product-productbrand',
  templateUrl: './productbrand.component.html',
})

export class ProductProductbrandComponent implements OnInit {
  islogin= true;
  dataList: any[] = [];
  pageNumber = 1;
  pageSize= 10;
  searchKey="";
  ids: any;
  @ViewChild('st') st: STComponent;
  columns: STColumn[] = [
    { title: '编号', index: '', type: 'checkbox',width:"5%",className:'text-center'},
    { title: "ID", index: "id", width: "15%" ,className:'text-center'},
    { title: 'LOGO', type: 'img', width: "15%", index: 'pic_url',className:'text-center' },
    { title: '品牌名称', index: 'name' ,width:"15%",className:'text-center'},
    { title: '品牌分类',index: 'cat_name',width:"15%",className:'text-center'},
    { title:"是否推荐", type: 'yn' ,index:"is_hot",width:"15%",className:'text-center'},
    {
      title: '操作', buttons: [
        { text: '编辑', click: (item: any) => this.edit(item) },
        { text: '删除', click: (item: any) => this.delete(item.id) },
      ],width:"15%",className:'text-center'
    },
  ];

  constructor(
    private http: _HttpClient,
    private modal: ModalHelper,
    private drawerService: NzDrawerService,
    private msg: NzModalService,
    private messageService:NzMessageService,
    private cdr: ChangeDetectorRef,
    private cookies:CookieService
  ) { }

  ngOnInit() {
    this.getData()
  }
  get sid() {
    return this.cookies.get("sid")
  }
  add() {
    this.drawerService.create<ProductProductbrandEditComponent, { addObj: any }, string>({
      nzTitle: "添加品牌",
      nzContent: ProductProductbrandEditComponent,
      nzWidth: '60%',
      nzContentParams: {
        addObj: { add: 1 }
      },
    }).afterClose.subscribe(res => {
      this.st.reload();
    })
  }
  edit(item) {
    this.drawerService.create<ProductProductbrandEditComponent, { editObj: any }, string>({
      nzTitle: "编辑品牌",
      nzContent: ProductProductbrandEditComponent,
      nzWidth: "60%",
      nzContentParams: {
        editObj:{item:item}
      }
    }).afterClose.subscribe(res => {
      this.getData();
      this.cdr.detectChanges()
    })
  }
  delete(id) {
    this.msg.confirm({
      nzTitle: '是否确认删除该信息',
      nzContent: '删除以后无法恢复，删除前请慎重考虑!',
      nzOnOk: () => {
        this.http.delete(environment.SPAPPS_URL + "/backend/web/v1/sp-goods-brand/" + id).subscribe(res => {
          if (res.code == 1) {
            this.messageService.success(res.message)
            this.getData()
          } else {
            this.messageService.error(res.message)
          }
        })
      }
    })
  }
  //全选的id
  // change(e) {
  //   console.log(e)
  //   let arr = [];
  //   if (e.type == 'checkbox' && e.checkbox.length != 0) {
  //     e.checkbox.forEach(element => {
  //       arr.push(element.id);
  //       this.ids = arr.reduce((pre, cur) => {
  //         if (!pre.includes(cur)) {
  //           return pre.concat(cur);
  //         } else {
  //           return pre;
  //         }
  //       }, []);
  //     });
  //   }
  // }
  search() {
    this.getData();
    this.searchKey = "";
  }
  getData() {
    this.http.get(environment.SPAPPS_URL + "/backend/web/v1/sp-goods-brand?sid="+this.sid+"&keyword="+this.searchKey).subscribe(res => {
      this.islogin = false;
      console.log("brand", res)
      if (res.code == 1) {
        this.dataList=res.data.list
      }
    })
  }
  getRest() {
    this.searchKey = "";
    this.getData();
  }
}
