import { Component, OnInit, ViewChild } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { STColumn, STComponent } from '@delon/abc';
import { SFSchema } from '@delon/form';
import { ProductProductasklistViewComponent } from "./view/view.component";
import { NzModalService } from "ng-zorro-antd/modal";
import { NzMessageService } from 'ng-zorro-antd/message';
import { environment } from '@env/environment';
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'app-product-productasklist',
  templateUrl: './productasklist.component.html',
})
export class ProductProductasklistComponent implements OnInit {
  isLogin = true;
  dataList = [];
  content = "";
  nickname = "";
  @ViewChild('st') st: STComponent;
  columns: STColumn[] = [
    { title: '编号', index: 'id' },
    { title: '用户',  index: 'username'},
    { title: '咨询内容', index: 'content'},
    { title: '商品', index: 'goods_name'},
    {
      title: '是否显示', type: 'badge', index: 'is_show', badge: {
        1: { text: "显示", color: "success" },
        0: { text: "不显示", color: "error" },
    } },
    { title: '咨询类型',  index: 'consult_type'},
    { title: '咨询时间', type: 'date', index: 'add_time'},
    {
      title: '操作',
      buttons: [
        { text: '查看', click: (item: any) =>this.look(item)  },
        { text: '删除', click: (item: any) =>this.delete(item.id)},
      ]
    }
  ];

  constructor(
    private http: _HttpClient,
    private modal: ModalHelper,
    private modalService: NzModalService,
    private messageService: NzMessageService,
    private cookies:CookieService
  ) { }
get sid(){
  return this.cookies.get("sid")
}
  ngOnInit() {
    this.getData()
   }


  look(item) {
    this.modal.createStatic(
      ProductProductasklistViewComponent,
      { view: item }).subscribe((res: any) => {
    })
  }
  delete(id) {
    this.modalService.confirm({
      nzTitle: '<i>是否要确认删除该信息</i>',
      nzContent: '<b>删除以后无法恢复，删除前请慎重考虑!</b>',
      nzOnOk: () => {
        this.http.get(environment.SPAPPS_URL + "/backend/web/v1/sp-goods-comment/del-ask?id="+id).subscribe(res => {
          if (res.code == 1) {
            this.messageService.success("删除成功")
            this.getData()
          } else {
            this.messageService.error(res.message)
          }
        })
      }
    })
  }
  getRest() {
  this.content = "";
  this.nickname = "";
  this.getData();
  }
  getData() {
    this.isLogin=false;
    this.http.get(environment.SPAPPS_URL + "/backend/web/v1/sp-goods-comment/ask-list?sid="+this.sid+"&nickname=" + this.nickname + "&content" + this.content).subscribe(res=>{
     console.log(res)
      if (res.code == 1) {
        this.dataList = res.data.list;
      }
    })
  }
}
