import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { NzModalService, NzDrawerService } from 'ng-zorro-antd';
import { ProductProductlistEditComponent } from './edit/edit.component';
import { NzMessageService } from 'ng-zorro-antd/message';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-product-productlist',
  templateUrl: './productlist.component.html',
})
export class ProductProductlistComponent implements OnInit {
  listOfData = [];
  brandList: any;
  brandList1: any;
  cateList1: any;
  cateList: any;
  selectedValue = [-1];
  selectedValue1 = "";
  selectedValue2 = '';
  selectedValue3 = '';
  selectedValue4 = '';
  searchkey = "";
  pageNumber = 1;
  pageSize = 10;
  total = 1;
  on_sale = "";
  constructor(
    private http: _HttpClient,
    private modal: ModalHelper,
    private modalService: NzModalService,
    private drawerService: NzDrawerService,
    private cookes: CookieService,
    private cdr: ChangeDetectorRef,
    private msg:NzMessageService,
  ) {}
  get sid() {
  return this.cookes.get("sid")
}
  ngOnInit() {
    this.getData();
  }

  add() {
    this.drawerService
      .create<ProductProductlistEditComponent, { addObj: any }, string>({
        nzTitle: '添加商品分类',
        nzContent: ProductProductlistEditComponent,
        nzWidth: '70%',
        nzContentParams: {
          addObj: {cate:this.cateList1,brand:this.brandList1},
        },
      })
      .afterClose.subscribe(() => {
        this.getData();
        this.cdr.detectChanges();
      });
  }
  edit(item) {
    this.drawerService
    .create<ProductProductlistEditComponent, { editObj: any }, string>({
      nzTitle: '添加商品分类',
      nzContent: ProductProductlistEditComponent,
      nzWidth: '70%',
      nzContentParams: {
        editObj: {item:item ,cate:this.cateList1,brand:this.brandList1},
      },
    })
    .afterClose.subscribe(() => {
      this.getData();
      this.cdr.detectChanges();
    });
  }
  delete(id, sale) {

    this.http.get(environment.SPAPPS_URL + "/backend/web/v1/sp-goods/sale-del?goods_id="+id + "&sale="+sale).subscribe(res => {
      console.log(res)
      if (res.code == 1) {
        this.msg.success(res.message);
        this.getData()
      } else {
        this.msg.error(res.message)
      }
    })
  }
  onChanges(e) {
    console.log(e)
    this.selectedValue1 = e[e.length - 1];
    this.selectedValue1 = e[e.length - 1]==-1?"":e[e.length - 1]
    this.getData()
  }
  getData() {
    this.http.get(environment.SPAPPS_URL + "/backend/web/v1/sp-goods?sid="+this.sid+"+&page="+this.pageNumber+"&per-page="+this.pageSize+"&keyword"+this.searchkey+"&cat_id="+this.selectedValue1+"&brand_id="+this.selectedValue2+"&is_on_sale="+this.selectedValue3+"&intro="+this.selectedValue4).subscribe(res => {
      console.log(res)
      this.listOfData = res.data.goods;
      this.brandList1 = res.data.brand;
      this.brandList= [{ id: "", name: '所有品牌' }].concat(this.brandList1)
      this.total = res.data.pagination.total;
      let cateList = [];
      res.data.cate.forEach(element => {
        const form1 = {
          value: element.id,
          label: element.name,
          children: [],
        }
        if (element.son) {
          element.son.forEach(ele => {
            const form2 = {
              value: ele.id,
              label: ele.name,
              children:[],
            }
            if (ele.son) {
              ele.son.forEach(el => {
                const form3 = {
                  label: el.name,
                  value: el.id,
                  isLeaf: true,
                }
                form2.children.push(form3)
              })
            } else {
              form2['isLeaf'] =true;
            }
            form1.children.push(form2)
          })
        }
        cateList.push(form1)
      });
      this.cateList1 = cateList;
      this.cateList=[{label: "所有分类",value: -1,isLeaf: true,}].concat(cateList)
      console.log("cateList",this.cateList)
    })
  }
  getRest() {
    this.searchkey = "";
    this.selectedValue = [];
    this.selectedValue1 = "";
    this.selectedValue2 = "";
    this.selectedValue3 = "";
    this.selectedValue4 = "";
    this.getData();
  }

}
