import { Component, OnInit, ViewChild ,ChangeDetectorRef} from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { STColumn, STComponent } from '@delon/abc';
import { SFSchema } from '@delon/form';
import { map } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { SFSchemaEnumType } from '@delon/form';
import { environment } from '@env/environment';
import { ContentProparamsEditComponent } from './edit/edit.component';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-content-proparams',
  templateUrl: './proparams.component.html',
})
export class ContentProparamsComponent implements OnInit {
  url = environment.SPAPPS_URL + '/backend/web/v1/productparams/productparamslist?sid='+this.sid;
  tabs = [
    {
      active: true,
      name: '图文参数列表',
      icon: 'apartment'
    },
    {
      active: false,
      name: '参数筛选列表',
      icon: 'filter'
    }
  ];
  //树形表格
  pageSize = 10;
  pageNumber = 1;
  total: any;
  productName = "";
  datalist = [];
  expand = true;
  searchSchema: SFSchema = {
    properties: {
      category: {
        type: 'string',
        title: '分类筛选',
        ui: {
          widget: 'tree-select',
          width: 260,
          allowClear: true,
          placeholder: '请选择所属分类',
          dropdownStyle: { height: '200px' },
          defaultExpandAll: true,
          asyncData: () => this.getTreeData(),
        },
      },
    }
  };
  @ViewChild('st') st: STComponent;
  columns: STColumn[] = [
    { title: '编号', index: 'id'},
    { title: '参数名称', index: 'name' },
    { title: '所属分类', index: 'cate_name',className: 'text-center' },
    { title: '是否通用', type: 'yn', index: 'is_gm', className: 'text-center' },
    { title: '加入筛选', type: 'yn', index: 'is_search',className: 'text-center' },
    {
      title: '操作',
      buttons: [
         { text: '编辑', click: (item: any) => this.editParams(item) },
         { text:'删除',click:(item:any)=> this.deleteParams(item.id) }
      ]
    }
  ];
  columns2: STColumn[] = [
    { title: '参数名称', index: 'name' }
  ];
  constructor(
    private http: _HttpClient,
    private modal: ModalHelper,
    private cookies: CookieService,
    private cdr: ChangeDetectorRef,
    private messageService: NzMessageService,
    private modalService:NzModalService,
  ) { }

  ngOnInit() {
    this.getdata();
   }

    //获取sid
    get sid() {
      return this.cookies.get('sid');
    }

    editParams(record:any){
        this.modal.createStatic(ContentProparamsEditComponent,{record}).subscribe(
          (res:any)=>{
            this.st.reload();
            this.getdata();
            this.cdr.detectChanges()
          }
        );
    }


    ///删除参数
  deleteParams(id) {
    this.modalService.confirm({
        nzTitle: '是否确认删除该信息',
        nzContent: '删除以后无法恢复，删除前请慎重考虑!',
        nzOnOk: () => {
          this.http.post(environment.SPAPPS_URL + "/backend/web/v1/productparams/deleteparamsname", {
            "id": id,
            "sid": this.sid,
          }).subscribe(res => {
            if (res.code == 1) {
              this.messageService.success("删除成功")
              this.st.reload();
            } else {
              this.messageService.error(res.message)
            }
          })
        }
      })
    }

    // 获取父节点
    getTreeData() {
      return this.http.get(environment.SPAPPS_URL + '/backend/web/v1/productcate/listproductcate?sid=' + this.sid).pipe(
        map((v, i) => {
          const arr: SFSchemaEnumType[] = [];
          (v as any).data.list.forEach(element => {
            arr.push(element);
          });
          return arr;
        }),
      );
    }

  add() {
    this.modal
      .createStatic(ContentProparamsEditComponent, { i: { id: 0 } })
      .subscribe(()=>{
        this.st.reload();
        this.getdata();
        this.cdr.detectChanges()
      })
  }
  changeNumber(data) {
    this.http.post(environment.SPAPPS_URL + "/backend/web/v1/productparams/productParamsValueUpdate", {
     "sid":this.sid,
      "text": data.text,
      "order": data.order,
    }).subscribe(res => {
      if (res.code == 1) {
        this.messageService.success(res.message)
        // this.getdata();
      } else {
        this.messageService.error(res.message);
      }
    })
  }
  deleteFilter(data) {
    this.modalService.confirm({
      nzTitle: '是否确认删除该信息',
      nzContent: '删除以后无法恢复，删除前请慎重考虑!',
      nzOnOk: () => {
        this.http.post(environment.SPAPPS_URL + "/backend/web/v1/productparams/product-params-value-delete", {
          "text": data.text,
          "sid": this.sid,
        }).subscribe(res => {
          if (res.code == 1) {
            this.messageService.success("删除成功")
            this.getdata()
            this.cdr.detectChanges();
          } else {
            this.messageService.error(res.message)
          }
        })
      }
    })
  }
  getdata() {
    this.http.get(environment.SPAPPS_URL + "/backend/web/v1/productparams/productParamsValueList?sid=" + this.sid + "&product_params_name=" + this.productName + "&page=" + this.pageNumber + "&per-page" + this.pageSize).subscribe(res => {
      console.log(res)
      if (res.code == 1) {
        this.datalist = res.data.list;
        this.total = res.data.pagination.total;
      }
    })
  }

}
