import { Component, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import * as distanceInWordsToNow from 'date-fns/distance_in_words_to_now';
import { NzMessageService } from 'ng-zorro-antd';
import { NoticeItem, NoticeIconList } from '@delon/abc';
import { _HttpClient } from '@delon/theme';
import { environment } from '@env/environment';
import { CookieService } from 'ngx-cookie-service';
import { SidebarComponent } from '../../sidebar/sidebar.component';
import { Router } from '@angular/router';

/**
 * 菜单通知
 */
@Component({
  selector: 'header-notify',
  template: `
    <notice-icon
      [data]="data"
      [count]="count"
      [loading]="loading"
      btnClass="alain-default__nav-item"
      btnIconClass="alain-default__nav-item-icon"
      (select)="select($event)"
      (clear)="clear($event)"
      (popoverVisibleChange)="loadData()"
    ></notice-icon>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderNotifyComponent {

  get sid() {
    return this.cookies.get('sid');
  }
  noticeList: any[] = [];
  data: NoticeItem[] = [
    {
      title: '通知',
      list: [],
      emptyText: '你已查看所有通知',
      emptyImage: 'https://gw.alipayobjects.com/zos/rmsportal/wAhyIChODzsoKIOBHcBk.svg',
      clearText: '清空通知',

    },
    {
      title: '消息',
      list: [],
      emptyText: '您已读完所有消息',
      emptyImage: 'https://gw.alipayobjects.com/zos/rmsportal/sAuJeJzSKbUmHfBQRzmZ.svg',
      clearText: '清空消息',
    },
    {
      title: '待办',
      list: [],
      emptyText: '你已完成所有待办',
      emptyImage: 'https://gw.alipayobjects.com/zos/rmsportal/HsIsxMZiWKrNUavQUXqx.svg',
      clearText: '清空待办',
    },
  ];
  count:any;
  loading = false;

  constructor(
    private msg: NzMessageService,
    private cdr: ChangeDetectorRef,
    private http: _HttpClient,
    private cookies: CookieService,
    private router: Router,
    ) { }

    ngOnInit(): void {
      this.getData()
  }
  private updateNoticeData(notices: NoticeIconList[]): NoticeItem[] {
    const data = this.data.slice();
    data.forEach(i => {
      i.list = [];
    });
    notices.forEach(item => {
      const newItem = { ...item };//{id: 6, title: "系统通知", description: "南京康辉国际旅行社从已提交，待审核转换到已上线，运行中", status: 0, type: 1}
      if (newItem.datetime)
        newItem.datetime = distanceInWordsToNow(item.datetime!, {
          locale: (window as any).__locale__,
        });
      if (newItem.extra && newItem.status) {
        newItem.color = {
          todo: undefined,
          processing: 'blue',
          urgent: 'red',
          doing: 'gold',
        }[newItem.status];
      }
      data.find(w => w.title === newItem.type)!.list.push(newItem);
    });
    return data;
  }

  loadData() {
    if (this.loading) return;
    this.loading = true;
    this.data = this.updateNoticeData(this.noticeList)
    this.loading = false;
    this.cdr.markForCheck();
    // setTimeout(() => {
      // this.data = this.updateNoticeData([
      //   {
      //     id: '000000001',
      //     avatar: 'https://gw.alipayobjects.com/zos/rmsportal/ThXAXghbEsBCCSDihZxY.png',
      //     title: '你收到了 14 份新周报',
      //     datetime: '2017-08-09',
      //     type: '通知',
      //   },
      //   {
      //     id: '000000006',
      //     avatar: 'https://gw.alipayobjects.com/zos/rmsportal/fcHMVNCjPOsbUGdEduuv.jpeg',
      //     title: '曲丽丽 评论了你',
      //     description: '描述信息描述信息描述信息',
      //     datetime: '2017-08-07',
      //     type: '消息',
      //   },
      //   {
      //     id: '000000009',
      //     title: '任务名称',
      //     description: '任务需要在 2017-01-12 20:00 前启动',
      //     extra: '未开始',
      //     status: 'todo',
      //     type: '待办',
      //   }
      // ]);
      // this.loading = false;
      // this.cdr.detectChanges();
    // }, 1000);
  }

  clear(type: string) {
    this.http.get(environment.SPAPPS_URL+"/backend/web/v1/notice/notice-read-all?sid="+this.sid+"&type="+type).subscribe(res=>{
      if (res.code == 1) {
        this.msg.success(`清空了 ${type}`);
        this.noticeList = [];
        this.count = 0;
        this.cdr.markForCheck();
      } else {
        this.msg.error(res.message)
      }
    })

  }

  select(item: any) {
    this.http.get(environment.SPAPPS_URL + "/backend/web/v1/notice/notice-read?notice_id=" + item.item.id).subscribe(res => {
      console.log(res)
      this.router.navigateByUrl("/sites/setting/notification");
    })
  }

  getData() {
    let list = [];
    this.http.get(environment.SPAPPS_URL + "/backend/web/v1/notice/notice-select?sid="+this.sid+"&view=0").subscribe(res => {
      this.count = res.data.list.length;
      this.cdr.detectChanges();
      res.data.list.forEach(element => {
        if (element.type === "通知") {
          const item = {
             id:element.notice_id,
             avatar: 'https://gw.alipayobjects.com/zos/rmsportal/ThXAXghbEsBCCSDihZxY.png',
             title:element.content,
             datetime:element.created_at,
             type: element.type,
          }
          this.noticeList.push(item);
        } else if (element.type === "消息") {
          const item = {
            id:element.notice_id,
            avatar: 'https://gw.alipayobjects.com/zos/rmsportal/ThXAXghbEsBCCSDihZxY.png',
            title:element.title,
            datetime:element.created_at,
            type: element.type,
            description: element.content,
          }
          this.noticeList.push(item);
        } else if(element.type === "代办"){
          const item = {
            id:element.notice_id,
            avatar: 'https://gw.alipayobjects.com/zos/rmsportal/ThXAXghbEsBCCSDihZxY.png',
            title:element.title,
            datetime:element.created_at,
            type: element.type,
            description: element.content,
            extra: '未开始',
            status: 'todo',
          }
          this.noticeList.push(item);
        }

      })
      // console.log("noticlelist",this.noticeList)
    })
  }
}
