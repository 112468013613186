import { Component, OnInit, ViewChild } from '@angular/core';
import { NzMessageService, UploadFile, NzDrawerRef, NzDrawerService } from 'ng-zorro-antd';
import { _HttpClient,ModalHelper } from '@delon/theme';
import { SFSchema, SFUISchema } from '@delon/form';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Observer } from 'rxjs/index';
import { environment } from '@env/environment';
import { CookieService } from 'ngx-cookie-service';
import {ContentImagesComponent} from '../../images/images.component';

@Component({
  selector: 'app-content-categorylist-add',
  templateUrl: './add.component.html',
})
export class ContentCategorylistAddComponent implements OnInit {
  validateForm: FormGroup;
  nodes: any = [];
  avatarUrl: string;
  picUrl = '';
  loading = false;
  media_id = 0;
  result:any = {};

  constructor(
    private cookies: CookieService,
    private msgSrv: NzMessageService,
    public http: _HttpClient,
    private fb: FormBuilder,
    private msg: NzMessageService,
    private drawerRef: NzDrawerRef,
    private modal: ModalHelper,
  ) {}

  // 获取表单数据
  get parent() {
    return this.validateForm.controls.parent;
  }
  get name() {
    return this.validateForm.controls.name;
  }
  get second_name() {
    return this.validateForm.controls.name;
  }
  get icon() {
    return this.validateForm.controls.icon;
  }
  get link() {
    return this.validateForm.controls.link;
  }
  get status() {
    return this.validateForm.controls.status;
  }
  get type() {
    return this.validateForm.controls.type;
  }
  get sort() {
    return this.validateForm.controls.sort;
  }

  get intro() {
    return this.validateForm.controls.intro;
  }

  get sid() {
    return this.cookies.get('sid');
  }

  ngOnInit(): void {
    // 上传图片
    this.picUrl = environment.SPAPPS_URL + '/backend/web/v1/commons/upload-pic?sid=' + this.sid;
    // 表单验证
    this.validateForm = this.fb.group({
      parent: [null],
      name: [null, [Validators.required]],
      second_name: [null],
      icon: [null],
      intro: [null],
      link: [null],
      status: ['1', [Validators.required]],
      type: [null],
      sort: [0, [Validators.required]],
    });
  }
  // 表单提交
  submitForm(): void {
    if (!this.validateForm.valid) {
      for (const i in this.validateForm.controls) {
        this.validateForm.controls[i].markAsDirty();
        this.validateForm.controls[i].updateValueAndValidity();
      }
    } else {
      this.http
        .post(environment.SPAPPS_URL + '/backend/web/v1/contentcate/addcontentcate', {
          parent: this.parent.value,
          name: this.name.value,
          second_name: this.second_name.value,
          icon: this.media_id.toString(),
          intro: this.intro.value,
          link: this.link.value,
          status: this.status.value,
          type: this.type.value,
          sort: this.sort.value,
          sid: this.sid,
        })
        .subscribe((res: any) => {
          if (res.code !== 1) {
            this.msg.error(res.message);
            return;
          }
          this.msgSrv.success('添加成功!');
          this.drawerRef.close();
        });
    }
  }

  checkImages(){
     this.modal.createStatic(ContentImagesComponent,{ size: 'md' }).subscribe((res:any)=>{
        console.log(res);
        this.avatarUrl = res.url;
        this.media_id = res.media_id;
     });
  }

}
