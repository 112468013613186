/**
 * 省市区联动json数组
 * desc: 适用ant.design
 * author: FengMind <FengMind@qq.com>
 * date: 2018-9-5
 */

export const areasList =
    [{
        "children": [{
            "children": [{
                "value": 1110101,
                "label": "东城区",
                "parent": 11101,
                "isLeaf": true
            }, {
                "value": 1110102,
                "label": "西城区",
                "parent": 11101,
                "isLeaf": true
            }, {
                "value": 1110105,
                "label": "朝阳区",
                "parent": 11101,
                "isLeaf": true
            }, {
                "value": 1110106,
                "label": "丰台区",
                "parent": 11101,
                "isLeaf": true
            }, {
                "value": 1110107,
                "label": "石景山区",
                "parent": 11101,
                "isLeaf": true
            }, {
                "value": 1110108,
                "label": "海淀区",
                "parent": 11101,
                "isLeaf": true
            }, {
                "value": 1110109,
                "label": "门头沟区",
                "parent": 11101,
                "isLeaf": true
            }, {
                "value": 1110111,
                "label": "房山区",
                "parent": 11101,
                "isLeaf": true
            }, {
                "value": 1110112,
                "label": "通州区",
                "parent": 11101,
                "isLeaf": true
            }, {
                "value": 1110113,
                "label": "顺义区",
                "parent": 11101,
                "isLeaf": true
            }, {
                "value": 1110114,
                "label": "昌平区",
                "parent": 11101,
                "isLeaf": true
            }, {
                "value": 1110115,
                "label": "大兴区",
                "parent": 11101,
                "isLeaf": true
            }, {
                "value": 1110116,
                "label": "怀柔区",
                "parent": 11101,
                "isLeaf": true
            }, {
                "value": 1110117,
                "label": "平谷区",
                "parent": 11101,
                "isLeaf": true
            }, {
                "value": 1110118,
                "label": "密云区",
                "parent": 11101,
                "isLeaf": true
            }, {
                "value": 1110119,
                "label": "延庆区",
                "parent": 11101,
                "isLeaf": true
            }],
            "value": 11101,
            "label": "北京市",
            "parent": 111
        }],
        "value": 111,
        "label": "北京市",
        "parent": 0
    }, {
        "children": [{
            "children": [{
                "value": 1120101,
                "label": "和平区",
                "parent": 11201,
                "isLeaf": true
            }, {
                "value": 1120102,
                "label": "河东区",
                "parent": 11201,
                "isLeaf": true
            }, {
                "value": 1120103,
                "label": "河西区",
                "parent": 11201,
                "isLeaf": true
            }, {
                "value": 1120104,
                "label": "南开区",
                "parent": 11201,
                "isLeaf": true
            }, {
                "value": 1120105,
                "label": "河北区",
                "parent": 11201,
                "isLeaf": true
            }, {
                "value": 1120106,
                "label": "红桥区",
                "parent": 11201,
                "isLeaf": true
            }, {
                "value": 1120110,
                "label": "东丽区",
                "parent": 11201,
                "isLeaf": true
            }, {
                "value": 1120111,
                "label": "西青区",
                "parent": 11201,
                "isLeaf": true
            }, {
                "value": 1120112,
                "label": "津南区",
                "parent": 11201,
                "isLeaf": true
            }, {
                "value": 1120113,
                "label": "北辰区",
                "parent": 11201,
                "isLeaf": true
            }, {
                "value": 1120114,
                "label": "武清区",
                "parent": 11201,
                "isLeaf": true
            }, {
                "value": 1120115,
                "label": "宝坻区",
                "parent": 11201,
                "isLeaf": true
            }, {
                "value": 1120116,
                "label": "滨海新区",
                "parent": 11201,
                "isLeaf": true
            }, {
                "value": 1120117,
                "label": "宁河区",
                "parent": 11201,
                "isLeaf": true
            }, {
                "value": 1120118,
                "label": "静海区",
                "parent": 11201,
                "isLeaf": true
            }],
            "value": 11201,
            "label": "天津市",
            "parent": 112
        }, {
            "children": [{
                "value": 1120225,
                "label": "蓟县",
                "parent": 11202,
                "isLeaf": true
            }],
            "value": 11202,
            "label": "天津市县",
            "parent": 112
        }],
        "value": 112,
        "label": "天津市",
        "parent": 0
    }, {
        "children": [{
            "children": [{
                "value": 1130102,
                "label": "长安区",
                "parent": 11301,
                "isLeaf": true
            }, {
                "value": 1130104,
                "label": "桥西区",
                "parent": 11301,
                "isLeaf": true
            }, {
                "value": 1130105,
                "label": "新华区",
                "parent": 11301,
                "isLeaf": true
            }, {
                "value": 1130107,
                "label": "井陉矿区",
                "parent": 11301,
                "isLeaf": true
            }, {
                "value": 1130108,
                "label": "裕华区",
                "parent": 11301,
                "isLeaf": true
            }, {
                "value": 1130109,
                "label": "藁城区",
                "parent": 11301,
                "isLeaf": true
            }, {
                "value": 1130110,
                "label": "鹿泉区",
                "parent": 11301,
                "isLeaf": true
            }, {
                "value": 1130111,
                "label": "栾城区",
                "parent": 11301,
                "isLeaf": true
            }, {
                "value": 1130121,
                "label": "井陉县",
                "parent": 11301,
                "isLeaf": true
            }, {
                "value": 1130123,
                "label": "正定县",
                "parent": 11301,
                "isLeaf": true
            }, {
                "value": 1130125,
                "label": "行唐县",
                "parent": 11301,
                "isLeaf": true
            }, {
                "value": 1130126,
                "label": "灵寿县",
                "parent": 11301,
                "isLeaf": true
            }, {
                "value": 1130127,
                "label": "高邑县",
                "parent": 11301,
                "isLeaf": true
            }, {
                "value": 1130128,
                "label": "深泽县",
                "parent": 11301,
                "isLeaf": true
            }, {
                "value": 1130129,
                "label": "赞皇县",
                "parent": 11301,
                "isLeaf": true
            }, {
                "value": 1130130,
                "label": "无极县",
                "parent": 11301,
                "isLeaf": true
            }, {
                "value": 1130131,
                "label": "平山县",
                "parent": 11301,
                "isLeaf": true
            }, {
                "value": 1130132,
                "label": "元氏县",
                "parent": 11301,
                "isLeaf": true
            }, {
                "value": 1130133,
                "label": "赵县",
                "parent": 11301,
                "isLeaf": true
            }, {
                "value": 1130181,
                "label": "辛集市",
                "parent": 11301,
                "isLeaf": true
            }, {
                "value": 1130183,
                "label": "晋州市",
                "parent": 11301,
                "isLeaf": true
            }, {
                "value": 1130184,
                "label": "新乐市",
                "parent": 11301,
                "isLeaf": true
            }],
            "value": 11301,
            "label": "石家庄市",
            "parent": 113
        }, {
            "children": [{
                "value": 1130202,
                "label": "路南区",
                "parent": 11302,
                "isLeaf": true
            }, {
                "value": 1130203,
                "label": "路北区",
                "parent": 11302,
                "isLeaf": true
            }, {
                "value": 1130204,
                "label": "古冶区",
                "parent": 11302,
                "isLeaf": true
            }, {
                "value": 1130205,
                "label": "开平区",
                "parent": 11302,
                "isLeaf": true
            }, {
                "value": 1130207,
                "label": "丰南区",
                "parent": 11302,
                "isLeaf": true
            }, {
                "value": 1130208,
                "label": "丰润区",
                "parent": 11302,
                "isLeaf": true
            }, {
                "value": 1130209,
                "label": "曹妃甸区",
                "parent": 11302,
                "isLeaf": true
            }, {
                "value": 1130223,
                "label": "滦县",
                "parent": 11302,
                "isLeaf": true
            }, {
                "value": 1130224,
                "label": "滦南县",
                "parent": 11302,
                "isLeaf": true
            }, {
                "value": 1130225,
                "label": "乐亭县",
                "parent": 11302,
                "isLeaf": true
            }, {
                "value": 1130227,
                "label": "迁西县",
                "parent": 11302,
                "isLeaf": true
            }, {
                "value": 1130229,
                "label": "玉田县",
                "parent": 11302,
                "isLeaf": true
            }, {
                "value": 1130281,
                "label": "遵化市",
                "parent": 11302,
                "isLeaf": true
            }, {
                "value": 1130283,
                "label": "迁安市",
                "parent": 11302,
                "isLeaf": true
            }],
            "value": 11302,
            "label": "唐山市",
            "parent": 113
        }, {
            "children": [{
                "value": 1130302,
                "label": "海港区",
                "parent": 11303,
                "isLeaf": true
            }, {
                "value": 1130303,
                "label": "山海关区",
                "parent": 11303,
                "isLeaf": true
            }, {
                "value": 1130304,
                "label": "北戴河区",
                "parent": 11303,
                "isLeaf": true
            }, {
                "value": 1130306,
                "label": "抚宁区",
                "parent": 11303,
                "isLeaf": true
            }, {
                "value": 1130321,
                "label": "青龙满族自治县",
                "parent": 11303,
                "isLeaf": true
            }, {
                "value": 1130322,
                "label": "昌黎县",
                "parent": 11303,
                "isLeaf": true
            }, {
                "value": 1130324,
                "label": "卢龙县",
                "parent": 11303,
                "isLeaf": true
            }],
            "value": 11303,
            "label": "秦皇岛市",
            "parent": 113
        }, {
            "children": [{
                "value": 1130402,
                "label": "邯山区",
                "parent": 11304,
                "isLeaf": true
            }, {
                "value": 1130403,
                "label": "丛台区",
                "parent": 11304,
                "isLeaf": true
            }, {
                "value": 1130404,
                "label": "复兴区",
                "parent": 11304,
                "isLeaf": true
            }, {
                "value": 1130406,
                "label": "峰峰矿区",
                "parent": 11304,
                "isLeaf": true
            }, {
                "value": 1130421,
                "label": "邯郸县",
                "parent": 11304,
                "isLeaf": true
            }, {
                "value": 1130423,
                "label": "临漳县",
                "parent": 11304,
                "isLeaf": true
            }, {
                "value": 1130424,
                "label": "成安县",
                "parent": 11304,
                "isLeaf": true
            }, {
                "value": 1130425,
                "label": "大名县",
                "parent": 11304,
                "isLeaf": true
            }, {
                "value": 1130426,
                "label": "涉县",
                "parent": 11304,
                "isLeaf": true
            }, {
                "value": 1130427,
                "label": "磁县",
                "parent": 11304,
                "isLeaf": true
            }, {
                "value": 1130428,
                "label": "肥乡县",
                "parent": 11304,
                "isLeaf": true
            }, {
                "value": 1130429,
                "label": "永年县",
                "parent": 11304,
                "isLeaf": true
            }, {
                "value": 1130430,
                "label": "邱县",
                "parent": 11304,
                "isLeaf": true
            }, {
                "value": 1130431,
                "label": "鸡泽县",
                "parent": 11304,
                "isLeaf": true
            }, {
                "value": 1130432,
                "label": "广平县",
                "parent": 11304,
                "isLeaf": true
            }, {
                "value": 1130433,
                "label": "馆陶县",
                "parent": 11304,
                "isLeaf": true
            }, {
                "value": 1130434,
                "label": "魏县",
                "parent": 11304,
                "isLeaf": true
            }, {
                "value": 1130435,
                "label": "曲周县",
                "parent": 11304,
                "isLeaf": true
            }, {
                "value": 1130481,
                "label": "武安市",
                "parent": 11304,
                "isLeaf": true
            }],
            "value": 11304,
            "label": "邯郸市",
            "parent": 113
        }, {
            "children": [{
                "value": 1130502,
                "label": "桥东区",
                "parent": 11305,
                "isLeaf": true
            }, {
                "value": 1130503,
                "label": "桥西区",
                "parent": 11305,
                "isLeaf": true
            }, {
                "value": 1130521,
                "label": "邢台县",
                "parent": 11305,
                "isLeaf": true
            }, {
                "value": 1130522,
                "label": "临城县",
                "parent": 11305,
                "isLeaf": true
            }, {
                "value": 1130523,
                "label": "内丘县",
                "parent": 11305,
                "isLeaf": true
            }, {
                "value": 1130524,
                "label": "柏乡县",
                "parent": 11305,
                "isLeaf": true
            }, {
                "value": 1130525,
                "label": "隆尧县",
                "parent": 11305,
                "isLeaf": true
            }, {
                "value": 1130526,
                "label": "任县",
                "parent": 11305,
                "isLeaf": true
            }, {
                "value": 1130527,
                "label": "南和县",
                "parent": 11305,
                "isLeaf": true
            }, {
                "value": 1130528,
                "label": "宁晋县",
                "parent": 11305,
                "isLeaf": true
            }, {
                "value": 1130529,
                "label": "巨鹿县",
                "parent": 11305,
                "isLeaf": true
            }, {
                "value": 1130530,
                "label": "新河县",
                "parent": 11305,
                "isLeaf": true
            }, {
                "value": 1130531,
                "label": "广宗县",
                "parent": 11305,
                "isLeaf": true
            }, {
                "value": 1130532,
                "label": "平乡县",
                "parent": 11305,
                "isLeaf": true
            }, {
                "value": 1130533,
                "label": "威县",
                "parent": 11305,
                "isLeaf": true
            }, {
                "value": 1130534,
                "label": "清河县",
                "parent": 11305,
                "isLeaf": true
            }, {
                "value": 1130535,
                "label": "临西县",
                "parent": 11305,
                "isLeaf": true
            }, {
                "value": 1130581,
                "label": "南宫市",
                "parent": 11305,
                "isLeaf": true
            }, {
                "value": 1130582,
                "label": "沙河市",
                "parent": 11305,
                "isLeaf": true
            }],
            "value": 11305,
            "label": "邢台市",
            "parent": 113
        }, {
            "children": [{
                "value": 1130602,
                "label": "竞秀区",
                "parent": 11306,
                "isLeaf": true
            }, {
                "value": 1130604,
                "label": "南市区",
                "parent": 11306,
                "isLeaf": true
            }, {
                "value": 1130606,
                "label": "莲池区",
                "parent": 11306,
                "isLeaf": true
            }, {
                "value": 1130607,
                "label": "满城区",
                "parent": 11306,
                "isLeaf": true
            }, {
                "value": 1130608,
                "label": "清苑区",
                "parent": 11306,
                "isLeaf": true
            }, {
                "value": 1130609,
                "label": "徐水区",
                "parent": 11306,
                "isLeaf": true
            }, {
                "value": 1130623,
                "label": "涞水县",
                "parent": 11306,
                "isLeaf": true
            }, {
                "value": 1130624,
                "label": "阜平县",
                "parent": 11306,
                "isLeaf": true
            }, {
                "value": 1130626,
                "label": "定兴县",
                "parent": 11306,
                "isLeaf": true
            }, {
                "value": 1130627,
                "label": "唐县",
                "parent": 11306,
                "isLeaf": true
            }, {
                "value": 1130628,
                "label": "高阳县",
                "parent": 11306,
                "isLeaf": true
            }, {
                "value": 1130629,
                "label": "容城县",
                "parent": 11306,
                "isLeaf": true
            }, {
                "value": 1130630,
                "label": "涞源县",
                "parent": 11306,
                "isLeaf": true
            }, {
                "value": 1130631,
                "label": "望都县",
                "parent": 11306,
                "isLeaf": true
            }, {
                "value": 1130632,
                "label": "安新县",
                "parent": 11306,
                "isLeaf": true
            }, {
                "value": 1130633,
                "label": "易县",
                "parent": 11306,
                "isLeaf": true
            }, {
                "value": 1130634,
                "label": "曲阳县",
                "parent": 11306,
                "isLeaf": true
            }, {
                "value": 1130635,
                "label": "蠡县",
                "parent": 11306,
                "isLeaf": true
            }, {
                "value": 1130636,
                "label": "顺平县",
                "parent": 11306,
                "isLeaf": true
            }, {
                "value": 1130637,
                "label": "博野县",
                "parent": 11306,
                "isLeaf": true
            }, {
                "value": 1130638,
                "label": "雄县",
                "parent": 11306,
                "isLeaf": true
            }, {
                "value": 1130681,
                "label": "涿州市",
                "parent": 11306,
                "isLeaf": true
            }, {
                "value": 1130682,
                "label": "定州市",
                "parent": 11306,
                "isLeaf": true
            }, {
                "value": 1130683,
                "label": "安国市",
                "parent": 11306,
                "isLeaf": true
            }, {
                "value": 1130684,
                "label": "高碑店市",
                "parent": 11306,
                "isLeaf": true
            }],
            "value": 11306,
            "label": "保定市",
            "parent": 113
        }, {
            "children": [{
                "value": 1130702,
                "label": "桥东区",
                "parent": 11307,
                "isLeaf": true
            }, {
                "value": 1130703,
                "label": "桥西区",
                "parent": 11307,
                "isLeaf": true
            }, {
                "value": 1130705,
                "label": "宣化区",
                "parent": 11307,
                "isLeaf": true
            }, {
                "value": 1130706,
                "label": "下花园区",
                "parent": 11307,
                "isLeaf": true
            }, {
                "value": 1130721,
                "label": "宣化县",
                "parent": 11307,
                "isLeaf": true
            }, {
                "value": 1130722,
                "label": "张北县",
                "parent": 11307,
                "isLeaf": true
            }, {
                "value": 1130723,
                "label": "康保县",
                "parent": 11307,
                "isLeaf": true
            }, {
                "value": 1130724,
                "label": "沽源县",
                "parent": 11307,
                "isLeaf": true
            }, {
                "value": 1130725,
                "label": "尚义县",
                "parent": 11307,
                "isLeaf": true
            }, {
                "value": 1130726,
                "label": "蔚县",
                "parent": 11307,
                "isLeaf": true
            }, {
                "value": 1130727,
                "label": "阳原县",
                "parent": 11307,
                "isLeaf": true
            }, {
                "value": 1130728,
                "label": "怀安县",
                "parent": 11307,
                "isLeaf": true
            }, {
                "value": 1130729,
                "label": "万全县",
                "parent": 11307,
                "isLeaf": true
            }, {
                "value": 1130730,
                "label": "怀来县",
                "parent": 11307,
                "isLeaf": true
            }, {
                "value": 1130731,
                "label": "涿鹿县",
                "parent": 11307,
                "isLeaf": true
            }, {
                "value": 1130732,
                "label": "赤城县",
                "parent": 11307,
                "isLeaf": true
            }, {
                "value": 1130733,
                "label": "崇礼县",
                "parent": 11307,
                "isLeaf": true
            }],
            "value": 11307,
            "label": "张家口市",
            "parent": 113
        }, {
            "children": [{
                "value": 1130802,
                "label": "双桥区",
                "parent": 11308,
                "isLeaf": true
            }, {
                "value": 1130803,
                "label": "双滦区",
                "parent": 11308,
                "isLeaf": true
            }, {
                "value": 1130804,
                "label": "鹰手营子矿区",
                "parent": 11308,
                "isLeaf": true
            }, {
                "value": 1130821,
                "label": "承德县",
                "parent": 11308,
                "isLeaf": true
            }, {
                "value": 1130822,
                "label": "兴隆县",
                "parent": 11308,
                "isLeaf": true
            }, {
                "value": 1130823,
                "label": "平泉县",
                "parent": 11308,
                "isLeaf": true
            }, {
                "value": 1130824,
                "label": "滦平县",
                "parent": 11308,
                "isLeaf": true
            }, {
                "value": 1130825,
                "label": "隆化县",
                "parent": 11308,
                "isLeaf": true
            }, {
                "value": 1130826,
                "label": "丰宁满族自治县",
                "parent": 11308,
                "isLeaf": true
            }, {
                "value": 1130827,
                "label": "宽城满族自治县",
                "parent": 11308,
                "isLeaf": true
            }, {
                "value": 1130828,
                "label": "围场满族蒙古族自治县",
                "parent": 11308,
                "isLeaf": true
            }],
            "value": 11308,
            "label": "承德市",
            "parent": 113
        }, {
            "children": [{
                "value": 1130902,
                "label": "新华区",
                "parent": 11309,
                "isLeaf": true
            }, {
                "value": 1130903,
                "label": "运河区",
                "parent": 11309,
                "isLeaf": true
            }, {
                "value": 1130921,
                "label": "沧县",
                "parent": 11309,
                "isLeaf": true
            }, {
                "value": 1130922,
                "label": "青县",
                "parent": 11309,
                "isLeaf": true
            }, {
                "value": 1130923,
                "label": "东光县",
                "parent": 11309,
                "isLeaf": true
            }, {
                "value": 1130924,
                "label": "海兴县",
                "parent": 11309,
                "isLeaf": true
            }, {
                "value": 1130925,
                "label": "盐山县",
                "parent": 11309,
                "isLeaf": true
            }, {
                "value": 1130926,
                "label": "肃宁县",
                "parent": 11309,
                "isLeaf": true
            }, {
                "value": 1130927,
                "label": "南皮县",
                "parent": 11309,
                "isLeaf": true
            }, {
                "value": 1130928,
                "label": "吴桥县",
                "parent": 11309,
                "isLeaf": true
            }, {
                "value": 1130929,
                "label": "献县",
                "parent": 11309,
                "isLeaf": true
            }, {
                "value": 1130930,
                "label": "孟村回族自治县",
                "parent": 11309,
                "isLeaf": true
            }, {
                "value": 1130981,
                "label": "泊头市",
                "parent": 11309,
                "isLeaf": true
            }, {
                "value": 1130982,
                "label": "任丘市",
                "parent": 11309,
                "isLeaf": true
            }, {
                "value": 1130983,
                "label": "黄骅市",
                "parent": 11309,
                "isLeaf": true
            }, {
                "value": 1130984,
                "label": "河间市",
                "parent": 11309,
                "isLeaf": true
            }],
            "value": 11309,
            "label": "沧州市",
            "parent": 113
        }, {
            "children": [{
                "value": 1131002,
                "label": "安次区",
                "parent": 11310,
                "isLeaf": true
            }, {
                "value": 1131003,
                "label": "广阳区",
                "parent": 11310,
                "isLeaf": true
            }, {
                "value": 1131022,
                "label": "固安县",
                "parent": 11310,
                "isLeaf": true
            }, {
                "value": 1131023,
                "label": "永清县",
                "parent": 11310,
                "isLeaf": true
            }, {
                "value": 1131024,
                "label": "香河县",
                "parent": 11310,
                "isLeaf": true
            }, {
                "value": 1131025,
                "label": "大城县",
                "parent": 11310,
                "isLeaf": true
            }, {
                "value": 1131026,
                "label": "文安县",
                "parent": 11310,
                "isLeaf": true
            }, {
                "value": 1131028,
                "label": "大厂回族自治县",
                "parent": 11310,
                "isLeaf": true
            }, {
                "value": 1131081,
                "label": "霸州市",
                "parent": 11310,
                "isLeaf": true
            }, {
                "value": 1131082,
                "label": "三河市",
                "parent": 11310,
                "isLeaf": true
            }],
            "value": 11310,
            "label": "廊坊市",
            "parent": 113
        }, {
            "children": [{
                "value": 1131102,
                "label": "桃城区",
                "parent": 11311,
                "isLeaf": true
            }, {
                "value": 1131121,
                "label": "枣强县",
                "parent": 11311,
                "isLeaf": true
            }, {
                "value": 1131122,
                "label": "武邑县",
                "parent": 11311,
                "isLeaf": true
            }, {
                "value": 1131123,
                "label": "武强县",
                "parent": 11311,
                "isLeaf": true
            }, {
                "value": 1131124,
                "label": "饶阳县",
                "parent": 11311,
                "isLeaf": true
            }, {
                "value": 1131125,
                "label": "安平县",
                "parent": 11311,
                "isLeaf": true
            }, {
                "value": 1131126,
                "label": "故城县",
                "parent": 11311,
                "isLeaf": true
            }, {
                "value": 1131127,
                "label": "景县",
                "parent": 11311,
                "isLeaf": true
            }, {
                "value": 1131128,
                "label": "阜城县",
                "parent": 11311,
                "isLeaf": true
            }, {
                "value": 1131181,
                "label": "冀州市",
                "parent": 11311,
                "isLeaf": true
            }, {
                "value": 1131182,
                "label": "深州市",
                "parent": 11311,
                "isLeaf": true
            }],
            "value": 11311,
            "label": "衡水市",
            "parent": 113
        }],
        "value": 113,
        "label": "河北省",
        "parent": 0
    }, {
        "children": [{
            "children": [{
                "value": 1140105,
                "label": "小店区",
                "parent": 11401,
                "isLeaf": true
            }, {
                "value": 1140106,
                "label": "迎泽区",
                "parent": 11401,
                "isLeaf": true
            }, {
                "value": 1140107,
                "label": "杏花岭区",
                "parent": 11401,
                "isLeaf": true
            }, {
                "value": 1140108,
                "label": "尖草坪区",
                "parent": 11401,
                "isLeaf": true
            }, {
                "value": 1140109,
                "label": "万柏林区",
                "parent": 11401,
                "isLeaf": true
            }, {
                "value": 1140110,
                "label": "晋源区",
                "parent": 11401,
                "isLeaf": true
            }, {
                "value": 1140121,
                "label": "清徐县",
                "parent": 11401,
                "isLeaf": true
            }, {
                "value": 1140122,
                "label": "阳曲县",
                "parent": 11401,
                "isLeaf": true
            }, {
                "value": 1140123,
                "label": "娄烦县",
                "parent": 11401,
                "isLeaf": true
            }, {
                "value": 1140181,
                "label": "古交市",
                "parent": 11401,
                "isLeaf": true
            }],
            "value": 11401,
            "label": "太原市",
            "parent": 114
        }, {
            "children": [{
                "value": 1140202,
                "label": "城区",
                "parent": 11402,
                "isLeaf": true
            }, {
                "value": 1140203,
                "label": "矿区",
                "parent": 11402,
                "isLeaf": true
            }, {
                "value": 1140211,
                "label": "南郊区",
                "parent": 11402,
                "isLeaf": true
            }, {
                "value": 1140212,
                "label": "新荣区",
                "parent": 11402,
                "isLeaf": true
            }, {
                "value": 1140221,
                "label": "阳高县",
                "parent": 11402,
                "isLeaf": true
            }, {
                "value": 1140222,
                "label": "天镇县",
                "parent": 11402,
                "isLeaf": true
            }, {
                "value": 1140223,
                "label": "广灵县",
                "parent": 11402,
                "isLeaf": true
            }, {
                "value": 1140224,
                "label": "灵丘县",
                "parent": 11402,
                "isLeaf": true
            }, {
                "value": 1140225,
                "label": "浑源县",
                "parent": 11402,
                "isLeaf": true
            }, {
                "value": 1140226,
                "label": "左云县",
                "parent": 11402,
                "isLeaf": true
            }, {
                "value": 1140227,
                "label": "大同县",
                "parent": 11402,
                "isLeaf": true
            }],
            "value": 11402,
            "label": "大同市",
            "parent": 114
        }, {
            "children": [{
                "value": 1140302,
                "label": "城区",
                "parent": 11403,
                "isLeaf": true
            }, {
                "value": 1140303,
                "label": "矿区",
                "parent": 11403,
                "isLeaf": true
            }, {
                "value": 1140311,
                "label": "郊区",
                "parent": 11403,
                "isLeaf": true
            }, {
                "value": 1140321,
                "label": "平定县",
                "parent": 11403,
                "isLeaf": true
            }, {
                "value": 1140322,
                "label": "盂县",
                "parent": 11403,
                "isLeaf": true
            }],
            "value": 11403,
            "label": "阳泉市",
            "parent": 114
        }, {
            "children": [{
                "value": 1140402,
                "label": "城区",
                "parent": 11404,
                "isLeaf": true
            }, {
                "value": 1140411,
                "label": "郊区",
                "parent": 11404,
                "isLeaf": true
            }, {
                "value": 1140421,
                "label": "长治县",
                "parent": 11404,
                "isLeaf": true
            }, {
                "value": 1140423,
                "label": "襄垣县",
                "parent": 11404,
                "isLeaf": true
            }, {
                "value": 1140424,
                "label": "屯留县",
                "parent": 11404,
                "isLeaf": true
            }, {
                "value": 1140425,
                "label": "平顺县",
                "parent": 11404,
                "isLeaf": true
            }, {
                "value": 1140426,
                "label": "黎城县",
                "parent": 11404,
                "isLeaf": true
            }, {
                "value": 1140427,
                "label": "壶关县",
                "parent": 11404,
                "isLeaf": true
            }, {
                "value": 1140428,
                "label": "长子县",
                "parent": 11404,
                "isLeaf": true
            }, {
                "value": 1140429,
                "label": "武乡县",
                "parent": 11404,
                "isLeaf": true
            }, {
                "value": 1140430,
                "label": "沁县",
                "parent": 11404,
                "isLeaf": true
            }, {
                "value": 1140431,
                "label": "沁源县",
                "parent": 11404,
                "isLeaf": true
            }, {
                "value": 1140481,
                "label": "潞城市",
                "parent": 11404,
                "isLeaf": true
            }],
            "value": 11404,
            "label": "长治市",
            "parent": 114
        }, {
            "children": [{
                "value": 1140502,
                "label": "城区",
                "parent": 11405,
                "isLeaf": true
            }, {
                "value": 1140521,
                "label": "沁水县",
                "parent": 11405,
                "isLeaf": true
            }, {
                "value": 1140522,
                "label": "阳城县",
                "parent": 11405,
                "isLeaf": true
            }, {
                "value": 1140524,
                "label": "陵川县",
                "parent": 11405,
                "isLeaf": true
            }, {
                "value": 1140525,
                "label": "泽州县",
                "parent": 11405,
                "isLeaf": true
            }, {
                "value": 1140581,
                "label": "高平市",
                "parent": 11405,
                "isLeaf": true
            }],
            "value": 11405,
            "label": "晋城市",
            "parent": 114
        }, {
            "children": [{
                "value": 1140602,
                "label": "朔城区",
                "parent": 11406,
                "isLeaf": true
            }, {
                "value": 1140603,
                "label": "平鲁区",
                "parent": 11406,
                "isLeaf": true
            }, {
                "value": 1140621,
                "label": "山阴县",
                "parent": 11406,
                "isLeaf": true
            }, {
                "value": 1140622,
                "label": "应县",
                "parent": 11406,
                "isLeaf": true
            }, {
                "value": 1140623,
                "label": "右玉县",
                "parent": 11406,
                "isLeaf": true
            }, {
                "value": 1140624,
                "label": "怀仁县",
                "parent": 11406,
                "isLeaf": true
            }],
            "value": 11406,
            "label": "朔州市",
            "parent": 114
        }, {
            "children": [{
                "value": 1140702,
                "label": "榆次区",
                "parent": 11407,
                "isLeaf": true
            }, {
                "value": 1140721,
                "label": "榆社县",
                "parent": 11407,
                "isLeaf": true
            }, {
                "value": 1140722,
                "label": "左权县",
                "parent": 11407,
                "isLeaf": true
            }, {
                "value": 1140723,
                "label": "和顺县",
                "parent": 11407,
                "isLeaf": true
            }, {
                "value": 1140724,
                "label": "昔阳县",
                "parent": 11407,
                "isLeaf": true
            }, {
                "value": 1140725,
                "label": "寿阳县",
                "parent": 11407,
                "isLeaf": true
            }, {
                "value": 1140726,
                "label": "太谷县",
                "parent": 11407,
                "isLeaf": true
            }, {
                "value": 1140727,
                "label": "祁县",
                "parent": 11407,
                "isLeaf": true
            }, {
                "value": 1140728,
                "label": "平遥县",
                "parent": 11407,
                "isLeaf": true
            }, {
                "value": 1140729,
                "label": "灵石县",
                "parent": 11407,
                "isLeaf": true
            }, {
                "value": 1140781,
                "label": "介休市",
                "parent": 11407,
                "isLeaf": true
            }],
            "value": 11407,
            "label": "晋中市",
            "parent": 114
        }, {
            "children": [{
                "value": 1140802,
                "label": "盐湖区",
                "parent": 11408,
                "isLeaf": true
            }, {
                "value": 1140821,
                "label": "临猗县",
                "parent": 11408,
                "isLeaf": true
            }, {
                "value": 1140822,
                "label": "万荣县",
                "parent": 11408,
                "isLeaf": true
            }, {
                "value": 1140823,
                "label": "闻喜县",
                "parent": 11408,
                "isLeaf": true
            }, {
                "value": 1140824,
                "label": "稷山县",
                "parent": 11408,
                "isLeaf": true
            }, {
                "value": 1140825,
                "label": "新绛县",
                "parent": 11408,
                "isLeaf": true
            }, {
                "value": 1140826,
                "label": "绛县",
                "parent": 11408,
                "isLeaf": true
            }, {
                "value": 1140827,
                "label": "垣曲县",
                "parent": 11408,
                "isLeaf": true
            }, {
                "value": 1140828,
                "label": "夏县",
                "parent": 11408,
                "isLeaf": true
            }, {
                "value": 1140829,
                "label": "平陆县",
                "parent": 11408,
                "isLeaf": true
            }, {
                "value": 1140830,
                "label": "芮城县",
                "parent": 11408,
                "isLeaf": true
            }, {
                "value": 1140881,
                "label": "永济市",
                "parent": 11408,
                "isLeaf": true
            }, {
                "value": 1140882,
                "label": "河津市",
                "parent": 11408,
                "isLeaf": true
            }],
            "value": 11408,
            "label": "运城市",
            "parent": 114
        }, {
            "children": [{
                "value": 1140902,
                "label": "忻府区",
                "parent": 11409,
                "isLeaf": true
            }, {
                "value": 1140921,
                "label": "定襄县",
                "parent": 11409,
                "isLeaf": true
            }, {
                "value": 1140922,
                "label": "五台县",
                "parent": 11409,
                "isLeaf": true
            }, {
                "value": 1140923,
                "label": "代县",
                "parent": 11409,
                "isLeaf": true
            }, {
                "value": 1140924,
                "label": "繁峙县",
                "parent": 11409,
                "isLeaf": true
            }, {
                "value": 1140925,
                "label": "宁武县",
                "parent": 11409,
                "isLeaf": true
            }, {
                "value": 1140926,
                "label": "静乐县",
                "parent": 11409,
                "isLeaf": true
            }, {
                "value": 1140927,
                "label": "神池县",
                "parent": 11409,
                "isLeaf": true
            }, {
                "value": 1140928,
                "label": "五寨县",
                "parent": 11409,
                "isLeaf": true
            }, {
                "value": 1140929,
                "label": "岢岚县",
                "parent": 11409,
                "isLeaf": true
            }, {
                "value": 1140930,
                "label": "河曲县",
                "parent": 11409,
                "isLeaf": true
            }, {
                "value": 1140931,
                "label": "保德县",
                "parent": 11409,
                "isLeaf": true
            }, {
                "value": 1140932,
                "label": "偏关县",
                "parent": 11409,
                "isLeaf": true
            }, {
                "value": 1140981,
                "label": "原平市",
                "parent": 11409,
                "isLeaf": true
            }],
            "value": 11409,
            "label": "忻州市",
            "parent": 114
        }, {
            "children": [{
                "value": 1141002,
                "label": "尧都区",
                "parent": 11410,
                "isLeaf": true
            }, {
                "value": 1141021,
                "label": "曲沃县",
                "parent": 11410,
                "isLeaf": true
            }, {
                "value": 1141022,
                "label": "翼城县",
                "parent": 11410,
                "isLeaf": true
            }, {
                "value": 1141023,
                "label": "襄汾县",
                "parent": 11410,
                "isLeaf": true
            }, {
                "value": 1141024,
                "label": "洪洞县",
                "parent": 11410,
                "isLeaf": true
            }, {
                "value": 1141025,
                "label": "古县",
                "parent": 11410,
                "isLeaf": true
            }, {
                "value": 1141026,
                "label": "安泽县",
                "parent": 11410,
                "isLeaf": true
            }, {
                "value": 1141027,
                "label": "浮山县",
                "parent": 11410,
                "isLeaf": true
            }, {
                "value": 1141028,
                "label": "吉县",
                "parent": 11410,
                "isLeaf": true
            }, {
                "value": 1141029,
                "label": "乡宁县",
                "parent": 11410,
                "isLeaf": true
            }, {
                "value": 1141030,
                "label": "大宁县",
                "parent": 11410,
                "isLeaf": true
            }, {
                "value": 1141031,
                "label": "隰县",
                "parent": 11410,
                "isLeaf": true
            }, {
                "value": 1141032,
                "label": "永和县",
                "parent": 11410,
                "isLeaf": true
            }, {
                "value": 1141033,
                "label": "蒲县",
                "parent": 11410,
                "isLeaf": true
            }, {
                "value": 1141034,
                "label": "汾西县",
                "parent": 11410,
                "isLeaf": true
            }, {
                "value": 1141081,
                "label": "侯马市",
                "parent": 11410,
                "isLeaf": true
            }, {
                "value": 1141082,
                "label": "霍州市",
                "parent": 11410,
                "isLeaf": true
            }],
            "value": 11410,
            "label": "临汾市",
            "parent": 114
        }, {
            "children": [{
                "value": 1141102,
                "label": "离石区",
                "parent": 11411,
                "isLeaf": true
            }, {
                "value": 1141121,
                "label": "文水县",
                "parent": 11411,
                "isLeaf": true
            }, {
                "value": 1141122,
                "label": "交城县",
                "parent": 11411,
                "isLeaf": true
            }, {
                "value": 1141123,
                "label": "兴县",
                "parent": 11411,
                "isLeaf": true
            }, {
                "value": 1141124,
                "label": "临县",
                "parent": 11411,
                "isLeaf": true
            }, {
                "value": 1141125,
                "label": "柳林县",
                "parent": 11411,
                "isLeaf": true
            }, {
                "value": 1141126,
                "label": "石楼县",
                "parent": 11411,
                "isLeaf": true
            }, {
                "value": 1141127,
                "label": "岚县",
                "parent": 11411,
                "isLeaf": true
            }, {
                "value": 1141128,
                "label": "方山县",
                "parent": 11411,
                "isLeaf": true
            }, {
                "value": 1141129,
                "label": "中阳县",
                "parent": 11411,
                "isLeaf": true
            }, {
                "value": 1141130,
                "label": "交口县",
                "parent": 11411,
                "isLeaf": true
            }, {
                "value": 1141181,
                "label": "孝义市",
                "parent": 11411,
                "isLeaf": true
            }, {
                "value": 1141182,
                "label": "汾阳市",
                "parent": 11411,
                "isLeaf": true
            }],
            "value": 11411,
            "label": "吕梁市",
            "parent": 114
        }],
        "value": 114,
        "label": "山西省",
        "parent": 0
    }, {
        "children": [{
            "children": [{
                "value": 1150102,
                "label": "新城区",
                "parent": 11501,
                "isLeaf": true
            }, {
                "value": 1150103,
                "label": "回民区",
                "parent": 11501,
                "isLeaf": true
            }, {
                "value": 1150104,
                "label": "玉泉区",
                "parent": 11501,
                "isLeaf": true
            }, {
                "value": 1150105,
                "label": "赛罕区",
                "parent": 11501,
                "isLeaf": true
            }, {
                "value": 1150121,
                "label": "土默特左旗",
                "parent": 11501,
                "isLeaf": true
            }, {
                "value": 1150122,
                "label": "托克托县",
                "parent": 11501,
                "isLeaf": true
            }, {
                "value": 1150123,
                "label": "和林格尔县",
                "parent": 11501,
                "isLeaf": true
            }, {
                "value": 1150124,
                "label": "清水河县",
                "parent": 11501,
                "isLeaf": true
            }, {
                "value": 1150125,
                "label": "武川县",
                "parent": 11501,
                "isLeaf": true
            }],
            "value": 11501,
            "label": "呼和浩特市",
            "parent": 115
        }, {
            "children": [{
                "value": 1150202,
                "label": "东河区",
                "parent": 11502,
                "isLeaf": true
            }, {
                "value": 1150203,
                "label": "昆都仑区",
                "parent": 11502,
                "isLeaf": true
            }, {
                "value": 1150204,
                "label": "青山区",
                "parent": 11502,
                "isLeaf": true
            }, {
                "value": 1150205,
                "label": "石拐区",
                "parent": 11502,
                "isLeaf": true
            }, {
                "value": 1150206,
                "label": "白云鄂博矿区",
                "parent": 11502,
                "isLeaf": true
            }, {
                "value": 1150207,
                "label": "九原区",
                "parent": 11502,
                "isLeaf": true
            }, {
                "value": 1150221,
                "label": "土默特右旗",
                "parent": 11502,
                "isLeaf": true
            }, {
                "value": 1150222,
                "label": "固阳县",
                "parent": 11502,
                "isLeaf": true
            }, {
                "value": 1150223,
                "label": "达尔罕茂明安联合旗",
                "parent": 11502,
                "isLeaf": true
            }],
            "value": 11502,
            "label": "包头市",
            "parent": 115
        }, {
            "children": [{
                "value": 1150302,
                "label": "海勃湾区",
                "parent": 11503,
                "isLeaf": true
            }, {
                "value": 1150303,
                "label": "海南区",
                "parent": 11503,
                "isLeaf": true
            }, {
                "value": 1150304,
                "label": "乌达区",
                "parent": 11503,
                "isLeaf": true
            }],
            "value": 11503,
            "label": "乌海市",
            "parent": 115
        }, {
            "children": [{
                "value": 1150402,
                "label": "红山区",
                "parent": 11504,
                "isLeaf": true
            }, {
                "value": 1150403,
                "label": "元宝山区",
                "parent": 11504,
                "isLeaf": true
            }, {
                "value": 1150404,
                "label": "松山区",
                "parent": 11504,
                "isLeaf": true
            }, {
                "value": 1150421,
                "label": "阿鲁科尔沁旗",
                "parent": 11504,
                "isLeaf": true
            }, {
                "value": 1150422,
                "label": "巴林左旗",
                "parent": 11504,
                "isLeaf": true
            }, {
                "value": 1150423,
                "label": "巴林右旗",
                "parent": 11504,
                "isLeaf": true
            }, {
                "value": 1150424,
                "label": "林西县",
                "parent": 11504,
                "isLeaf": true
            }, {
                "value": 1150425,
                "label": "克什克腾旗",
                "parent": 11504,
                "isLeaf": true
            }, {
                "value": 1150426,
                "label": "翁牛特旗",
                "parent": 11504,
                "isLeaf": true
            }, {
                "value": 1150428,
                "label": "喀喇沁旗",
                "parent": 11504,
                "isLeaf": true
            }, {
                "value": 1150429,
                "label": "宁城县",
                "parent": 11504,
                "isLeaf": true
            }, {
                "value": 1150430,
                "label": "敖汉旗",
                "parent": 11504,
                "isLeaf": true
            }],
            "value": 11504,
            "label": "赤峰市",
            "parent": 115
        }, {
            "children": [{
                "value": 1150502,
                "label": "科尔沁区",
                "parent": 11505,
                "isLeaf": true
            }, {
                "value": 1150521,
                "label": "科尔沁左翼中旗",
                "parent": 11505,
                "isLeaf": true
            }, {
                "value": 1150522,
                "label": "科尔沁左翼后旗",
                "parent": 11505,
                "isLeaf": true
            }, {
                "value": 1150523,
                "label": "开鲁县",
                "parent": 11505,
                "isLeaf": true
            }, {
                "value": 1150524,
                "label": "库伦旗",
                "parent": 11505,
                "isLeaf": true
            }, {
                "value": 1150525,
                "label": "奈曼旗",
                "parent": 11505,
                "isLeaf": true
            }, {
                "value": 1150526,
                "label": "扎鲁特旗",
                "parent": 11505,
                "isLeaf": true
            }, {
                "value": 1150581,
                "label": "霍林郭勒市",
                "parent": 11505,
                "isLeaf": true
            }],
            "value": 11505,
            "label": "通辽市",
            "parent": 115
        }, {
            "children": [{
                "value": 1150602,
                "label": "东胜区",
                "parent": 11506,
                "isLeaf": true
            }, {
                "value": 1150621,
                "label": "达拉特旗",
                "parent": 11506,
                "isLeaf": true
            }, {
                "value": 1150622,
                "label": "准格尔旗",
                "parent": 11506,
                "isLeaf": true
            }, {
                "value": 1150623,
                "label": "鄂托克前旗",
                "parent": 11506,
                "isLeaf": true
            }, {
                "value": 1150624,
                "label": "鄂托克旗",
                "parent": 11506,
                "isLeaf": true
            }, {
                "value": 1150625,
                "label": "杭锦旗",
                "parent": 11506,
                "isLeaf": true
            }, {
                "value": 1150626,
                "label": "乌审旗",
                "parent": 11506,
                "isLeaf": true
            }, {
                "value": 1150627,
                "label": "伊金霍洛旗",
                "parent": 11506,
                "isLeaf": true
            }],
            "value": 11506,
            "label": "鄂尔多斯市",
            "parent": 115
        }, {
            "children": [{
                "value": 1150702,
                "label": "海拉尔区",
                "parent": 11507,
                "isLeaf": true
            }, {
                "value": 1150703,
                "label": "扎赉诺尔区",
                "parent": 11507,
                "isLeaf": true
            }, {
                "value": 1150721,
                "label": "阿荣旗",
                "parent": 11507,
                "isLeaf": true
            }, {
                "value": 1150722,
                "label": "莫力达瓦达斡尔族自治旗",
                "parent": 11507,
                "isLeaf": true
            }, {
                "value": 1150723,
                "label": "鄂伦春自治旗",
                "parent": 11507,
                "isLeaf": true
            }, {
                "value": 1150724,
                "label": "鄂温克族自治旗",
                "parent": 11507,
                "isLeaf": true
            }, {
                "value": 1150725,
                "label": "陈巴尔虎旗",
                "parent": 11507,
                "isLeaf": true
            }, {
                "value": 1150726,
                "label": "新巴尔虎左旗",
                "parent": 11507,
                "isLeaf": true
            }, {
                "value": 1150727,
                "label": "新巴尔虎右旗",
                "parent": 11507,
                "isLeaf": true
            }, {
                "value": 1150781,
                "label": "满洲里市",
                "parent": 11507,
                "isLeaf": true
            }, {
                "value": 1150782,
                "label": "牙克石市",
                "parent": 11507,
                "isLeaf": true
            }, {
                "value": 1150783,
                "label": "扎兰屯市",
                "parent": 11507,
                "isLeaf": true
            }, {
                "value": 1150784,
                "label": "额尔古纳市",
                "parent": 11507,
                "isLeaf": true
            }, {
                "value": 1150785,
                "label": "根河市",
                "parent": 11507,
                "isLeaf": true
            }],
            "value": 11507,
            "label": "呼伦贝尔市",
            "parent": 115
        }, {
            "children": [{
                "value": 1150802,
                "label": "临河区",
                "parent": 11508,
                "isLeaf": true
            }, {
                "value": 1150821,
                "label": "五原县",
                "parent": 11508,
                "isLeaf": true
            }, {
                "value": 1150822,
                "label": "磴口县",
                "parent": 11508,
                "isLeaf": true
            }, {
                "value": 1150823,
                "label": "乌拉特前旗",
                "parent": 11508,
                "isLeaf": true
            }, {
                "value": 1150824,
                "label": "乌拉特中旗",
                "parent": 11508,
                "isLeaf": true
            }, {
                "value": 1150825,
                "label": "乌拉特后旗",
                "parent": 11508,
                "isLeaf": true
            }, {
                "value": 1150826,
                "label": "杭锦后旗",
                "parent": 11508,
                "isLeaf": true
            }],
            "value": 11508,
            "label": "巴彦淖尔市",
            "parent": 115
        }, {
            "children": [{
                "value": 1150902,
                "label": "集宁区",
                "parent": 11509,
                "isLeaf": true
            }, {
                "value": 1150921,
                "label": "卓资县",
                "parent": 11509,
                "isLeaf": true
            }, {
                "value": 1150922,
                "label": "化德县",
                "parent": 11509,
                "isLeaf": true
            }, {
                "value": 1150923,
                "label": "商都县",
                "parent": 11509,
                "isLeaf": true
            }, {
                "value": 1150924,
                "label": "兴和县",
                "parent": 11509,
                "isLeaf": true
            }, {
                "value": 1150925,
                "label": "凉城县",
                "parent": 11509,
                "isLeaf": true
            }, {
                "value": 1150926,
                "label": "察哈尔右翼前旗",
                "parent": 11509,
                "isLeaf": true
            }, {
                "value": 1150927,
                "label": "察哈尔右翼中旗",
                "parent": 11509,
                "isLeaf": true
            }, {
                "value": 1150928,
                "label": "察哈尔右翼后旗",
                "parent": 11509,
                "isLeaf": true
            }, {
                "value": 1150929,
                "label": "四子王旗",
                "parent": 11509,
                "isLeaf": true
            }, {
                "value": 1150981,
                "label": "丰镇市",
                "parent": 11509,
                "isLeaf": true
            }],
            "value": 11509,
            "label": "乌兰察布市",
            "parent": 115
        }, {
            "children": [{
                "value": 1152201,
                "label": "乌兰浩特市",
                "parent": 11522,
                "isLeaf": true
            }, {
                "value": 1152202,
                "label": "阿尔山市",
                "parent": 11522,
                "isLeaf": true
            }, {
                "value": 1152221,
                "label": "科尔沁右翼前旗",
                "parent": 11522,
                "isLeaf": true
            }, {
                "value": 1152222,
                "label": "科尔沁右翼中旗",
                "parent": 11522,
                "isLeaf": true
            }, {
                "value": 1152223,
                "label": "扎赉特旗",
                "parent": 11522,
                "isLeaf": true
            }, {
                "value": 1152224,
                "label": "突泉县",
                "parent": 11522,
                "isLeaf": true
            }],
            "value": 11522,
            "label": "兴安盟",
            "parent": 115
        }, {
            "children": [{
                "value": 1152501,
                "label": "二连浩特市",
                "parent": 11525,
                "isLeaf": true
            }, {
                "value": 1152502,
                "label": "锡林浩特市",
                "parent": 11525,
                "isLeaf": true
            }, {
                "value": 1152522,
                "label": "阿巴嘎旗",
                "parent": 11525,
                "isLeaf": true
            }, {
                "value": 1152523,
                "label": "苏尼特左旗",
                "parent": 11525,
                "isLeaf": true
            }, {
                "value": 1152524,
                "label": "苏尼特右旗",
                "parent": 11525,
                "isLeaf": true
            }, {
                "value": 1152525,
                "label": "东乌珠穆沁旗",
                "parent": 11525,
                "isLeaf": true
            }, {
                "value": 1152526,
                "label": "西乌珠穆沁旗",
                "parent": 11525,
                "isLeaf": true
            }, {
                "value": 1152527,
                "label": "太仆寺旗",
                "parent": 11525,
                "isLeaf": true
            }, {
                "value": 1152528,
                "label": "镶黄旗",
                "parent": 11525,
                "isLeaf": true
            }, {
                "value": 1152529,
                "label": "正镶白旗",
                "parent": 11525,
                "isLeaf": true
            }, {
                "value": 1152530,
                "label": "正蓝旗",
                "parent": 11525,
                "isLeaf": true
            }, {
                "value": 1152531,
                "label": "多伦县",
                "parent": 11525,
                "isLeaf": true
            }],
            "value": 11525,
            "label": "锡林郭勒盟",
            "parent": 115
        }, {
            "children": [{
                "value": 1152921,
                "label": "阿拉善左旗",
                "parent": 11529,
                "isLeaf": true
            }, {
                "value": 1152922,
                "label": "阿拉善右旗",
                "parent": 11529,
                "isLeaf": true
            }, {
                "value": 1152923,
                "label": "额济纳旗",
                "parent": 11529,
                "isLeaf": true
            }],
            "value": 11529,
            "label": "阿拉善盟",
            "parent": 115
        }],
        "value": 115,
        "label": "内蒙古自治区",
        "parent": 0
    }, {
        "children": [{
            "children": [{
                "value": 1210102,
                "label": "和平区",
                "parent": 12101,
                "isLeaf": true
            }, {
                "value": 1210103,
                "label": "沈河区",
                "parent": 12101,
                "isLeaf": true
            }, {
                "value": 1210104,
                "label": "大东区",
                "parent": 12101,
                "isLeaf": true
            }, {
                "value": 1210105,
                "label": "皇姑区",
                "parent": 12101,
                "isLeaf": true
            }, {
                "value": 1210106,
                "label": "铁西区",
                "parent": 12101,
                "isLeaf": true
            }, {
                "value": 1210111,
                "label": "苏家屯区",
                "parent": 12101,
                "isLeaf": true
            }, {
                "value": 1210112,
                "label": "东陵区",
                "parent": 12101,
                "isLeaf": true
            }, {
                "value": 1210113,
                "label": "沈北新区",
                "parent": 12101,
                "isLeaf": true
            }, {
                "value": 1210114,
                "label": "于洪区",
                "parent": 12101,
                "isLeaf": true
            }, {
                "value": 1210122,
                "label": "辽中县",
                "parent": 12101,
                "isLeaf": true
            }, {
                "value": 1210123,
                "label": "康平县",
                "parent": 12101,
                "isLeaf": true
            }, {
                "value": 1210124,
                "label": "法库县",
                "parent": 12101,
                "isLeaf": true
            }, {
                "value": 1210181,
                "label": "新民市",
                "parent": 12101,
                "isLeaf": true
            }],
            "value": 12101,
            "label": "沈阳市",
            "parent": 121
        }, {
            "children": [{
                "value": 1210202,
                "label": "中山区",
                "parent": 12102,
                "isLeaf": true
            }, {
                "value": 1210203,
                "label": "西岗区",
                "parent": 12102,
                "isLeaf": true
            }, {
                "value": 1210204,
                "label": "沙河口区",
                "parent": 12102,
                "isLeaf": true
            }, {
                "value": 1210211,
                "label": "甘井子区",
                "parent": 12102,
                "isLeaf": true
            }, {
                "value": 1210212,
                "label": "旅顺口区",
                "parent": 12102,
                "isLeaf": true
            }, {
                "value": 1210213,
                "label": "金州区",
                "parent": 12102,
                "isLeaf": true
            }, {
                "value": 1210214,
                "label": "普兰店区",
                "parent": 12102,
                "isLeaf": true
            }, {
                "value": 1210224,
                "label": "长海县",
                "parent": 12102,
                "isLeaf": true
            }, {
                "value": 1210281,
                "label": "瓦房店市",
                "parent": 12102,
                "isLeaf": true
            }, {
                "value": 1210283,
                "label": "庄河市",
                "parent": 12102,
                "isLeaf": true
            }],
            "value": 12102,
            "label": "大连市",
            "parent": 121
        }, {
            "children": [{
                "value": 1210302,
                "label": "铁东区",
                "parent": 12103,
                "isLeaf": true
            }, {
                "value": 1210303,
                "label": "铁西区",
                "parent": 12103,
                "isLeaf": true
            }, {
                "value": 1210304,
                "label": "立山区",
                "parent": 12103,
                "isLeaf": true
            }, {
                "value": 1210311,
                "label": "千山区",
                "parent": 12103,
                "isLeaf": true
            }, {
                "value": 1210321,
                "label": "台安县",
                "parent": 12103,
                "isLeaf": true
            }, {
                "value": 1210323,
                "label": "岫岩满族自治县",
                "parent": 12103,
                "isLeaf": true
            }, {
                "value": 1210381,
                "label": "海城市",
                "parent": 12103,
                "isLeaf": true
            }],
            "value": 12103,
            "label": "鞍山市",
            "parent": 121
        }, {
            "children": [{
                "value": 1210402,
                "label": "新抚区",
                "parent": 12104,
                "isLeaf": true
            }, {
                "value": 1210403,
                "label": "东洲区",
                "parent": 12104,
                "isLeaf": true
            }, {
                "value": 1210404,
                "label": "望花区",
                "parent": 12104,
                "isLeaf": true
            }, {
                "value": 1210411,
                "label": "顺城区",
                "parent": 12104,
                "isLeaf": true
            }, {
                "value": 1210421,
                "label": "抚顺县",
                "parent": 12104,
                "isLeaf": true
            }, {
                "value": 1210422,
                "label": "新宾满族自治县",
                "parent": 12104,
                "isLeaf": true
            }, {
                "value": 1210423,
                "label": "清原满族自治县",
                "parent": 12104,
                "isLeaf": true
            }],
            "value": 12104,
            "label": "抚顺市",
            "parent": 121
        }, {
            "children": [{
                "value": 1210502,
                "label": "平山区",
                "parent": 12105,
                "isLeaf": true
            }, {
                "value": 1210503,
                "label": "溪湖区",
                "parent": 12105,
                "isLeaf": true
            }, {
                "value": 1210504,
                "label": "明山区",
                "parent": 12105,
                "isLeaf": true
            }, {
                "value": 1210505,
                "label": "南芬区",
                "parent": 12105,
                "isLeaf": true
            }, {
                "value": 1210521,
                "label": "本溪满族自治县",
                "parent": 12105,
                "isLeaf": true
            }, {
                "value": 1210522,
                "label": "桓仁满族自治县",
                "parent": 12105,
                "isLeaf": true
            }],
            "value": 12105,
            "label": "本溪市",
            "parent": 121
        }, {
            "children": [{
                "value": 1210602,
                "label": "元宝区",
                "parent": 12106,
                "isLeaf": true
            }, {
                "value": 1210603,
                "label": "振兴区",
                "parent": 12106,
                "isLeaf": true
            }, {
                "value": 1210604,
                "label": "振安区",
                "parent": 12106,
                "isLeaf": true
            }, {
                "value": 1210624,
                "label": "宽甸满族自治县",
                "parent": 12106,
                "isLeaf": true
            }, {
                "value": 1210681,
                "label": "东港市",
                "parent": 12106,
                "isLeaf": true
            }, {
                "value": 1210682,
                "label": "凤城市",
                "parent": 12106,
                "isLeaf": true
            }],
            "value": 12106,
            "label": "丹东市",
            "parent": 121
        }, {
            "children": [{
                "value": 1210702,
                "label": "古塔区",
                "parent": 12107,
                "isLeaf": true
            }, {
                "value": 1210703,
                "label": "凌河区",
                "parent": 12107,
                "isLeaf": true
            }, {
                "value": 1210711,
                "label": "太和区",
                "parent": 12107,
                "isLeaf": true
            }, {
                "value": 1210726,
                "label": "黑山县",
                "parent": 12107,
                "isLeaf": true
            }, {
                "value": 1210727,
                "label": "义县",
                "parent": 12107,
                "isLeaf": true
            }, {
                "value": 1210781,
                "label": "凌海市",
                "parent": 12107,
                "isLeaf": true
            }, {
                "value": 1210782,
                "label": "北镇市",
                "parent": 12107,
                "isLeaf": true
            }],
            "value": 12107,
            "label": "锦州市",
            "parent": 121
        }, {
            "children": [{
                "value": 1210802,
                "label": "站前区",
                "parent": 12108,
                "isLeaf": true
            }, {
                "value": 1210803,
                "label": "西市区",
                "parent": 12108,
                "isLeaf": true
            }, {
                "value": 1210804,
                "label": "鲅鱼圈区",
                "parent": 12108,
                "isLeaf": true
            }, {
                "value": 1210811,
                "label": "老边区",
                "parent": 12108,
                "isLeaf": true
            }, {
                "value": 1210881,
                "label": "盖州市",
                "parent": 12108,
                "isLeaf": true
            }, {
                "value": 1210882,
                "label": "大石桥市",
                "parent": 12108,
                "isLeaf": true
            }],
            "value": 12108,
            "label": "营口市",
            "parent": 121
        }, {
            "children": [{
                "value": 1210902,
                "label": "海州区",
                "parent": 12109,
                "isLeaf": true
            }, {
                "value": 1210903,
                "label": "新邱区",
                "parent": 12109,
                "isLeaf": true
            }, {
                "value": 1210904,
                "label": "太平区",
                "parent": 12109,
                "isLeaf": true
            }, {
                "value": 1210905,
                "label": "清河门区",
                "parent": 12109,
                "isLeaf": true
            }, {
                "value": 1210911,
                "label": "细河区",
                "parent": 12109,
                "isLeaf": true
            }, {
                "value": 1210921,
                "label": "阜新蒙古族自治县",
                "parent": 12109,
                "isLeaf": true
            }, {
                "value": 1210922,
                "label": "彰武县",
                "parent": 12109,
                "isLeaf": true
            }],
            "value": 12109,
            "label": "阜新市",
            "parent": 121
        }, {
            "children": [{
                "value": 1211002,
                "label": "白塔区",
                "parent": 12110,
                "isLeaf": true
            }, {
                "value": 1211003,
                "label": "文圣区",
                "parent": 12110,
                "isLeaf": true
            }, {
                "value": 1211004,
                "label": "宏伟区",
                "parent": 12110,
                "isLeaf": true
            }, {
                "value": 1211005,
                "label": "弓长岭区",
                "parent": 12110,
                "isLeaf": true
            }, {
                "value": 1211011,
                "label": "太子河区",
                "parent": 12110,
                "isLeaf": true
            }, {
                "value": 1211021,
                "label": "辽阳县",
                "parent": 12110,
                "isLeaf": true
            }, {
                "value": 1211081,
                "label": "灯塔市",
                "parent": 12110,
                "isLeaf": true
            }],
            "value": 12110,
            "label": "辽阳市",
            "parent": 121
        }, {
            "children": [{
                "value": 1211102,
                "label": "双台子区",
                "parent": 12111,
                "isLeaf": true
            }, {
                "value": 1211103,
                "label": "兴隆台区",
                "parent": 12111,
                "isLeaf": true
            }, {
                "value": 1211121,
                "label": "大洼县",
                "parent": 12111,
                "isLeaf": true
            }, {
                "value": 1211122,
                "label": "盘山县",
                "parent": 12111,
                "isLeaf": true
            }],
            "value": 12111,
            "label": "盘锦市",
            "parent": 121
        }, {
            "children": [{
                "value": 1211202,
                "label": "银州区",
                "parent": 12112,
                "isLeaf": true
            }, {
                "value": 1211204,
                "label": "清河区",
                "parent": 12112,
                "isLeaf": true
            }, {
                "value": 1211221,
                "label": "铁岭县",
                "parent": 12112,
                "isLeaf": true
            }, {
                "value": 1211223,
                "label": "西丰县",
                "parent": 12112,
                "isLeaf": true
            }, {
                "value": 1211224,
                "label": "昌图县",
                "parent": 12112,
                "isLeaf": true
            }, {
                "value": 1211281,
                "label": "调兵山市",
                "parent": 12112,
                "isLeaf": true
            }, {
                "value": 1211282,
                "label": "开原市",
                "parent": 12112,
                "isLeaf": true
            }],
            "value": 12112,
            "label": "铁岭市",
            "parent": 121
        }, {
            "children": [{
                "value": 1211302,
                "label": "双塔区",
                "parent": 12113,
                "isLeaf": true
            }, {
                "value": 1211303,
                "label": "龙城区",
                "parent": 12113,
                "isLeaf": true
            }, {
                "value": 1211321,
                "label": "朝阳县",
                "parent": 12113,
                "isLeaf": true
            }, {
                "value": 1211322,
                "label": "建平县",
                "parent": 12113,
                "isLeaf": true
            }, {
                "value": 1211324,
                "label": "喀喇沁左翼蒙古族自治县",
                "parent": 12113,
                "isLeaf": true
            }, {
                "value": 1211381,
                "label": "北票市",
                "parent": 12113,
                "isLeaf": true
            }, {
                "value": 1211382,
                "label": "凌源市",
                "parent": 12113,
                "isLeaf": true
            }],
            "value": 12113,
            "label": "朝阳市",
            "parent": 121
        }, {
            "children": [{
                "value": 1211402,
                "label": "连山区",
                "parent": 12114,
                "isLeaf": true
            }, {
                "value": 1211403,
                "label": "龙港区",
                "parent": 12114,
                "isLeaf": true
            }, {
                "value": 1211404,
                "label": "南票区",
                "parent": 12114,
                "isLeaf": true
            }, {
                "value": 1211421,
                "label": "绥中县",
                "parent": 12114,
                "isLeaf": true
            }, {
                "value": 1211422,
                "label": "建昌县",
                "parent": 12114,
                "isLeaf": true
            }, {
                "value": 1211481,
                "label": "兴城市",
                "parent": 12114,
                "isLeaf": true
            }],
            "value": 12114,
            "label": "葫芦岛市",
            "parent": 121
        }],
        "value": 121,
        "label": "辽宁省",
        "parent": 0
    }, {
        "children": [{
            "children": [{
                "value": 1220102,
                "label": "南关区",
                "parent": 12201,
                "isLeaf": true
            }, {
                "value": 1220103,
                "label": "宽城区",
                "parent": 12201,
                "isLeaf": true
            }, {
                "value": 1220104,
                "label": "朝阳区",
                "parent": 12201,
                "isLeaf": true
            }, {
                "value": 1220105,
                "label": "二道区",
                "parent": 12201,
                "isLeaf": true
            }, {
                "value": 1220106,
                "label": "绿园区",
                "parent": 12201,
                "isLeaf": true
            }, {
                "value": 1220112,
                "label": "双阳区",
                "parent": 12201,
                "isLeaf": true
            }, {
                "value": 1220113,
                "label": "九台区",
                "parent": 12201,
                "isLeaf": true
            }, {
                "value": 1220122,
                "label": "农安县",
                "parent": 12201,
                "isLeaf": true
            }, {
                "value": 1220182,
                "label": "榆树市",
                "parent": 12201,
                "isLeaf": true
            }, {
                "value": 1220183,
                "label": "德惠市",
                "parent": 12201,
                "isLeaf": true
            }],
            "value": 12201,
            "label": "长春市",
            "parent": 122
        }, {
            "children": [{
                "value": 1220202,
                "label": "昌邑区",
                "parent": 12202,
                "isLeaf": true
            }, {
                "value": 1220203,
                "label": "龙潭区",
                "parent": 12202,
                "isLeaf": true
            }, {
                "value": 1220204,
                "label": "船营区",
                "parent": 12202,
                "isLeaf": true
            }, {
                "value": 1220211,
                "label": "丰满区",
                "parent": 12202,
                "isLeaf": true
            }, {
                "value": 1220221,
                "label": "永吉县",
                "parent": 12202,
                "isLeaf": true
            }, {
                "value": 1220281,
                "label": "蛟河市",
                "parent": 12202,
                "isLeaf": true
            }, {
                "value": 1220282,
                "label": "桦甸市",
                "parent": 12202,
                "isLeaf": true
            }, {
                "value": 1220283,
                "label": "舒兰市",
                "parent": 12202,
                "isLeaf": true
            }, {
                "value": 1220284,
                "label": "磐石市",
                "parent": 12202,
                "isLeaf": true
            }],
            "value": 12202,
            "label": "吉林市",
            "parent": 122
        }, {
            "children": [{
                "value": 1220302,
                "label": "铁西区",
                "parent": 12203,
                "isLeaf": true
            }, {
                "value": 1220303,
                "label": "铁东区",
                "parent": 12203,
                "isLeaf": true
            }, {
                "value": 1220322,
                "label": "梨树县",
                "parent": 12203,
                "isLeaf": true
            }, {
                "value": 1220323,
                "label": "伊通满族自治县",
                "parent": 12203,
                "isLeaf": true
            }, {
                "value": 1220381,
                "label": "公主岭市",
                "parent": 12203,
                "isLeaf": true
            }, {
                "value": 1220382,
                "label": "双辽市",
                "parent": 12203,
                "isLeaf": true
            }],
            "value": 12203,
            "label": "四平市",
            "parent": 122
        }, {
            "children": [{
                "value": 1220402,
                "label": "龙山区",
                "parent": 12204,
                "isLeaf": true
            }, {
                "value": 1220403,
                "label": "西安区",
                "parent": 12204,
                "isLeaf": true
            }, {
                "value": 1220421,
                "label": "东丰县",
                "parent": 12204,
                "isLeaf": true
            }, {
                "value": 1220422,
                "label": "东辽县",
                "parent": 12204,
                "isLeaf": true
            }],
            "value": 12204,
            "label": "辽源市",
            "parent": 122
        }, {
            "children": [{
                "value": 1220502,
                "label": "东昌区",
                "parent": 12205,
                "isLeaf": true
            }, {
                "value": 1220503,
                "label": "二道江区",
                "parent": 12205,
                "isLeaf": true
            }, {
                "value": 1220521,
                "label": "通化县",
                "parent": 12205,
                "isLeaf": true
            }, {
                "value": 1220523,
                "label": "辉南县",
                "parent": 12205,
                "isLeaf": true
            }, {
                "value": 1220524,
                "label": "柳河县",
                "parent": 12205,
                "isLeaf": true
            }, {
                "value": 1220581,
                "label": "梅河口市",
                "parent": 12205,
                "isLeaf": true
            }, {
                "value": 1220582,
                "label": "集安市",
                "parent": 12205,
                "isLeaf": true
            }],
            "value": 12205,
            "label": "通化市",
            "parent": 122
        }, {
            "children": [{
                "value": 1220602,
                "label": "浑江区",
                "parent": 12206,
                "isLeaf": true
            }, {
                "value": 1220605,
                "label": "江源区",
                "parent": 12206,
                "isLeaf": true
            }, {
                "value": 1220621,
                "label": "抚松县",
                "parent": 12206,
                "isLeaf": true
            }, {
                "value": 1220622,
                "label": "靖宇县",
                "parent": 12206,
                "isLeaf": true
            }, {
                "value": 1220623,
                "label": "长白朝鲜族自治县",
                "parent": 12206,
                "isLeaf": true
            }, {
                "value": 1220681,
                "label": "临江市",
                "parent": 12206,
                "isLeaf": true
            }],
            "value": 12206,
            "label": "白山市",
            "parent": 122
        }, {
            "children": [{
                "value": 1220702,
                "label": "宁江区",
                "parent": 12207,
                "isLeaf": true
            }, {
                "value": 1220721,
                "label": "前郭尔罗斯蒙古族自治县",
                "parent": 12207,
                "isLeaf": true
            }, {
                "value": 1220722,
                "label": "长岭县",
                "parent": 12207,
                "isLeaf": true
            }, {
                "value": 1220723,
                "label": "乾安县",
                "parent": 12207,
                "isLeaf": true
            }, {
                "value": 1220781,
                "label": "扶余市",
                "parent": 12207,
                "isLeaf": true
            }],
            "value": 12207,
            "label": "松原市",
            "parent": 122
        }, {
            "children": [{
                "value": 1220802,
                "label": "洮北区",
                "parent": 12208,
                "isLeaf": true
            }, {
                "value": 1220821,
                "label": "镇赉县",
                "parent": 12208,
                "isLeaf": true
            }, {
                "value": 1220822,
                "label": "通榆县",
                "parent": 12208,
                "isLeaf": true
            }, {
                "value": 1220881,
                "label": "洮南市",
                "parent": 12208,
                "isLeaf": true
            }, {
                "value": 1220882,
                "label": "大安市",
                "parent": 12208,
                "isLeaf": true
            }],
            "value": 12208,
            "label": "白城市",
            "parent": 122
        }, {
            "children": [{
                "value": 1222401,
                "label": "延吉市",
                "parent": 12224,
                "isLeaf": true
            }, {
                "value": 1222402,
                "label": "图们市",
                "parent": 12224,
                "isLeaf": true
            }, {
                "value": 1222403,
                "label": "敦化市",
                "parent": 12224,
                "isLeaf": true
            }, {
                "value": 1222404,
                "label": "珲春市",
                "parent": 12224,
                "isLeaf": true
            }, {
                "value": 1222405,
                "label": "龙井市",
                "parent": 12224,
                "isLeaf": true
            }, {
                "value": 1222406,
                "label": "和龙市",
                "parent": 12224,
                "isLeaf": true
            }, {
                "value": 1222424,
                "label": "汪清县",
                "parent": 12224,
                "isLeaf": true
            }, {
                "value": 1222426,
                "label": "安图县",
                "parent": 12224,
                "isLeaf": true
            }],
            "value": 12224,
            "label": "延边朝鲜族自治州",
            "parent": 122
        }],
        "value": 122,
        "label": "吉林省",
        "parent": 0
    }, {
        "children": [{
            "children": [{
                "value": 1230102,
                "label": "道里区",
                "parent": 12301,
                "isLeaf": true
            }, {
                "value": 1230103,
                "label": "南岗区",
                "parent": 12301,
                "isLeaf": true
            }, {
                "value": 1230104,
                "label": "道外区",
                "parent": 12301,
                "isLeaf": true
            }, {
                "value": 1230108,
                "label": "平房区",
                "parent": 12301,
                "isLeaf": true
            }, {
                "value": 1230109,
                "label": "松北区",
                "parent": 12301,
                "isLeaf": true
            }, {
                "value": 1230110,
                "label": "香坊区",
                "parent": 12301,
                "isLeaf": true
            }, {
                "value": 1230111,
                "label": "呼兰区",
                "parent": 12301,
                "isLeaf": true
            }, {
                "value": 1230112,
                "label": "阿城区",
                "parent": 12301,
                "isLeaf": true
            }, {
                "value": 1230113,
                "label": "双城区",
                "parent": 12301,
                "isLeaf": true
            }, {
                "value": 1230123,
                "label": "依兰县",
                "parent": 12301,
                "isLeaf": true
            }, {
                "value": 1230124,
                "label": "方正县",
                "parent": 12301,
                "isLeaf": true
            }, {
                "value": 1230125,
                "label": "宾县",
                "parent": 12301,
                "isLeaf": true
            }, {
                "value": 1230126,
                "label": "巴彦县",
                "parent": 12301,
                "isLeaf": true
            }, {
                "value": 1230127,
                "label": "木兰县",
                "parent": 12301,
                "isLeaf": true
            }, {
                "value": 1230128,
                "label": "通河县",
                "parent": 12301,
                "isLeaf": true
            }, {
                "value": 1230129,
                "label": "延寿县",
                "parent": 12301,
                "isLeaf": true
            }, {
                "value": 1230183,
                "label": "尚志市",
                "parent": 12301,
                "isLeaf": true
            }, {
                "value": 1230184,
                "label": "五常市",
                "parent": 12301,
                "isLeaf": true
            }],
            "value": 12301,
            "label": "哈尔滨市",
            "parent": 123
        }, {
            "children": [{
                "value": 1230202,
                "label": "龙沙区",
                "parent": 12302,
                "isLeaf": true
            }, {
                "value": 1230203,
                "label": "建华区",
                "parent": 12302,
                "isLeaf": true
            }, {
                "value": 1230204,
                "label": "铁锋区",
                "parent": 12302,
                "isLeaf": true
            }, {
                "value": 1230205,
                "label": "昂昂溪区",
                "parent": 12302,
                "isLeaf": true
            }, {
                "value": 1230206,
                "label": "富拉尔基区",
                "parent": 12302,
                "isLeaf": true
            }, {
                "value": 1230207,
                "label": "碾子山区",
                "parent": 12302,
                "isLeaf": true
            }, {
                "value": 1230208,
                "label": "梅里斯达斡尔族区",
                "parent": 12302,
                "isLeaf": true
            }, {
                "value": 1230221,
                "label": "龙江县",
                "parent": 12302,
                "isLeaf": true
            }, {
                "value": 1230223,
                "label": "依安县",
                "parent": 12302,
                "isLeaf": true
            }, {
                "value": 1230224,
                "label": "泰来县",
                "parent": 12302,
                "isLeaf": true
            }, {
                "value": 1230225,
                "label": "甘南县",
                "parent": 12302,
                "isLeaf": true
            }, {
                "value": 1230227,
                "label": "富裕县",
                "parent": 12302,
                "isLeaf": true
            }, {
                "value": 1230229,
                "label": "克山县",
                "parent": 12302,
                "isLeaf": true
            }, {
                "value": 1230230,
                "label": "克东县",
                "parent": 12302,
                "isLeaf": true
            }, {
                "value": 1230231,
                "label": "拜泉县",
                "parent": 12302,
                "isLeaf": true
            }, {
                "value": 1230281,
                "label": "讷河市",
                "parent": 12302,
                "isLeaf": true
            }],
            "value": 12302,
            "label": "齐齐哈尔市",
            "parent": 123
        }, {
            "children": [{
                "value": 1230302,
                "label": "鸡冠区",
                "parent": 12303,
                "isLeaf": true
            }, {
                "value": 1230303,
                "label": "恒山区",
                "parent": 12303,
                "isLeaf": true
            }, {
                "value": 1230304,
                "label": "滴道区",
                "parent": 12303,
                "isLeaf": true
            }, {
                "value": 1230305,
                "label": "梨树区",
                "parent": 12303,
                "isLeaf": true
            }, {
                "value": 1230306,
                "label": "城子河区",
                "parent": 12303,
                "isLeaf": true
            }, {
                "value": 1230307,
                "label": "麻山区",
                "parent": 12303,
                "isLeaf": true
            }, {
                "value": 1230321,
                "label": "鸡东县",
                "parent": 12303,
                "isLeaf": true
            }, {
                "value": 1230381,
                "label": "虎林市",
                "parent": 12303,
                "isLeaf": true
            }, {
                "value": 1230382,
                "label": "密山市",
                "parent": 12303,
                "isLeaf": true
            }],
            "value": 12303,
            "label": "鸡西市",
            "parent": 123
        }, {
            "children": [{
                "value": 1230402,
                "label": "向阳区",
                "parent": 12304,
                "isLeaf": true
            }, {
                "value": 1230403,
                "label": "工农区",
                "parent": 12304,
                "isLeaf": true
            }, {
                "value": 1230404,
                "label": "南山区",
                "parent": 12304,
                "isLeaf": true
            }, {
                "value": 1230405,
                "label": "兴安区",
                "parent": 12304,
                "isLeaf": true
            }, {
                "value": 1230406,
                "label": "东山区",
                "parent": 12304,
                "isLeaf": true
            }, {
                "value": 1230407,
                "label": "兴山区",
                "parent": 12304,
                "isLeaf": true
            }, {
                "value": 1230421,
                "label": "萝北县",
                "parent": 12304,
                "isLeaf": true
            }, {
                "value": 1230422,
                "label": "绥滨县",
                "parent": 12304,
                "isLeaf": true
            }],
            "value": 12304,
            "label": "鹤岗市",
            "parent": 123
        }, {
            "children": [{
                "value": 1230502,
                "label": "尖山区",
                "parent": 12305,
                "isLeaf": true
            }, {
                "value": 1230503,
                "label": "岭东区",
                "parent": 12305,
                "isLeaf": true
            }, {
                "value": 1230505,
                "label": "四方台区",
                "parent": 12305,
                "isLeaf": true
            }, {
                "value": 1230506,
                "label": "宝山区",
                "parent": 12305,
                "isLeaf": true
            }, {
                "value": 1230521,
                "label": "集贤县",
                "parent": 12305,
                "isLeaf": true
            }, {
                "value": 1230522,
                "label": "友谊县",
                "parent": 12305,
                "isLeaf": true
            }, {
                "value": 1230523,
                "label": "宝清县",
                "parent": 12305,
                "isLeaf": true
            }, {
                "value": 1230524,
                "label": "饶河县",
                "parent": 12305,
                "isLeaf": true
            }],
            "value": 12305,
            "label": "双鸭山市",
            "parent": 123
        }, {
            "children": [{
                "value": 1230602,
                "label": "萨尔图区",
                "parent": 12306,
                "isLeaf": true
            }, {
                "value": 1230603,
                "label": "龙凤区",
                "parent": 12306,
                "isLeaf": true
            }, {
                "value": 1230604,
                "label": "让胡路区",
                "parent": 12306,
                "isLeaf": true
            }, {
                "value": 1230605,
                "label": "红岗区",
                "parent": 12306,
                "isLeaf": true
            }, {
                "value": 1230606,
                "label": "大同区",
                "parent": 12306,
                "isLeaf": true
            }, {
                "value": 1230621,
                "label": "肇州县",
                "parent": 12306,
                "isLeaf": true
            }, {
                "value": 1230622,
                "label": "肇源县",
                "parent": 12306,
                "isLeaf": true
            }, {
                "value": 1230623,
                "label": "林甸县",
                "parent": 12306,
                "isLeaf": true
            }, {
                "value": 1230624,
                "label": "杜尔伯特蒙古族自治县",
                "parent": 12306,
                "isLeaf": true
            }],
            "value": 12306,
            "label": "大庆市",
            "parent": 123
        }, {
            "children": [{
                "value": 1230702,
                "label": "伊春区",
                "parent": 12307,
                "isLeaf": true
            }, {
                "value": 1230703,
                "label": "南岔区",
                "parent": 12307,
                "isLeaf": true
            }, {
                "value": 1230704,
                "label": "友好区",
                "parent": 12307,
                "isLeaf": true
            }, {
                "value": 1230705,
                "label": "西林区",
                "parent": 12307,
                "isLeaf": true
            }, {
                "value": 1230706,
                "label": "翠峦区",
                "parent": 12307,
                "isLeaf": true
            }, {
                "value": 1230707,
                "label": "新青区",
                "parent": 12307,
                "isLeaf": true
            }, {
                "value": 1230708,
                "label": "美溪区",
                "parent": 12307,
                "isLeaf": true
            }, {
                "value": 1230709,
                "label": "金山屯区",
                "parent": 12307,
                "isLeaf": true
            }, {
                "value": 1230710,
                "label": "五营区",
                "parent": 12307,
                "isLeaf": true
            }, {
                "value": 1230711,
                "label": "乌马河区",
                "parent": 12307,
                "isLeaf": true
            }, {
                "value": 1230712,
                "label": "汤旺河区",
                "parent": 12307,
                "isLeaf": true
            }, {
                "value": 1230713,
                "label": "带岭区",
                "parent": 12307,
                "isLeaf": true
            }, {
                "value": 1230714,
                "label": "乌伊岭区",
                "parent": 12307,
                "isLeaf": true
            }, {
                "value": 1230715,
                "label": "红星区",
                "parent": 12307,
                "isLeaf": true
            }, {
                "value": 1230716,
                "label": "上甘岭区",
                "parent": 12307,
                "isLeaf": true
            }, {
                "value": 1230722,
                "label": "嘉荫县",
                "parent": 12307,
                "isLeaf": true
            }, {
                "value": 1230781,
                "label": "铁力市",
                "parent": 12307,
                "isLeaf": true
            }],
            "value": 12307,
            "label": "伊春市",
            "parent": 123
        }, {
            "children": [{
                "value": 1230803,
                "label": "向阳区",
                "parent": 12308,
                "isLeaf": true
            }, {
                "value": 1230804,
                "label": "前进区",
                "parent": 12308,
                "isLeaf": true
            }, {
                "value": 1230805,
                "label": "东风区",
                "parent": 12308,
                "isLeaf": true
            }, {
                "value": 1230811,
                "label": "郊区",
                "parent": 12308,
                "isLeaf": true
            }, {
                "value": 1230822,
                "label": "桦南县",
                "parent": 12308,
                "isLeaf": true
            }, {
                "value": 1230826,
                "label": "桦川县",
                "parent": 12308,
                "isLeaf": true
            }, {
                "value": 1230828,
                "label": "汤原县",
                "parent": 12308,
                "isLeaf": true
            }, {
                "value": 1230833,
                "label": "抚远县",
                "parent": 12308,
                "isLeaf": true
            }, {
                "value": 1230881,
                "label": "同江市",
                "parent": 12308,
                "isLeaf": true
            }, {
                "value": 1230882,
                "label": "富锦市",
                "parent": 12308,
                "isLeaf": true
            }],
            "value": 12308,
            "label": "佳木斯市",
            "parent": 123
        }, {
            "children": [{
                "value": 1230902,
                "label": "新兴区",
                "parent": 12309,
                "isLeaf": true
            }, {
                "value": 1230903,
                "label": "桃山区",
                "parent": 12309,
                "isLeaf": true
            }, {
                "value": 1230904,
                "label": "茄子河区",
                "parent": 12309,
                "isLeaf": true
            }, {
                "value": 1230921,
                "label": "勃利县",
                "parent": 12309,
                "isLeaf": true
            }],
            "value": 12309,
            "label": "七台河市",
            "parent": 123
        }, {
            "children": [{
                "value": 1231002,
                "label": "东安区",
                "parent": 12310,
                "isLeaf": true
            }, {
                "value": 1231003,
                "label": "阳明区",
                "parent": 12310,
                "isLeaf": true
            }, {
                "value": 1231004,
                "label": "爱民区",
                "parent": 12310,
                "isLeaf": true
            }, {
                "value": 1231005,
                "label": "西安区",
                "parent": 12310,
                "isLeaf": true
            }, {
                "value": 1231025,
                "label": "林口县",
                "parent": 12310,
                "isLeaf": true
            }, {
                "value": 1231081,
                "label": "绥芬河市",
                "parent": 12310,
                "isLeaf": true
            }, {
                "value": 1231083,
                "label": "海林市",
                "parent": 12310,
                "isLeaf": true
            }, {
                "value": 1231084,
                "label": "宁安市",
                "parent": 12310,
                "isLeaf": true
            }, {
                "value": 1231085,
                "label": "穆棱市",
                "parent": 12310,
                "isLeaf": true
            }, {
                "value": 1231086,
                "label": "东宁市",
                "parent": 12310,
                "isLeaf": true
            }],
            "value": 12310,
            "label": "牡丹江市",
            "parent": 123
        }, {
            "children": [{
                "value": 1231102,
                "label": "爱辉区",
                "parent": 12311,
                "isLeaf": true
            }, {
                "value": 1231121,
                "label": "嫩江县",
                "parent": 12311,
                "isLeaf": true
            }, {
                "value": 1231123,
                "label": "逊克县",
                "parent": 12311,
                "isLeaf": true
            }, {
                "value": 1231124,
                "label": "孙吴县",
                "parent": 12311,
                "isLeaf": true
            }, {
                "value": 1231181,
                "label": "北安市",
                "parent": 12311,
                "isLeaf": true
            }, {
                "value": 1231182,
                "label": "五大连池市",
                "parent": 12311,
                "isLeaf": true
            }],
            "value": 12311,
            "label": "黑河市",
            "parent": 123
        }, {
            "children": [{
                "value": 1231202,
                "label": "北林区",
                "parent": 12312,
                "isLeaf": true
            }, {
                "value": 1231221,
                "label": "望奎县",
                "parent": 12312,
                "isLeaf": true
            }, {
                "value": 1231222,
                "label": "兰西县",
                "parent": 12312,
                "isLeaf": true
            }, {
                "value": 1231223,
                "label": "青冈县",
                "parent": 12312,
                "isLeaf": true
            }, {
                "value": 1231224,
                "label": "庆安县",
                "parent": 12312,
                "isLeaf": true
            }, {
                "value": 1231225,
                "label": "明水县",
                "parent": 12312,
                "isLeaf": true
            }, {
                "value": 1231226,
                "label": "绥棱县",
                "parent": 12312,
                "isLeaf": true
            }, {
                "value": 1231281,
                "label": "安达市",
                "parent": 12312,
                "isLeaf": true
            }, {
                "value": 1231282,
                "label": "肇东市",
                "parent": 12312,
                "isLeaf": true
            }, {
                "value": 1231283,
                "label": "海伦市",
                "parent": 12312,
                "isLeaf": true
            }],
            "value": 12312,
            "label": "绥化市",
            "parent": 123
        }, {
            "children": [{
                "value": 1232701,
                "label": "加格达奇区",
                "parent": 12327,
                "isLeaf": true
            }, {
                "value": 1232702,
                "label": "松岭区",
                "parent": 12327,
                "isLeaf": true
            }, {
                "value": 1232703,
                "label": "新林区",
                "parent": 12327,
                "isLeaf": true
            }, {
                "value": 1232704,
                "label": "呼中区",
                "parent": 12327,
                "isLeaf": true
            }, {
                "value": 1232721,
                "label": "呼玛县",
                "parent": 12327,
                "isLeaf": true
            }, {
                "value": 1232722,
                "label": "塔河县",
                "parent": 12327,
                "isLeaf": true
            }, {
                "value": 1232723,
                "label": "漠河县",
                "parent": 12327,
                "isLeaf": true
            }],
            "value": 12327,
            "label": "大兴安岭地区",
            "parent": 123
        }],
        "value": 123,
        "label": "黑龙江省",
        "parent": 0
    }, {
        "children": [{
            "children": [{
                "value": 1310101,
                "label": "黄浦区",
                "parent": 13101,
                "isLeaf": true
            }, {
                "value": 1310104,
                "label": "徐汇区",
                "parent": 13101,
                "isLeaf": true
            }, {
                "value": 1310105,
                "label": "长宁区",
                "parent": 13101,
                "isLeaf": true
            }, {
                "value": 1310106,
                "label": "静安区",
                "parent": 13101,
                "isLeaf": true
            }, {
                "value": 1310107,
                "label": "普陀区",
                "parent": 13101,
                "isLeaf": true
            }, {
                "value": 1310109,
                "label": "虹口区",
                "parent": 13101,
                "isLeaf": true
            }, {
                "value": 1310110,
                "label": "杨浦区",
                "parent": 13101,
                "isLeaf": true
            }, {
                "value": 1310112,
                "label": "闵行区",
                "parent": 13101,
                "isLeaf": true
            }, {
                "value": 1310113,
                "label": "宝山区",
                "parent": 13101,
                "isLeaf": true
            }, {
                "value": 1310114,
                "label": "嘉定区",
                "parent": 13101,
                "isLeaf": true
            }, {
                "value": 1310115,
                "label": "浦东新区",
                "parent": 13101,
                "isLeaf": true
            }, {
                "value": 1310116,
                "label": "金山区",
                "parent": 13101,
                "isLeaf": true
            }, {
                "value": 1310117,
                "label": "松江区",
                "parent": 13101,
                "isLeaf": true
            }, {
                "value": 1310118,
                "label": "青浦区",
                "parent": 13101,
                "isLeaf": true
            }, {
                "value": 1310120,
                "label": "奉贤区",
                "parent": 13101,
                "isLeaf": true
            }],
            "value": 13101,
            "label": "上海市辖区",
            "parent": 131
        }, {
            "children": [{
                "value": 1310230,
                "label": "崇明县",
                "parent": 13102,
                "isLeaf": true
            }],
            "value": 13102,
            "label": "上海市县",
            "parent": 131
        }],
        "value": 131,
        "label": "上海市",
        "parent": 0
    }, {
        "children": [{
            "children": [{
                "value": 1320102,
                "label": "玄武区",
                "parent": 13201,
                "isLeaf": true
            }, {
                "value": 1320104,
                "label": "秦淮区",
                "parent": 13201,
                "isLeaf": true
            }, {
                "value": 1320105,
                "label": "建邺区",
                "parent": 13201,
                "isLeaf": true
            }, {
                "value": 1320106,
                "label": "鼓楼区",
                "parent": 13201,
                "isLeaf": true
            }, {
                "value": 1320111,
                "label": "浦口区",
                "parent": 13201,
                "isLeaf": true
            }, {
                "value": 1320113,
                "label": "栖霞区",
                "parent": 13201,
                "isLeaf": true
            }, {
                "value": 1320114,
                "label": "雨花台区",
                "parent": 13201,
                "isLeaf": true
            }, {
                "value": 1320115,
                "label": "江宁区",
                "parent": 13201,
                "isLeaf": true
            }, {
                "value": 1320116,
                "label": "六合区",
                "parent": 13201,
                "isLeaf": true
            }, {
                "value": 1320117,
                "label": "溧水区",
                "parent": 13201,
                "isLeaf": true
            }, {
                "value": 1320118,
                "label": "高淳区",
                "parent": 13201,
                "isLeaf": true
            }],
            "value": 13201,
            "label": "南京市",
            "parent": 132
        }, {
            "children": [{
                "value": 1320205,
                "label": "锡山区",
                "parent": 13202,
                "isLeaf": true
            }, {
                "value": 1320206,
                "label": "惠山区",
                "parent": 13202,
                "isLeaf": true
            }, {
                "value": 1320211,
                "label": "滨湖区",
                "parent": 13202,
                "isLeaf": true
            }, {
                "value": 1320213,
                "label": "梁溪区",
                "parent": 13202,
                "isLeaf": true
            }, {
                "value": 1320214,
                "label": "新吴区",
                "parent": 13202,
                "isLeaf": true
            }, {
                "value": 1320281,
                "label": "江阴市",
                "parent": 13202,
                "isLeaf": true
            }, {
                "value": 1320282,
                "label": "宜兴市",
                "parent": 13202,
                "isLeaf": true
            }],
            "value": 13202,
            "label": "无锡市",
            "parent": 132
        }, {
            "children": [{
                "value": 1320302,
                "label": "鼓楼区",
                "parent": 13203,
                "isLeaf": true
            }, {
                "value": 1320303,
                "label": "云龙区",
                "parent": 13203,
                "isLeaf": true
            }, {
                "value": 1320305,
                "label": "贾汪区",
                "parent": 13203,
                "isLeaf": true
            }, {
                "value": 1320311,
                "label": "泉山区",
                "parent": 13203,
                "isLeaf": true
            }, {
                "value": 1320312,
                "label": "铜山区",
                "parent": 13203,
                "isLeaf": true
            }, {
                "value": 1320321,
                "label": "丰县",
                "parent": 13203,
                "isLeaf": true
            }, {
                "value": 1320322,
                "label": "沛县",
                "parent": 13203,
                "isLeaf": true
            }, {
                "value": 1320324,
                "label": "睢宁县",
                "parent": 13203,
                "isLeaf": true
            }, {
                "value": 1320381,
                "label": "新沂市",
                "parent": 13203,
                "isLeaf": true
            }, {
                "value": 1320382,
                "label": "邳州市",
                "parent": 13203,
                "isLeaf": true
            }],
            "value": 13203,
            "label": "徐州市",
            "parent": 132
        }, {
            "children": [{
                "value": 1320402,
                "label": "天宁区",
                "parent": 13204,
                "isLeaf": true
            }, {
                "value": 1320404,
                "label": "钟楼区",
                "parent": 13204,
                "isLeaf": true
            }, {
                "value": 1320411,
                "label": "新北区",
                "parent": 13204,
                "isLeaf": true
            }, {
                "value": 1320412,
                "label": "武进区",
                "parent": 13204,
                "isLeaf": true
            }, {
                "value": 1320413,
                "label": "金坛区",
                "parent": 13204,
                "isLeaf": true
            }, {
                "value": 1320481,
                "label": "溧阳市",
                "parent": 13204,
                "isLeaf": true
            }],
            "value": 13204,
            "label": "常州市",
            "parent": 132
        }, {
            "children": [{
                "value": 1320505,
                "label": "虎丘区",
                "parent": 13205,
                "isLeaf": true
            }, {
                "value": 1320506,
                "label": "吴中区",
                "parent": 13205,
                "isLeaf": true
            }, {
                "value": 1320507,
                "label": "相城区",
                "parent": 13205,
                "isLeaf": true
            }, {
                "value": 1320508,
                "label": "姑苏区",
                "parent": 13205,
                "isLeaf": true
            }, {
                "value": 1320509,
                "label": "吴江区",
                "parent": 13205,
                "isLeaf": true
            }, {
                "value": 1320581,
                "label": "常熟市",
                "parent": 13205,
                "isLeaf": true
            }, {
                "value": 1320582,
                "label": "张家港市",
                "parent": 13205,
                "isLeaf": true
            }, {
                "value": 1320583,
                "label": "昆山市",
                "parent": 13205,
                "isLeaf": true
            }, {
                "value": 1320585,
                "label": "太仓市",
                "parent": 13205,
                "isLeaf": true
            }],
            "value": 13205,
            "label": "苏州市",
            "parent": 132
        }, {
            "children": [{
                "value": 1320602,
                "label": "崇川区",
                "parent": 13206,
                "isLeaf": true
            }, {
                "value": 1320611,
                "label": "港闸区",
                "parent": 13206,
                "isLeaf": true
            }, {
                "value": 1320612,
                "label": "通州区",
                "parent": 13206,
                "isLeaf": true
            }, {
                "value": 1320621,
                "label": "海安县",
                "parent": 13206,
                "isLeaf": true
            }, {
                "value": 1320623,
                "label": "如东县",
                "parent": 13206,
                "isLeaf": true
            }, {
                "value": 1320681,
                "label": "启东市",
                "parent": 13206,
                "isLeaf": true
            }, {
                "value": 1320682,
                "label": "如皋市",
                "parent": 13206,
                "isLeaf": true
            }, {
                "value": 1320684,
                "label": "海门市",
                "parent": 13206,
                "isLeaf": true
            }],
            "value": 13206,
            "label": "南通市",
            "parent": 132
        }, {
            "children": [{
                "value": 1320703,
                "label": "连云区",
                "parent": 13207,
                "isLeaf": true
            }, {
                "value": 1320706,
                "label": "海州区",
                "parent": 13207,
                "isLeaf": true
            }, {
                "value": 1320707,
                "label": "赣榆区",
                "parent": 13207,
                "isLeaf": true
            }, {
                "value": 1320722,
                "label": "东海县",
                "parent": 13207,
                "isLeaf": true
            }, {
                "value": 1320723,
                "label": "灌云县",
                "parent": 13207,
                "isLeaf": true
            }, {
                "value": 1320724,
                "label": "灌南县",
                "parent": 13207,
                "isLeaf": true
            }],
            "value": 13207,
            "label": "连云港市",
            "parent": 132
        }, {
            "children": [{
                "value": 1320802,
                "label": "清河区",
                "parent": 13208,
                "isLeaf": true
            }, {
                "value": 1320803,
                "label": "淮安区",
                "parent": 13208,
                "isLeaf": true
            }, {
                "value": 1320804,
                "label": "淮阴区",
                "parent": 13208,
                "isLeaf": true
            }, {
                "value": 1320811,
                "label": "清浦区",
                "parent": 13208,
                "isLeaf": true
            }, {
                "value": 1320826,
                "label": "涟水县",
                "parent": 13208,
                "isLeaf": true
            }, {
                "value": 1320829,
                "label": "洪泽县",
                "parent": 13208,
                "isLeaf": true
            }, {
                "value": 1320830,
                "label": "盱眙县",
                "parent": 13208,
                "isLeaf": true
            }, {
                "value": 1320831,
                "label": "金湖县",
                "parent": 13208,
                "isLeaf": true
            }],
            "value": 13208,
            "label": "淮安市",
            "parent": 132
        }, {
            "children": [{
                "value": 1320902,
                "label": "亭湖区",
                "parent": 13209,
                "isLeaf": true
            }, {
                "value": 1320903,
                "label": "盐都区",
                "parent": 13209,
                "isLeaf": true
            }, {
                "value": 1320904,
                "label": "大丰区",
                "parent": 13209,
                "isLeaf": true
            }, {
                "value": 1320921,
                "label": "响水县",
                "parent": 13209,
                "isLeaf": true
            }, {
                "value": 1320922,
                "label": "滨海县",
                "parent": 13209,
                "isLeaf": true
            }, {
                "value": 1320923,
                "label": "阜宁县",
                "parent": 13209,
                "isLeaf": true
            }, {
                "value": 1320924,
                "label": "射阳县",
                "parent": 13209,
                "isLeaf": true
            }, {
                "value": 1320925,
                "label": "建湖县",
                "parent": 13209,
                "isLeaf": true
            }, {
                "value": 1320981,
                "label": "东台市",
                "parent": 13209,
                "isLeaf": true
            }],
            "value": 13209,
            "label": "盐城市",
            "parent": 132
        }, {
            "children": [{
                "value": 1321002,
                "label": "广陵区",
                "parent": 13210,
                "isLeaf": true
            }, {
                "value": 1321003,
                "label": "邗江区",
                "parent": 13210,
                "isLeaf": true
            }, {
                "value": 1321012,
                "label": "江都区",
                "parent": 13210,
                "isLeaf": true
            }, {
                "value": 1321023,
                "label": "宝应县",
                "parent": 13210,
                "isLeaf": true
            }, {
                "value": 1321081,
                "label": "仪征市",
                "parent": 13210,
                "isLeaf": true
            }, {
                "value": 1321084,
                "label": "高邮市",
                "parent": 13210,
                "isLeaf": true
            }],
            "value": 13210,
            "label": "扬州市",
            "parent": 132
        }, {
            "children": [{
                "value": 1321102,
                "label": "京口区",
                "parent": 13211,
                "isLeaf": true
            }, {
                "value": 1321111,
                "label": "润州区",
                "parent": 13211,
                "isLeaf": true
            }, {
                "value": 1321112,
                "label": "丹徒区",
                "parent": 13211,
                "isLeaf": true
            }, {
                "value": 1321181,
                "label": "丹阳市",
                "parent": 13211,
                "isLeaf": true
            }, {
                "value": 1321182,
                "label": "扬中市",
                "parent": 13211,
                "isLeaf": true
            }, {
                "value": 1321183,
                "label": "句容市",
                "parent": 13211,
                "isLeaf": true
            }],
            "value": 13211,
            "label": "镇江市",
            "parent": 132
        }, {
            "children": [{
                "value": 1321202,
                "label": "海陵区",
                "parent": 13212,
                "isLeaf": true
            }, {
                "value": 1321203,
                "label": "高港区",
                "parent": 13212,
                "isLeaf": true
            }, {
                "value": 1321204,
                "label": "姜堰区",
                "parent": 13212,
                "isLeaf": true
            }, {
                "value": 1321281,
                "label": "兴化市",
                "parent": 13212,
                "isLeaf": true
            }, {
                "value": 1321282,
                "label": "靖江市",
                "parent": 13212,
                "isLeaf": true
            }, {
                "value": 1321283,
                "label": "泰兴市",
                "parent": 13212,
                "isLeaf": true
            }],
            "value": 13212,
            "label": "泰州市",
            "parent": 132
        }, {
            "children": [{
                "value": 1321302,
                "label": "宿城区",
                "parent": 13213,
                "isLeaf": true
            }, {
                "value": 1321311,
                "label": "宿豫区",
                "parent": 13213,
                "isLeaf": true
            }, {
                "value": 1321322,
                "label": "沭阳县",
                "parent": 13213,
                "isLeaf": true
            }, {
                "value": 1321323,
                "label": "泗阳县",
                "parent": 13213,
                "isLeaf": true
            }, {
                "value": 1321324,
                "label": "泗洪县",
                "parent": 13213,
                "isLeaf": true
            }],
            "value": 13213,
            "label": "宿迁市",
            "parent": 132
        }],
        "value": 132,
        "label": "江苏省",
        "parent": 0
    }, {
        "children": [{
            "children": [{
                "value": 1330102,
                "label": "上城区",
                "parent": 13301,
                "isLeaf": true
            }, {
                "value": 1330103,
                "label": "下城区",
                "parent": 13301,
                "isLeaf": true
            }, {
                "value": 1330104,
                "label": "江干区",
                "parent": 13301,
                "isLeaf": true
            }, {
                "value": 1330105,
                "label": "拱墅区",
                "parent": 13301,
                "isLeaf": true
            }, {
                "value": 1330106,
                "label": "西湖区",
                "parent": 13301,
                "isLeaf": true
            }, {
                "value": 1330108,
                "label": "滨江区",
                "parent": 13301,
                "isLeaf": true
            }, {
                "value": 1330109,
                "label": "萧山区",
                "parent": 13301,
                "isLeaf": true
            }, {
                "value": 1330110,
                "label": "余杭区",
                "parent": 13301,
                "isLeaf": true
            }, {
                "value": 1330111,
                "label": "富阳区",
                "parent": 13301,
                "isLeaf": true
            }, {
                "value": 1330122,
                "label": "桐庐县",
                "parent": 13301,
                "isLeaf": true
            }, {
                "value": 1330127,
                "label": "淳安县",
                "parent": 13301,
                "isLeaf": true
            }, {
                "value": 1330182,
                "label": "建德市",
                "parent": 13301,
                "isLeaf": true
            }, {
                "value": 1330185,
                "label": "临安市",
                "parent": 13301,
                "isLeaf": true
            }],
            "value": 13301,
            "label": "杭州市",
            "parent": 133
        }, {
            "children": [{
                "value": 1330203,
                "label": "海曙区",
                "parent": 13302,
                "isLeaf": true
            }, {
                "value": 1330204,
                "label": "江东区",
                "parent": 13302,
                "isLeaf": true
            }, {
                "value": 1330205,
                "label": "江北区",
                "parent": 13302,
                "isLeaf": true
            }, {
                "value": 1330206,
                "label": "北仑区",
                "parent": 13302,
                "isLeaf": true
            }, {
                "value": 1330211,
                "label": "镇海区",
                "parent": 13302,
                "isLeaf": true
            }, {
                "value": 1330212,
                "label": "鄞州区",
                "parent": 13302,
                "isLeaf": true
            }, {
                "value": 1330225,
                "label": "象山县",
                "parent": 13302,
                "isLeaf": true
            }, {
                "value": 1330226,
                "label": "宁海县",
                "parent": 13302,
                "isLeaf": true
            }, {
                "value": 1330281,
                "label": "余姚市",
                "parent": 13302,
                "isLeaf": true
            }, {
                "value": 1330282,
                "label": "慈溪市",
                "parent": 13302,
                "isLeaf": true
            }, {
                "value": 1330283,
                "label": "奉化市",
                "parent": 13302,
                "isLeaf": true
            }],
            "value": 13302,
            "label": "宁波市",
            "parent": 133
        }, {
            "children": [{
                "value": 1330302,
                "label": "鹿城区",
                "parent": 13303,
                "isLeaf": true
            }, {
                "value": 1330303,
                "label": "龙湾区",
                "parent": 13303,
                "isLeaf": true
            }, {
                "value": 1330304,
                "label": "瓯海区",
                "parent": 13303,
                "isLeaf": true
            }, {
                "value": 1330305,
                "label": "洞头区",
                "parent": 13303,
                "isLeaf": true
            }, {
                "value": 1330324,
                "label": "永嘉县",
                "parent": 13303,
                "isLeaf": true
            }, {
                "value": 1330326,
                "label": "平阳县",
                "parent": 13303,
                "isLeaf": true
            }, {
                "value": 1330327,
                "label": "苍南县",
                "parent": 13303,
                "isLeaf": true
            }, {
                "value": 1330328,
                "label": "文成县",
                "parent": 13303,
                "isLeaf": true
            }, {
                "value": 1330329,
                "label": "泰顺县",
                "parent": 13303,
                "isLeaf": true
            }, {
                "value": 1330381,
                "label": "瑞安市",
                "parent": 13303,
                "isLeaf": true
            }, {
                "value": 1330382,
                "label": "乐清市",
                "parent": 13303,
                "isLeaf": true
            }],
            "value": 13303,
            "label": "温州市",
            "parent": 133
        }, {
            "children": [{
                "value": 1330402,
                "label": "南湖区",
                "parent": 13304,
                "isLeaf": true
            }, {
                "value": 1330411,
                "label": "秀洲区",
                "parent": 13304,
                "isLeaf": true
            }, {
                "value": 1330421,
                "label": "嘉善县",
                "parent": 13304,
                "isLeaf": true
            }, {
                "value": 1330424,
                "label": "海盐县",
                "parent": 13304,
                "isLeaf": true
            }, {
                "value": 1330481,
                "label": "海宁市",
                "parent": 13304,
                "isLeaf": true
            }, {
                "value": 1330482,
                "label": "平湖市",
                "parent": 13304,
                "isLeaf": true
            }, {
                "value": 1330483,
                "label": "桐乡市",
                "parent": 13304,
                "isLeaf": true
            }],
            "value": 13304,
            "label": "嘉兴市",
            "parent": 133
        }, {
            "children": [{
                "value": 1330502,
                "label": "吴兴区",
                "parent": 13305,
                "isLeaf": true
            }, {
                "value": 1330503,
                "label": "南浔区",
                "parent": 13305,
                "isLeaf": true
            }, {
                "value": 1330521,
                "label": "德清县",
                "parent": 13305,
                "isLeaf": true
            }, {
                "value": 1330522,
                "label": "长兴县",
                "parent": 13305,
                "isLeaf": true
            }, {
                "value": 1330523,
                "label": "安吉县",
                "parent": 13305,
                "isLeaf": true
            }],
            "value": 13305,
            "label": "湖州市",
            "parent": 133
        }, {
            "children": [{
                "value": 1330602,
                "label": "越城区",
                "parent": 13306,
                "isLeaf": true
            }, {
                "value": 1330603,
                "label": "柯桥区",
                "parent": 13306,
                "isLeaf": true
            }, {
                "value": 1330604,
                "label": "上虞区",
                "parent": 13306,
                "isLeaf": true
            }, {
                "value": 1330624,
                "label": "新昌县",
                "parent": 13306,
                "isLeaf": true
            }, {
                "value": 1330681,
                "label": "诸暨市",
                "parent": 13306,
                "isLeaf": true
            }, {
                "value": 1330683,
                "label": "嵊州市",
                "parent": 13306,
                "isLeaf": true
            }],
            "value": 13306,
            "label": "绍兴市",
            "parent": 133
        }, {
            "children": [{
                "value": 1330702,
                "label": "婺城区",
                "parent": 13307,
                "isLeaf": true
            }, {
                "value": 1330703,
                "label": "金东区",
                "parent": 13307,
                "isLeaf": true
            }, {
                "value": 1330723,
                "label": "武义县",
                "parent": 13307,
                "isLeaf": true
            }, {
                "value": 1330726,
                "label": "浦江县",
                "parent": 13307,
                "isLeaf": true
            }, {
                "value": 1330727,
                "label": "磐安县",
                "parent": 13307,
                "isLeaf": true
            }, {
                "value": 1330781,
                "label": "兰溪市",
                "parent": 13307,
                "isLeaf": true
            }, {
                "value": 1330782,
                "label": "义乌市",
                "parent": 13307,
                "isLeaf": true
            }, {
                "value": 1330783,
                "label": "东阳市",
                "parent": 13307,
                "isLeaf": true
            }, {
                "value": 1330784,
                "label": "永康市",
                "parent": 13307,
                "isLeaf": true
            }],
            "value": 13307,
            "label": "金华市",
            "parent": 133
        }, {
            "children": [{
                "value": 1330802,
                "label": "柯城区",
                "parent": 13308,
                "isLeaf": true
            }, {
                "value": 1330803,
                "label": "衢江区",
                "parent": 13308,
                "isLeaf": true
            }, {
                "value": 1330822,
                "label": "常山县",
                "parent": 13308,
                "isLeaf": true
            }, {
                "value": 1330824,
                "label": "开化县",
                "parent": 13308,
                "isLeaf": true
            }, {
                "value": 1330825,
                "label": "龙游县",
                "parent": 13308,
                "isLeaf": true
            }, {
                "value": 1330881,
                "label": "江山市",
                "parent": 13308,
                "isLeaf": true
            }],
            "value": 13308,
            "label": "衢州市",
            "parent": 133
        }, {
            "children": [{
                "value": 1330902,
                "label": "定海区",
                "parent": 13309,
                "isLeaf": true
            }, {
                "value": 1330903,
                "label": "普陀区",
                "parent": 13309,
                "isLeaf": true
            }, {
                "value": 1330921,
                "label": "岱山县",
                "parent": 13309,
                "isLeaf": true
            }, {
                "value": 1330922,
                "label": "嵊泗县",
                "parent": 13309,
                "isLeaf": true
            }],
            "value": 13309,
            "label": "舟山市",
            "parent": 133
        }, {
            "children": [{
                "value": 1331002,
                "label": "椒江区",
                "parent": 13310,
                "isLeaf": true
            }, {
                "value": 1331003,
                "label": "黄岩区",
                "parent": 13310,
                "isLeaf": true
            }, {
                "value": 1331004,
                "label": "路桥区",
                "parent": 13310,
                "isLeaf": true
            }, {
                "value": 1331021,
                "label": "玉环县",
                "parent": 13310,
                "isLeaf": true
            }, {
                "value": 1331022,
                "label": "三门县",
                "parent": 13310,
                "isLeaf": true
            }, {
                "value": 1331023,
                "label": "天台县",
                "parent": 13310,
                "isLeaf": true
            }, {
                "value": 1331024,
                "label": "仙居县",
                "parent": 13310,
                "isLeaf": true
            }, {
                "value": 1331081,
                "label": "温岭市",
                "parent": 13310,
                "isLeaf": true
            }, {
                "value": 1331082,
                "label": "临海市",
                "parent": 13310,
                "isLeaf": true
            }],
            "value": 13310,
            "label": "台州市",
            "parent": 133
        }, {
            "children": [{
                "value": 1331102,
                "label": "莲都区",
                "parent": 13311,
                "isLeaf": true
            }, {
                "value": 1331121,
                "label": "青田县",
                "parent": 13311,
                "isLeaf": true
            }, {
                "value": 1331122,
                "label": "缙云县",
                "parent": 13311,
                "isLeaf": true
            }, {
                "value": 1331123,
                "label": "遂昌县",
                "parent": 13311,
                "isLeaf": true
            }, {
                "value": 1331124,
                "label": "松阳县",
                "parent": 13311,
                "isLeaf": true
            }, {
                "value": 1331125,
                "label": "云和县",
                "parent": 13311,
                "isLeaf": true
            }, {
                "value": 1331126,
                "label": "庆元县",
                "parent": 13311,
                "isLeaf": true
            }, {
                "value": 1331127,
                "label": "景宁畲族自治县",
                "parent": 13311,
                "isLeaf": true
            }, {
                "value": 1331181,
                "label": "龙泉市",
                "parent": 13311,
                "isLeaf": true
            }],
            "value": 13311,
            "label": "丽水市",
            "parent": 133
        }],
        "value": 133,
        "label": "浙江省",
        "parent": 0
    }, {
        "children": [{
            "children": [{
                "value": 1340102,
                "label": "瑶海区",
                "parent": 13401,
                "isLeaf": true
            }, {
                "value": 1340103,
                "label": "庐阳区",
                "parent": 13401,
                "isLeaf": true
            }, {
                "value": 1340104,
                "label": "蜀山区",
                "parent": 13401,
                "isLeaf": true
            }, {
                "value": 1340111,
                "label": "包河区",
                "parent": 13401,
                "isLeaf": true
            }, {
                "value": 1340121,
                "label": "长丰县",
                "parent": 13401,
                "isLeaf": true
            }, {
                "value": 1340122,
                "label": "肥东县",
                "parent": 13401,
                "isLeaf": true
            }, {
                "value": 1340123,
                "label": "肥西县",
                "parent": 13401,
                "isLeaf": true
            }, {
                "value": 1340124,
                "label": "庐江县",
                "parent": 13401,
                "isLeaf": true
            }, {
                "value": 1340181,
                "label": "巢湖市",
                "parent": 13401,
                "isLeaf": true
            }],
            "value": 13401,
            "label": "合肥市",
            "parent": 134
        }, {
            "children": [{
                "value": 1340202,
                "label": "镜湖区",
                "parent": 13402,
                "isLeaf": true
            }, {
                "value": 1340203,
                "label": "弋江区",
                "parent": 13402,
                "isLeaf": true
            }, {
                "value": 1340207,
                "label": "鸠江区",
                "parent": 13402,
                "isLeaf": true
            }, {
                "value": 1340208,
                "label": "三山区",
                "parent": 13402,
                "isLeaf": true
            }, {
                "value": 1340221,
                "label": "芜湖县",
                "parent": 13402,
                "isLeaf": true
            }, {
                "value": 1340222,
                "label": "繁昌县",
                "parent": 13402,
                "isLeaf": true
            }, {
                "value": 1340223,
                "label": "南陵县",
                "parent": 13402,
                "isLeaf": true
            }, {
                "value": 1340225,
                "label": "无为县",
                "parent": 13402,
                "isLeaf": true
            }],
            "value": 13402,
            "label": "芜湖市",
            "parent": 134
        }, {
            "children": [{
                "value": 1340302,
                "label": "龙子湖区",
                "parent": 13403,
                "isLeaf": true
            }, {
                "value": 1340303,
                "label": "蚌山区",
                "parent": 13403,
                "isLeaf": true
            }, {
                "value": 1340304,
                "label": "禹会区",
                "parent": 13403,
                "isLeaf": true
            }, {
                "value": 1340311,
                "label": "淮上区",
                "parent": 13403,
                "isLeaf": true
            }, {
                "value": 1340321,
                "label": "怀远县",
                "parent": 13403,
                "isLeaf": true
            }, {
                "value": 1340322,
                "label": "五河县",
                "parent": 13403,
                "isLeaf": true
            }, {
                "value": 1340323,
                "label": "固镇县",
                "parent": 13403,
                "isLeaf": true
            }],
            "value": 13403,
            "label": "蚌埠市",
            "parent": 134
        }, {
            "children": [{
                "value": 1340402,
                "label": "大通区",
                "parent": 13404,
                "isLeaf": true
            }, {
                "value": 1340403,
                "label": "田家庵区",
                "parent": 13404,
                "isLeaf": true
            }, {
                "value": 1340404,
                "label": "谢家集区",
                "parent": 13404,
                "isLeaf": true
            }, {
                "value": 1340405,
                "label": "八公山区",
                "parent": 13404,
                "isLeaf": true
            }, {
                "value": 1340406,
                "label": "潘集区",
                "parent": 13404,
                "isLeaf": true
            }, {
                "value": 1340421,
                "label": "凤台县",
                "parent": 13404,
                "isLeaf": true
            }, {
                "value": 1340422,
                "label": "寿县",
                "parent": 13404,
                "isLeaf": true
            }],
            "value": 13404,
            "label": "淮南市",
            "parent": 134
        }, {
            "children": [{
                "value": 1340503,
                "label": "花山区",
                "parent": 13405,
                "isLeaf": true
            }, {
                "value": 1340504,
                "label": "雨山区",
                "parent": 13405,
                "isLeaf": true
            }, {
                "value": 1340506,
                "label": "博望区",
                "parent": 13405,
                "isLeaf": true
            }, {
                "value": 1340521,
                "label": "当涂县",
                "parent": 13405,
                "isLeaf": true
            }, {
                "value": 1340522,
                "label": "含山县",
                "parent": 13405,
                "isLeaf": true
            }, {
                "value": 1340523,
                "label": "和县",
                "parent": 13405,
                "isLeaf": true
            }],
            "value": 13405,
            "label": "马鞍山市",
            "parent": 134
        }, {
            "children": [{
                "value": 1340602,
                "label": "杜集区",
                "parent": 13406,
                "isLeaf": true
            }, {
                "value": 1340603,
                "label": "相山区",
                "parent": 13406,
                "isLeaf": true
            }, {
                "value": 1340604,
                "label": "烈山区",
                "parent": 13406,
                "isLeaf": true
            }, {
                "value": 1340621,
                "label": "濉溪县",
                "parent": 13406,
                "isLeaf": true
            }],
            "value": 13406,
            "label": "淮北市",
            "parent": 134
        }, {
            "children": [{
                "value": 1340705,
                "label": "铜官区",
                "parent": 13407,
                "isLeaf": true
            }, {
                "value": 1340706,
                "label": "义安区",
                "parent": 13407,
                "isLeaf": true
            }, {
                "value": 1340711,
                "label": "郊区",
                "parent": 13407,
                "isLeaf": true
            }, {
                "value": 1340722,
                "label": "枞阳县",
                "parent": 13407,
                "isLeaf": true
            }],
            "value": 13407,
            "label": "铜陵市",
            "parent": 134
        }, {
            "children": [{
                "value": 1340802,
                "label": "迎江区",
                "parent": 13408,
                "isLeaf": true
            }, {
                "value": 1340803,
                "label": "大观区",
                "parent": 13408,
                "isLeaf": true
            }, {
                "value": 1340811,
                "label": "宜秀区",
                "parent": 13408,
                "isLeaf": true
            }, {
                "value": 1340822,
                "label": "怀宁县",
                "parent": 13408,
                "isLeaf": true
            }, {
                "value": 1340824,
                "label": "潜山县",
                "parent": 13408,
                "isLeaf": true
            }, {
                "value": 1340825,
                "label": "太湖县",
                "parent": 13408,
                "isLeaf": true
            }, {
                "value": 1340826,
                "label": "宿松县",
                "parent": 13408,
                "isLeaf": true
            }, {
                "value": 1340827,
                "label": "望江县",
                "parent": 13408,
                "isLeaf": true
            }, {
                "value": 1340828,
                "label": "岳西县",
                "parent": 13408,
                "isLeaf": true
            }, {
                "value": 1340881,
                "label": "桐城市",
                "parent": 13408,
                "isLeaf": true
            }],
            "value": 13408,
            "label": "安庆市",
            "parent": 134
        }, {
            "children": [{
                "value": 1341002,
                "label": "屯溪区",
                "parent": 13410,
                "isLeaf": true
            }, {
                "value": 1341003,
                "label": "黄山区",
                "parent": 13410,
                "isLeaf": true
            }, {
                "value": 1341004,
                "label": "徽州区",
                "parent": 13410,
                "isLeaf": true
            }, {
                "value": 1341021,
                "label": "歙县",
                "parent": 13410,
                "isLeaf": true
            }, {
                "value": 1341022,
                "label": "休宁县",
                "parent": 13410,
                "isLeaf": true
            }, {
                "value": 1341023,
                "label": "黟县",
                "parent": 13410,
                "isLeaf": true
            }, {
                "value": 1341024,
                "label": "祁门县",
                "parent": 13410,
                "isLeaf": true
            }],
            "value": 13410,
            "label": "黄山市",
            "parent": 134
        }, {
            "children": [{
                "value": 1341102,
                "label": "琅琊区",
                "parent": 13411,
                "isLeaf": true
            }, {
                "value": 1341103,
                "label": "南谯区",
                "parent": 13411,
                "isLeaf": true
            }, {
                "value": 1341122,
                "label": "来安县",
                "parent": 13411,
                "isLeaf": true
            }, {
                "value": 1341124,
                "label": "全椒县",
                "parent": 13411,
                "isLeaf": true
            }, {
                "value": 1341125,
                "label": "定远县",
                "parent": 13411,
                "isLeaf": true
            }, {
                "value": 1341126,
                "label": "凤阳县",
                "parent": 13411,
                "isLeaf": true
            }, {
                "value": 1341181,
                "label": "天长市",
                "parent": 13411,
                "isLeaf": true
            }, {
                "value": 1341182,
                "label": "明光市",
                "parent": 13411,
                "isLeaf": true
            }],
            "value": 13411,
            "label": "滁州市",
            "parent": 134
        }, {
            "children": [{
                "value": 1341202,
                "label": "颍州区",
                "parent": 13412,
                "isLeaf": true
            }, {
                "value": 1341203,
                "label": "颍东区",
                "parent": 13412,
                "isLeaf": true
            }, {
                "value": 1341204,
                "label": "颍泉区",
                "parent": 13412,
                "isLeaf": true
            }, {
                "value": 1341221,
                "label": "临泉县",
                "parent": 13412,
                "isLeaf": true
            }, {
                "value": 1341222,
                "label": "太和县",
                "parent": 13412,
                "isLeaf": true
            }, {
                "value": 1341225,
                "label": "阜南县",
                "parent": 13412,
                "isLeaf": true
            }, {
                "value": 1341226,
                "label": "颍上县",
                "parent": 13412,
                "isLeaf": true
            }, {
                "value": 1341282,
                "label": "界首市",
                "parent": 13412,
                "isLeaf": true
            }],
            "value": 13412,
            "label": "阜阳市",
            "parent": 134
        }, {
            "children": [{
                "value": 1341302,
                "label": "埇桥区",
                "parent": 13413,
                "isLeaf": true
            }, {
                "value": 1341321,
                "label": "砀山县",
                "parent": 13413,
                "isLeaf": true
            }, {
                "value": 1341322,
                "label": "萧县",
                "parent": 13413,
                "isLeaf": true
            }, {
                "value": 1341323,
                "label": "灵璧县",
                "parent": 13413,
                "isLeaf": true
            }, {
                "value": 1341324,
                "label": "泗县",
                "parent": 13413,
                "isLeaf": true
            }],
            "value": 13413,
            "label": "宿州市",
            "parent": 134
        }, {
            "children": [{
                "value": 1341502,
                "label": "金安区",
                "parent": 13415,
                "isLeaf": true
            }, {
                "value": 1341503,
                "label": "裕安区",
                "parent": 13415,
                "isLeaf": true
            }, {
                "value": 1341504,
                "label": "叶集区",
                "parent": 13415,
                "isLeaf": true
            }, {
                "value": 1341522,
                "label": "霍邱县",
                "parent": 13415,
                "isLeaf": true
            }, {
                "value": 1341523,
                "label": "舒城县",
                "parent": 13415,
                "isLeaf": true
            }, {
                "value": 1341524,
                "label": "金寨县",
                "parent": 13415,
                "isLeaf": true
            }, {
                "value": 1341525,
                "label": "霍山县",
                "parent": 13415,
                "isLeaf": true
            }],
            "value": 13415,
            "label": "六安市",
            "parent": 134
        }, {
            "children": [{
                "value": 1341602,
                "label": "谯城区",
                "parent": 13416,
                "isLeaf": true
            }, {
                "value": 1341621,
                "label": "涡阳县",
                "parent": 13416,
                "isLeaf": true
            }, {
                "value": 1341622,
                "label": "蒙城县",
                "parent": 13416,
                "isLeaf": true
            }, {
                "value": 1341623,
                "label": "利辛县",
                "parent": 13416,
                "isLeaf": true
            }],
            "value": 13416,
            "label": "亳州市",
            "parent": 134
        }, {
            "children": [{
                "value": 1341702,
                "label": "贵池区",
                "parent": 13417,
                "isLeaf": true
            }, {
                "value": 1341721,
                "label": "东至县",
                "parent": 13417,
                "isLeaf": true
            }, {
                "value": 1341722,
                "label": "石台县",
                "parent": 13417,
                "isLeaf": true
            }, {
                "value": 1341723,
                "label": "青阳县",
                "parent": 13417,
                "isLeaf": true
            }],
            "value": 13417,
            "label": "池州市",
            "parent": 134
        }, {
            "children": [{
                "value": 1341802,
                "label": "宣州区",
                "parent": 13418,
                "isLeaf": true
            }, {
                "value": 1341821,
                "label": "郎溪县",
                "parent": 13418,
                "isLeaf": true
            }, {
                "value": 1341822,
                "label": "广德县",
                "parent": 13418,
                "isLeaf": true
            }, {
                "value": 1341823,
                "label": "泾县",
                "parent": 13418,
                "isLeaf": true
            }, {
                "value": 1341824,
                "label": "绩溪县",
                "parent": 13418,
                "isLeaf": true
            }, {
                "value": 1341825,
                "label": "旌德县",
                "parent": 13418,
                "isLeaf": true
            }, {
                "value": 1341881,
                "label": "宁国市",
                "parent": 13418,
                "isLeaf": true
            }],
            "value": 13418,
            "label": "宣城市",
            "parent": 134
        }],
        "value": 134,
        "label": "安徽省",
        "parent": 0
    }, {
        "children": [{
            "children": [{
                "value": 1350102,
                "label": "鼓楼区",
                "parent": 13501,
                "isLeaf": true
            }, {
                "value": 1350103,
                "label": "台江区",
                "parent": 13501,
                "isLeaf": true
            }, {
                "value": 1350104,
                "label": "仓山区",
                "parent": 13501,
                "isLeaf": true
            }, {
                "value": 1350105,
                "label": "马尾区",
                "parent": 13501,
                "isLeaf": true
            }, {
                "value": 1350111,
                "label": "晋安区",
                "parent": 13501,
                "isLeaf": true
            }, {
                "value": 1350121,
                "label": "闽侯县",
                "parent": 13501,
                "isLeaf": true
            }, {
                "value": 1350122,
                "label": "连江县",
                "parent": 13501,
                "isLeaf": true
            }, {
                "value": 1350123,
                "label": "罗源县",
                "parent": 13501,
                "isLeaf": true
            }, {
                "value": 1350124,
                "label": "闽清县",
                "parent": 13501,
                "isLeaf": true
            }, {
                "value": 1350125,
                "label": "永泰县",
                "parent": 13501,
                "isLeaf": true
            }, {
                "value": 1350128,
                "label": "平潭县",
                "parent": 13501,
                "isLeaf": true
            }, {
                "value": 1350181,
                "label": "福清市",
                "parent": 13501,
                "isLeaf": true
            }, {
                "value": 1350182,
                "label": "长乐市",
                "parent": 13501,
                "isLeaf": true
            }],
            "value": 13501,
            "label": "福州市",
            "parent": 135
        }, {
            "children": [{
                "value": 1350203,
                "label": "思明区",
                "parent": 13502,
                "isLeaf": true
            }, {
                "value": 1350205,
                "label": "海沧区",
                "parent": 13502,
                "isLeaf": true
            }, {
                "value": 1350206,
                "label": "湖里区",
                "parent": 13502,
                "isLeaf": true
            }, {
                "value": 1350211,
                "label": "集美区",
                "parent": 13502,
                "isLeaf": true
            }, {
                "value": 1350212,
                "label": "同安区",
                "parent": 13502,
                "isLeaf": true
            }, {
                "value": 1350213,
                "label": "翔安区",
                "parent": 13502,
                "isLeaf": true
            }],
            "value": 13502,
            "label": "厦门市",
            "parent": 135
        }, {
            "children": [{
                "value": 1350302,
                "label": "城厢区",
                "parent": 13503,
                "isLeaf": true
            }, {
                "value": 1350303,
                "label": "涵江区",
                "parent": 13503,
                "isLeaf": true
            }, {
                "value": 1350304,
                "label": "荔城区",
                "parent": 13503,
                "isLeaf": true
            }, {
                "value": 1350305,
                "label": "秀屿区",
                "parent": 13503,
                "isLeaf": true
            }, {
                "value": 1350322,
                "label": "仙游县",
                "parent": 13503,
                "isLeaf": true
            }],
            "value": 13503,
            "label": "莆田市",
            "parent": 135
        }, {
            "children": [{
                "value": 1350402,
                "label": "梅列区",
                "parent": 13504,
                "isLeaf": true
            }, {
                "value": 1350403,
                "label": "三元区",
                "parent": 13504,
                "isLeaf": true
            }, {
                "value": 1350421,
                "label": "明溪县",
                "parent": 13504,
                "isLeaf": true
            }, {
                "value": 1350423,
                "label": "清流县",
                "parent": 13504,
                "isLeaf": true
            }, {
                "value": 1350424,
                "label": "宁化县",
                "parent": 13504,
                "isLeaf": true
            }, {
                "value": 1350425,
                "label": "大田县",
                "parent": 13504,
                "isLeaf": true
            }, {
                "value": 1350426,
                "label": "尤溪县",
                "parent": 13504,
                "isLeaf": true
            }, {
                "value": 1350427,
                "label": "沙县",
                "parent": 13504,
                "isLeaf": true
            }, {
                "value": 1350428,
                "label": "将乐县",
                "parent": 13504,
                "isLeaf": true
            }, {
                "value": 1350429,
                "label": "泰宁县",
                "parent": 13504,
                "isLeaf": true
            }, {
                "value": 1350430,
                "label": "建宁县",
                "parent": 13504,
                "isLeaf": true
            }, {
                "value": 1350481,
                "label": "永安市",
                "parent": 13504,
                "isLeaf": true
            }],
            "value": 13504,
            "label": "三明市",
            "parent": 135
        }, {
            "children": [{
                "value": 1350502,
                "label": "鲤城区",
                "parent": 13505,
                "isLeaf": true
            }, {
                "value": 1350503,
                "label": "丰泽区",
                "parent": 13505,
                "isLeaf": true
            }, {
                "value": 1350504,
                "label": "洛江区",
                "parent": 13505,
                "isLeaf": true
            }, {
                "value": 1350505,
                "label": "泉港区",
                "parent": 13505,
                "isLeaf": true
            }, {
                "value": 1350521,
                "label": "惠安县",
                "parent": 13505,
                "isLeaf": true
            }, {
                "value": 1350524,
                "label": "安溪县",
                "parent": 13505,
                "isLeaf": true
            }, {
                "value": 1350525,
                "label": "永春县",
                "parent": 13505,
                "isLeaf": true
            }, {
                "value": 1350526,
                "label": "德化县",
                "parent": 13505,
                "isLeaf": true
            }, {
                "value": 1350527,
                "label": "金门县",
                "parent": 13505,
                "isLeaf": true
            }, {
                "value": 1350581,
                "label": "石狮市",
                "parent": 13505,
                "isLeaf": true
            }, {
                "value": 1350582,
                "label": "晋江市",
                "parent": 13505,
                "isLeaf": true
            }, {
                "value": 1350583,
                "label": "南安市",
                "parent": 13505,
                "isLeaf": true
            }],
            "value": 13505,
            "label": "泉州市",
            "parent": 135
        }, {
            "children": [{
                "value": 1350602,
                "label": "芗城区",
                "parent": 13506,
                "isLeaf": true
            }, {
                "value": 1350603,
                "label": "龙文区",
                "parent": 13506,
                "isLeaf": true
            }, {
                "value": 1350622,
                "label": "云霄县",
                "parent": 13506,
                "isLeaf": true
            }, {
                "value": 1350623,
                "label": "漳浦县",
                "parent": 13506,
                "isLeaf": true
            }, {
                "value": 1350624,
                "label": "诏安县",
                "parent": 13506,
                "isLeaf": true
            }, {
                "value": 1350625,
                "label": "长泰县",
                "parent": 13506,
                "isLeaf": true
            }, {
                "value": 1350626,
                "label": "东山县",
                "parent": 13506,
                "isLeaf": true
            }, {
                "value": 1350627,
                "label": "南靖县",
                "parent": 13506,
                "isLeaf": true
            }, {
                "value": 1350628,
                "label": "平和县",
                "parent": 13506,
                "isLeaf": true
            }, {
                "value": 1350629,
                "label": "华安县",
                "parent": 13506,
                "isLeaf": true
            }, {
                "value": 1350681,
                "label": "龙海市",
                "parent": 13506,
                "isLeaf": true
            }],
            "value": 13506,
            "label": "漳州市",
            "parent": 135
        }, {
            "children": [{
                "value": 1350702,
                "label": "延平区",
                "parent": 13507,
                "isLeaf": true
            }, {
                "value": 1350703,
                "label": "建阳区",
                "parent": 13507,
                "isLeaf": true
            }, {
                "value": 1350721,
                "label": "顺昌县",
                "parent": 13507,
                "isLeaf": true
            }, {
                "value": 1350722,
                "label": "浦城县",
                "parent": 13507,
                "isLeaf": true
            }, {
                "value": 1350723,
                "label": "光泽县",
                "parent": 13507,
                "isLeaf": true
            }, {
                "value": 1350724,
                "label": "松溪县",
                "parent": 13507,
                "isLeaf": true
            }, {
                "value": 1350725,
                "label": "政和县",
                "parent": 13507,
                "isLeaf": true
            }, {
                "value": 1350781,
                "label": "邵武市",
                "parent": 13507,
                "isLeaf": true
            }, {
                "value": 1350782,
                "label": "武夷山市",
                "parent": 13507,
                "isLeaf": true
            }, {
                "value": 1350783,
                "label": "建瓯市",
                "parent": 13507,
                "isLeaf": true
            }],
            "value": 13507,
            "label": "南平市",
            "parent": 135
        }, {
            "children": [{
                "value": 1350802,
                "label": "新罗区",
                "parent": 13508,
                "isLeaf": true
            }, {
                "value": 1350803,
                "label": "永定区",
                "parent": 13508,
                "isLeaf": true
            }, {
                "value": 1350821,
                "label": "长汀县",
                "parent": 13508,
                "isLeaf": true
            }, {
                "value": 1350823,
                "label": "上杭县",
                "parent": 13508,
                "isLeaf": true
            }, {
                "value": 1350824,
                "label": "武平县",
                "parent": 13508,
                "isLeaf": true
            }, {
                "value": 1350825,
                "label": "连城县",
                "parent": 13508,
                "isLeaf": true
            }, {
                "value": 1350881,
                "label": "漳平市",
                "parent": 13508,
                "isLeaf": true
            }],
            "value": 13508,
            "label": "龙岩市",
            "parent": 135
        }, {
            "children": [{
                "value": 1350902,
                "label": "蕉城区",
                "parent": 13509,
                "isLeaf": true
            }, {
                "value": 1350921,
                "label": "霞浦县",
                "parent": 13509,
                "isLeaf": true
            }, {
                "value": 1350922,
                "label": "古田县",
                "parent": 13509,
                "isLeaf": true
            }, {
                "value": 1350923,
                "label": "屏南县",
                "parent": 13509,
                "isLeaf": true
            }, {
                "value": 1350924,
                "label": "寿宁县",
                "parent": 13509,
                "isLeaf": true
            }, {
                "value": 1350925,
                "label": "周宁县",
                "parent": 13509,
                "isLeaf": true
            }, {
                "value": 1350926,
                "label": "柘荣县",
                "parent": 13509,
                "isLeaf": true
            }, {
                "value": 1350981,
                "label": "福安市",
                "parent": 13509,
                "isLeaf": true
            }, {
                "value": 1350982,
                "label": "福鼎市",
                "parent": 13509,
                "isLeaf": true
            }],
            "value": 13509,
            "label": "宁德市",
            "parent": 135
        }],
        "value": 135,
        "label": "福建省",
        "parent": 0
    }, {
        "children": [{
            "children": [{
                "value": 1360102,
                "label": "东湖区",
                "parent": 13601,
                "isLeaf": true
            }, {
                "value": 1360103,
                "label": "西湖区",
                "parent": 13601,
                "isLeaf": true
            }, {
                "value": 1360104,
                "label": "青云谱区",
                "parent": 13601,
                "isLeaf": true
            }, {
                "value": 1360105,
                "label": "湾里区",
                "parent": 13601,
                "isLeaf": true
            }, {
                "value": 1360111,
                "label": "青山湖区",
                "parent": 13601,
                "isLeaf": true
            }, {
                "value": 1360112,
                "label": "新建区",
                "parent": 13601,
                "isLeaf": true
            }, {
                "value": 1360121,
                "label": "南昌县",
                "parent": 13601,
                "isLeaf": true
            }, {
                "value": 1360123,
                "label": "安义县",
                "parent": 13601,
                "isLeaf": true
            }, {
                "value": 1360124,
                "label": "进贤县",
                "parent": 13601,
                "isLeaf": true
            }],
            "value": 13601,
            "label": "南昌市",
            "parent": 136
        }, {
            "children": [{
                "value": 1360202,
                "label": "昌江区",
                "parent": 13602,
                "isLeaf": true
            }, {
                "value": 1360203,
                "label": "珠山区",
                "parent": 13602,
                "isLeaf": true
            }, {
                "value": 1360222,
                "label": "浮梁县",
                "parent": 13602,
                "isLeaf": true
            }, {
                "value": 1360281,
                "label": "乐平市",
                "parent": 13602,
                "isLeaf": true
            }],
            "value": 13602,
            "label": "景德镇市",
            "parent": 136
        }, {
            "children": [{
                "value": 1360302,
                "label": "安源区",
                "parent": 13603,
                "isLeaf": true
            }, {
                "value": 1360313,
                "label": "湘东区",
                "parent": 13603,
                "isLeaf": true
            }, {
                "value": 1360321,
                "label": "莲花县",
                "parent": 13603,
                "isLeaf": true
            }, {
                "value": 1360322,
                "label": "上栗县",
                "parent": 13603,
                "isLeaf": true
            }, {
                "value": 1360323,
                "label": "芦溪县",
                "parent": 13603,
                "isLeaf": true
            }],
            "value": 13603,
            "label": "萍乡市",
            "parent": 136
        }, {
            "children": [{
                "value": 1360402,
                "label": "庐山区",
                "parent": 13604,
                "isLeaf": true
            }, {
                "value": 1360403,
                "label": "浔阳区",
                "parent": 13604,
                "isLeaf": true
            }, {
                "value": 1360421,
                "label": "九江县",
                "parent": 13604,
                "isLeaf": true
            }, {
                "value": 1360423,
                "label": "武宁县",
                "parent": 13604,
                "isLeaf": true
            }, {
                "value": 1360424,
                "label": "修水县",
                "parent": 13604,
                "isLeaf": true
            }, {
                "value": 1360425,
                "label": "永修县",
                "parent": 13604,
                "isLeaf": true
            }, {
                "value": 1360426,
                "label": "德安县",
                "parent": 13604,
                "isLeaf": true
            }, {
                "value": 1360427,
                "label": "星子县",
                "parent": 13604,
                "isLeaf": true
            }, {
                "value": 1360428,
                "label": "都昌县",
                "parent": 13604,
                "isLeaf": true
            }, {
                "value": 1360429,
                "label": "湖口县",
                "parent": 13604,
                "isLeaf": true
            }, {
                "value": 1360430,
                "label": "彭泽县",
                "parent": 13604,
                "isLeaf": true
            }, {
                "value": 1360481,
                "label": "瑞昌市",
                "parent": 13604,
                "isLeaf": true
            }, {
                "value": 1360482,
                "label": "共青城市",
                "parent": 13604,
                "isLeaf": true
            }],
            "value": 13604,
            "label": "九江市",
            "parent": 136
        }, {
            "children": [{
                "value": 1360502,
                "label": "渝水区",
                "parent": 13605,
                "isLeaf": true
            }, {
                "value": 1360521,
                "label": "分宜县",
                "parent": 13605,
                "isLeaf": true
            }],
            "value": 13605,
            "label": "新余市",
            "parent": 136
        }, {
            "children": [{
                "value": 1360602,
                "label": "月湖区",
                "parent": 13606,
                "isLeaf": true
            }, {
                "value": 1360622,
                "label": "余江县",
                "parent": 13606,
                "isLeaf": true
            }, {
                "value": 1360681,
                "label": "贵溪市",
                "parent": 13606,
                "isLeaf": true
            }],
            "value": 13606,
            "label": "鹰潭市",
            "parent": 136
        }, {
            "children": [{
                "value": 1360702,
                "label": "章贡区",
                "parent": 13607,
                "isLeaf": true
            }, {
                "value": 1360703,
                "label": "南康区",
                "parent": 13607,
                "isLeaf": true
            }, {
                "value": 1360721,
                "label": "赣县",
                "parent": 13607,
                "isLeaf": true
            }, {
                "value": 1360722,
                "label": "信丰县",
                "parent": 13607,
                "isLeaf": true
            }, {
                "value": 1360723,
                "label": "大余县",
                "parent": 13607,
                "isLeaf": true
            }, {
                "value": 1360724,
                "label": "上犹县",
                "parent": 13607,
                "isLeaf": true
            }, {
                "value": 1360725,
                "label": "崇义县",
                "parent": 13607,
                "isLeaf": true
            }, {
                "value": 1360726,
                "label": "安远县",
                "parent": 13607,
                "isLeaf": true
            }, {
                "value": 1360727,
                "label": "龙南县",
                "parent": 13607,
                "isLeaf": true
            }, {
                "value": 1360728,
                "label": "定南县",
                "parent": 13607,
                "isLeaf": true
            }, {
                "value": 1360729,
                "label": "全南县",
                "parent": 13607,
                "isLeaf": true
            }, {
                "value": 1360730,
                "label": "宁都县",
                "parent": 13607,
                "isLeaf": true
            }, {
                "value": 1360731,
                "label": "于都县",
                "parent": 13607,
                "isLeaf": true
            }, {
                "value": 1360732,
                "label": "兴国县",
                "parent": 13607,
                "isLeaf": true
            }, {
                "value": 1360733,
                "label": "会昌县",
                "parent": 13607,
                "isLeaf": true
            }, {
                "value": 1360734,
                "label": "寻乌县",
                "parent": 13607,
                "isLeaf": true
            }, {
                "value": 1360735,
                "label": "石城县",
                "parent": 13607,
                "isLeaf": true
            }, {
                "value": 1360781,
                "label": "瑞金市",
                "parent": 13607,
                "isLeaf": true
            }],
            "value": 13607,
            "label": "赣州市",
            "parent": 136
        }, {
            "children": [{
                "value": 1360802,
                "label": "吉州区",
                "parent": 13608,
                "isLeaf": true
            }, {
                "value": 1360803,
                "label": "青原区",
                "parent": 13608,
                "isLeaf": true
            }, {
                "value": 1360821,
                "label": "吉安县",
                "parent": 13608,
                "isLeaf": true
            }, {
                "value": 1360822,
                "label": "吉水县",
                "parent": 13608,
                "isLeaf": true
            }, {
                "value": 1360823,
                "label": "峡江县",
                "parent": 13608,
                "isLeaf": true
            }, {
                "value": 1360824,
                "label": "新干县",
                "parent": 13608,
                "isLeaf": true
            }, {
                "value": 1360825,
                "label": "永丰县",
                "parent": 13608,
                "isLeaf": true
            }, {
                "value": 1360826,
                "label": "泰和县",
                "parent": 13608,
                "isLeaf": true
            }, {
                "value": 1360827,
                "label": "遂川县",
                "parent": 13608,
                "isLeaf": true
            }, {
                "value": 1360828,
                "label": "万安县",
                "parent": 13608,
                "isLeaf": true
            }, {
                "value": 1360829,
                "label": "安福县",
                "parent": 13608,
                "isLeaf": true
            }, {
                "value": 1360830,
                "label": "永新县",
                "parent": 13608,
                "isLeaf": true
            }, {
                "value": 1360881,
                "label": "井冈山市",
                "parent": 13608,
                "isLeaf": true
            }],
            "value": 13608,
            "label": "吉安市",
            "parent": 136
        }, {
            "children": [{
                "value": 1360902,
                "label": "袁州区",
                "parent": 13609,
                "isLeaf": true
            }, {
                "value": 1360921,
                "label": "奉新县",
                "parent": 13609,
                "isLeaf": true
            }, {
                "value": 1360922,
                "label": "万载县",
                "parent": 13609,
                "isLeaf": true
            }, {
                "value": 1360923,
                "label": "上高县",
                "parent": 13609,
                "isLeaf": true
            }, {
                "value": 1360924,
                "label": "宜丰县",
                "parent": 13609,
                "isLeaf": true
            }, {
                "value": 1360925,
                "label": "靖安县",
                "parent": 13609,
                "isLeaf": true
            }, {
                "value": 1360926,
                "label": "铜鼓县",
                "parent": 13609,
                "isLeaf": true
            }, {
                "value": 1360981,
                "label": "丰城市",
                "parent": 13609,
                "isLeaf": true
            }, {
                "value": 1360982,
                "label": "樟树市",
                "parent": 13609,
                "isLeaf": true
            }, {
                "value": 1360983,
                "label": "高安市",
                "parent": 13609,
                "isLeaf": true
            }],
            "value": 13609,
            "label": "宜春市",
            "parent": 136
        }, {
            "children": [{
                "value": 1361002,
                "label": "临川区",
                "parent": 13610,
                "isLeaf": true
            }, {
                "value": 1361021,
                "label": "南城县",
                "parent": 13610,
                "isLeaf": true
            }, {
                "value": 1361022,
                "label": "黎川县",
                "parent": 13610,
                "isLeaf": true
            }, {
                "value": 1361023,
                "label": "南丰县",
                "parent": 13610,
                "isLeaf": true
            }, {
                "value": 1361024,
                "label": "崇仁县",
                "parent": 13610,
                "isLeaf": true
            }, {
                "value": 1361025,
                "label": "乐安县",
                "parent": 13610,
                "isLeaf": true
            }, {
                "value": 1361026,
                "label": "宜黄县",
                "parent": 13610,
                "isLeaf": true
            }, {
                "value": 1361027,
                "label": "金溪县",
                "parent": 13610,
                "isLeaf": true
            }, {
                "value": 1361028,
                "label": "资溪县",
                "parent": 13610,
                "isLeaf": true
            }, {
                "value": 1361029,
                "label": "东乡县",
                "parent": 13610,
                "isLeaf": true
            }, {
                "value": 1361030,
                "label": "广昌县",
                "parent": 13610,
                "isLeaf": true
            }],
            "value": 13610,
            "label": "抚州市",
            "parent": 136
        }, {
            "children": [{
                "value": 1361102,
                "label": "信州区",
                "parent": 13611,
                "isLeaf": true
            }, {
                "value": 1361103,
                "label": "广丰区",
                "parent": 13611,
                "isLeaf": true
            }, {
                "value": 1361121,
                "label": "上饶县",
                "parent": 13611,
                "isLeaf": true
            }, {
                "value": 1361123,
                "label": "玉山县",
                "parent": 13611,
                "isLeaf": true
            }, {
                "value": 1361124,
                "label": "铅山县",
                "parent": 13611,
                "isLeaf": true
            }, {
                "value": 1361125,
                "label": "横峰县",
                "parent": 13611,
                "isLeaf": true
            }, {
                "value": 1361126,
                "label": "弋阳县",
                "parent": 13611,
                "isLeaf": true
            }, {
                "value": 1361127,
                "label": "余干县",
                "parent": 13611,
                "isLeaf": true
            }, {
                "value": 1361128,
                "label": "鄱阳县",
                "parent": 13611,
                "isLeaf": true
            }, {
                "value": 1361129,
                "label": "万年县",
                "parent": 13611,
                "isLeaf": true
            }, {
                "value": 1361130,
                "label": "婺源县",
                "parent": 13611,
                "isLeaf": true
            }, {
                "value": 1361181,
                "label": "德兴市",
                "parent": 13611,
                "isLeaf": true
            }],
            "value": 13611,
            "label": "上饶市",
            "parent": 136
        }],
        "value": 136,
        "label": "江西省",
        "parent": 0
    }, {
        "children": [{
            "children": [{
                "value": 1370102,
                "label": "历下区",
                "parent": 13701,
                "isLeaf": true
            }, {
                "value": 1370103,
                "label": "市中区",
                "parent": 13701,
                "isLeaf": true
            }, {
                "value": 1370104,
                "label": "槐荫区",
                "parent": 13701,
                "isLeaf": true
            }, {
                "value": 1370105,
                "label": "天桥区",
                "parent": 13701,
                "isLeaf": true
            }, {
                "value": 1370112,
                "label": "历城区",
                "parent": 13701,
                "isLeaf": true
            }, {
                "value": 1370113,
                "label": "长清区",
                "parent": 13701,
                "isLeaf": true
            }, {
                "value": 1370124,
                "label": "平阴县",
                "parent": 13701,
                "isLeaf": true
            }, {
                "value": 1370125,
                "label": "济阳县",
                "parent": 13701,
                "isLeaf": true
            }, {
                "value": 1370126,
                "label": "商河县",
                "parent": 13701,
                "isLeaf": true
            }, {
                "value": 1370181,
                "label": "章丘市",
                "parent": 13701,
                "isLeaf": true
            }],
            "value": 13701,
            "label": "济南市",
            "parent": 137
        }, {
            "children": [{
                "value": 1370202,
                "label": "市南区",
                "parent": 13702,
                "isLeaf": true
            }, {
                "value": 1370203,
                "label": "市北区",
                "parent": 13702,
                "isLeaf": true
            }, {
                "value": 1370211,
                "label": "黄岛区",
                "parent": 13702,
                "isLeaf": true
            }, {
                "value": 1370212,
                "label": "崂山区",
                "parent": 13702,
                "isLeaf": true
            }, {
                "value": 1370213,
                "label": "李沧区",
                "parent": 13702,
                "isLeaf": true
            }, {
                "value": 1370214,
                "label": "城阳区",
                "parent": 13702,
                "isLeaf": true
            }, {
                "value": 1370281,
                "label": "胶州市",
                "parent": 13702,
                "isLeaf": true
            }, {
                "value": 1370282,
                "label": "即墨市",
                "parent": 13702,
                "isLeaf": true
            }, {
                "value": 1370283,
                "label": "平度市",
                "parent": 13702,
                "isLeaf": true
            }, {
                "value": 1370285,
                "label": "莱西市",
                "parent": 13702,
                "isLeaf": true
            }],
            "value": 13702,
            "label": "青岛市",
            "parent": 137
        }, {
            "children": [{
                "value": 1370302,
                "label": "淄川区",
                "parent": 13703,
                "isLeaf": true
            }, {
                "value": 1370303,
                "label": "张店区",
                "parent": 13703,
                "isLeaf": true
            }, {
                "value": 1370304,
                "label": "博山区",
                "parent": 13703,
                "isLeaf": true
            }, {
                "value": 1370305,
                "label": "临淄区",
                "parent": 13703,
                "isLeaf": true
            }, {
                "value": 1370306,
                "label": "周村区",
                "parent": 13703,
                "isLeaf": true
            }, {
                "value": 1370321,
                "label": "桓台县",
                "parent": 13703,
                "isLeaf": true
            }, {
                "value": 1370322,
                "label": "高青县",
                "parent": 13703,
                "isLeaf": true
            }, {
                "value": 1370323,
                "label": "沂源县",
                "parent": 13703,
                "isLeaf": true
            }],
            "value": 13703,
            "label": "淄博市",
            "parent": 137
        }, {
            "children": [{
                "value": 1370402,
                "label": "市中区",
                "parent": 13704,
                "isLeaf": true
            }, {
                "value": 1370403,
                "label": "薛城区",
                "parent": 13704,
                "isLeaf": true
            }, {
                "value": 1370404,
                "label": "峄城区",
                "parent": 13704,
                "isLeaf": true
            }, {
                "value": 1370405,
                "label": "台儿庄区",
                "parent": 13704,
                "isLeaf": true
            }, {
                "value": 1370406,
                "label": "山亭区",
                "parent": 13704,
                "isLeaf": true
            }, {
                "value": 1370481,
                "label": "滕州市",
                "parent": 13704,
                "isLeaf": true
            }],
            "value": 13704,
            "label": "枣庄市",
            "parent": 137
        }, {
            "children": [{
                "value": 1370502,
                "label": "东营区",
                "parent": 13705,
                "isLeaf": true
            }, {
                "value": 1370503,
                "label": "河口区",
                "parent": 13705,
                "isLeaf": true
            }, {
                "value": 1370521,
                "label": "垦利县",
                "parent": 13705,
                "isLeaf": true
            }, {
                "value": 1370522,
                "label": "利津县",
                "parent": 13705,
                "isLeaf": true
            }, {
                "value": 1370523,
                "label": "广饶县",
                "parent": 13705,
                "isLeaf": true
            }],
            "value": 13705,
            "label": "东营市",
            "parent": 137
        }, {
            "children": [{
                "value": 1370602,
                "label": "芝罘区",
                "parent": 13706,
                "isLeaf": true
            }, {
                "value": 1370611,
                "label": "福山区",
                "parent": 13706,
                "isLeaf": true
            }, {
                "value": 1370612,
                "label": "牟平区",
                "parent": 13706,
                "isLeaf": true
            }, {
                "value": 1370613,
                "label": "莱山区",
                "parent": 13706,
                "isLeaf": true
            }, {
                "value": 1370634,
                "label": "长岛县",
                "parent": 13706,
                "isLeaf": true
            }, {
                "value": 1370681,
                "label": "龙口市",
                "parent": 13706,
                "isLeaf": true
            }, {
                "value": 1370682,
                "label": "莱阳市",
                "parent": 13706,
                "isLeaf": true
            }, {
                "value": 1370683,
                "label": "莱州市",
                "parent": 13706,
                "isLeaf": true
            }, {
                "value": 1370684,
                "label": "蓬莱市",
                "parent": 13706,
                "isLeaf": true
            }, {
                "value": 1370685,
                "label": "招远市",
                "parent": 13706,
                "isLeaf": true
            }, {
                "value": 1370686,
                "label": "栖霞市",
                "parent": 13706,
                "isLeaf": true
            }, {
                "value": 1370687,
                "label": "海阳市",
                "parent": 13706,
                "isLeaf": true
            }],
            "value": 13706,
            "label": "烟台市",
            "parent": 137
        }, {
            "children": [{
                "value": 1370702,
                "label": "潍城区",
                "parent": 13707,
                "isLeaf": true
            }, {
                "value": 1370703,
                "label": "寒亭区",
                "parent": 13707,
                "isLeaf": true
            }, {
                "value": 1370704,
                "label": "坊子区",
                "parent": 13707,
                "isLeaf": true
            }, {
                "value": 1370705,
                "label": "奎文区",
                "parent": 13707,
                "isLeaf": true
            }, {
                "value": 1370724,
                "label": "临朐县",
                "parent": 13707,
                "isLeaf": true
            }, {
                "value": 1370725,
                "label": "昌乐县",
                "parent": 13707,
                "isLeaf": true
            }, {
                "value": 1370781,
                "label": "青州市",
                "parent": 13707,
                "isLeaf": true
            }, {
                "value": 1370782,
                "label": "诸城市",
                "parent": 13707,
                "isLeaf": true
            }, {
                "value": 1370783,
                "label": "寿光市",
                "parent": 13707,
                "isLeaf": true
            }, {
                "value": 1370784,
                "label": "安丘市",
                "parent": 13707,
                "isLeaf": true
            }, {
                "value": 1370785,
                "label": "高密市",
                "parent": 13707,
                "isLeaf": true
            }, {
                "value": 1370786,
                "label": "昌邑市",
                "parent": 13707,
                "isLeaf": true
            }],
            "value": 13707,
            "label": "潍坊市",
            "parent": 137
        }, {
            "children": [{
                "value": 1370811,
                "label": "任城区",
                "parent": 13708,
                "isLeaf": true
            }, {
                "value": 1370812,
                "label": "兖州区",
                "parent": 13708,
                "isLeaf": true
            }, {
                "value": 1370826,
                "label": "微山县",
                "parent": 13708,
                "isLeaf": true
            }, {
                "value": 1370827,
                "label": "鱼台县",
                "parent": 13708,
                "isLeaf": true
            }, {
                "value": 1370828,
                "label": "金乡县",
                "parent": 13708,
                "isLeaf": true
            }, {
                "value": 1370829,
                "label": "嘉祥县",
                "parent": 13708,
                "isLeaf": true
            }, {
                "value": 1370830,
                "label": "汶上县",
                "parent": 13708,
                "isLeaf": true
            }, {
                "value": 1370831,
                "label": "泗水县",
                "parent": 13708,
                "isLeaf": true
            }, {
                "value": 1370832,
                "label": "梁山县",
                "parent": 13708,
                "isLeaf": true
            }, {
                "value": 1370881,
                "label": "曲阜市",
                "parent": 13708,
                "isLeaf": true
            }, {
                "value": 1370883,
                "label": "邹城市",
                "parent": 13708,
                "isLeaf": true
            }],
            "value": 13708,
            "label": "济宁市",
            "parent": 137
        }, {
            "children": [{
                "value": 1370902,
                "label": "泰山区",
                "parent": 13709,
                "isLeaf": true
            }, {
                "value": 1370911,
                "label": "岱岳区",
                "parent": 13709,
                "isLeaf": true
            }, {
                "value": 1370921,
                "label": "宁阳县",
                "parent": 13709,
                "isLeaf": true
            }, {
                "value": 1370923,
                "label": "东平县",
                "parent": 13709,
                "isLeaf": true
            }, {
                "value": 1370982,
                "label": "新泰市",
                "parent": 13709,
                "isLeaf": true
            }, {
                "value": 1370983,
                "label": "肥城市",
                "parent": 13709,
                "isLeaf": true
            }],
            "value": 13709,
            "label": "泰安市",
            "parent": 137
        }, {
            "children": [{
                "value": 1371002,
                "label": "环翠区",
                "parent": 13710,
                "isLeaf": true
            }, {
                "value": 1371003,
                "label": "文登区",
                "parent": 13710,
                "isLeaf": true
            }, {
                "value": 1371082,
                "label": "荣成市",
                "parent": 13710,
                "isLeaf": true
            }, {
                "value": 1371083,
                "label": "乳山市",
                "parent": 13710,
                "isLeaf": true
            }],
            "value": 13710,
            "label": "威海市",
            "parent": 137
        }, {
            "children": [{
                "value": 1371102,
                "label": "东港区",
                "parent": 13711,
                "isLeaf": true
            }, {
                "value": 1371103,
                "label": "岚山区",
                "parent": 13711,
                "isLeaf": true
            }, {
                "value": 1371121,
                "label": "五莲县",
                "parent": 13711,
                "isLeaf": true
            }, {
                "value": 1371122,
                "label": "莒县",
                "parent": 13711,
                "isLeaf": true
            }],
            "value": 13711,
            "label": "日照市",
            "parent": 137
        }, {
            "children": [{
                "value": 1371202,
                "label": "莱城区",
                "parent": 13712,
                "isLeaf": true
            }, {
                "value": 1371203,
                "label": "钢城区",
                "parent": 13712,
                "isLeaf": true
            }],
            "value": 13712,
            "label": "莱芜市",
            "parent": 137
        }, {
            "children": [{
                "value": 1371302,
                "label": "兰山区",
                "parent": 13713,
                "isLeaf": true
            }, {
                "value": 1371311,
                "label": "罗庄区",
                "parent": 13713,
                "isLeaf": true
            }, {
                "value": 1371312,
                "label": "河东区",
                "parent": 13713,
                "isLeaf": true
            }, {
                "value": 1371321,
                "label": "沂南县",
                "parent": 13713,
                "isLeaf": true
            }, {
                "value": 1371322,
                "label": "郯城县",
                "parent": 13713,
                "isLeaf": true
            }, {
                "value": 1371323,
                "label": "沂水县",
                "parent": 13713,
                "isLeaf": true
            }, {
                "value": 1371324,
                "label": "兰陵县",
                "parent": 13713,
                "isLeaf": true
            }, {
                "value": 1371325,
                "label": "费县",
                "parent": 13713,
                "isLeaf": true
            }, {
                "value": 1371326,
                "label": "平邑县",
                "parent": 13713,
                "isLeaf": true
            }, {
                "value": 1371327,
                "label": "莒南县",
                "parent": 13713,
                "isLeaf": true
            }, {
                "value": 1371328,
                "label": "蒙阴县",
                "parent": 13713,
                "isLeaf": true
            }, {
                "value": 1371329,
                "label": "临沭县",
                "parent": 13713,
                "isLeaf": true
            }],
            "value": 13713,
            "label": "临沂市",
            "parent": 137
        }, {
            "children": [{
                "value": 1371402,
                "label": "德城区",
                "parent": 13714,
                "isLeaf": true
            }, {
                "value": 1371403,
                "label": "陵城区",
                "parent": 13714,
                "isLeaf": true
            }, {
                "value": 1371422,
                "label": "宁津县",
                "parent": 13714,
                "isLeaf": true
            }, {
                "value": 1371423,
                "label": "庆云县",
                "parent": 13714,
                "isLeaf": true
            }, {
                "value": 1371424,
                "label": "临邑县",
                "parent": 13714,
                "isLeaf": true
            }, {
                "value": 1371425,
                "label": "齐河县",
                "parent": 13714,
                "isLeaf": true
            }, {
                "value": 1371426,
                "label": "平原县",
                "parent": 13714,
                "isLeaf": true
            }, {
                "value": 1371427,
                "label": "夏津县",
                "parent": 13714,
                "isLeaf": true
            }, {
                "value": 1371428,
                "label": "武城县",
                "parent": 13714,
                "isLeaf": true
            }, {
                "value": 1371481,
                "label": "乐陵市",
                "parent": 13714,
                "isLeaf": true
            }, {
                "value": 1371482,
                "label": "禹城市",
                "parent": 13714,
                "isLeaf": true
            }],
            "value": 13714,
            "label": "德州市",
            "parent": 137
        }, {
            "children": [{
                "value": 1371502,
                "label": "东昌府区",
                "parent": 13715,
                "isLeaf": true
            }, {
                "value": 1371521,
                "label": "阳谷县",
                "parent": 13715,
                "isLeaf": true
            }, {
                "value": 1371522,
                "label": "莘县",
                "parent": 13715,
                "isLeaf": true
            }, {
                "value": 1371523,
                "label": "茌平县",
                "parent": 13715,
                "isLeaf": true
            }, {
                "value": 1371524,
                "label": "东阿县",
                "parent": 13715,
                "isLeaf": true
            }, {
                "value": 1371525,
                "label": "冠县",
                "parent": 13715,
                "isLeaf": true
            }, {
                "value": 1371526,
                "label": "高唐县",
                "parent": 13715,
                "isLeaf": true
            }, {
                "value": 1371581,
                "label": "临清市",
                "parent": 13715,
                "isLeaf": true
            }],
            "value": 13715,
            "label": "聊城市",
            "parent": 137
        }, {
            "children": [{
                "value": 1371602,
                "label": "滨城区",
                "parent": 13716,
                "isLeaf": true
            }, {
                "value": 1371603,
                "label": "沾化区",
                "parent": 13716,
                "isLeaf": true
            }, {
                "value": 1371621,
                "label": "惠民县",
                "parent": 13716,
                "isLeaf": true
            }, {
                "value": 1371622,
                "label": "阳信县",
                "parent": 13716,
                "isLeaf": true
            }, {
                "value": 1371623,
                "label": "无棣县",
                "parent": 13716,
                "isLeaf": true
            }, {
                "value": 1371625,
                "label": "博兴县",
                "parent": 13716,
                "isLeaf": true
            }, {
                "value": 1371626,
                "label": "邹平县",
                "parent": 13716,
                "isLeaf": true
            }],
            "value": 13716,
            "label": "滨州市",
            "parent": 137
        }, {
            "children": [{
                "value": 1371702,
                "label": "牡丹区",
                "parent": 13717,
                "isLeaf": true
            }, {
                "value": 1371721,
                "label": "曹县",
                "parent": 13717,
                "isLeaf": true
            }, {
                "value": 1371722,
                "label": "单县",
                "parent": 13717,
                "isLeaf": true
            }, {
                "value": 1371723,
                "label": "成武县",
                "parent": 13717,
                "isLeaf": true
            }, {
                "value": 1371724,
                "label": "巨野县",
                "parent": 13717,
                "isLeaf": true
            }, {
                "value": 1371725,
                "label": "郓城县",
                "parent": 13717,
                "isLeaf": true
            }, {
                "value": 1371726,
                "label": "鄄城县",
                "parent": 13717,
                "isLeaf": true
            }, {
                "value": 1371727,
                "label": "定陶县",
                "parent": 13717,
                "isLeaf": true
            }, {
                "value": 1371728,
                "label": "东明县",
                "parent": 13717,
                "isLeaf": true
            }],
            "value": 13717,
            "label": "菏泽市",
            "parent": 137
        }],
        "value": 137,
        "label": "山东省",
        "parent": 0
    }, {
        "children": [{
            "children": [{
                "value": 1410102,
                "label": "中原区",
                "parent": 14101,
                "isLeaf": true
            }, {
                "value": 1410103,
                "label": "二七区",
                "parent": 14101,
                "isLeaf": true
            }, {
                "value": 1410104,
                "label": "管城回族区",
                "parent": 14101,
                "isLeaf": true
            }, {
                "value": 1410105,
                "label": "金水区",
                "parent": 14101,
                "isLeaf": true
            }, {
                "value": 1410106,
                "label": "上街区",
                "parent": 14101,
                "isLeaf": true
            }, {
                "value": 1410108,
                "label": "惠济区",
                "parent": 14101,
                "isLeaf": true
            }, {
                "value": 1410122,
                "label": "中牟县",
                "parent": 14101,
                "isLeaf": true
            }, {
                "value": 1410181,
                "label": "巩义市",
                "parent": 14101,
                "isLeaf": true
            }, {
                "value": 1410182,
                "label": "荥阳市",
                "parent": 14101,
                "isLeaf": true
            }, {
                "value": 1410183,
                "label": "新密市",
                "parent": 14101,
                "isLeaf": true
            }, {
                "value": 1410184,
                "label": "新郑市",
                "parent": 14101,
                "isLeaf": true
            }, {
                "value": 1410185,
                "label": "登封市",
                "parent": 14101,
                "isLeaf": true
            }],
            "value": 14101,
            "label": "郑州市",
            "parent": 141
        }, {
            "children": [{
                "value": 1410202,
                "label": "龙亭区",
                "parent": 14102,
                "isLeaf": true
            }, {
                "value": 1410203,
                "label": "顺河回族区",
                "parent": 14102,
                "isLeaf": true
            }, {
                "value": 1410204,
                "label": "鼓楼区",
                "parent": 14102,
                "isLeaf": true
            }, {
                "value": 1410205,
                "label": "禹王台区",
                "parent": 14102,
                "isLeaf": true
            }, {
                "value": 1410212,
                "label": "祥符区",
                "parent": 14102,
                "isLeaf": true
            }, {
                "value": 1410221,
                "label": "杞县",
                "parent": 14102,
                "isLeaf": true
            }, {
                "value": 1410222,
                "label": "通许县",
                "parent": 14102,
                "isLeaf": true
            }, {
                "value": 1410223,
                "label": "尉氏县",
                "parent": 14102,
                "isLeaf": true
            }, {
                "value": 1410225,
                "label": "兰考县",
                "parent": 14102,
                "isLeaf": true
            }],
            "value": 14102,
            "label": "开封市",
            "parent": 141
        }, {
            "children": [{
                "value": 1410302,
                "label": "老城区",
                "parent": 14103,
                "isLeaf": true
            }, {
                "value": 1410303,
                "label": "西工区",
                "parent": 14103,
                "isLeaf": true
            }, {
                "value": 1410304,
                "label": "瀍河回族区",
                "parent": 14103,
                "isLeaf": true
            }, {
                "value": 1410305,
                "label": "涧西区",
                "parent": 14103,
                "isLeaf": true
            }, {
                "value": 1410306,
                "label": "吉利区",
                "parent": 14103,
                "isLeaf": true
            }, {
                "value": 1410311,
                "label": "洛龙区",
                "parent": 14103,
                "isLeaf": true
            }, {
                "value": 1410322,
                "label": "孟津县",
                "parent": 14103,
                "isLeaf": true
            }, {
                "value": 1410323,
                "label": "新安县",
                "parent": 14103,
                "isLeaf": true
            }, {
                "value": 1410324,
                "label": "栾川县",
                "parent": 14103,
                "isLeaf": true
            }, {
                "value": 1410325,
                "label": "嵩县",
                "parent": 14103,
                "isLeaf": true
            }, {
                "value": 1410326,
                "label": "汝阳县",
                "parent": 14103,
                "isLeaf": true
            }, {
                "value": 1410327,
                "label": "宜阳县",
                "parent": 14103,
                "isLeaf": true
            }, {
                "value": 1410328,
                "label": "洛宁县",
                "parent": 14103,
                "isLeaf": true
            }, {
                "value": 1410329,
                "label": "伊川县",
                "parent": 14103,
                "isLeaf": true
            }, {
                "value": 1410381,
                "label": "偃师市",
                "parent": 14103,
                "isLeaf": true
            }],
            "value": 14103,
            "label": "洛阳市",
            "parent": 141
        }, {
            "children": [{
                "value": 1410402,
                "label": "新华区",
                "parent": 14104,
                "isLeaf": true
            }, {
                "value": 1410403,
                "label": "卫东区",
                "parent": 14104,
                "isLeaf": true
            }, {
                "value": 1410404,
                "label": "石龙区",
                "parent": 14104,
                "isLeaf": true
            }, {
                "value": 1410411,
                "label": "湛河区",
                "parent": 14104,
                "isLeaf": true
            }, {
                "value": 1410421,
                "label": "宝丰县",
                "parent": 14104,
                "isLeaf": true
            }, {
                "value": 1410422,
                "label": "叶县",
                "parent": 14104,
                "isLeaf": true
            }, {
                "value": 1410423,
                "label": "鲁山县",
                "parent": 14104,
                "isLeaf": true
            }, {
                "value": 1410425,
                "label": "郏县",
                "parent": 14104,
                "isLeaf": true
            }, {
                "value": 1410481,
                "label": "舞钢市",
                "parent": 14104,
                "isLeaf": true
            }, {
                "value": 1410482,
                "label": "汝州市",
                "parent": 14104,
                "isLeaf": true
            }],
            "value": 14104,
            "label": "平顶山市",
            "parent": 141
        }, {
            "children": [{
                "value": 1410502,
                "label": "文峰区",
                "parent": 14105,
                "isLeaf": true
            }, {
                "value": 1410503,
                "label": "北关区",
                "parent": 14105,
                "isLeaf": true
            }, {
                "value": 1410505,
                "label": "殷都区",
                "parent": 14105,
                "isLeaf": true
            }, {
                "value": 1410506,
                "label": "龙安区",
                "parent": 14105,
                "isLeaf": true
            }, {
                "value": 1410522,
                "label": "安阳县",
                "parent": 14105,
                "isLeaf": true
            }, {
                "value": 1410523,
                "label": "汤阴县",
                "parent": 14105,
                "isLeaf": true
            }, {
                "value": 1410526,
                "label": "滑县",
                "parent": 14105,
                "isLeaf": true
            }, {
                "value": 1410527,
                "label": "内黄县",
                "parent": 14105,
                "isLeaf": true
            }, {
                "value": 1410581,
                "label": "林州市",
                "parent": 14105,
                "isLeaf": true
            }],
            "value": 14105,
            "label": "安阳市",
            "parent": 141
        }, {
            "children": [{
                "value": 1410602,
                "label": "鹤山区",
                "parent": 14106,
                "isLeaf": true
            }, {
                "value": 1410603,
                "label": "山城区",
                "parent": 14106,
                "isLeaf": true
            }, {
                "value": 1410611,
                "label": "淇滨区",
                "parent": 14106,
                "isLeaf": true
            }, {
                "value": 1410621,
                "label": "浚县",
                "parent": 14106,
                "isLeaf": true
            }, {
                "value": 1410622,
                "label": "淇县",
                "parent": 14106,
                "isLeaf": true
            }],
            "value": 14106,
            "label": "鹤壁市",
            "parent": 141
        }, {
            "children": [{
                "value": 1410702,
                "label": "红旗区",
                "parent": 14107,
                "isLeaf": true
            }, {
                "value": 1410703,
                "label": "卫滨区",
                "parent": 14107,
                "isLeaf": true
            }, {
                "value": 1410704,
                "label": "凤泉区",
                "parent": 14107,
                "isLeaf": true
            }, {
                "value": 1410711,
                "label": "牧野区",
                "parent": 14107,
                "isLeaf": true
            }, {
                "value": 1410721,
                "label": "新乡县",
                "parent": 14107,
                "isLeaf": true
            }, {
                "value": 1410724,
                "label": "获嘉县",
                "parent": 14107,
                "isLeaf": true
            }, {
                "value": 1410725,
                "label": "原阳县",
                "parent": 14107,
                "isLeaf": true
            }, {
                "value": 1410726,
                "label": "延津县",
                "parent": 14107,
                "isLeaf": true
            }, {
                "value": 1410727,
                "label": "封丘县",
                "parent": 14107,
                "isLeaf": true
            }, {
                "value": 1410728,
                "label": "长垣县",
                "parent": 14107,
                "isLeaf": true
            }, {
                "value": 1410781,
                "label": "卫辉市",
                "parent": 14107,
                "isLeaf": true
            }, {
                "value": 1410782,
                "label": "辉县市",
                "parent": 14107,
                "isLeaf": true
            }],
            "value": 14107,
            "label": "新乡市",
            "parent": 141
        }, {
            "children": [{
                "value": 1410802,
                "label": "解放区",
                "parent": 14108,
                "isLeaf": true
            }, {
                "value": 1410803,
                "label": "中站区",
                "parent": 14108,
                "isLeaf": true
            }, {
                "value": 1410804,
                "label": "马村区",
                "parent": 14108,
                "isLeaf": true
            }, {
                "value": 1410811,
                "label": "山阳区",
                "parent": 14108,
                "isLeaf": true
            }, {
                "value": 1410821,
                "label": "修武县",
                "parent": 14108,
                "isLeaf": true
            }, {
                "value": 1410822,
                "label": "博爱县",
                "parent": 14108,
                "isLeaf": true
            }, {
                "value": 1410823,
                "label": "武陟县",
                "parent": 14108,
                "isLeaf": true
            }, {
                "value": 1410825,
                "label": "温县",
                "parent": 14108,
                "isLeaf": true
            }, {
                "value": 1410882,
                "label": "沁阳市",
                "parent": 14108,
                "isLeaf": true
            }, {
                "value": 1410883,
                "label": "孟州市",
                "parent": 14108,
                "isLeaf": true
            }],
            "value": 14108,
            "label": "焦作市",
            "parent": 141
        }, {
            "children": [{
                "value": 1410902,
                "label": "华龙区",
                "parent": 14109,
                "isLeaf": true
            }, {
                "value": 1410922,
                "label": "清丰县",
                "parent": 14109,
                "isLeaf": true
            }, {
                "value": 1410923,
                "label": "南乐县",
                "parent": 14109,
                "isLeaf": true
            }, {
                "value": 1410926,
                "label": "范县",
                "parent": 14109,
                "isLeaf": true
            }, {
                "value": 1410927,
                "label": "台前县",
                "parent": 14109,
                "isLeaf": true
            }, {
                "value": 1410928,
                "label": "濮阳县",
                "parent": 14109,
                "isLeaf": true
            }],
            "value": 14109,
            "label": "濮阳市",
            "parent": 141
        }, {
            "children": [{
                "value": 1411002,
                "label": "魏都区",
                "parent": 14110,
                "isLeaf": true
            }, {
                "value": 1411023,
                "label": "许昌县",
                "parent": 14110,
                "isLeaf": true
            }, {
                "value": 1411024,
                "label": "鄢陵县",
                "parent": 14110,
                "isLeaf": true
            }, {
                "value": 1411025,
                "label": "襄城县",
                "parent": 14110,
                "isLeaf": true
            }, {
                "value": 1411081,
                "label": "禹州市",
                "parent": 14110,
                "isLeaf": true
            }, {
                "value": 1411082,
                "label": "长葛市",
                "parent": 14110,
                "isLeaf": true
            }],
            "value": 14110,
            "label": "许昌市",
            "parent": 141
        }, {
            "children": [{
                "value": 1411102,
                "label": "源汇区",
                "parent": 14111,
                "isLeaf": true
            }, {
                "value": 1411103,
                "label": "郾城区",
                "parent": 14111,
                "isLeaf": true
            }, {
                "value": 1411104,
                "label": "召陵区",
                "parent": 14111,
                "isLeaf": true
            }, {
                "value": 1411121,
                "label": "舞阳县",
                "parent": 14111,
                "isLeaf": true
            }, {
                "value": 1411122,
                "label": "临颍县",
                "parent": 14111,
                "isLeaf": true
            }],
            "value": 14111,
            "label": "漯河市",
            "parent": 141
        }, {
            "children": [{
                "value": 1411202,
                "label": "湖滨区",
                "parent": 14112,
                "isLeaf": true
            }, {
                "value": 1411203,
                "label": "陕州区",
                "parent": 14112,
                "isLeaf": true
            }, {
                "value": 1411221,
                "label": "渑池县",
                "parent": 14112,
                "isLeaf": true
            }, {
                "value": 1411224,
                "label": "卢氏县",
                "parent": 14112,
                "isLeaf": true
            }, {
                "value": 1411281,
                "label": "义马市",
                "parent": 14112,
                "isLeaf": true
            }, {
                "value": 1411282,
                "label": "灵宝市",
                "parent": 14112,
                "isLeaf": true
            }],
            "value": 14112,
            "label": "三门峡市",
            "parent": 141
        }, {
            "children": [{
                "value": 1411302,
                "label": "宛城区",
                "parent": 14113,
                "isLeaf": true
            }, {
                "value": 1411303,
                "label": "卧龙区",
                "parent": 14113,
                "isLeaf": true
            }, {
                "value": 1411321,
                "label": "南召县",
                "parent": 14113,
                "isLeaf": true
            }, {
                "value": 1411322,
                "label": "方城县",
                "parent": 14113,
                "isLeaf": true
            }, {
                "value": 1411323,
                "label": "西峡县",
                "parent": 14113,
                "isLeaf": true
            }, {
                "value": 1411324,
                "label": "镇平县",
                "parent": 14113,
                "isLeaf": true
            }, {
                "value": 1411325,
                "label": "内乡县",
                "parent": 14113,
                "isLeaf": true
            }, {
                "value": 1411326,
                "label": "淅川县",
                "parent": 14113,
                "isLeaf": true
            }, {
                "value": 1411327,
                "label": "社旗县",
                "parent": 14113,
                "isLeaf": true
            }, {
                "value": 1411328,
                "label": "唐河县",
                "parent": 14113,
                "isLeaf": true
            }, {
                "value": 1411329,
                "label": "新野县",
                "parent": 14113,
                "isLeaf": true
            }, {
                "value": 1411330,
                "label": "桐柏县",
                "parent": 14113,
                "isLeaf": true
            }, {
                "value": 1411381,
                "label": "邓州市",
                "parent": 14113,
                "isLeaf": true
            }],
            "value": 14113,
            "label": "南阳市",
            "parent": 141
        }, {
            "children": [{
                "value": 1411402,
                "label": "梁园区",
                "parent": 14114,
                "isLeaf": true
            }, {
                "value": 1411403,
                "label": "睢阳区",
                "parent": 14114,
                "isLeaf": true
            }, {
                "value": 1411421,
                "label": "民权县",
                "parent": 14114,
                "isLeaf": true
            }, {
                "value": 1411422,
                "label": "睢县",
                "parent": 14114,
                "isLeaf": true
            }, {
                "value": 1411423,
                "label": "宁陵县",
                "parent": 14114,
                "isLeaf": true
            }, {
                "value": 1411424,
                "label": "柘城县",
                "parent": 14114,
                "isLeaf": true
            }, {
                "value": 1411425,
                "label": "虞城县",
                "parent": 14114,
                "isLeaf": true
            }, {
                "value": 1411426,
                "label": "夏邑县",
                "parent": 14114,
                "isLeaf": true
            }, {
                "value": 1411481,
                "label": "永城市",
                "parent": 14114,
                "isLeaf": true
            }],
            "value": 14114,
            "label": "商丘市",
            "parent": 141
        }, {
            "children": [{
                "value": 1411502,
                "label": "浉河区",
                "parent": 14115,
                "isLeaf": true
            }, {
                "value": 1411503,
                "label": "平桥区",
                "parent": 14115,
                "isLeaf": true
            }, {
                "value": 1411521,
                "label": "罗山县",
                "parent": 14115,
                "isLeaf": true
            }, {
                "value": 1411522,
                "label": "光山县",
                "parent": 14115,
                "isLeaf": true
            }, {
                "value": 1411523,
                "label": "新县",
                "parent": 14115,
                "isLeaf": true
            }, {
                "value": 1411524,
                "label": "商城县",
                "parent": 14115,
                "isLeaf": true
            }, {
                "value": 1411525,
                "label": "固始县",
                "parent": 14115,
                "isLeaf": true
            }, {
                "value": 1411526,
                "label": "潢川县",
                "parent": 14115,
                "isLeaf": true
            }, {
                "value": 1411527,
                "label": "淮滨县",
                "parent": 14115,
                "isLeaf": true
            }, {
                "value": 1411528,
                "label": "息县",
                "parent": 14115,
                "isLeaf": true
            }],
            "value": 14115,
            "label": "信阳市",
            "parent": 141
        }, {
            "children": [{
                "value": 1411602,
                "label": "川汇区",
                "parent": 14116,
                "isLeaf": true
            }, {
                "value": 1411621,
                "label": "扶沟县",
                "parent": 14116,
                "isLeaf": true
            }, {
                "value": 1411622,
                "label": "西华县",
                "parent": 14116,
                "isLeaf": true
            }, {
                "value": 1411623,
                "label": "商水县",
                "parent": 14116,
                "isLeaf": true
            }, {
                "value": 1411624,
                "label": "沈丘县",
                "parent": 14116,
                "isLeaf": true
            }, {
                "value": 1411625,
                "label": "郸城县",
                "parent": 14116,
                "isLeaf": true
            }, {
                "value": 1411626,
                "label": "淮阳县",
                "parent": 14116,
                "isLeaf": true
            }, {
                "value": 1411627,
                "label": "太康县",
                "parent": 14116,
                "isLeaf": true
            }, {
                "value": 1411628,
                "label": "鹿邑县",
                "parent": 14116,
                "isLeaf": true
            }, {
                "value": 1411681,
                "label": "项城市",
                "parent": 14116,
                "isLeaf": true
            }],
            "value": 14116,
            "label": "周口市",
            "parent": 141
        }, {
            "children": [{
                "value": 1411702,
                "label": "驿城区",
                "parent": 14117,
                "isLeaf": true
            }, {
                "value": 1411721,
                "label": "西平县",
                "parent": 14117,
                "isLeaf": true
            }, {
                "value": 1411722,
                "label": "上蔡县",
                "parent": 14117,
                "isLeaf": true
            }, {
                "value": 1411723,
                "label": "平舆县",
                "parent": 14117,
                "isLeaf": true
            }, {
                "value": 1411724,
                "label": "正阳县",
                "parent": 14117,
                "isLeaf": true
            }, {
                "value": 1411725,
                "label": "确山县",
                "parent": 14117,
                "isLeaf": true
            }, {
                "value": 1411726,
                "label": "泌阳县",
                "parent": 14117,
                "isLeaf": true
            }, {
                "value": 1411727,
                "label": "汝南县",
                "parent": 14117,
                "isLeaf": true
            }, {
                "value": 1411728,
                "label": "遂平县",
                "parent": 14117,
                "isLeaf": true
            }, {
                "value": 1411729,
                "label": "新蔡县",
                "parent": 14117,
                "isLeaf": true
            }],
            "value": 14117,
            "label": "驻马店市",
            "parent": 141
        }, {
            "children": [{
                "value": 1419001,
                "label": "济源市",
                "parent": 14190,
                "isLeaf": true
            }],
            "value": 14190,
            "label": "济源市",
            "parent": 141
        }],
        "value": 141,
        "label": "河南省",
        "parent": 0
    }, {
        "children": [{
            "children": [{
                "value": 1420102,
                "label": "江岸区",
                "parent": 14201,
                "isLeaf": true
            }, {
                "value": 1420103,
                "label": "江汉区",
                "parent": 14201,
                "isLeaf": true
            }, {
                "value": 1420104,
                "label": "硚口区",
                "parent": 14201,
                "isLeaf": true
            }, {
                "value": 1420105,
                "label": "汉阳区",
                "parent": 14201,
                "isLeaf": true
            }, {
                "value": 1420106,
                "label": "武昌区",
                "parent": 14201,
                "isLeaf": true
            }, {
                "value": 1420107,
                "label": "青山区",
                "parent": 14201,
                "isLeaf": true
            }, {
                "value": 1420111,
                "label": "洪山区",
                "parent": 14201,
                "isLeaf": true
            }, {
                "value": 1420112,
                "label": "东西湖区",
                "parent": 14201,
                "isLeaf": true
            }, {
                "value": 1420113,
                "label": "汉南区",
                "parent": 14201,
                "isLeaf": true
            }, {
                "value": 1420114,
                "label": "蔡甸区",
                "parent": 14201,
                "isLeaf": true
            }, {
                "value": 1420115,
                "label": "江夏区",
                "parent": 14201,
                "isLeaf": true
            }, {
                "value": 1420116,
                "label": "黄陂区",
                "parent": 14201,
                "isLeaf": true
            }, {
                "value": 1420117,
                "label": "新洲区",
                "parent": 14201,
                "isLeaf": true
            }],
            "value": 14201,
            "label": "武汉市",
            "parent": 142
        }, {
            "children": [{
                "value": 1420202,
                "label": "黄石港区",
                "parent": 14202,
                "isLeaf": true
            }, {
                "value": 1420203,
                "label": "西塞山区",
                "parent": 14202,
                "isLeaf": true
            }, {
                "value": 1420204,
                "label": "下陆区",
                "parent": 14202,
                "isLeaf": true
            }, {
                "value": 1420205,
                "label": "铁山区",
                "parent": 14202,
                "isLeaf": true
            }, {
                "value": 1420222,
                "label": "阳新县",
                "parent": 14202,
                "isLeaf": true
            }, {
                "value": 1420281,
                "label": "大冶市",
                "parent": 14202,
                "isLeaf": true
            }],
            "value": 14202,
            "label": "黄石市",
            "parent": 142
        }, {
            "children": [{
                "value": 1420302,
                "label": "茅箭区",
                "parent": 14203,
                "isLeaf": true
            }, {
                "value": 1420303,
                "label": "张湾区",
                "parent": 14203,
                "isLeaf": true
            }, {
                "value": 1420304,
                "label": "郧阳区",
                "parent": 14203,
                "isLeaf": true
            }, {
                "value": 1420322,
                "label": "郧西县",
                "parent": 14203,
                "isLeaf": true
            }, {
                "value": 1420323,
                "label": "竹山县",
                "parent": 14203,
                "isLeaf": true
            }, {
                "value": 1420324,
                "label": "竹溪县",
                "parent": 14203,
                "isLeaf": true
            }, {
                "value": 1420325,
                "label": "房县",
                "parent": 14203,
                "isLeaf": true
            }, {
                "value": 1420381,
                "label": "丹江口市",
                "parent": 14203,
                "isLeaf": true
            }],
            "value": 14203,
            "label": "十堰市",
            "parent": 142
        }, {
            "children": [{
                "value": 1420502,
                "label": "西陵区",
                "parent": 14205,
                "isLeaf": true
            }, {
                "value": 1420503,
                "label": "伍家岗区",
                "parent": 14205,
                "isLeaf": true
            }, {
                "value": 1420504,
                "label": "点军区",
                "parent": 14205,
                "isLeaf": true
            }, {
                "value": 1420505,
                "label": "猇亭区",
                "parent": 14205,
                "isLeaf": true
            }, {
                "value": 1420506,
                "label": "夷陵区",
                "parent": 14205,
                "isLeaf": true
            }, {
                "value": 1420525,
                "label": "远安县",
                "parent": 14205,
                "isLeaf": true
            }, {
                "value": 1420526,
                "label": "兴山县",
                "parent": 14205,
                "isLeaf": true
            }, {
                "value": 1420527,
                "label": "秭归县",
                "parent": 14205,
                "isLeaf": true
            }, {
                "value": 1420528,
                "label": "长阳土家族自治县",
                "parent": 14205,
                "isLeaf": true
            }, {
                "value": 1420529,
                "label": "五峰土家族自治县",
                "parent": 14205,
                "isLeaf": true
            }, {
                "value": 1420581,
                "label": "宜都市",
                "parent": 14205,
                "isLeaf": true
            }, {
                "value": 1420582,
                "label": "当阳市",
                "parent": 14205,
                "isLeaf": true
            }, {
                "value": 1420583,
                "label": "枝江市",
                "parent": 14205,
                "isLeaf": true
            }],
            "value": 14205,
            "label": "宜昌市",
            "parent": 142
        }, {
            "children": [{
                "value": 1420602,
                "label": "襄城区",
                "parent": 14206,
                "isLeaf": true
            }, {
                "value": 1420606,
                "label": "樊城区",
                "parent": 14206,
                "isLeaf": true
            }, {
                "value": 1420607,
                "label": "襄州区",
                "parent": 14206,
                "isLeaf": true
            }, {
                "value": 1420624,
                "label": "南漳县",
                "parent": 14206,
                "isLeaf": true
            }, {
                "value": 1420625,
                "label": "谷城县",
                "parent": 14206,
                "isLeaf": true
            }, {
                "value": 1420626,
                "label": "保康县",
                "parent": 14206,
                "isLeaf": true
            }, {
                "value": 1420682,
                "label": "老河口市",
                "parent": 14206,
                "isLeaf": true
            }, {
                "value": 1420683,
                "label": "枣阳市",
                "parent": 14206,
                "isLeaf": true
            }, {
                "value": 1420684,
                "label": "宜城市",
                "parent": 14206,
                "isLeaf": true
            }],
            "value": 14206,
            "label": "襄阳市",
            "parent": 142
        }, {
            "children": [{
                "value": 1420702,
                "label": "梁子湖区",
                "parent": 14207,
                "isLeaf": true
            }, {
                "value": 1420703,
                "label": "华容区",
                "parent": 14207,
                "isLeaf": true
            }, {
                "value": 1420704,
                "label": "鄂城区",
                "parent": 14207,
                "isLeaf": true
            }],
            "value": 14207,
            "label": "鄂州市",
            "parent": 142
        }, {
            "children": [{
                "value": 1420802,
                "label": "东宝区",
                "parent": 14208,
                "isLeaf": true
            }, {
                "value": 1420804,
                "label": "掇刀区",
                "parent": 14208,
                "isLeaf": true
            }, {
                "value": 1420821,
                "label": "京山县",
                "parent": 14208,
                "isLeaf": true
            }, {
                "value": 1420822,
                "label": "沙洋县",
                "parent": 14208,
                "isLeaf": true
            }, {
                "value": 1420881,
                "label": "钟祥市",
                "parent": 14208,
                "isLeaf": true
            }],
            "value": 14208,
            "label": "荆门市",
            "parent": 142
        }, {
            "children": [{
                "value": 1420902,
                "label": "孝南区",
                "parent": 14209,
                "isLeaf": true
            }, {
                "value": 1420921,
                "label": "孝昌县",
                "parent": 14209,
                "isLeaf": true
            }, {
                "value": 1420922,
                "label": "大悟县",
                "parent": 14209,
                "isLeaf": true
            }, {
                "value": 1420923,
                "label": "云梦县",
                "parent": 14209,
                "isLeaf": true
            }, {
                "value": 1420981,
                "label": "应城市",
                "parent": 14209,
                "isLeaf": true
            }, {
                "value": 1420982,
                "label": "安陆市",
                "parent": 14209,
                "isLeaf": true
            }, {
                "value": 1420984,
                "label": "汉川市",
                "parent": 14209,
                "isLeaf": true
            }],
            "value": 14209,
            "label": "孝感市",
            "parent": 142
        }, {
            "children": [{
                "value": 1421002,
                "label": "沙市区",
                "parent": 14210,
                "isLeaf": true
            }, {
                "value": 1421003,
                "label": "荆州区",
                "parent": 14210,
                "isLeaf": true
            }, {
                "value": 1421022,
                "label": "公安县",
                "parent": 14210,
                "isLeaf": true
            }, {
                "value": 1421023,
                "label": "监利县",
                "parent": 14210,
                "isLeaf": true
            }, {
                "value": 1421024,
                "label": "江陵县",
                "parent": 14210,
                "isLeaf": true
            }, {
                "value": 1421081,
                "label": "石首市",
                "parent": 14210,
                "isLeaf": true
            }, {
                "value": 1421083,
                "label": "洪湖市",
                "parent": 14210,
                "isLeaf": true
            }, {
                "value": 1421087,
                "label": "松滋市",
                "parent": 14210,
                "isLeaf": true
            }],
            "value": 14210,
            "label": "荆州市",
            "parent": 142
        }, {
            "children": [{
                "value": 1421102,
                "label": "黄州区",
                "parent": 14211,
                "isLeaf": true
            }, {
                "value": 1421121,
                "label": "团风县",
                "parent": 14211,
                "isLeaf": true
            }, {
                "value": 1421122,
                "label": "红安县",
                "parent": 14211,
                "isLeaf": true
            }, {
                "value": 1421123,
                "label": "罗田县",
                "parent": 14211,
                "isLeaf": true
            }, {
                "value": 1421124,
                "label": "英山县",
                "parent": 14211,
                "isLeaf": true
            }, {
                "value": 1421125,
                "label": "浠水县",
                "parent": 14211,
                "isLeaf": true
            }, {
                "value": 1421126,
                "label": "蕲春县",
                "parent": 14211,
                "isLeaf": true
            }, {
                "value": 1421127,
                "label": "黄梅县",
                "parent": 14211,
                "isLeaf": true
            }, {
                "value": 1421181,
                "label": "麻城市",
                "parent": 14211,
                "isLeaf": true
            }, {
                "value": 1421182,
                "label": "武穴市",
                "parent": 14211,
                "isLeaf": true
            }],
            "value": 14211,
            "label": "黄冈市",
            "parent": 142
        }, {
            "children": [{
                "value": 1421202,
                "label": "咸安区",
                "parent": 14212,
                "isLeaf": true
            }, {
                "value": 1421221,
                "label": "嘉鱼县",
                "parent": 14212,
                "isLeaf": true
            }, {
                "value": 1421222,
                "label": "通城县",
                "parent": 14212,
                "isLeaf": true
            }, {
                "value": 1421223,
                "label": "崇阳县",
                "parent": 14212,
                "isLeaf": true
            }, {
                "value": 1421224,
                "label": "通山县",
                "parent": 14212,
                "isLeaf": true
            }, {
                "value": 1421281,
                "label": "赤壁市",
                "parent": 14212,
                "isLeaf": true
            }],
            "value": 14212,
            "label": "咸宁市",
            "parent": 142
        }, {
            "children": [{
                "value": 1421303,
                "label": "曾都区",
                "parent": 14213,
                "isLeaf": true
            }, {
                "value": 1421321,
                "label": "随县",
                "parent": 14213,
                "isLeaf": true
            }, {
                "value": 1421381,
                "label": "广水市",
                "parent": 14213,
                "isLeaf": true
            }],
            "value": 14213,
            "label": "随州市",
            "parent": 142
        }, {
            "children": [{
                "value": 1422801,
                "label": "恩施市",
                "parent": 14228,
                "isLeaf": true
            }, {
                "value": 1422802,
                "label": "利川市",
                "parent": 14228,
                "isLeaf": true
            }, {
                "value": 1422822,
                "label": "建始县",
                "parent": 14228,
                "isLeaf": true
            }, {
                "value": 1422823,
                "label": "巴东县",
                "parent": 14228,
                "isLeaf": true
            }, {
                "value": 1422825,
                "label": "宣恩县",
                "parent": 14228,
                "isLeaf": true
            }, {
                "value": 1422826,
                "label": "咸丰县",
                "parent": 14228,
                "isLeaf": true
            }, {
                "value": 1422827,
                "label": "来凤县",
                "parent": 14228,
                "isLeaf": true
            }, {
                "value": 1422828,
                "label": "鹤峰县",
                "parent": 14228,
                "isLeaf": true
            }],
            "value": 14228,
            "label": "恩施土家族苗族自治州",
            "parent": 142
        }, {
            "children": [{
                "value": 1429004,
                "label": "仙桃市",
                "parent": 14290,
                "isLeaf": true
            }, {
                "value": 1429005,
                "label": "潜江市",
                "parent": 14290,
                "isLeaf": true
            }, {
                "value": 1429006,
                "label": "天门市",
                "parent": 14290,
                "isLeaf": true
            }, {
                "value": 1429021,
                "label": "神农架林区",
                "parent": 14290,
                "isLeaf": true
            }],
            "value": 14290,
            "label": "省直辖县级行政区划",
            "parent": 142
        }],
        "value": 142,
        "label": "湖北省",
        "parent": 0
    }, {
        "children": [{
            "children": [{
                "value": 1430102,
                "label": "芙蓉区",
                "parent": 14301,
                "isLeaf": true
            }, {
                "value": 1430103,
                "label": "天心区",
                "parent": 14301,
                "isLeaf": true
            }, {
                "value": 1430104,
                "label": "岳麓区",
                "parent": 14301,
                "isLeaf": true
            }, {
                "value": 1430105,
                "label": "开福区",
                "parent": 14301,
                "isLeaf": true
            }, {
                "value": 1430111,
                "label": "雨花区",
                "parent": 14301,
                "isLeaf": true
            }, {
                "value": 1430112,
                "label": "望城区",
                "parent": 14301,
                "isLeaf": true
            }, {
                "value": 1430121,
                "label": "长沙县",
                "parent": 14301,
                "isLeaf": true
            }, {
                "value": 1430124,
                "label": "宁乡县",
                "parent": 14301,
                "isLeaf": true
            }, {
                "value": 1430181,
                "label": "浏阳市",
                "parent": 14301,
                "isLeaf": true
            }],
            "value": 14301,
            "label": "长沙市",
            "parent": 143
        }, {
            "children": [{
                "value": 1430202,
                "label": "荷塘区",
                "parent": 14302,
                "isLeaf": true
            }, {
                "value": 1430203,
                "label": "芦淞区",
                "parent": 14302,
                "isLeaf": true
            }, {
                "value": 1430204,
                "label": "石峰区",
                "parent": 14302,
                "isLeaf": true
            }, {
                "value": 1430211,
                "label": "天元区",
                "parent": 14302,
                "isLeaf": true
            }, {
                "value": 1430221,
                "label": "株洲县",
                "parent": 14302,
                "isLeaf": true
            }, {
                "value": 1430223,
                "label": "攸县",
                "parent": 14302,
                "isLeaf": true
            }, {
                "value": 1430224,
                "label": "茶陵县",
                "parent": 14302,
                "isLeaf": true
            }, {
                "value": 1430225,
                "label": "炎陵县",
                "parent": 14302,
                "isLeaf": true
            }, {
                "value": 1430281,
                "label": "醴陵市",
                "parent": 14302,
                "isLeaf": true
            }],
            "value": 14302,
            "label": "株洲市",
            "parent": 143
        }, {
            "children": [{
                "value": 1430302,
                "label": "雨湖区",
                "parent": 14303,
                "isLeaf": true
            }, {
                "value": 1430304,
                "label": "岳塘区",
                "parent": 14303,
                "isLeaf": true
            }, {
                "value": 1430321,
                "label": "湘潭县",
                "parent": 14303,
                "isLeaf": true
            }, {
                "value": 1430381,
                "label": "湘乡市",
                "parent": 14303,
                "isLeaf": true
            }, {
                "value": 1430382,
                "label": "韶山市",
                "parent": 14303,
                "isLeaf": true
            }],
            "value": 14303,
            "label": "湘潭市",
            "parent": 143
        }, {
            "children": [{
                "value": 1430405,
                "label": "珠晖区",
                "parent": 14304,
                "isLeaf": true
            }, {
                "value": 1430406,
                "label": "雁峰区",
                "parent": 14304,
                "isLeaf": true
            }, {
                "value": 1430407,
                "label": "石鼓区",
                "parent": 14304,
                "isLeaf": true
            }, {
                "value": 1430408,
                "label": "蒸湘区",
                "parent": 14304,
                "isLeaf": true
            }, {
                "value": 1430412,
                "label": "南岳区",
                "parent": 14304,
                "isLeaf": true
            }, {
                "value": 1430421,
                "label": "衡阳县",
                "parent": 14304,
                "isLeaf": true
            }, {
                "value": 1430422,
                "label": "衡南县",
                "parent": 14304,
                "isLeaf": true
            }, {
                "value": 1430423,
                "label": "衡山县",
                "parent": 14304,
                "isLeaf": true
            }, {
                "value": 1430424,
                "label": "衡东县",
                "parent": 14304,
                "isLeaf": true
            }, {
                "value": 1430426,
                "label": "祁东县",
                "parent": 14304,
                "isLeaf": true
            }, {
                "value": 1430481,
                "label": "耒阳市",
                "parent": 14304,
                "isLeaf": true
            }, {
                "value": 1430482,
                "label": "常宁市",
                "parent": 14304,
                "isLeaf": true
            }],
            "value": 14304,
            "label": "衡阳市",
            "parent": 143
        }, {
            "children": [{
                "value": 1430502,
                "label": "双清区",
                "parent": 14305,
                "isLeaf": true
            }, {
                "value": 1430503,
                "label": "大祥区",
                "parent": 14305,
                "isLeaf": true
            }, {
                "value": 1430511,
                "label": "北塔区",
                "parent": 14305,
                "isLeaf": true
            }, {
                "value": 1430521,
                "label": "邵东县",
                "parent": 14305,
                "isLeaf": true
            }, {
                "value": 1430522,
                "label": "新邵县",
                "parent": 14305,
                "isLeaf": true
            }, {
                "value": 1430523,
                "label": "邵阳县",
                "parent": 14305,
                "isLeaf": true
            }, {
                "value": 1430524,
                "label": "隆回县",
                "parent": 14305,
                "isLeaf": true
            }, {
                "value": 1430525,
                "label": "洞口县",
                "parent": 14305,
                "isLeaf": true
            }, {
                "value": 1430527,
                "label": "绥宁县",
                "parent": 14305,
                "isLeaf": true
            }, {
                "value": 1430528,
                "label": "新宁县",
                "parent": 14305,
                "isLeaf": true
            }, {
                "value": 1430529,
                "label": "城步苗族自治县",
                "parent": 14305,
                "isLeaf": true
            }, {
                "value": 1430581,
                "label": "武冈市",
                "parent": 14305,
                "isLeaf": true
            }],
            "value": 14305,
            "label": "邵阳市",
            "parent": 143
        }, {
            "children": [{
                "value": 1430602,
                "label": "岳阳楼区",
                "parent": 14306,
                "isLeaf": true
            }, {
                "value": 1430603,
                "label": "云溪区",
                "parent": 14306,
                "isLeaf": true
            }, {
                "value": 1430611,
                "label": "君山区",
                "parent": 14306,
                "isLeaf": true
            }, {
                "value": 1430621,
                "label": "岳阳县",
                "parent": 14306,
                "isLeaf": true
            }, {
                "value": 1430623,
                "label": "华容县",
                "parent": 14306,
                "isLeaf": true
            }, {
                "value": 1430624,
                "label": "湘阴县",
                "parent": 14306,
                "isLeaf": true
            }, {
                "value": 1430626,
                "label": "平江县",
                "parent": 14306,
                "isLeaf": true
            }, {
                "value": 1430681,
                "label": "汨罗市",
                "parent": 14306,
                "isLeaf": true
            }, {
                "value": 1430682,
                "label": "临湘市",
                "parent": 14306,
                "isLeaf": true
            }],
            "value": 14306,
            "label": "岳阳市",
            "parent": 143
        }, {
            "children": [{
                "value": 1430702,
                "label": "武陵区",
                "parent": 14307,
                "isLeaf": true
            }, {
                "value": 1430703,
                "label": "鼎城区",
                "parent": 14307,
                "isLeaf": true
            }, {
                "value": 1430721,
                "label": "安乡县",
                "parent": 14307,
                "isLeaf": true
            }, {
                "value": 1430722,
                "label": "汉寿县",
                "parent": 14307,
                "isLeaf": true
            }, {
                "value": 1430723,
                "label": "澧县",
                "parent": 14307,
                "isLeaf": true
            }, {
                "value": 1430724,
                "label": "临澧县",
                "parent": 14307,
                "isLeaf": true
            }, {
                "value": 1430725,
                "label": "桃源县",
                "parent": 14307,
                "isLeaf": true
            }, {
                "value": 1430726,
                "label": "石门县",
                "parent": 14307,
                "isLeaf": true
            }, {
                "value": 1430781,
                "label": "津市市",
                "parent": 14307,
                "isLeaf": true
            }],
            "value": 14307,
            "label": "常德市",
            "parent": 143
        }, {
            "children": [{
                "value": 1430802,
                "label": "永定区",
                "parent": 14308,
                "isLeaf": true
            }, {
                "value": 1430811,
                "label": "武陵源区",
                "parent": 14308,
                "isLeaf": true
            }, {
                "value": 1430821,
                "label": "慈利县",
                "parent": 14308,
                "isLeaf": true
            }, {
                "value": 1430822,
                "label": "桑植县",
                "parent": 14308,
                "isLeaf": true
            }],
            "value": 14308,
            "label": "张家界市",
            "parent": 143
        }, {
            "children": [{
                "value": 1430902,
                "label": "资阳区",
                "parent": 14309,
                "isLeaf": true
            }, {
                "value": 1430903,
                "label": "赫山区",
                "parent": 14309,
                "isLeaf": true
            }, {
                "value": 1430921,
                "label": "南县",
                "parent": 14309,
                "isLeaf": true
            }, {
                "value": 1430922,
                "label": "桃江县",
                "parent": 14309,
                "isLeaf": true
            }, {
                "value": 1430923,
                "label": "安化县",
                "parent": 14309,
                "isLeaf": true
            }, {
                "value": 1430981,
                "label": "沅江市",
                "parent": 14309,
                "isLeaf": true
            }],
            "value": 14309,
            "label": "益阳市",
            "parent": 143
        }, {
            "children": [{
                "value": 1431002,
                "label": "北湖区",
                "parent": 14310,
                "isLeaf": true
            }, {
                "value": 1431003,
                "label": "苏仙区",
                "parent": 14310,
                "isLeaf": true
            }, {
                "value": 1431021,
                "label": "桂阳县",
                "parent": 14310,
                "isLeaf": true
            }, {
                "value": 1431022,
                "label": "宜章县",
                "parent": 14310,
                "isLeaf": true
            }, {
                "value": 1431023,
                "label": "永兴县",
                "parent": 14310,
                "isLeaf": true
            }, {
                "value": 1431024,
                "label": "嘉禾县",
                "parent": 14310,
                "isLeaf": true
            }, {
                "value": 1431025,
                "label": "临武县",
                "parent": 14310,
                "isLeaf": true
            }, {
                "value": 1431026,
                "label": "汝城县",
                "parent": 14310,
                "isLeaf": true
            }, {
                "value": 1431027,
                "label": "桂东县",
                "parent": 14310,
                "isLeaf": true
            }, {
                "value": 1431028,
                "label": "安仁县",
                "parent": 14310,
                "isLeaf": true
            }, {
                "value": 1431081,
                "label": "资兴市",
                "parent": 14310,
                "isLeaf": true
            }],
            "value": 14310,
            "label": "郴州市",
            "parent": 143
        }, {
            "children": [{
                "value": 1431102,
                "label": "零陵区",
                "parent": 14311,
                "isLeaf": true
            }, {
                "value": 1431103,
                "label": "冷水滩区",
                "parent": 14311,
                "isLeaf": true
            }, {
                "value": 1431121,
                "label": "祁阳县",
                "parent": 14311,
                "isLeaf": true
            }, {
                "value": 1431122,
                "label": "东安县",
                "parent": 14311,
                "isLeaf": true
            }, {
                "value": 1431123,
                "label": "双牌县",
                "parent": 14311,
                "isLeaf": true
            }, {
                "value": 1431124,
                "label": "道县",
                "parent": 14311,
                "isLeaf": true
            }, {
                "value": 1431125,
                "label": "江永县",
                "parent": 14311,
                "isLeaf": true
            }, {
                "value": 1431126,
                "label": "宁远县",
                "parent": 14311,
                "isLeaf": true
            }, {
                "value": 1431127,
                "label": "蓝山县",
                "parent": 14311,
                "isLeaf": true
            }, {
                "value": 1431128,
                "label": "新田县",
                "parent": 14311,
                "isLeaf": true
            }, {
                "value": 1431129,
                "label": "江华瑶族自治县",
                "parent": 14311,
                "isLeaf": true
            }],
            "value": 14311,
            "label": "永州市",
            "parent": 143
        }, {
            "children": [{
                "value": 1431202,
                "label": "鹤城区",
                "parent": 14312,
                "isLeaf": true
            }, {
                "value": 1431221,
                "label": "中方县",
                "parent": 14312,
                "isLeaf": true
            }, {
                "value": 1431222,
                "label": "沅陵县",
                "parent": 14312,
                "isLeaf": true
            }, {
                "value": 1431223,
                "label": "辰溪县",
                "parent": 14312,
                "isLeaf": true
            }, {
                "value": 1431224,
                "label": "溆浦县",
                "parent": 14312,
                "isLeaf": true
            }, {
                "value": 1431225,
                "label": "会同县",
                "parent": 14312,
                "isLeaf": true
            }, {
                "value": 1431226,
                "label": "麻阳苗族自治县",
                "parent": 14312,
                "isLeaf": true
            }, {
                "value": 1431227,
                "label": "新晃侗族自治县",
                "parent": 14312,
                "isLeaf": true
            }, {
                "value": 1431228,
                "label": "芷江侗族自治县",
                "parent": 14312,
                "isLeaf": true
            }, {
                "value": 1431229,
                "label": "靖州苗族侗族自治县",
                "parent": 14312,
                "isLeaf": true
            }, {
                "value": 1431230,
                "label": "通道侗族自治县",
                "parent": 14312,
                "isLeaf": true
            }, {
                "value": 1431281,
                "label": "洪江市",
                "parent": 14312,
                "isLeaf": true
            }],
            "value": 14312,
            "label": "怀化市",
            "parent": 143
        }, {
            "children": [{
                "value": 1431302,
                "label": "娄星区",
                "parent": 14313,
                "isLeaf": true
            }, {
                "value": 1431321,
                "label": "双峰县",
                "parent": 14313,
                "isLeaf": true
            }, {
                "value": 1431322,
                "label": "新化县",
                "parent": 14313,
                "isLeaf": true
            }, {
                "value": 1431381,
                "label": "冷水江市",
                "parent": 14313,
                "isLeaf": true
            }, {
                "value": 1431382,
                "label": "涟源市",
                "parent": 14313,
                "isLeaf": true
            }],
            "value": 14313,
            "label": "娄底市",
            "parent": 143
        }, {
            "children": [{
                "value": 1433101,
                "label": "吉首市",
                "parent": 14331,
                "isLeaf": true
            }, {
                "value": 1433122,
                "label": "泸溪县",
                "parent": 14331,
                "isLeaf": true
            }, {
                "value": 1433123,
                "label": "凤凰县",
                "parent": 14331,
                "isLeaf": true
            }, {
                "value": 1433124,
                "label": "花垣县",
                "parent": 14331,
                "isLeaf": true
            }, {
                "value": 1433125,
                "label": "保靖县",
                "parent": 14331,
                "isLeaf": true
            }, {
                "value": 1433126,
                "label": "古丈县",
                "parent": 14331,
                "isLeaf": true
            }, {
                "value": 1433127,
                "label": "永顺县",
                "parent": 14331,
                "isLeaf": true
            }, {
                "value": 1433130,
                "label": "龙山县",
                "parent": 14331,
                "isLeaf": true
            }],
            "value": 14331,
            "label": "湘西土家族苗族自治州",
            "parent": 143
        }],
        "value": 143,
        "label": "湖南省",
        "parent": 0
    }, {
        "children": [{
            "children": [{
                "value": 1440103,
                "label": "荔湾区",
                "parent": 14401,
                "isLeaf": true
            }, {
                "value": 1440104,
                "label": "越秀区",
                "parent": 14401,
                "isLeaf": true
            }, {
                "value": 1440105,
                "label": "海珠区",
                "parent": 14401,
                "isLeaf": true
            }, {
                "value": 1440106,
                "label": "天河区",
                "parent": 14401,
                "isLeaf": true
            }, {
                "value": 1440111,
                "label": "白云区",
                "parent": 14401,
                "isLeaf": true
            }, {
                "value": 1440112,
                "label": "黄埔区",
                "parent": 14401,
                "isLeaf": true
            }, {
                "value": 1440113,
                "label": "番禺区",
                "parent": 14401,
                "isLeaf": true
            }, {
                "value": 1440114,
                "label": "花都区",
                "parent": 14401,
                "isLeaf": true
            }, {
                "value": 1440115,
                "label": "南沙区",
                "parent": 14401,
                "isLeaf": true
            }, {
                "value": 1440117,
                "label": "从化区",
                "parent": 14401,
                "isLeaf": true
            }, {
                "value": 1440118,
                "label": "增城区",
                "parent": 14401,
                "isLeaf": true
            }],
            "value": 14401,
            "label": "广州市",
            "parent": 144
        }, {
            "children": [{
                "value": 1440203,
                "label": "武江区",
                "parent": 14402,
                "isLeaf": true
            }, {
                "value": 1440204,
                "label": "浈江区",
                "parent": 14402,
                "isLeaf": true
            }, {
                "value": 1440205,
                "label": "曲江区",
                "parent": 14402,
                "isLeaf": true
            }, {
                "value": 1440222,
                "label": "始兴县",
                "parent": 14402,
                "isLeaf": true
            }, {
                "value": 1440224,
                "label": "仁化县",
                "parent": 14402,
                "isLeaf": true
            }, {
                "value": 1440229,
                "label": "翁源县",
                "parent": 14402,
                "isLeaf": true
            }, {
                "value": 1440232,
                "label": "乳源瑶族自治县",
                "parent": 14402,
                "isLeaf": true
            }, {
                "value": 1440233,
                "label": "新丰县",
                "parent": 14402,
                "isLeaf": true
            }, {
                "value": 1440281,
                "label": "乐昌市",
                "parent": 14402,
                "isLeaf": true
            }, {
                "value": 1440282,
                "label": "南雄市",
                "parent": 14402,
                "isLeaf": true
            }],
            "value": 14402,
            "label": "韶关市",
            "parent": 144
        }, {
            "children": [{
                "value": 1440303,
                "label": "罗湖区",
                "parent": 14403,
                "isLeaf": true
            }, {
                "value": 1440304,
                "label": "福田区",
                "parent": 14403,
                "isLeaf": true
            }, {
                "value": 1440305,
                "label": "南山区",
                "parent": 14403,
                "isLeaf": true
            }, {
                "value": 1440306,
                "label": "宝安区",
                "parent": 14403,
                "isLeaf": true
            }, {
                "value": 1440307,
                "label": "龙岗区",
                "parent": 14403,
                "isLeaf": true
            }, {
                "value": 1440308,
                "label": "盐田区",
                "parent": 14403,
                "isLeaf": true
            }],
            "value": 14403,
            "label": "深圳市",
            "parent": 144
        }, {
            "children": [{
                "value": 1440402,
                "label": "香洲区",
                "parent": 14404,
                "isLeaf": true
            }, {
                "value": 1440403,
                "label": "斗门区",
                "parent": 14404,
                "isLeaf": true
            }, {
                "value": 1440404,
                "label": "金湾区",
                "parent": 14404,
                "isLeaf": true
            }],
            "value": 14404,
            "label": "珠海市",
            "parent": 144
        }, {
            "children": [{
                "value": 1440507,
                "label": "龙湖区",
                "parent": 14405,
                "isLeaf": true
            }, {
                "value": 1440511,
                "label": "金平区",
                "parent": 14405,
                "isLeaf": true
            }, {
                "value": 1440512,
                "label": "濠江区",
                "parent": 14405,
                "isLeaf": true
            }, {
                "value": 1440513,
                "label": "潮阳区",
                "parent": 14405,
                "isLeaf": true
            }, {
                "value": 1440514,
                "label": "潮南区",
                "parent": 14405,
                "isLeaf": true
            }, {
                "value": 1440515,
                "label": "澄海区",
                "parent": 14405,
                "isLeaf": true
            }, {
                "value": 1440523,
                "label": "南澳县",
                "parent": 14405,
                "isLeaf": true
            }],
            "value": 14405,
            "label": "汕头市",
            "parent": 144
        }, {
            "children": [{
                "value": 1440604,
                "label": "禅城区",
                "parent": 14406,
                "isLeaf": true
            }, {
                "value": 1440605,
                "label": "南海区",
                "parent": 14406,
                "isLeaf": true
            }, {
                "value": 1440606,
                "label": "顺德区",
                "parent": 14406,
                "isLeaf": true
            }, {
                "value": 1440607,
                "label": "三水区",
                "parent": 14406,
                "isLeaf": true
            }, {
                "value": 1440608,
                "label": "高明区",
                "parent": 14406,
                "isLeaf": true
            }],
            "value": 14406,
            "label": "佛山市",
            "parent": 144
        }, {
            "children": [{
                "value": 1440703,
                "label": "蓬江区",
                "parent": 14407,
                "isLeaf": true
            }, {
                "value": 1440704,
                "label": "江海区",
                "parent": 14407,
                "isLeaf": true
            }, {
                "value": 1440705,
                "label": "新会区",
                "parent": 14407,
                "isLeaf": true
            }, {
                "value": 1440781,
                "label": "台山市",
                "parent": 14407,
                "isLeaf": true
            }, {
                "value": 1440783,
                "label": "开平市",
                "parent": 14407,
                "isLeaf": true
            }, {
                "value": 1440784,
                "label": "鹤山市",
                "parent": 14407,
                "isLeaf": true
            }, {
                "value": 1440785,
                "label": "恩平市",
                "parent": 14407,
                "isLeaf": true
            }],
            "value": 14407,
            "label": "江门市",
            "parent": 144
        }, {
            "children": [{
                "value": 1440802,
                "label": "赤坎区",
                "parent": 14408,
                "isLeaf": true
            }, {
                "value": 1440803,
                "label": "霞山区",
                "parent": 14408,
                "isLeaf": true
            }, {
                "value": 1440804,
                "label": "坡头区",
                "parent": 14408,
                "isLeaf": true
            }, {
                "value": 1440811,
                "label": "麻章区",
                "parent": 14408,
                "isLeaf": true
            }, {
                "value": 1440823,
                "label": "遂溪县",
                "parent": 14408,
                "isLeaf": true
            }, {
                "value": 1440825,
                "label": "徐闻县",
                "parent": 14408,
                "isLeaf": true
            }, {
                "value": 1440881,
                "label": "廉江市",
                "parent": 14408,
                "isLeaf": true
            }, {
                "value": 1440882,
                "label": "雷州市",
                "parent": 14408,
                "isLeaf": true
            }, {
                "value": 1440883,
                "label": "吴川市",
                "parent": 14408,
                "isLeaf": true
            }],
            "value": 14408,
            "label": "湛江市",
            "parent": 144
        }, {
            "children": [{
                "value": 1440902,
                "label": "茂南区",
                "parent": 14409,
                "isLeaf": true
            }, {
                "value": 1440904,
                "label": "电白区",
                "parent": 14409,
                "isLeaf": true
            }, {
                "value": 1440981,
                "label": "高州市",
                "parent": 14409,
                "isLeaf": true
            }, {
                "value": 1440982,
                "label": "化州市",
                "parent": 14409,
                "isLeaf": true
            }, {
                "value": 1440983,
                "label": "信宜市",
                "parent": 14409,
                "isLeaf": true
            }],
            "value": 14409,
            "label": "茂名市",
            "parent": 144
        }, {
            "children": [{
                "value": 1441202,
                "label": "端州区",
                "parent": 14412,
                "isLeaf": true
            }, {
                "value": 1441203,
                "label": "鼎湖区",
                "parent": 14412,
                "isLeaf": true
            }, {
                "value": 1441204,
                "label": "高要区",
                "parent": 14412,
                "isLeaf": true
            }, {
                "value": 1441223,
                "label": "广宁县",
                "parent": 14412,
                "isLeaf": true
            }, {
                "value": 1441224,
                "label": "怀集县",
                "parent": 14412,
                "isLeaf": true
            }, {
                "value": 1441225,
                "label": "封开县",
                "parent": 14412,
                "isLeaf": true
            }, {
                "value": 1441226,
                "label": "德庆县",
                "parent": 14412,
                "isLeaf": true
            }, {
                "value": 1441284,
                "label": "四会市",
                "parent": 14412,
                "isLeaf": true
            }],
            "value": 14412,
            "label": "肇庆市",
            "parent": 144
        }, {
            "children": [{
                "value": 1441302,
                "label": "惠城区",
                "parent": 14413,
                "isLeaf": true
            }, {
                "value": 1441303,
                "label": "惠阳区",
                "parent": 14413,
                "isLeaf": true
            }, {
                "value": 1441322,
                "label": "博罗县",
                "parent": 14413,
                "isLeaf": true
            }, {
                "value": 1441323,
                "label": "惠东县",
                "parent": 14413,
                "isLeaf": true
            }, {
                "value": 1441324,
                "label": "龙门县",
                "parent": 14413,
                "isLeaf": true
            }],
            "value": 14413,
            "label": "惠州市",
            "parent": 144
        }, {
            "children": [{
                "value": 1441402,
                "label": "梅江区",
                "parent": 14414,
                "isLeaf": true
            }, {
                "value": 1441403,
                "label": "梅县区",
                "parent": 14414,
                "isLeaf": true
            }, {
                "value": 1441422,
                "label": "大埔县",
                "parent": 14414,
                "isLeaf": true
            }, {
                "value": 1441423,
                "label": "丰顺县",
                "parent": 14414,
                "isLeaf": true
            }, {
                "value": 1441424,
                "label": "五华县",
                "parent": 14414,
                "isLeaf": true
            }, {
                "value": 1441426,
                "label": "平远县",
                "parent": 14414,
                "isLeaf": true
            }, {
                "value": 1441427,
                "label": "蕉岭县",
                "parent": 14414,
                "isLeaf": true
            }, {
                "value": 1441481,
                "label": "兴宁市",
                "parent": 14414,
                "isLeaf": true
            }],
            "value": 14414,
            "label": "梅州市",
            "parent": 144
        }, {
            "children": [{
                "value": 1441502,
                "label": "城区",
                "parent": 14415,
                "isLeaf": true
            }, {
                "value": 1441521,
                "label": "海丰县",
                "parent": 14415,
                "isLeaf": true
            }, {
                "value": 1441523,
                "label": "陆河县",
                "parent": 14415,
                "isLeaf": true
            }, {
                "value": 1441581,
                "label": "陆丰市",
                "parent": 14415,
                "isLeaf": true
            }],
            "value": 14415,
            "label": "汕尾市",
            "parent": 144
        }, {
            "children": [{
                "value": 1441602,
                "label": "源城区",
                "parent": 14416,
                "isLeaf": true
            }, {
                "value": 1441621,
                "label": "紫金县",
                "parent": 14416,
                "isLeaf": true
            }, {
                "value": 1441622,
                "label": "龙川县",
                "parent": 14416,
                "isLeaf": true
            }, {
                "value": 1441623,
                "label": "连平县",
                "parent": 14416,
                "isLeaf": true
            }, {
                "value": 1441624,
                "label": "和平县",
                "parent": 14416,
                "isLeaf": true
            }, {
                "value": 1441625,
                "label": "东源县",
                "parent": 14416,
                "isLeaf": true
            }],
            "value": 14416,
            "label": "河源市",
            "parent": 144
        }, {
            "children": [{
                "value": 1441702,
                "label": "江城区",
                "parent": 14417,
                "isLeaf": true
            }, {
                "value": 1441704,
                "label": "阳东区",
                "parent": 14417,
                "isLeaf": true
            }, {
                "value": 1441721,
                "label": "阳西县",
                "parent": 14417,
                "isLeaf": true
            }, {
                "value": 1441781,
                "label": "阳春市",
                "parent": 14417,
                "isLeaf": true
            }],
            "value": 14417,
            "label": "阳江市",
            "parent": 144
        }, {
            "children": [{
                "value": 1441802,
                "label": "清城区",
                "parent": 14418,
                "isLeaf": true
            }, {
                "value": 1441803,
                "label": "清新区",
                "parent": 14418,
                "isLeaf": true
            }, {
                "value": 1441821,
                "label": "佛冈县",
                "parent": 14418,
                "isLeaf": true
            }, {
                "value": 1441823,
                "label": "阳山县",
                "parent": 14418,
                "isLeaf": true
            }, {
                "value": 1441825,
                "label": "连山壮族瑶族自治县",
                "parent": 14418,
                "isLeaf": true
            }, {
                "value": 1441826,
                "label": "连南瑶族自治县",
                "parent": 14418,
                "isLeaf": true
            }, {
                "value": 1441881,
                "label": "英德市",
                "parent": 14418,
                "isLeaf": true
            }, {
                "value": 1441882,
                "label": "连州市",
                "parent": 14418,
                "isLeaf": true
            }],
            "value": 14418,
            "label": "清远市",
            "parent": 144
        }, {
            "children": [{
                "value": 1441900,
                "label": "东莞市",
                "parent": 14419,
                "isLeaf": true
            }],
            "value": 14419,
            "label": "东莞市",
            "parent": 144
        }, {
            "children": [{
                "value": 1442000,
                "label": "中山市",
                "parent": 14420,
                "isLeaf": true
            }],
            "value": 14420,
            "label": "中山市",
            "parent": 144
        }, {
            "children": [{
                "value": 1445102,
                "label": "湘桥区",
                "parent": 14451,
                "isLeaf": true
            }, {
                "value": 1445103,
                "label": "潮安区",
                "parent": 14451,
                "isLeaf": true
            }, {
                "value": 1445122,
                "label": "饶平县",
                "parent": 14451,
                "isLeaf": true
            }],
            "value": 14451,
            "label": "潮州市",
            "parent": 144
        }, {
            "children": [{
                "value": 1445202,
                "label": "榕城区",
                "parent": 14452,
                "isLeaf": true
            }, {
                "value": 1445203,
                "label": "揭东区",
                "parent": 14452,
                "isLeaf": true
            }, {
                "value": 1445222,
                "label": "揭西县",
                "parent": 14452,
                "isLeaf": true
            }, {
                "value": 1445224,
                "label": "惠来县",
                "parent": 14452,
                "isLeaf": true
            }, {
                "value": 1445281,
                "label": "普宁市",
                "parent": 14452,
                "isLeaf": true
            }],
            "value": 14452,
            "label": "揭阳市",
            "parent": 144
        }, {
            "children": [{
                "value": 1445302,
                "label": "云城区",
                "parent": 14453,
                "isLeaf": true
            }, {
                "value": 1445303,
                "label": "云安区",
                "parent": 14453,
                "isLeaf": true
            }, {
                "value": 1445321,
                "label": "新兴县",
                "parent": 14453,
                "isLeaf": true
            }, {
                "value": 1445322,
                "label": "郁南县",
                "parent": 14453,
                "isLeaf": true
            }, {
                "value": 1445381,
                "label": "罗定市",
                "parent": 14453,
                "isLeaf": true
            }],
            "value": 14453,
            "label": "云浮市",
            "parent": 144
        }],
        "value": 144,
        "label": "广东省",
        "parent": 0
    }, {
        "children": [{
            "children": [{
                "value": 1450102,
                "label": "兴宁区",
                "parent": 14501,
                "isLeaf": true
            }, {
                "value": 1450103,
                "label": "青秀区",
                "parent": 14501,
                "isLeaf": true
            }, {
                "value": 1450105,
                "label": "江南区",
                "parent": 14501,
                "isLeaf": true
            }, {
                "value": 1450107,
                "label": "西乡塘区",
                "parent": 14501,
                "isLeaf": true
            }, {
                "value": 1450108,
                "label": "良庆区",
                "parent": 14501,
                "isLeaf": true
            }, {
                "value": 1450109,
                "label": "邕宁区",
                "parent": 14501,
                "isLeaf": true
            }, {
                "value": 1450110,
                "label": "武鸣区",
                "parent": 14501,
                "isLeaf": true
            }, {
                "value": 1450123,
                "label": "隆安县",
                "parent": 14501,
                "isLeaf": true
            }, {
                "value": 1450124,
                "label": "马山县",
                "parent": 14501,
                "isLeaf": true
            }, {
                "value": 1450125,
                "label": "上林县",
                "parent": 14501,
                "isLeaf": true
            }, {
                "value": 1450126,
                "label": "宾阳县",
                "parent": 14501,
                "isLeaf": true
            }, {
                "value": 1450127,
                "label": "横县",
                "parent": 14501,
                "isLeaf": true
            }],
            "value": 14501,
            "label": "南宁市",
            "parent": 145
        }, {
            "children": [{
                "value": 1450202,
                "label": "城中区",
                "parent": 14502,
                "isLeaf": true
            }, {
                "value": 1450203,
                "label": "鱼峰区",
                "parent": 14502,
                "isLeaf": true
            }, {
                "value": 1450204,
                "label": "柳南区",
                "parent": 14502,
                "isLeaf": true
            }, {
                "value": 1450205,
                "label": "柳北区",
                "parent": 14502,
                "isLeaf": true
            }, {
                "value": 1450221,
                "label": "柳江县",
                "parent": 14502,
                "isLeaf": true
            }, {
                "value": 1450222,
                "label": "柳城县",
                "parent": 14502,
                "isLeaf": true
            }, {
                "value": 1450223,
                "label": "鹿寨县",
                "parent": 14502,
                "isLeaf": true
            }, {
                "value": 1450224,
                "label": "融安县",
                "parent": 14502,
                "isLeaf": true
            }, {
                "value": 1450225,
                "label": "融水苗族自治县",
                "parent": 14502,
                "isLeaf": true
            }, {
                "value": 1450226,
                "label": "三江侗族自治县",
                "parent": 14502,
                "isLeaf": true
            }],
            "value": 14502,
            "label": "柳州市",
            "parent": 145
        }, {
            "children": [{
                "value": 1450302,
                "label": "秀峰区",
                "parent": 14503,
                "isLeaf": true
            }, {
                "value": 1450303,
                "label": "叠彩区",
                "parent": 14503,
                "isLeaf": true
            }, {
                "value": 1450304,
                "label": "象山区",
                "parent": 14503,
                "isLeaf": true
            }, {
                "value": 1450305,
                "label": "七星区",
                "parent": 14503,
                "isLeaf": true
            }, {
                "value": 1450311,
                "label": "雁山区",
                "parent": 14503,
                "isLeaf": true
            }, {
                "value": 1450312,
                "label": "临桂区",
                "parent": 14503,
                "isLeaf": true
            }, {
                "value": 1450321,
                "label": "阳朔县",
                "parent": 14503,
                "isLeaf": true
            }, {
                "value": 1450323,
                "label": "灵川县",
                "parent": 14503,
                "isLeaf": true
            }, {
                "value": 1450324,
                "label": "全州县",
                "parent": 14503,
                "isLeaf": true
            }, {
                "value": 1450325,
                "label": "兴安县",
                "parent": 14503,
                "isLeaf": true
            }, {
                "value": 1450326,
                "label": "永福县",
                "parent": 14503,
                "isLeaf": true
            }, {
                "value": 1450327,
                "label": "灌阳县",
                "parent": 14503,
                "isLeaf": true
            }, {
                "value": 1450328,
                "label": "龙胜各族自治县",
                "parent": 14503,
                "isLeaf": true
            }, {
                "value": 1450329,
                "label": "资源县",
                "parent": 14503,
                "isLeaf": true
            }, {
                "value": 1450330,
                "label": "平乐县",
                "parent": 14503,
                "isLeaf": true
            }, {
                "value": 1450331,
                "label": "荔浦县",
                "parent": 14503,
                "isLeaf": true
            }, {
                "value": 1450332,
                "label": "恭城瑶族自治县",
                "parent": 14503,
                "isLeaf": true
            }],
            "value": 14503,
            "label": "桂林市",
            "parent": 145
        }, {
            "children": [{
                "value": 1450403,
                "label": "万秀区",
                "parent": 14504,
                "isLeaf": true
            }, {
                "value": 1450405,
                "label": "长洲区",
                "parent": 14504,
                "isLeaf": true
            }, {
                "value": 1450406,
                "label": "龙圩区",
                "parent": 14504,
                "isLeaf": true
            }, {
                "value": 1450421,
                "label": "苍梧县",
                "parent": 14504,
                "isLeaf": true
            }, {
                "value": 1450422,
                "label": "藤县",
                "parent": 14504,
                "isLeaf": true
            }, {
                "value": 1450423,
                "label": "蒙山县",
                "parent": 14504,
                "isLeaf": true
            }, {
                "value": 1450481,
                "label": "岑溪市",
                "parent": 14504,
                "isLeaf": true
            }],
            "value": 14504,
            "label": "梧州市",
            "parent": 145
        }, {
            "children": [{
                "value": 1450502,
                "label": "海城区",
                "parent": 14505,
                "isLeaf": true
            }, {
                "value": 1450503,
                "label": "银海区",
                "parent": 14505,
                "isLeaf": true
            }, {
                "value": 1450512,
                "label": "铁山港区",
                "parent": 14505,
                "isLeaf": true
            }, {
                "value": 1450521,
                "label": "合浦县",
                "parent": 14505,
                "isLeaf": true
            }],
            "value": 14505,
            "label": "北海市",
            "parent": 145
        }, {
            "children": [{
                "value": 1450602,
                "label": "港口区",
                "parent": 14506,
                "isLeaf": true
            }, {
                "value": 1450603,
                "label": "防城区",
                "parent": 14506,
                "isLeaf": true
            }, {
                "value": 1450621,
                "label": "上思县",
                "parent": 14506,
                "isLeaf": true
            }, {
                "value": 1450681,
                "label": "东兴市",
                "parent": 14506,
                "isLeaf": true
            }],
            "value": 14506,
            "label": "防城港市",
            "parent": 145
        }, {
            "children": [{
                "value": 1450702,
                "label": "钦南区",
                "parent": 14507,
                "isLeaf": true
            }, {
                "value": 1450703,
                "label": "钦北区",
                "parent": 14507,
                "isLeaf": true
            }, {
                "value": 1450721,
                "label": "灵山县",
                "parent": 14507,
                "isLeaf": true
            }, {
                "value": 1450722,
                "label": "浦北县",
                "parent": 14507,
                "isLeaf": true
            }],
            "value": 14507,
            "label": "钦州市",
            "parent": 145
        }, {
            "children": [{
                "value": 1450802,
                "label": "港北区",
                "parent": 14508,
                "isLeaf": true
            }, {
                "value": 1450803,
                "label": "港南区",
                "parent": 14508,
                "isLeaf": true
            }, {
                "value": 1450804,
                "label": "覃塘区",
                "parent": 14508,
                "isLeaf": true
            }, {
                "value": 1450821,
                "label": "平南县",
                "parent": 14508,
                "isLeaf": true
            }, {
                "value": 1450881,
                "label": "桂平市",
                "parent": 14508,
                "isLeaf": true
            }],
            "value": 14508,
            "label": "贵港市",
            "parent": 145
        }, {
            "children": [{
                "value": 1450902,
                "label": "玉州区",
                "parent": 14509,
                "isLeaf": true
            }, {
                "value": 1450903,
                "label": "福绵区",
                "parent": 14509,
                "isLeaf": true
            }, {
                "value": 1450921,
                "label": "容县",
                "parent": 14509,
                "isLeaf": true
            }, {
                "value": 1450922,
                "label": "陆川县",
                "parent": 14509,
                "isLeaf": true
            }, {
                "value": 1450923,
                "label": "博白县",
                "parent": 14509,
                "isLeaf": true
            }, {
                "value": 1450924,
                "label": "兴业县",
                "parent": 14509,
                "isLeaf": true
            }, {
                "value": 1450981,
                "label": "北流市",
                "parent": 14509,
                "isLeaf": true
            }],
            "value": 14509,
            "label": "玉林市",
            "parent": 145
        }, {
            "children": [{
                "value": 1451002,
                "label": "右江区",
                "parent": 14510,
                "isLeaf": true
            }, {
                "value": 1451021,
                "label": "田阳县",
                "parent": 14510,
                "isLeaf": true
            }, {
                "value": 1451022,
                "label": "田东县",
                "parent": 14510,
                "isLeaf": true
            }, {
                "value": 1451023,
                "label": "平果县",
                "parent": 14510,
                "isLeaf": true
            }, {
                "value": 1451024,
                "label": "德保县",
                "parent": 14510,
                "isLeaf": true
            }, {
                "value": 1451026,
                "label": "那坡县",
                "parent": 14510,
                "isLeaf": true
            }, {
                "value": 1451027,
                "label": "凌云县",
                "parent": 14510,
                "isLeaf": true
            }, {
                "value": 1451028,
                "label": "乐业县",
                "parent": 14510,
                "isLeaf": true
            }, {
                "value": 1451029,
                "label": "田林县",
                "parent": 14510,
                "isLeaf": true
            }, {
                "value": 1451030,
                "label": "西林县",
                "parent": 14510,
                "isLeaf": true
            }, {
                "value": 1451031,
                "label": "隆林各族自治县",
                "parent": 14510,
                "isLeaf": true
            }, {
                "value": 1451081,
                "label": "靖西市",
                "parent": 14510,
                "isLeaf": true
            }],
            "value": 14510,
            "label": "百色市",
            "parent": 145
        }, {
            "children": [{
                "value": 1451102,
                "label": "八步区",
                "parent": 14511,
                "isLeaf": true
            }, {
                "value": 1451103,
                "label": "平桂管理区",
                "parent": 14511,
                "isLeaf": true
            }, {
                "value": 1451121,
                "label": "昭平县",
                "parent": 14511,
                "isLeaf": true
            }, {
                "value": 1451122,
                "label": "钟山县",
                "parent": 14511,
                "isLeaf": true
            }, {
                "value": 1451123,
                "label": "富川瑶族自治县",
                "parent": 14511,
                "isLeaf": true
            }],
            "value": 14511,
            "label": "贺州市",
            "parent": 145
        }, {
            "children": [{
                "value": 1451202,
                "label": "金城江区",
                "parent": 14512,
                "isLeaf": true
            }, {
                "value": 1451221,
                "label": "南丹县",
                "parent": 14512,
                "isLeaf": true
            }, {
                "value": 1451222,
                "label": "天峨县",
                "parent": 14512,
                "isLeaf": true
            }, {
                "value": 1451223,
                "label": "凤山县",
                "parent": 14512,
                "isLeaf": true
            }, {
                "value": 1451224,
                "label": "东兰县",
                "parent": 14512,
                "isLeaf": true
            }, {
                "value": 1451225,
                "label": "罗城仫佬族自治县",
                "parent": 14512,
                "isLeaf": true
            }, {
                "value": 1451226,
                "label": "环江毛南族自治县",
                "parent": 14512,
                "isLeaf": true
            }, {
                "value": 1451227,
                "label": "巴马瑶族自治县",
                "parent": 14512,
                "isLeaf": true
            }, {
                "value": 1451228,
                "label": "都安瑶族自治县",
                "parent": 14512,
                "isLeaf": true
            }, {
                "value": 1451229,
                "label": "大化瑶族自治县",
                "parent": 14512,
                "isLeaf": true
            }, {
                "value": 1451281,
                "label": "宜州市",
                "parent": 14512,
                "isLeaf": true
            }],
            "value": 14512,
            "label": "河池市",
            "parent": 145
        }, {
            "children": [{
                "value": 1451302,
                "label": "兴宾区",
                "parent": 14513,
                "isLeaf": true
            }, {
                "value": 1451321,
                "label": "忻城县",
                "parent": 14513,
                "isLeaf": true
            }, {
                "value": 1451322,
                "label": "象州县",
                "parent": 14513,
                "isLeaf": true
            }, {
                "value": 1451323,
                "label": "武宣县",
                "parent": 14513,
                "isLeaf": true
            }, {
                "value": 1451324,
                "label": "金秀瑶族自治县",
                "parent": 14513,
                "isLeaf": true
            }, {
                "value": 1451381,
                "label": "合山市",
                "parent": 14513,
                "isLeaf": true
            }],
            "value": 14513,
            "label": "来宾市",
            "parent": 145
        }, {
            "children": [{
                "value": 1451402,
                "label": "江州区",
                "parent": 14514,
                "isLeaf": true
            }, {
                "value": 1451421,
                "label": "扶绥县",
                "parent": 14514,
                "isLeaf": true
            }, {
                "value": 1451422,
                "label": "宁明县",
                "parent": 14514,
                "isLeaf": true
            }, {
                "value": 1451423,
                "label": "龙州县",
                "parent": 14514,
                "isLeaf": true
            }, {
                "value": 1451424,
                "label": "大新县",
                "parent": 14514,
                "isLeaf": true
            }, {
                "value": 1451425,
                "label": "天等县",
                "parent": 14514,
                "isLeaf": true
            }, {
                "value": 1451481,
                "label": "凭祥市",
                "parent": 14514,
                "isLeaf": true
            }],
            "value": 14514,
            "label": "崇左市",
            "parent": 145
        }],
        "value": 145,
        "label": "广西壮族自治区",
        "parent": 0
    }, {
        "children": [{
            "children": [{
                "value": 1460105,
                "label": "秀英区",
                "parent": 14601,
                "isLeaf": true
            }, {
                "value": 1460106,
                "label": "龙华区",
                "parent": 14601,
                "isLeaf": true
            }, {
                "value": 1460107,
                "label": "琼山区",
                "parent": 14601,
                "isLeaf": true
            }, {
                "value": 1460108,
                "label": "美兰区",
                "parent": 14601,
                "isLeaf": true
            }],
            "value": 14601,
            "label": "海口市",
            "parent": 146
        }, {
            "children": [{
                "value": 1460201,
                "label": "三亚市",
                "parent": 14602,
                "isLeaf": true
            }, {
                "value": 1460202,
                "label": "海棠区",
                "parent": 14602,
                "isLeaf": true
            }, {
                "value": 1460203,
                "label": "吉阳区",
                "parent": 14602,
                "isLeaf": true
            }, {
                "value": 1460204,
                "label": "天涯区",
                "parent": 14602,
                "isLeaf": true
            }, {
                "value": 1460205,
                "label": "崖州区",
                "parent": 14602,
                "isLeaf": true
            }],
            "value": 14602,
            "label": "三亚市",
            "parent": 146
        }, {
            "children": [{
                "value": 1460321,
                "label": "西沙群岛",
                "parent": 14603,
                "isLeaf": true
            }, {
                "value": 1460322,
                "label": "南沙群岛",
                "parent": 14603,
                "isLeaf": true
            }, {
                "value": 1460323,
                "label": "中沙群岛的岛礁及其海域",
                "parent": 14603,
                "isLeaf": true
            }],
            "value": 14603,
            "label": "三沙市",
            "parent": 146
        }, {
            "children": [{
                "value": 1460400,
                "label": "儋州市",
                "parent": 14604,
                "isLeaf": true
            }],
            "value": 14604,
            "label": "儋州市",
            "parent": 146
        }, {
            "children": [{
                "value": 1469001,
                "label": "五指山市",
                "parent": 14690,
                "isLeaf": true
            }, {
                "value": 1469002,
                "label": "琼海市",
                "parent": 14690,
                "isLeaf": true
            }, {
                "value": 1469005,
                "label": "文昌市",
                "parent": 14690,
                "isLeaf": true
            }, {
                "value": 1469006,
                "label": "万宁市",
                "parent": 14690,
                "isLeaf": true
            }, {
                "value": 1469007,
                "label": "东方市",
                "parent": 14690,
                "isLeaf": true
            }, {
                "value": 1469021,
                "label": "定安县",
                "parent": 14690,
                "isLeaf": true
            }, {
                "value": 1469022,
                "label": "屯昌县",
                "parent": 14690,
                "isLeaf": true
            }, {
                "value": 1469023,
                "label": "澄迈县",
                "parent": 14690,
                "isLeaf": true
            }, {
                "value": 1469024,
                "label": "临高县",
                "parent": 14690,
                "isLeaf": true
            }, {
                "value": 1469025,
                "label": "白沙黎族自治县",
                "parent": 14690,
                "isLeaf": true
            }, {
                "value": 1469026,
                "label": "昌江黎族自治县",
                "parent": 14690,
                "isLeaf": true
            }, {
                "value": 1469027,
                "label": "乐东黎族自治县",
                "parent": 14690,
                "isLeaf": true
            }, {
                "value": 1469028,
                "label": "陵水黎族自治县",
                "parent": 14690,
                "isLeaf": true
            }, {
                "value": 1469029,
                "label": "保亭黎族苗族自治县",
                "parent": 14690,
                "isLeaf": true
            }, {
                "value": 1469030,
                "label": "琼中黎族苗族自治县",
                "parent": 14690,
                "isLeaf": true
            }],
            "value": 14690,
            "label": "省直辖县级行政区划",
            "parent": 146
        }],
        "value": 146,
        "label": "海南省",
        "parent": 0
    }, {
        "children": [{
            "children": [{
                "value": 1500101,
                "label": "万州区",
                "parent": 15001,
                "isLeaf": true
            }, {
                "value": 1500102,
                "label": "涪陵区",
                "parent": 15001,
                "isLeaf": true
            }, {
                "value": 1500103,
                "label": "渝中区",
                "parent": 15001,
                "isLeaf": true
            }, {
                "value": 1500104,
                "label": "大渡口区",
                "parent": 15001,
                "isLeaf": true
            }, {
                "value": 1500105,
                "label": "江北区",
                "parent": 15001,
                "isLeaf": true
            }, {
                "value": 1500106,
                "label": "沙坪坝区",
                "parent": 15001,
                "isLeaf": true
            }, {
                "value": 1500107,
                "label": "九龙坡区",
                "parent": 15001,
                "isLeaf": true
            }, {
                "value": 1500108,
                "label": "南岸区",
                "parent": 15001,
                "isLeaf": true
            }, {
                "value": 1500109,
                "label": "北碚区",
                "parent": 15001,
                "isLeaf": true
            }, {
                "value": 1500110,
                "label": "綦江区",
                "parent": 15001,
                "isLeaf": true
            }, {
                "value": 1500111,
                "label": "大足区",
                "parent": 15001,
                "isLeaf": true
            }, {
                "value": 1500112,
                "label": "渝北区",
                "parent": 15001,
                "isLeaf": true
            }, {
                "value": 1500113,
                "label": "巴南区",
                "parent": 15001,
                "isLeaf": true
            }, {
                "value": 1500114,
                "label": "黔江区",
                "parent": 15001,
                "isLeaf": true
            }, {
                "value": 1500115,
                "label": "长寿区",
                "parent": 15001,
                "isLeaf": true
            }, {
                "value": 1500116,
                "label": "江津区",
                "parent": 15001,
                "isLeaf": true
            }, {
                "value": 1500117,
                "label": "合川区",
                "parent": 15001,
                "isLeaf": true
            }, {
                "value": 1500118,
                "label": "永川区",
                "parent": 15001,
                "isLeaf": true
            }, {
                "value": 1500119,
                "label": "南川区",
                "parent": 15001,
                "isLeaf": true
            }, {
                "value": 1500120,
                "label": "璧山区",
                "parent": 15001,
                "isLeaf": true
            }, {
                "value": 1500151,
                "label": "铜梁区",
                "parent": 15001,
                "isLeaf": true
            }, {
                "value": 1500152,
                "label": "潼南区",
                "parent": 15001,
                "isLeaf": true
            }, {
                "value": 1500153,
                "label": "荣昌区",
                "parent": 15001,
                "isLeaf": true
            }],
            "value": 15001,
            "label": "重庆市辖区",
            "parent": 150
        }, {
            "children": [{
                "value": 1500228,
                "label": "梁平县",
                "parent": 15002,
                "isLeaf": true
            }, {
                "value": 1500229,
                "label": "城口县",
                "parent": 15002,
                "isLeaf": true
            }, {
                "value": 1500230,
                "label": "丰都县",
                "parent": 15002,
                "isLeaf": true
            }, {
                "value": 1500231,
                "label": "垫江县",
                "parent": 15002,
                "isLeaf": true
            }, {
                "value": 1500232,
                "label": "武隆县",
                "parent": 15002,
                "isLeaf": true
            }, {
                "value": 1500233,
                "label": "忠县",
                "parent": 15002,
                "isLeaf": true
            }, {
                "value": 1500234,
                "label": "开县",
                "parent": 15002,
                "isLeaf": true
            }, {
                "value": 1500235,
                "label": "云阳县",
                "parent": 15002,
                "isLeaf": true
            }, {
                "value": 1500236,
                "label": "奉节县",
                "parent": 15002,
                "isLeaf": true
            }, {
                "value": 1500237,
                "label": "巫山县",
                "parent": 15002,
                "isLeaf": true
            }, {
                "value": 1500238,
                "label": "巫溪县",
                "parent": 15002,
                "isLeaf": true
            }, {
                "value": 1500240,
                "label": "石柱土家族自治县",
                "parent": 15002,
                "isLeaf": true
            }, {
                "value": 1500241,
                "label": "秀山土家族苗族自治县",
                "parent": 15002,
                "isLeaf": true
            }, {
                "value": 1500242,
                "label": "酉阳土家族苗族自治县",
                "parent": 15002,
                "isLeaf": true
            }, {
                "value": 1500243,
                "label": "彭水苗族土家族自治县",
                "parent": 15002,
                "isLeaf": true
            }],
            "value": 15002,
            "label": "重庆市县",
            "parent": 150
        }],
        "value": 150,
        "label": "重庆市",
        "parent": 0
    }, {
        "children": [{
            "children": [{
                "value": 1510104,
                "label": "锦江区",
                "parent": 15101,
                "isLeaf": true
            }, {
                "value": 1510105,
                "label": "青羊区",
                "parent": 15101,
                "isLeaf": true
            }, {
                "value": 1510106,
                "label": "金牛区",
                "parent": 15101,
                "isLeaf": true
            }, {
                "value": 1510107,
                "label": "武侯区",
                "parent": 15101,
                "isLeaf": true
            }, {
                "value": 1510108,
                "label": "成华区",
                "parent": 15101,
                "isLeaf": true
            }, {
                "value": 1510112,
                "label": "龙泉驿区",
                "parent": 15101,
                "isLeaf": true
            }, {
                "value": 1510113,
                "label": "青白江区",
                "parent": 15101,
                "isLeaf": true
            }, {
                "value": 1510114,
                "label": "新都区",
                "parent": 15101,
                "isLeaf": true
            }, {
                "value": 1510115,
                "label": "温江区",
                "parent": 15101,
                "isLeaf": true
            }, {
                "value": 1510116,
                "label": "双流区",
                "parent": 15101,
                "isLeaf": true
            }, {
                "value": 1510121,
                "label": "金堂县",
                "parent": 15101,
                "isLeaf": true
            }, {
                "value": 1510124,
                "label": "郫县",
                "parent": 15101,
                "isLeaf": true
            }, {
                "value": 1510129,
                "label": "大邑县",
                "parent": 15101,
                "isLeaf": true
            }, {
                "value": 1510131,
                "label": "蒲江县",
                "parent": 15101,
                "isLeaf": true
            }, {
                "value": 1510132,
                "label": "新津县",
                "parent": 15101,
                "isLeaf": true
            }, {
                "value": 1510181,
                "label": "都江堰市",
                "parent": 15101,
                "isLeaf": true
            }, {
                "value": 1510182,
                "label": "彭州市",
                "parent": 15101,
                "isLeaf": true
            }, {
                "value": 1510183,
                "label": "邛崃市",
                "parent": 15101,
                "isLeaf": true
            }, {
                "value": 1510184,
                "label": "崇州市",
                "parent": 15101,
                "isLeaf": true
            }],
            "value": 15101,
            "label": "成都市",
            "parent": 151
        }, {
            "children": [{
                "value": 1510302,
                "label": "自流井区",
                "parent": 15103,
                "isLeaf": true
            }, {
                "value": 1510303,
                "label": "贡井区",
                "parent": 15103,
                "isLeaf": true
            }, {
                "value": 1510304,
                "label": "大安区",
                "parent": 15103,
                "isLeaf": true
            }, {
                "value": 1510311,
                "label": "沿滩区",
                "parent": 15103,
                "isLeaf": true
            }, {
                "value": 1510321,
                "label": "荣县",
                "parent": 15103,
                "isLeaf": true
            }, {
                "value": 1510322,
                "label": "富顺县",
                "parent": 15103,
                "isLeaf": true
            }],
            "value": 15103,
            "label": "自贡市",
            "parent": 151
        }, {
            "children": [{
                "value": 1510402,
                "label": "东区",
                "parent": 15104,
                "isLeaf": true
            }, {
                "value": 1510403,
                "label": "西区",
                "parent": 15104,
                "isLeaf": true
            }, {
                "value": 1510411,
                "label": "仁和区",
                "parent": 15104,
                "isLeaf": true
            }, {
                "value": 1510421,
                "label": "米易县",
                "parent": 15104,
                "isLeaf": true
            }, {
                "value": 1510422,
                "label": "盐边县",
                "parent": 15104,
                "isLeaf": true
            }],
            "value": 15104,
            "label": "攀枝花市",
            "parent": 151
        }, {
            "children": [{
                "value": 1510502,
                "label": "江阳区",
                "parent": 15105,
                "isLeaf": true
            }, {
                "value": 1510503,
                "label": "纳溪区",
                "parent": 15105,
                "isLeaf": true
            }, {
                "value": 1510504,
                "label": "龙马潭区",
                "parent": 15105,
                "isLeaf": true
            }, {
                "value": 1510521,
                "label": "泸县",
                "parent": 15105,
                "isLeaf": true
            }, {
                "value": 1510522,
                "label": "合江县",
                "parent": 15105,
                "isLeaf": true
            }, {
                "value": 1510524,
                "label": "叙永县",
                "parent": 15105,
                "isLeaf": true
            }, {
                "value": 1510525,
                "label": "古蔺县",
                "parent": 15105,
                "isLeaf": true
            }],
            "value": 15105,
            "label": "泸州市",
            "parent": 151
        }, {
            "children": [{
                "value": 1510603,
                "label": "旌阳区",
                "parent": 15106,
                "isLeaf": true
            }, {
                "value": 1510623,
                "label": "中江县",
                "parent": 15106,
                "isLeaf": true
            }, {
                "value": 1510626,
                "label": "罗江县",
                "parent": 15106,
                "isLeaf": true
            }, {
                "value": 1510681,
                "label": "广汉市",
                "parent": 15106,
                "isLeaf": true
            }, {
                "value": 1510682,
                "label": "什邡市",
                "parent": 15106,
                "isLeaf": true
            }, {
                "value": 1510683,
                "label": "绵竹市",
                "parent": 15106,
                "isLeaf": true
            }],
            "value": 15106,
            "label": "德阳市",
            "parent": 151
        }, {
            "children": [{
                "value": 1510703,
                "label": "涪城区",
                "parent": 15107,
                "isLeaf": true
            }, {
                "value": 1510704,
                "label": "游仙区",
                "parent": 15107,
                "isLeaf": true
            }, {
                "value": 1510722,
                "label": "三台县",
                "parent": 15107,
                "isLeaf": true
            }, {
                "value": 1510723,
                "label": "盐亭县",
                "parent": 15107,
                "isLeaf": true
            }, {
                "value": 1510724,
                "label": "安县",
                "parent": 15107,
                "isLeaf": true
            }, {
                "value": 1510725,
                "label": "梓潼县",
                "parent": 15107,
                "isLeaf": true
            }, {
                "value": 1510726,
                "label": "北川羌族自治县",
                "parent": 15107,
                "isLeaf": true
            }, {
                "value": 1510727,
                "label": "平武县",
                "parent": 15107,
                "isLeaf": true
            }, {
                "value": 1510781,
                "label": "江油市",
                "parent": 15107,
                "isLeaf": true
            }],
            "value": 15107,
            "label": "绵阳市",
            "parent": 151
        }, {
            "children": [{
                "value": 1510802,
                "label": "利州区",
                "parent": 15108,
                "isLeaf": true
            }, {
                "value": 1510811,
                "label": "昭化区",
                "parent": 15108,
                "isLeaf": true
            }, {
                "value": 1510812,
                "label": "朝天区",
                "parent": 15108,
                "isLeaf": true
            }, {
                "value": 1510821,
                "label": "旺苍县",
                "parent": 15108,
                "isLeaf": true
            }, {
                "value": 1510822,
                "label": "青川县",
                "parent": 15108,
                "isLeaf": true
            }, {
                "value": 1510823,
                "label": "剑阁县",
                "parent": 15108,
                "isLeaf": true
            }, {
                "value": 1510824,
                "label": "苍溪县",
                "parent": 15108,
                "isLeaf": true
            }],
            "value": 15108,
            "label": "广元市",
            "parent": 151
        }, {
            "children": [{
                "value": 1510903,
                "label": "船山区",
                "parent": 15109,
                "isLeaf": true
            }, {
                "value": 1510904,
                "label": "安居区",
                "parent": 15109,
                "isLeaf": true
            }, {
                "value": 1510921,
                "label": "蓬溪县",
                "parent": 15109,
                "isLeaf": true
            }, {
                "value": 1510922,
                "label": "射洪县",
                "parent": 15109,
                "isLeaf": true
            }, {
                "value": 1510923,
                "label": "大英县",
                "parent": 15109,
                "isLeaf": true
            }],
            "value": 15109,
            "label": "遂宁市",
            "parent": 151
        }, {
            "children": [{
                "value": 1511002,
                "label": "市中区",
                "parent": 15110,
                "isLeaf": true
            }, {
                "value": 1511011,
                "label": "东兴区",
                "parent": 15110,
                "isLeaf": true
            }, {
                "value": 1511024,
                "label": "威远县",
                "parent": 15110,
                "isLeaf": true
            }, {
                "value": 1511025,
                "label": "资中县",
                "parent": 15110,
                "isLeaf": true
            }, {
                "value": 1511028,
                "label": "隆昌县",
                "parent": 15110,
                "isLeaf": true
            }],
            "value": 15110,
            "label": "内江市",
            "parent": 151
        }, {
            "children": [{
                "value": 1511102,
                "label": "市中区",
                "parent": 15111,
                "isLeaf": true
            }, {
                "value": 1511111,
                "label": "沙湾区",
                "parent": 15111,
                "isLeaf": true
            }, {
                "value": 1511112,
                "label": "五通桥区",
                "parent": 15111,
                "isLeaf": true
            }, {
                "value": 1511113,
                "label": "金口河区",
                "parent": 15111,
                "isLeaf": true
            }, {
                "value": 1511123,
                "label": "犍为县",
                "parent": 15111,
                "isLeaf": true
            }, {
                "value": 1511124,
                "label": "井研县",
                "parent": 15111,
                "isLeaf": true
            }, {
                "value": 1511126,
                "label": "夹江县",
                "parent": 15111,
                "isLeaf": true
            }, {
                "value": 1511129,
                "label": "沐川县",
                "parent": 15111,
                "isLeaf": true
            }, {
                "value": 1511132,
                "label": "峨边彝族自治县",
                "parent": 15111,
                "isLeaf": true
            }, {
                "value": 1511133,
                "label": "马边彝族自治县",
                "parent": 15111,
                "isLeaf": true
            }, {
                "value": 1511181,
                "label": "峨眉山市",
                "parent": 15111,
                "isLeaf": true
            }],
            "value": 15111,
            "label": "乐山市",
            "parent": 151
        }, {
            "children": [{
                "value": 1511302,
                "label": "顺庆区",
                "parent": 15113,
                "isLeaf": true
            }, {
                "value": 1511303,
                "label": "高坪区",
                "parent": 15113,
                "isLeaf": true
            }, {
                "value": 1511304,
                "label": "嘉陵区",
                "parent": 15113,
                "isLeaf": true
            }, {
                "value": 1511321,
                "label": "南部县",
                "parent": 15113,
                "isLeaf": true
            }, {
                "value": 1511322,
                "label": "营山县",
                "parent": 15113,
                "isLeaf": true
            }, {
                "value": 1511323,
                "label": "蓬安县",
                "parent": 15113,
                "isLeaf": true
            }, {
                "value": 1511324,
                "label": "仪陇县",
                "parent": 15113,
                "isLeaf": true
            }, {
                "value": 1511325,
                "label": "西充县",
                "parent": 15113,
                "isLeaf": true
            }, {
                "value": 1511381,
                "label": "阆中市",
                "parent": 15113,
                "isLeaf": true
            }],
            "value": 15113,
            "label": "南充市",
            "parent": 151
        }, {
            "children": [{
                "value": 1511402,
                "label": "东坡区",
                "parent": 15114,
                "isLeaf": true
            }, {
                "value": 1511403,
                "label": "彭山区",
                "parent": 15114,
                "isLeaf": true
            }, {
                "value": 1511421,
                "label": "仁寿县",
                "parent": 15114,
                "isLeaf": true
            }, {
                "value": 1511423,
                "label": "洪雅县",
                "parent": 15114,
                "isLeaf": true
            }, {
                "value": 1511424,
                "label": "丹棱县",
                "parent": 15114,
                "isLeaf": true
            }, {
                "value": 1511425,
                "label": "青神县",
                "parent": 15114,
                "isLeaf": true
            }],
            "value": 15114,
            "label": "眉山市",
            "parent": 151
        }, {
            "children": [{
                "value": 1511502,
                "label": "翠屏区",
                "parent": 15115,
                "isLeaf": true
            }, {
                "value": 1511503,
                "label": "南溪区",
                "parent": 15115,
                "isLeaf": true
            }, {
                "value": 1511521,
                "label": "宜宾县",
                "parent": 15115,
                "isLeaf": true
            }, {
                "value": 1511523,
                "label": "江安县",
                "parent": 15115,
                "isLeaf": true
            }, {
                "value": 1511524,
                "label": "长宁县",
                "parent": 15115,
                "isLeaf": true
            }, {
                "value": 1511525,
                "label": "高县",
                "parent": 15115,
                "isLeaf": true
            }, {
                "value": 1511526,
                "label": "珙县",
                "parent": 15115,
                "isLeaf": true
            }, {
                "value": 1511527,
                "label": "筠连县",
                "parent": 15115,
                "isLeaf": true
            }, {
                "value": 1511528,
                "label": "兴文县",
                "parent": 15115,
                "isLeaf": true
            }, {
                "value": 1511529,
                "label": "屏山县",
                "parent": 15115,
                "isLeaf": true
            }],
            "value": 15115,
            "label": "宜宾市",
            "parent": 151
        }, {
            "children": [{
                "value": 1511602,
                "label": "广安区",
                "parent": 15116,
                "isLeaf": true
            }, {
                "value": 1511603,
                "label": "前锋区",
                "parent": 15116,
                "isLeaf": true
            }, {
                "value": 1511621,
                "label": "岳池县",
                "parent": 15116,
                "isLeaf": true
            }, {
                "value": 1511622,
                "label": "武胜县",
                "parent": 15116,
                "isLeaf": true
            }, {
                "value": 1511623,
                "label": "邻水县",
                "parent": 15116,
                "isLeaf": true
            }, {
                "value": 1511681,
                "label": "华蓥市",
                "parent": 15116,
                "isLeaf": true
            }],
            "value": 15116,
            "label": "广安市",
            "parent": 151
        }, {
            "children": [{
                "value": 1511702,
                "label": "通川区",
                "parent": 15117,
                "isLeaf": true
            }, {
                "value": 1511703,
                "label": "达川区",
                "parent": 15117,
                "isLeaf": true
            }, {
                "value": 1511722,
                "label": "宣汉县",
                "parent": 15117,
                "isLeaf": true
            }, {
                "value": 1511723,
                "label": "开江县",
                "parent": 15117,
                "isLeaf": true
            }, {
                "value": 1511724,
                "label": "大竹县",
                "parent": 15117,
                "isLeaf": true
            }, {
                "value": 1511725,
                "label": "渠县",
                "parent": 15117,
                "isLeaf": true
            }, {
                "value": 1511781,
                "label": "万源市",
                "parent": 15117,
                "isLeaf": true
            }],
            "value": 15117,
            "label": "达州市",
            "parent": 151
        }, {
            "children": [{
                "value": 1511802,
                "label": "雨城区",
                "parent": 15118,
                "isLeaf": true
            }, {
                "value": 1511803,
                "label": "名山区",
                "parent": 15118,
                "isLeaf": true
            }, {
                "value": 1511822,
                "label": "荥经县",
                "parent": 15118,
                "isLeaf": true
            }, {
                "value": 1511823,
                "label": "汉源县",
                "parent": 15118,
                "isLeaf": true
            }, {
                "value": 1511824,
                "label": "石棉县",
                "parent": 15118,
                "isLeaf": true
            }, {
                "value": 1511825,
                "label": "天全县",
                "parent": 15118,
                "isLeaf": true
            }, {
                "value": 1511826,
                "label": "芦山县",
                "parent": 15118,
                "isLeaf": true
            }, {
                "value": 1511827,
                "label": "宝兴县",
                "parent": 15118,
                "isLeaf": true
            }],
            "value": 15118,
            "label": "雅安市",
            "parent": 151
        }, {
            "children": [{
                "value": 1511902,
                "label": "巴州区",
                "parent": 15119,
                "isLeaf": true
            }, {
                "value": 1511903,
                "label": "恩阳区",
                "parent": 15119,
                "isLeaf": true
            }, {
                "value": 1511921,
                "label": "通江县",
                "parent": 15119,
                "isLeaf": true
            }, {
                "value": 1511922,
                "label": "南江县",
                "parent": 15119,
                "isLeaf": true
            }, {
                "value": 1511923,
                "label": "平昌县",
                "parent": 15119,
                "isLeaf": true
            }],
            "value": 15119,
            "label": "巴中市",
            "parent": 151
        }, {
            "children": [{
                "value": 1512002,
                "label": "雁江区",
                "parent": 15120,
                "isLeaf": true
            }, {
                "value": 1512021,
                "label": "安岳县",
                "parent": 15120,
                "isLeaf": true
            }, {
                "value": 1512022,
                "label": "乐至县",
                "parent": 15120,
                "isLeaf": true
            }, {
                "value": 1512081,
                "label": "简阳市",
                "parent": 15120,
                "isLeaf": true
            }],
            "value": 15120,
            "label": "资阳市",
            "parent": 151
        }, {
            "children": [{
                "value": 1513201,
                "label": "马尔康区",
                "parent": 15132,
                "isLeaf": true
            }, {
                "value": 1513221,
                "label": "汶川县",
                "parent": 15132,
                "isLeaf": true
            }, {
                "value": 1513222,
                "label": "理县",
                "parent": 15132,
                "isLeaf": true
            }, {
                "value": 1513223,
                "label": "茂县",
                "parent": 15132,
                "isLeaf": true
            }, {
                "value": 1513224,
                "label": "松潘县",
                "parent": 15132,
                "isLeaf": true
            }, {
                "value": 1513225,
                "label": "九寨沟县",
                "parent": 15132,
                "isLeaf": true
            }, {
                "value": 1513226,
                "label": "金川县",
                "parent": 15132,
                "isLeaf": true
            }, {
                "value": 1513227,
                "label": "小金县",
                "parent": 15132,
                "isLeaf": true
            }, {
                "value": 1513228,
                "label": "黑水县",
                "parent": 15132,
                "isLeaf": true
            }, {
                "value": 1513230,
                "label": "壤塘县",
                "parent": 15132,
                "isLeaf": true
            }, {
                "value": 1513231,
                "label": "阿坝县",
                "parent": 15132,
                "isLeaf": true
            }, {
                "value": 1513232,
                "label": "若尔盖县",
                "parent": 15132,
                "isLeaf": true
            }, {
                "value": 1513233,
                "label": "红原县",
                "parent": 15132,
                "isLeaf": true
            }],
            "value": 15132,
            "label": "阿坝藏族羌族自治州",
            "parent": 151
        }, {
            "children": [{
                "value": 1513301,
                "label": "康定市",
                "parent": 15133,
                "isLeaf": true
            }, {
                "value": 1513322,
                "label": "泸定县",
                "parent": 15133,
                "isLeaf": true
            }, {
                "value": 1513323,
                "label": "丹巴县",
                "parent": 15133,
                "isLeaf": true
            }, {
                "value": 1513324,
                "label": "九龙县",
                "parent": 15133,
                "isLeaf": true
            }, {
                "value": 1513325,
                "label": "雅江县",
                "parent": 15133,
                "isLeaf": true
            }, {
                "value": 1513326,
                "label": "道孚县",
                "parent": 15133,
                "isLeaf": true
            }, {
                "value": 1513327,
                "label": "炉霍县",
                "parent": 15133,
                "isLeaf": true
            }, {
                "value": 1513328,
                "label": "甘孜县",
                "parent": 15133,
                "isLeaf": true
            }, {
                "value": 1513329,
                "label": "新龙县",
                "parent": 15133,
                "isLeaf": true
            }, {
                "value": 1513330,
                "label": "德格县",
                "parent": 15133,
                "isLeaf": true
            }, {
                "value": 1513331,
                "label": "白玉县",
                "parent": 15133,
                "isLeaf": true
            }, {
                "value": 1513332,
                "label": "石渠县",
                "parent": 15133,
                "isLeaf": true
            }, {
                "value": 1513333,
                "label": "色达县",
                "parent": 15133,
                "isLeaf": true
            }, {
                "value": 1513334,
                "label": "理塘县",
                "parent": 15133,
                "isLeaf": true
            }, {
                "value": 1513335,
                "label": "巴塘县",
                "parent": 15133,
                "isLeaf": true
            }, {
                "value": 1513336,
                "label": "乡城县",
                "parent": 15133,
                "isLeaf": true
            }, {
                "value": 1513337,
                "label": "稻城县",
                "parent": 15133,
                "isLeaf": true
            }, {
                "value": 1513338,
                "label": "得荣县",
                "parent": 15133,
                "isLeaf": true
            }],
            "value": 15133,
            "label": "甘孜藏族自治州",
            "parent": 151
        }, {
            "children": [{
                "value": 1513401,
                "label": "西昌市",
                "parent": 15134,
                "isLeaf": true
            }, {
                "value": 1513422,
                "label": "木里藏族自治县",
                "parent": 15134,
                "isLeaf": true
            }, {
                "value": 1513423,
                "label": "盐源县",
                "parent": 15134,
                "isLeaf": true
            }, {
                "value": 1513424,
                "label": "德昌县",
                "parent": 15134,
                "isLeaf": true
            }, {
                "value": 1513425,
                "label": "会理县",
                "parent": 15134,
                "isLeaf": true
            }, {
                "value": 1513426,
                "label": "会东县",
                "parent": 15134,
                "isLeaf": true
            }, {
                "value": 1513427,
                "label": "宁南县",
                "parent": 15134,
                "isLeaf": true
            }, {
                "value": 1513428,
                "label": "普格县",
                "parent": 15134,
                "isLeaf": true
            }, {
                "value": 1513429,
                "label": "布拖县",
                "parent": 15134,
                "isLeaf": true
            }, {
                "value": 1513430,
                "label": "金阳县",
                "parent": 15134,
                "isLeaf": true
            }, {
                "value": 1513431,
                "label": "昭觉县",
                "parent": 15134,
                "isLeaf": true
            }, {
                "value": 1513432,
                "label": "喜德县",
                "parent": 15134,
                "isLeaf": true
            }, {
                "value": 1513433,
                "label": "冕宁县",
                "parent": 15134,
                "isLeaf": true
            }, {
                "value": 1513434,
                "label": "越西县",
                "parent": 15134,
                "isLeaf": true
            }, {
                "value": 1513435,
                "label": "甘洛县",
                "parent": 15134,
                "isLeaf": true
            }, {
                "value": 1513436,
                "label": "美姑县",
                "parent": 15134,
                "isLeaf": true
            }, {
                "value": 1513437,
                "label": "雷波县",
                "parent": 15134,
                "isLeaf": true
            }],
            "value": 15134,
            "label": "凉山彝族自治州",
            "parent": 151
        }],
        "value": 151,
        "label": "四川省",
        "parent": 0
    }, {
        "children": [{
            "children": [{
                "value": 1520102,
                "label": "南明区",
                "parent": 15201,
                "isLeaf": true
            }, {
                "value": 1520103,
                "label": "云岩区",
                "parent": 15201,
                "isLeaf": true
            }, {
                "value": 1520111,
                "label": "花溪区",
                "parent": 15201,
                "isLeaf": true
            }, {
                "value": 1520112,
                "label": "乌当区",
                "parent": 15201,
                "isLeaf": true
            }, {
                "value": 1520113,
                "label": "白云区",
                "parent": 15201,
                "isLeaf": true
            }, {
                "value": 1520115,
                "label": "观山湖区",
                "parent": 15201,
                "isLeaf": true
            }, {
                "value": 1520121,
                "label": "开阳县",
                "parent": 15201,
                "isLeaf": true
            }, {
                "value": 1520122,
                "label": "息烽县",
                "parent": 15201,
                "isLeaf": true
            }, {
                "value": 1520123,
                "label": "修文县",
                "parent": 15201,
                "isLeaf": true
            }, {
                "value": 1520181,
                "label": "清镇市",
                "parent": 15201,
                "isLeaf": true
            }],
            "value": 15201,
            "label": "贵阳市",
            "parent": 152
        }, {
            "children": [{
                "value": 1520201,
                "label": "钟山区",
                "parent": 15202,
                "isLeaf": true
            }, {
                "value": 1520203,
                "label": "六枝特区",
                "parent": 15202,
                "isLeaf": true
            }, {
                "value": 1520221,
                "label": "水城县",
                "parent": 15202,
                "isLeaf": true
            }, {
                "value": 1520222,
                "label": "盘县",
                "parent": 15202,
                "isLeaf": true
            }],
            "value": 15202,
            "label": "六盘水市",
            "parent": 152
        }, {
            "children": [{
                "value": 1520302,
                "label": "红花岗区",
                "parent": 15203,
                "isLeaf": true
            }, {
                "value": 1520303,
                "label": "汇川区",
                "parent": 15203,
                "isLeaf": true
            }, {
                "value": 1520321,
                "label": "遵义县",
                "parent": 15203,
                "isLeaf": true
            }, {
                "value": 1520322,
                "label": "桐梓县",
                "parent": 15203,
                "isLeaf": true
            }, {
                "value": 1520323,
                "label": "绥阳县",
                "parent": 15203,
                "isLeaf": true
            }, {
                "value": 1520324,
                "label": "正安县",
                "parent": 15203,
                "isLeaf": true
            }, {
                "value": 1520325,
                "label": "道真仡佬族苗族自治县",
                "parent": 15203,
                "isLeaf": true
            }, {
                "value": 1520326,
                "label": "务川仡佬族苗族自治县",
                "parent": 15203,
                "isLeaf": true
            }, {
                "value": 1520327,
                "label": "凤冈县",
                "parent": 15203,
                "isLeaf": true
            }, {
                "value": 1520328,
                "label": "湄潭县",
                "parent": 15203,
                "isLeaf": true
            }, {
                "value": 1520329,
                "label": "余庆县",
                "parent": 15203,
                "isLeaf": true
            }, {
                "value": 1520330,
                "label": "习水县",
                "parent": 15203,
                "isLeaf": true
            }, {
                "value": 1520381,
                "label": "赤水市",
                "parent": 15203,
                "isLeaf": true
            }, {
                "value": 1520382,
                "label": "仁怀市",
                "parent": 15203,
                "isLeaf": true
            }],
            "value": 15203,
            "label": "遵义市",
            "parent": 152
        }, {
            "children": [{
                "value": 1520402,
                "label": "西秀区",
                "parent": 15204,
                "isLeaf": true
            }, {
                "value": 1520403,
                "label": "平坝区",
                "parent": 15204,
                "isLeaf": true
            }, {
                "value": 1520422,
                "label": "普定县",
                "parent": 15204,
                "isLeaf": true
            }, {
                "value": 1520423,
                "label": "镇宁布依族苗族自治县",
                "parent": 15204,
                "isLeaf": true
            }, {
                "value": 1520424,
                "label": "关岭布依族苗族自治县",
                "parent": 15204,
                "isLeaf": true
            }, {
                "value": 1520425,
                "label": "紫云苗族布依族自治县",
                "parent": 15204,
                "isLeaf": true
            }],
            "value": 15204,
            "label": "安顺市",
            "parent": 152
        }, {
            "children": [{
                "value": 1520502,
                "label": "七星关区",
                "parent": 15205,
                "isLeaf": true
            }, {
                "value": 1520521,
                "label": "大方县",
                "parent": 15205,
                "isLeaf": true
            }, {
                "value": 1520522,
                "label": "黔西县",
                "parent": 15205,
                "isLeaf": true
            }, {
                "value": 1520523,
                "label": "金沙县",
                "parent": 15205,
                "isLeaf": true
            }, {
                "value": 1520524,
                "label": "织金县",
                "parent": 15205,
                "isLeaf": true
            }, {
                "value": 1520525,
                "label": "纳雍县",
                "parent": 15205,
                "isLeaf": true
            }, {
                "value": 1520526,
                "label": "威宁彝族回族苗族自治县",
                "parent": 15205,
                "isLeaf": true
            }, {
                "value": 1520527,
                "label": "赫章县",
                "parent": 15205,
                "isLeaf": true
            }],
            "value": 15205,
            "label": "毕节市",
            "parent": 152
        }, {
            "children": [{
                "value": 1520602,
                "label": "碧江区",
                "parent": 15206,
                "isLeaf": true
            }, {
                "value": 1520603,
                "label": "万山区",
                "parent": 15206,
                "isLeaf": true
            }, {
                "value": 1520621,
                "label": "江口县",
                "parent": 15206,
                "isLeaf": true
            }, {
                "value": 1520622,
                "label": "玉屏侗族自治县",
                "parent": 15206,
                "isLeaf": true
            }, {
                "value": 1520623,
                "label": "石阡县",
                "parent": 15206,
                "isLeaf": true
            }, {
                "value": 1520624,
                "label": "思南县",
                "parent": 15206,
                "isLeaf": true
            }, {
                "value": 1520625,
                "label": "印江土家族苗族自治县",
                "parent": 15206,
                "isLeaf": true
            }, {
                "value": 1520626,
                "label": "德江县",
                "parent": 15206,
                "isLeaf": true
            }, {
                "value": 1520627,
                "label": "沿河土家族自治县",
                "parent": 15206,
                "isLeaf": true
            }, {
                "value": 1520628,
                "label": "松桃苗族自治县",
                "parent": 15206,
                "isLeaf": true
            }],
            "value": 15206,
            "label": "铜仁市",
            "parent": 152
        }, {
            "children": [{
                "value": 1522301,
                "label": "兴义市",
                "parent": 15223,
                "isLeaf": true
            }, {
                "value": 1522322,
                "label": "兴仁县",
                "parent": 15223,
                "isLeaf": true
            }, {
                "value": 1522323,
                "label": "普安县",
                "parent": 15223,
                "isLeaf": true
            }, {
                "value": 1522324,
                "label": "晴隆县",
                "parent": 15223,
                "isLeaf": true
            }, {
                "value": 1522325,
                "label": "贞丰县",
                "parent": 15223,
                "isLeaf": true
            }, {
                "value": 1522326,
                "label": "望谟县",
                "parent": 15223,
                "isLeaf": true
            }, {
                "value": 1522327,
                "label": "册亨县",
                "parent": 15223,
                "isLeaf": true
            }, {
                "value": 1522328,
                "label": "安龙县",
                "parent": 15223,
                "isLeaf": true
            }],
            "value": 15223,
            "label": "黔西南布依族苗族自治州",
            "parent": 152
        }, {
            "children": [{
                "value": 1522601,
                "label": "凯里市",
                "parent": 15226,
                "isLeaf": true
            }, {
                "value": 1522622,
                "label": "黄平县",
                "parent": 15226,
                "isLeaf": true
            }, {
                "value": 1522623,
                "label": "施秉县",
                "parent": 15226,
                "isLeaf": true
            }, {
                "value": 1522624,
                "label": "三穗县",
                "parent": 15226,
                "isLeaf": true
            }, {
                "value": 1522625,
                "label": "镇远县",
                "parent": 15226,
                "isLeaf": true
            }, {
                "value": 1522626,
                "label": "岑巩县",
                "parent": 15226,
                "isLeaf": true
            }, {
                "value": 1522627,
                "label": "天柱县",
                "parent": 15226,
                "isLeaf": true
            }, {
                "value": 1522628,
                "label": "锦屏县",
                "parent": 15226,
                "isLeaf": true
            }, {
                "value": 1522629,
                "label": "剑河县",
                "parent": 15226,
                "isLeaf": true
            }, {
                "value": 1522630,
                "label": "台江县",
                "parent": 15226,
                "isLeaf": true
            }, {
                "value": 1522631,
                "label": "黎平县",
                "parent": 15226,
                "isLeaf": true
            }, {
                "value": 1522632,
                "label": "榕江县",
                "parent": 15226,
                "isLeaf": true
            }, {
                "value": 1522633,
                "label": "从江县",
                "parent": 15226,
                "isLeaf": true
            }, {
                "value": 1522634,
                "label": "雷山县",
                "parent": 15226,
                "isLeaf": true
            }, {
                "value": 1522635,
                "label": "麻江县",
                "parent": 15226,
                "isLeaf": true
            }, {
                "value": 1522636,
                "label": "丹寨县",
                "parent": 15226,
                "isLeaf": true
            }],
            "value": 15226,
            "label": "黔东南苗族侗族自治州",
            "parent": 152
        }, {
            "children": [{
                "value": 1522701,
                "label": "都匀市",
                "parent": 15227,
                "isLeaf": true
            }, {
                "value": 1522702,
                "label": "福泉市",
                "parent": 15227,
                "isLeaf": true
            }, {
                "value": 1522722,
                "label": "荔波县",
                "parent": 15227,
                "isLeaf": true
            }, {
                "value": 1522723,
                "label": "贵定县",
                "parent": 15227,
                "isLeaf": true
            }, {
                "value": 1522725,
                "label": "瓮安县",
                "parent": 15227,
                "isLeaf": true
            }, {
                "value": 1522726,
                "label": "独山县",
                "parent": 15227,
                "isLeaf": true
            }, {
                "value": 1522727,
                "label": "平塘县",
                "parent": 15227,
                "isLeaf": true
            }, {
                "value": 1522728,
                "label": "罗甸县",
                "parent": 15227,
                "isLeaf": true
            }, {
                "value": 1522729,
                "label": "长顺县",
                "parent": 15227,
                "isLeaf": true
            }, {
                "value": 1522730,
                "label": "龙里县",
                "parent": 15227,
                "isLeaf": true
            }, {
                "value": 1522731,
                "label": "惠水县",
                "parent": 15227,
                "isLeaf": true
            }, {
                "value": 1522732,
                "label": "三都水族自治县",
                "parent": 15227,
                "isLeaf": true
            }],
            "value": 15227,
            "label": "黔南布依族苗族自治州",
            "parent": 152
        }],
        "value": 152,
        "label": "贵州省",
        "parent": 0
    }, {
        "children": [{
            "children": [{
                "value": 1530102,
                "label": "五华区",
                "parent": 15301,
                "isLeaf": true
            }, {
                "value": 1530103,
                "label": "盘龙区",
                "parent": 15301,
                "isLeaf": true
            }, {
                "value": 1530111,
                "label": "官渡区",
                "parent": 15301,
                "isLeaf": true
            }, {
                "value": 1530112,
                "label": "西山区",
                "parent": 15301,
                "isLeaf": true
            }, {
                "value": 1530113,
                "label": "东川区",
                "parent": 15301,
                "isLeaf": true
            }, {
                "value": 1530114,
                "label": "呈贡区",
                "parent": 15301,
                "isLeaf": true
            }, {
                "value": 1530122,
                "label": "晋宁县",
                "parent": 15301,
                "isLeaf": true
            }, {
                "value": 1530124,
                "label": "富民县",
                "parent": 15301,
                "isLeaf": true
            }, {
                "value": 1530125,
                "label": "宜良县",
                "parent": 15301,
                "isLeaf": true
            }, {
                "value": 1530126,
                "label": "石林彝族自治县",
                "parent": 15301,
                "isLeaf": true
            }, {
                "value": 1530127,
                "label": "嵩明县",
                "parent": 15301,
                "isLeaf": true
            }, {
                "value": 1530128,
                "label": "禄劝彝族苗族自治县",
                "parent": 15301,
                "isLeaf": true
            }, {
                "value": 1530129,
                "label": "寻甸回族彝族自治县",
                "parent": 15301,
                "isLeaf": true
            }, {
                "value": 1530181,
                "label": "安宁市",
                "parent": 15301,
                "isLeaf": true
            }],
            "value": 15301,
            "label": "昆明市",
            "parent": 153
        }, {
            "children": [{
                "value": 1530302,
                "label": "麒麟区",
                "parent": 15303,
                "isLeaf": true
            }, {
                "value": 1530321,
                "label": "马龙县",
                "parent": 15303,
                "isLeaf": true
            }, {
                "value": 1530322,
                "label": "陆良县",
                "parent": 15303,
                "isLeaf": true
            }, {
                "value": 1530323,
                "label": "师宗县",
                "parent": 15303,
                "isLeaf": true
            }, {
                "value": 1530324,
                "label": "罗平县",
                "parent": 15303,
                "isLeaf": true
            }, {
                "value": 1530325,
                "label": "富源县",
                "parent": 15303,
                "isLeaf": true
            }, {
                "value": 1530326,
                "label": "会泽县",
                "parent": 15303,
                "isLeaf": true
            }, {
                "value": 1530328,
                "label": "沾益县",
                "parent": 15303,
                "isLeaf": true
            }, {
                "value": 1530381,
                "label": "宣威市",
                "parent": 15303,
                "isLeaf": true
            }],
            "value": 15303,
            "label": "曲靖市",
            "parent": 153
        }, {
            "children": [{
                "value": 1530402,
                "label": "红塔区",
                "parent": 15304,
                "isLeaf": true
            }, {
                "value": 1530403,
                "label": "江川区",
                "parent": 15304,
                "isLeaf": true
            }, {
                "value": 1530422,
                "label": "澄江县",
                "parent": 15304,
                "isLeaf": true
            }, {
                "value": 1530423,
                "label": "通海县",
                "parent": 15304,
                "isLeaf": true
            }, {
                "value": 1530424,
                "label": "华宁县",
                "parent": 15304,
                "isLeaf": true
            }, {
                "value": 1530425,
                "label": "易门县",
                "parent": 15304,
                "isLeaf": true
            }, {
                "value": 1530426,
                "label": "峨山彝族自治县",
                "parent": 15304,
                "isLeaf": true
            }, {
                "value": 1530427,
                "label": "新平彝族傣族自治县",
                "parent": 15304,
                "isLeaf": true
            }, {
                "value": 1530428,
                "label": "元江哈尼族彝族傣族自治县",
                "parent": 15304,
                "isLeaf": true
            }],
            "value": 15304,
            "label": "玉溪市",
            "parent": 153
        }, {
            "children": [{
                "value": 1530502,
                "label": "隆阳区",
                "parent": 15305,
                "isLeaf": true
            }, {
                "value": 1530521,
                "label": "施甸县",
                "parent": 15305,
                "isLeaf": true
            }, {
                "value": 1530523,
                "label": "龙陵县",
                "parent": 15305,
                "isLeaf": true
            }, {
                "value": 1530524,
                "label": "昌宁县",
                "parent": 15305,
                "isLeaf": true
            }, {
                "value": 1530581,
                "label": "腾冲市",
                "parent": 15305,
                "isLeaf": true
            }],
            "value": 15305,
            "label": "保山市",
            "parent": 153
        }, {
            "children": [{
                "value": 1530602,
                "label": "昭阳区",
                "parent": 15306,
                "isLeaf": true
            }, {
                "value": 1530621,
                "label": "鲁甸县",
                "parent": 15306,
                "isLeaf": true
            }, {
                "value": 1530622,
                "label": "巧家县",
                "parent": 15306,
                "isLeaf": true
            }, {
                "value": 1530623,
                "label": "盐津县",
                "parent": 15306,
                "isLeaf": true
            }, {
                "value": 1530624,
                "label": "大关县",
                "parent": 15306,
                "isLeaf": true
            }, {
                "value": 1530625,
                "label": "永善县",
                "parent": 15306,
                "isLeaf": true
            }, {
                "value": 1530626,
                "label": "绥江县",
                "parent": 15306,
                "isLeaf": true
            }, {
                "value": 1530627,
                "label": "镇雄县",
                "parent": 15306,
                "isLeaf": true
            }, {
                "value": 1530628,
                "label": "彝良县",
                "parent": 15306,
                "isLeaf": true
            }, {
                "value": 1530629,
                "label": "威信县",
                "parent": 15306,
                "isLeaf": true
            }, {
                "value": 1530630,
                "label": "水富县",
                "parent": 15306,
                "isLeaf": true
            }],
            "value": 15306,
            "label": "昭通市",
            "parent": 153
        }, {
            "children": [{
                "value": 1530702,
                "label": "古城区",
                "parent": 15307,
                "isLeaf": true
            }, {
                "value": 1530721,
                "label": "玉龙纳西族自治县",
                "parent": 15307,
                "isLeaf": true
            }, {
                "value": 1530722,
                "label": "永胜县",
                "parent": 15307,
                "isLeaf": true
            }, {
                "value": 1530723,
                "label": "华坪县",
                "parent": 15307,
                "isLeaf": true
            }, {
                "value": 1530724,
                "label": "宁蒗彝族自治县",
                "parent": 15307,
                "isLeaf": true
            }],
            "value": 15307,
            "label": "丽江市",
            "parent": 153
        }, {
            "children": [{
                "value": 1530802,
                "label": "思茅区",
                "parent": 15308,
                "isLeaf": true
            }, {
                "value": 1530821,
                "label": "宁洱哈尼族彝族自治县",
                "parent": 15308,
                "isLeaf": true
            }, {
                "value": 1530822,
                "label": "墨江哈尼族自治县",
                "parent": 15308,
                "isLeaf": true
            }, {
                "value": 1530823,
                "label": "景东彝族自治县",
                "parent": 15308,
                "isLeaf": true
            }, {
                "value": 1530824,
                "label": "景谷傣族彝族自治县",
                "parent": 15308,
                "isLeaf": true
            }, {
                "value": 1530825,
                "label": "镇沅彝族哈尼族拉祜族自治县",
                "parent": 15308,
                "isLeaf": true
            }, {
                "value": 1530826,
                "label": "江城哈尼族彝族自治县",
                "parent": 15308,
                "isLeaf": true
            }, {
                "value": 1530827,
                "label": "孟连傣族拉祜族佤族自治县",
                "parent": 15308,
                "isLeaf": true
            }, {
                "value": 1530828,
                "label": "澜沧拉祜族自治县",
                "parent": 15308,
                "isLeaf": true
            }, {
                "value": 1530829,
                "label": "西盟佤族自治县",
                "parent": 15308,
                "isLeaf": true
            }],
            "value": 15308,
            "label": "普洱市",
            "parent": 153
        }, {
            "children": [{
                "value": 1530902,
                "label": "临翔区",
                "parent": 15309,
                "isLeaf": true
            }, {
                "value": 1530921,
                "label": "凤庆县",
                "parent": 15309,
                "isLeaf": true
            }, {
                "value": 1530922,
                "label": "云县",
                "parent": 15309,
                "isLeaf": true
            }, {
                "value": 1530923,
                "label": "永德县",
                "parent": 15309,
                "isLeaf": true
            }, {
                "value": 1530924,
                "label": "镇康县",
                "parent": 15309,
                "isLeaf": true
            }, {
                "value": 1530925,
                "label": "双江拉祜族佤族布朗族傣族自治县",
                "parent": 15309,
                "isLeaf": true
            }, {
                "value": 1530926,
                "label": "耿马傣族佤族自治县",
                "parent": 15309,
                "isLeaf": true
            }, {
                "value": 1530927,
                "label": "沧源佤族自治县",
                "parent": 15309,
                "isLeaf": true
            }],
            "value": 15309,
            "label": "临沧市",
            "parent": 153
        }, {
            "children": [{
                "value": 1532301,
                "label": "楚雄市",
                "parent": 15323,
                "isLeaf": true
            }, {
                "value": 1532322,
                "label": "双柏县",
                "parent": 15323,
                "isLeaf": true
            }, {
                "value": 1532323,
                "label": "牟定县",
                "parent": 15323,
                "isLeaf": true
            }, {
                "value": 1532324,
                "label": "南华县",
                "parent": 15323,
                "isLeaf": true
            }, {
                "value": 1532325,
                "label": "姚安县",
                "parent": 15323,
                "isLeaf": true
            }, {
                "value": 1532326,
                "label": "大姚县",
                "parent": 15323,
                "isLeaf": true
            }, {
                "value": 1532327,
                "label": "永仁县",
                "parent": 15323,
                "isLeaf": true
            }, {
                "value": 1532328,
                "label": "元谋县",
                "parent": 15323,
                "isLeaf": true
            }, {
                "value": 1532329,
                "label": "武定县",
                "parent": 15323,
                "isLeaf": true
            }, {
                "value": 1532331,
                "label": "禄丰县",
                "parent": 15323,
                "isLeaf": true
            }],
            "value": 15323,
            "label": "楚雄彝族自治州",
            "parent": 153
        }, {
            "children": [{
                "value": 1532501,
                "label": "个旧市",
                "parent": 15325,
                "isLeaf": true
            }, {
                "value": 1532502,
                "label": "开远市",
                "parent": 15325,
                "isLeaf": true
            }, {
                "value": 1532503,
                "label": "蒙自市",
                "parent": 15325,
                "isLeaf": true
            }, {
                "value": 1532504,
                "label": "弥勒市",
                "parent": 15325,
                "isLeaf": true
            }, {
                "value": 1532523,
                "label": "屏边苗族自治县",
                "parent": 15325,
                "isLeaf": true
            }, {
                "value": 1532524,
                "label": "建水县",
                "parent": 15325,
                "isLeaf": true
            }, {
                "value": 1532525,
                "label": "石屏县",
                "parent": 15325,
                "isLeaf": true
            }, {
                "value": 1532527,
                "label": "泸西县",
                "parent": 15325,
                "isLeaf": true
            }, {
                "value": 1532528,
                "label": "元阳县",
                "parent": 15325,
                "isLeaf": true
            }, {
                "value": 1532529,
                "label": "红河县",
                "parent": 15325,
                "isLeaf": true
            }, {
                "value": 1532530,
                "label": "金平苗族瑶族傣族自治县",
                "parent": 15325,
                "isLeaf": true
            }, {
                "value": 1532531,
                "label": "绿春县",
                "parent": 15325,
                "isLeaf": true
            }, {
                "value": 1532532,
                "label": "河口瑶族自治县",
                "parent": 15325,
                "isLeaf": true
            }],
            "value": 15325,
            "label": "红河哈尼族彝族自治州",
            "parent": 153
        }, {
            "children": [{
                "value": 1532601,
                "label": "文山市",
                "parent": 15326,
                "isLeaf": true
            }, {
                "value": 1532622,
                "label": "砚山县",
                "parent": 15326,
                "isLeaf": true
            }, {
                "value": 1532623,
                "label": "西畴县",
                "parent": 15326,
                "isLeaf": true
            }, {
                "value": 1532624,
                "label": "麻栗坡县",
                "parent": 15326,
                "isLeaf": true
            }, {
                "value": 1532625,
                "label": "马关县",
                "parent": 15326,
                "isLeaf": true
            }, {
                "value": 1532626,
                "label": "丘北县",
                "parent": 15326,
                "isLeaf": true
            }, {
                "value": 1532627,
                "label": "广南县",
                "parent": 15326,
                "isLeaf": true
            }, {
                "value": 1532628,
                "label": "富宁县",
                "parent": 15326,
                "isLeaf": true
            }],
            "value": 15326,
            "label": "文山壮族苗族自治州",
            "parent": 153
        }, {
            "children": [{
                "value": 1532801,
                "label": "景洪市",
                "parent": 15328,
                "isLeaf": true
            }, {
                "value": 1532822,
                "label": "勐海县",
                "parent": 15328,
                "isLeaf": true
            }, {
                "value": 1532823,
                "label": "勐腊县",
                "parent": 15328,
                "isLeaf": true
            }],
            "value": 15328,
            "label": "西双版纳傣族自治州",
            "parent": 153
        }, {
            "children": [{
                "value": 1532901,
                "label": "大理市",
                "parent": 15329,
                "isLeaf": true
            }, {
                "value": 1532922,
                "label": "漾濞彝族自治县",
                "parent": 15329,
                "isLeaf": true
            }, {
                "value": 1532923,
                "label": "祥云县",
                "parent": 15329,
                "isLeaf": true
            }, {
                "value": 1532924,
                "label": "宾川县",
                "parent": 15329,
                "isLeaf": true
            }, {
                "value": 1532925,
                "label": "弥渡县",
                "parent": 15329,
                "isLeaf": true
            }, {
                "value": 1532926,
                "label": "南涧彝族自治县",
                "parent": 15329,
                "isLeaf": true
            }, {
                "value": 1532927,
                "label": "巍山彝族回族自治县",
                "parent": 15329,
                "isLeaf": true
            }, {
                "value": 1532928,
                "label": "永平县",
                "parent": 15329,
                "isLeaf": true
            }, {
                "value": 1532929,
                "label": "云龙县",
                "parent": 15329,
                "isLeaf": true
            }, {
                "value": 1532930,
                "label": "洱源县",
                "parent": 15329,
                "isLeaf": true
            }, {
                "value": 1532931,
                "label": "剑川县",
                "parent": 15329,
                "isLeaf": true
            }, {
                "value": 1532932,
                "label": "鹤庆县",
                "parent": 15329,
                "isLeaf": true
            }],
            "value": 15329,
            "label": "大理白族自治州",
            "parent": 153
        }, {
            "children": [{
                "value": 1533102,
                "label": "瑞丽市",
                "parent": 15331,
                "isLeaf": true
            }, {
                "value": 1533103,
                "label": "芒市",
                "parent": 15331,
                "isLeaf": true
            }, {
                "value": 1533122,
                "label": "梁河县",
                "parent": 15331,
                "isLeaf": true
            }, {
                "value": 1533123,
                "label": "盈江县",
                "parent": 15331,
                "isLeaf": true
            }, {
                "value": 1533124,
                "label": "陇川县",
                "parent": 15331,
                "isLeaf": true
            }],
            "value": 15331,
            "label": "德宏傣族景颇族自治州",
            "parent": 153
        }, {
            "children": [{
                "value": 1533321,
                "label": "泸水县",
                "parent": 15333,
                "isLeaf": true
            }, {
                "value": 1533323,
                "label": "福贡县",
                "parent": 15333,
                "isLeaf": true
            }, {
                "value": 1533324,
                "label": "贡山独龙族怒族自治县",
                "parent": 15333,
                "isLeaf": true
            }, {
                "value": 1533325,
                "label": "兰坪白族普米族自治县",
                "parent": 15333,
                "isLeaf": true
            }],
            "value": 15333,
            "label": "怒江傈僳族自治州",
            "parent": 153
        }, {
            "children": [{
                "value": 1533400,
                "label": "迪庆藏族自治州",
                "parent": 15334,
                "isLeaf": true
            }, {
                "value": 1533401,
                "label": "香格里拉市",
                "parent": 15334,
                "isLeaf": true
            }, {
                "value": 1533422,
                "label": "德钦县",
                "parent": 15334,
                "isLeaf": true
            }, {
                "value": 1533423,
                "label": "维西傈僳族自治县",
                "parent": 15334,
                "isLeaf": true
            }],
            "value": 15334,
            "label": "迪庆藏族自治州",
            "parent": 153
        }],
        "value": 153,
        "label": "云南省",
        "parent": 0
    }, {
        "children": [{
            "children": [{
                "value": 1540102,
                "label": "城关区",
                "parent": 15401,
                "isLeaf": true
            }, {
                "value": 1540103,
                "label": "堆龙德庆区",
                "parent": 15401,
                "isLeaf": true
            }, {
                "value": 1540121,
                "label": "林周县",
                "parent": 15401,
                "isLeaf": true
            }, {
                "value": 1540122,
                "label": "当雄县",
                "parent": 15401,
                "isLeaf": true
            }, {
                "value": 1540123,
                "label": "尼木县",
                "parent": 15401,
                "isLeaf": true
            }, {
                "value": 1540124,
                "label": "曲水县",
                "parent": 15401,
                "isLeaf": true
            }, {
                "value": 1540126,
                "label": "达孜县",
                "parent": 15401,
                "isLeaf": true
            }, {
                "value": 1540127,
                "label": "墨竹工卡县",
                "parent": 15401,
                "isLeaf": true
            }],
            "value": 15401,
            "label": "拉萨市",
            "parent": 154
        }, {
            "children": [{
                "value": 1540202,
                "label": "桑珠孜区",
                "parent": 15402,
                "isLeaf": true
            }, {
                "value": 1540221,
                "label": "南木林县",
                "parent": 15402,
                "isLeaf": true
            }, {
                "value": 1540222,
                "label": "江孜县",
                "parent": 15402,
                "isLeaf": true
            }, {
                "value": 1540223,
                "label": "定日县",
                "parent": 15402,
                "isLeaf": true
            }, {
                "value": 1540224,
                "label": "萨迦县",
                "parent": 15402,
                "isLeaf": true
            }, {
                "value": 1540225,
                "label": "拉孜县",
                "parent": 15402,
                "isLeaf": true
            }, {
                "value": 1540226,
                "label": "昂仁县",
                "parent": 15402,
                "isLeaf": true
            }, {
                "value": 1540227,
                "label": "谢通门县",
                "parent": 15402,
                "isLeaf": true
            }, {
                "value": 1540228,
                "label": "白朗县",
                "parent": 15402,
                "isLeaf": true
            }, {
                "value": 1540229,
                "label": "仁布县",
                "parent": 15402,
                "isLeaf": true
            }, {
                "value": 1540230,
                "label": "康马县",
                "parent": 15402,
                "isLeaf": true
            }, {
                "value": 1540231,
                "label": "定结县",
                "parent": 15402,
                "isLeaf": true
            }, {
                "value": 1540232,
                "label": "仲巴县",
                "parent": 15402,
                "isLeaf": true
            }, {
                "value": 1540233,
                "label": "亚东县",
                "parent": 15402,
                "isLeaf": true
            }, {
                "value": 1540234,
                "label": "吉隆县",
                "parent": 15402,
                "isLeaf": true
            }, {
                "value": 1540235,
                "label": "聂拉木县",
                "parent": 15402,
                "isLeaf": true
            }, {
                "value": 1540236,
                "label": "萨嘎县",
                "parent": 15402,
                "isLeaf": true
            }, {
                "value": 1540237,
                "label": "岗巴县",
                "parent": 15402,
                "isLeaf": true
            }],
            "value": 15402,
            "label": "日喀则市",
            "parent": 154
        }, {
            "children": [{
                "value": 1540302,
                "label": "卡若区",
                "parent": 15403,
                "isLeaf": true
            }, {
                "value": 1540321,
                "label": "江达县",
                "parent": 15403,
                "isLeaf": true
            }, {
                "value": 1540322,
                "label": "贡觉县",
                "parent": 15403,
                "isLeaf": true
            }, {
                "value": 1540323,
                "label": "类乌齐县",
                "parent": 15403,
                "isLeaf": true
            }, {
                "value": 1540324,
                "label": "丁青县",
                "parent": 15403,
                "isLeaf": true
            }, {
                "value": 1540325,
                "label": "察雅县",
                "parent": 15403,
                "isLeaf": true
            }, {
                "value": 1540326,
                "label": "八宿县",
                "parent": 15403,
                "isLeaf": true
            }, {
                "value": 1540327,
                "label": "左贡县",
                "parent": 15403,
                "isLeaf": true
            }, {
                "value": 1540328,
                "label": "芒康县",
                "parent": 15403,
                "isLeaf": true
            }, {
                "value": 1540329,
                "label": "洛隆县",
                "parent": 15403,
                "isLeaf": true
            }, {
                "value": 1540330,
                "label": "边坝县",
                "parent": 15403,
                "isLeaf": true
            }],
            "value": 15403,
            "label": "昌都市",
            "parent": 154
        }, {
            "children": [{
                "value": 1540402,
                "label": "巴宜区",
                "parent": 15404,
                "isLeaf": true
            }, {
                "value": 1540421,
                "label": "工布江达县",
                "parent": 15404,
                "isLeaf": true
            }, {
                "value": 1540422,
                "label": "米林县",
                "parent": 15404,
                "isLeaf": true
            }, {
                "value": 1540423,
                "label": "墨脱县",
                "parent": 15404,
                "isLeaf": true
            }, {
                "value": 1540424,
                "label": "波密县",
                "parent": 15404,
                "isLeaf": true
            }, {
                "value": 1540425,
                "label": "察隅县",
                "parent": 15404,
                "isLeaf": true
            }, {
                "value": 1540426,
                "label": "朗县",
                "parent": 15404,
                "isLeaf": true
            }],
            "value": 15404,
            "label": "林芝市",
            "parent": 154
        }, {
            "children": [{
                "value": 1542221,
                "label": "乃东县",
                "parent": 15422,
                "isLeaf": true
            }, {
                "value": 1542222,
                "label": "扎囊县",
                "parent": 15422,
                "isLeaf": true
            }, {
                "value": 1542223,
                "label": "贡嘎县",
                "parent": 15422,
                "isLeaf": true
            }, {
                "value": 1542224,
                "label": "桑日县",
                "parent": 15422,
                "isLeaf": true
            }, {
                "value": 1542225,
                "label": "琼结县",
                "parent": 15422,
                "isLeaf": true
            }, {
                "value": 1542226,
                "label": "曲松县",
                "parent": 15422,
                "isLeaf": true
            }, {
                "value": 1542227,
                "label": "措美县",
                "parent": 15422,
                "isLeaf": true
            }, {
                "value": 1542228,
                "label": "洛扎县",
                "parent": 15422,
                "isLeaf": true
            }, {
                "value": 1542229,
                "label": "加查县",
                "parent": 15422,
                "isLeaf": true
            }, {
                "value": 1542231,
                "label": "隆子县",
                "parent": 15422,
                "isLeaf": true
            }, {
                "value": 1542232,
                "label": "错那县",
                "parent": 15422,
                "isLeaf": true
            }, {
                "value": 1542233,
                "label": "浪卡子县",
                "parent": 15422,
                "isLeaf": true
            }],
            "value": 15422,
            "label": "山南地区",
            "parent": 154
        }, {
            "children": [{
                "value": 1542421,
                "label": "那曲县",
                "parent": 15424,
                "isLeaf": true
            }, {
                "value": 1542422,
                "label": "嘉黎县",
                "parent": 15424,
                "isLeaf": true
            }, {
                "value": 1542423,
                "label": "比如县",
                "parent": 15424,
                "isLeaf": true
            }, {
                "value": 1542424,
                "label": "聂荣县",
                "parent": 15424,
                "isLeaf": true
            }, {
                "value": 1542425,
                "label": "安多县",
                "parent": 15424,
                "isLeaf": true
            }, {
                "value": 1542426,
                "label": "申扎县",
                "parent": 15424,
                "isLeaf": true
            }, {
                "value": 1542427,
                "label": "索县",
                "parent": 15424,
                "isLeaf": true
            }, {
                "value": 1542428,
                "label": "班戈县",
                "parent": 15424,
                "isLeaf": true
            }, {
                "value": 1542429,
                "label": "巴青县",
                "parent": 15424,
                "isLeaf": true
            }, {
                "value": 1542430,
                "label": "尼玛县",
                "parent": 15424,
                "isLeaf": true
            }, {
                "value": 1542431,
                "label": "双湖县",
                "parent": 15424,
                "isLeaf": true
            }],
            "value": 15424,
            "label": "那曲地区",
            "parent": 154
        }, {
            "children": [{
                "value": 1542521,
                "label": "普兰县",
                "parent": 15425,
                "isLeaf": true
            }, {
                "value": 1542522,
                "label": "札达县",
                "parent": 15425,
                "isLeaf": true
            }, {
                "value": 1542523,
                "label": "噶尔县",
                "parent": 15425,
                "isLeaf": true
            }, {
                "value": 1542524,
                "label": "日土县",
                "parent": 15425,
                "isLeaf": true
            }, {
                "value": 1542525,
                "label": "革吉县",
                "parent": 15425,
                "isLeaf": true
            }, {
                "value": 1542526,
                "label": "改则县",
                "parent": 15425,
                "isLeaf": true
            }, {
                "value": 1542527,
                "label": "措勤县",
                "parent": 15425,
                "isLeaf": true
            }],
            "value": 15425,
            "label": "阿里地区",
            "parent": 154
        }],
        "value": 154,
        "label": "西藏自治区",
        "parent": 0
    }, {
        "children": [{
            "children": [{
                "value": 1610102,
                "label": "新城区",
                "parent": 16101,
                "isLeaf": true
            }, {
                "value": 1610103,
                "label": "碑林区",
                "parent": 16101,
                "isLeaf": true
            }, {
                "value": 1610104,
                "label": "莲湖区",
                "parent": 16101,
                "isLeaf": true
            }, {
                "value": 1610111,
                "label": "灞桥区",
                "parent": 16101,
                "isLeaf": true
            }, {
                "value": 1610112,
                "label": "未央区",
                "parent": 16101,
                "isLeaf": true
            }, {
                "value": 1610113,
                "label": "雁塔区",
                "parent": 16101,
                "isLeaf": true
            }, {
                "value": 1610114,
                "label": "阎良区",
                "parent": 16101,
                "isLeaf": true
            }, {
                "value": 1610115,
                "label": "临潼区",
                "parent": 16101,
                "isLeaf": true
            }, {
                "value": 1610116,
                "label": "长安区",
                "parent": 16101,
                "isLeaf": true
            }, {
                "value": 1610117,
                "label": "高陵区",
                "parent": 16101,
                "isLeaf": true
            }, {
                "value": 1610122,
                "label": "蓝田县",
                "parent": 16101,
                "isLeaf": true
            }, {
                "value": 1610124,
                "label": "周至县",
                "parent": 16101,
                "isLeaf": true
            }, {
                "value": 1610125,
                "label": "户县",
                "parent": 16101,
                "isLeaf": true
            }],
            "value": 16101,
            "label": "西安市",
            "parent": 161
        }, {
            "children": [{
                "value": 1610202,
                "label": "王益区",
                "parent": 16102,
                "isLeaf": true
            }, {
                "value": 1610203,
                "label": "印台区",
                "parent": 16102,
                "isLeaf": true
            }, {
                "value": 1610204,
                "label": "耀州区",
                "parent": 16102,
                "isLeaf": true
            }, {
                "value": 1610222,
                "label": "宜君县",
                "parent": 16102,
                "isLeaf": true
            }],
            "value": 16102,
            "label": "铜川市",
            "parent": 161
        }, {
            "children": [{
                "value": 1610302,
                "label": "渭滨区",
                "parent": 16103,
                "isLeaf": true
            }, {
                "value": 1610303,
                "label": "金台区",
                "parent": 16103,
                "isLeaf": true
            }, {
                "value": 1610304,
                "label": "陈仓区",
                "parent": 16103,
                "isLeaf": true
            }, {
                "value": 1610322,
                "label": "凤翔县",
                "parent": 16103,
                "isLeaf": true
            }, {
                "value": 1610323,
                "label": "岐山县",
                "parent": 16103,
                "isLeaf": true
            }, {
                "value": 1610324,
                "label": "扶风县",
                "parent": 16103,
                "isLeaf": true
            }, {
                "value": 1610326,
                "label": "眉县",
                "parent": 16103,
                "isLeaf": true
            }, {
                "value": 1610327,
                "label": "陇县",
                "parent": 16103,
                "isLeaf": true
            }, {
                "value": 1610328,
                "label": "千阳县",
                "parent": 16103,
                "isLeaf": true
            }, {
                "value": 1610329,
                "label": "麟游县",
                "parent": 16103,
                "isLeaf": true
            }, {
                "value": 1610330,
                "label": "凤县",
                "parent": 16103,
                "isLeaf": true
            }, {
                "value": 1610331,
                "label": "太白县",
                "parent": 16103,
                "isLeaf": true
            }],
            "value": 16103,
            "label": "宝鸡市",
            "parent": 161
        }, {
            "children": [{
                "value": 1610402,
                "label": "秦都区",
                "parent": 16104,
                "isLeaf": true
            }, {
                "value": 1610403,
                "label": "杨陵区",
                "parent": 16104,
                "isLeaf": true
            }, {
                "value": 1610404,
                "label": "渭城区",
                "parent": 16104,
                "isLeaf": true
            }, {
                "value": 1610422,
                "label": "三原县",
                "parent": 16104,
                "isLeaf": true
            }, {
                "value": 1610423,
                "label": "泾阳县",
                "parent": 16104,
                "isLeaf": true
            }, {
                "value": 1610424,
                "label": "乾县",
                "parent": 16104,
                "isLeaf": true
            }, {
                "value": 1610425,
                "label": "礼泉县",
                "parent": 16104,
                "isLeaf": true
            }, {
                "value": 1610426,
                "label": "永寿县",
                "parent": 16104,
                "isLeaf": true
            }, {
                "value": 1610427,
                "label": "彬县",
                "parent": 16104,
                "isLeaf": true
            }, {
                "value": 1610428,
                "label": "长武县",
                "parent": 16104,
                "isLeaf": true
            }, {
                "value": 1610429,
                "label": "旬邑县",
                "parent": 16104,
                "isLeaf": true
            }, {
                "value": 1610430,
                "label": "淳化县",
                "parent": 16104,
                "isLeaf": true
            }, {
                "value": 1610431,
                "label": "武功县",
                "parent": 16104,
                "isLeaf": true
            }, {
                "value": 1610481,
                "label": "兴平市",
                "parent": 16104,
                "isLeaf": true
            }],
            "value": 16104,
            "label": "咸阳市",
            "parent": 161
        }, {
            "children": [{
                "value": 1610502,
                "label": "临渭区",
                "parent": 16105,
                "isLeaf": true
            }, {
                "value": 1610503,
                "label": "华州区",
                "parent": 16105,
                "isLeaf": true
            }, {
                "value": 1610522,
                "label": "潼关县",
                "parent": 16105,
                "isLeaf": true
            }, {
                "value": 1610523,
                "label": "大荔县",
                "parent": 16105,
                "isLeaf": true
            }, {
                "value": 1610524,
                "label": "合阳县",
                "parent": 16105,
                "isLeaf": true
            }, {
                "value": 1610525,
                "label": "澄城县",
                "parent": 16105,
                "isLeaf": true
            }, {
                "value": 1610526,
                "label": "蒲城县",
                "parent": 16105,
                "isLeaf": true
            }, {
                "value": 1610527,
                "label": "白水县",
                "parent": 16105,
                "isLeaf": true
            }, {
                "value": 1610528,
                "label": "富平县",
                "parent": 16105,
                "isLeaf": true
            }, {
                "value": 1610581,
                "label": "韩城市",
                "parent": 16105,
                "isLeaf": true
            }, {
                "value": 1610582,
                "label": "华阴市",
                "parent": 16105,
                "isLeaf": true
            }],
            "value": 16105,
            "label": "渭南市",
            "parent": 161
        }, {
            "children": [{
                "value": 1610602,
                "label": "宝塔区",
                "parent": 16106,
                "isLeaf": true
            }, {
                "value": 1610621,
                "label": "延长县",
                "parent": 16106,
                "isLeaf": true
            }, {
                "value": 1610622,
                "label": "延川县",
                "parent": 16106,
                "isLeaf": true
            }, {
                "value": 1610623,
                "label": "子长县",
                "parent": 16106,
                "isLeaf": true
            }, {
                "value": 1610624,
                "label": "安塞县",
                "parent": 16106,
                "isLeaf": true
            }, {
                "value": 1610625,
                "label": "志丹县",
                "parent": 16106,
                "isLeaf": true
            }, {
                "value": 1610626,
                "label": "吴起县",
                "parent": 16106,
                "isLeaf": true
            }, {
                "value": 1610627,
                "label": "甘泉县",
                "parent": 16106,
                "isLeaf": true
            }, {
                "value": 1610628,
                "label": "富县",
                "parent": 16106,
                "isLeaf": true
            }, {
                "value": 1610629,
                "label": "洛川县",
                "parent": 16106,
                "isLeaf": true
            }, {
                "value": 1610630,
                "label": "宜川县",
                "parent": 16106,
                "isLeaf": true
            }, {
                "value": 1610631,
                "label": "黄龙县",
                "parent": 16106,
                "isLeaf": true
            }, {
                "value": 1610632,
                "label": "黄陵县",
                "parent": 16106,
                "isLeaf": true
            }],
            "value": 16106,
            "label": "延安市",
            "parent": 161
        }, {
            "children": [{
                "value": 1610702,
                "label": "汉台区",
                "parent": 16107,
                "isLeaf": true
            }, {
                "value": 1610721,
                "label": "南郑县",
                "parent": 16107,
                "isLeaf": true
            }, {
                "value": 1610722,
                "label": "城固县",
                "parent": 16107,
                "isLeaf": true
            }, {
                "value": 1610723,
                "label": "洋县",
                "parent": 16107,
                "isLeaf": true
            }, {
                "value": 1610724,
                "label": "西乡县",
                "parent": 16107,
                "isLeaf": true
            }, {
                "value": 1610725,
                "label": "勉县",
                "parent": 16107,
                "isLeaf": true
            }, {
                "value": 1610726,
                "label": "宁强县",
                "parent": 16107,
                "isLeaf": true
            }, {
                "value": 1610727,
                "label": "略阳县",
                "parent": 16107,
                "isLeaf": true
            }, {
                "value": 1610728,
                "label": "镇巴县",
                "parent": 16107,
                "isLeaf": true
            }, {
                "value": 1610729,
                "label": "留坝县",
                "parent": 16107,
                "isLeaf": true
            }, {
                "value": 1610730,
                "label": "佛坪县",
                "parent": 16107,
                "isLeaf": true
            }],
            "value": 16107,
            "label": "汉中市",
            "parent": 161
        }, {
            "children": [{
                "value": 1610802,
                "label": "榆阳区",
                "parent": 16108,
                "isLeaf": true
            }, {
                "value": 1610803,
                "label": "横山区",
                "parent": 16108,
                "isLeaf": true
            }, {
                "value": 1610821,
                "label": "神木县",
                "parent": 16108,
                "isLeaf": true
            }, {
                "value": 1610822,
                "label": "府谷县",
                "parent": 16108,
                "isLeaf": true
            }, {
                "value": 1610824,
                "label": "靖边县",
                "parent": 16108,
                "isLeaf": true
            }, {
                "value": 1610825,
                "label": "定边县",
                "parent": 16108,
                "isLeaf": true
            }, {
                "value": 1610826,
                "label": "绥德县",
                "parent": 16108,
                "isLeaf": true
            }, {
                "value": 1610827,
                "label": "米脂县",
                "parent": 16108,
                "isLeaf": true
            }, {
                "value": 1610828,
                "label": "佳县",
                "parent": 16108,
                "isLeaf": true
            }, {
                "value": 1610829,
                "label": "吴堡县",
                "parent": 16108,
                "isLeaf": true
            }, {
                "value": 1610830,
                "label": "清涧县",
                "parent": 16108,
                "isLeaf": true
            }, {
                "value": 1610831,
                "label": "子洲县",
                "parent": 16108,
                "isLeaf": true
            }],
            "value": 16108,
            "label": "榆林市",
            "parent": 161
        }, {
            "children": [{
                "value": 1610902,
                "label": "汉滨区",
                "parent": 16109,
                "isLeaf": true
            }, {
                "value": 1610921,
                "label": "汉阴县",
                "parent": 16109,
                "isLeaf": true
            }, {
                "value": 1610922,
                "label": "石泉县",
                "parent": 16109,
                "isLeaf": true
            }, {
                "value": 1610923,
                "label": "宁陕县",
                "parent": 16109,
                "isLeaf": true
            }, {
                "value": 1610924,
                "label": "紫阳县",
                "parent": 16109,
                "isLeaf": true
            }, {
                "value": 1610925,
                "label": "岚皋县",
                "parent": 16109,
                "isLeaf": true
            }, {
                "value": 1610926,
                "label": "平利县",
                "parent": 16109,
                "isLeaf": true
            }, {
                "value": 1610927,
                "label": "镇坪县",
                "parent": 16109,
                "isLeaf": true
            }, {
                "value": 1610928,
                "label": "旬阳县",
                "parent": 16109,
                "isLeaf": true
            }, {
                "value": 1610929,
                "label": "白河县",
                "parent": 16109,
                "isLeaf": true
            }],
            "value": 16109,
            "label": "安康市",
            "parent": 161
        }, {
            "children": [{
                "value": 1611002,
                "label": "商州区",
                "parent": 16110,
                "isLeaf": true
            }, {
                "value": 1611021,
                "label": "洛南县",
                "parent": 16110,
                "isLeaf": true
            }, {
                "value": 1611022,
                "label": "丹凤县",
                "parent": 16110,
                "isLeaf": true
            }, {
                "value": 1611023,
                "label": "商南县",
                "parent": 16110,
                "isLeaf": true
            }, {
                "value": 1611024,
                "label": "山阳县",
                "parent": 16110,
                "isLeaf": true
            }, {
                "value": 1611025,
                "label": "镇安县",
                "parent": 16110,
                "isLeaf": true
            }, {
                "value": 1611026,
                "label": "柞水县",
                "parent": 16110,
                "isLeaf": true
            }],
            "value": 16110,
            "label": "商洛市",
            "parent": 161
        }],
        "value": 161,
        "label": "陕西省",
        "parent": 0
    }, {
        "children": [{
            "children": [{
                "value": 1620102,
                "label": "城关区",
                "parent": 16201,
                "isLeaf": true
            }, {
                "value": 1620103,
                "label": "七里河区",
                "parent": 16201,
                "isLeaf": true
            }, {
                "value": 1620104,
                "label": "西固区",
                "parent": 16201,
                "isLeaf": true
            }, {
                "value": 1620105,
                "label": "安宁区",
                "parent": 16201,
                "isLeaf": true
            }, {
                "value": 1620111,
                "label": "红古区",
                "parent": 16201,
                "isLeaf": true
            }, {
                "value": 1620121,
                "label": "永登县",
                "parent": 16201,
                "isLeaf": true
            }, {
                "value": 1620122,
                "label": "皋兰县",
                "parent": 16201,
                "isLeaf": true
            }, {
                "value": 1620123,
                "label": "榆中县",
                "parent": 16201,
                "isLeaf": true
            }],
            "value": 16201,
            "label": "兰州市",
            "parent": 162
        }, {
            "children": [{
                "value": 1620201,
                "label": "市辖区",
                "parent": 16202,
                "isLeaf": true
            }],
            "value": 16202,
            "label": "嘉峪关市",
            "parent": 162
        }, {
            "children": [{
                "value": 1620302,
                "label": "金川区",
                "parent": 16203,
                "isLeaf": true
            }, {
                "value": 1620321,
                "label": "永昌县",
                "parent": 16203,
                "isLeaf": true
            }],
            "value": 16203,
            "label": "金昌市",
            "parent": 162
        }, {
            "children": [{
                "value": 1620402,
                "label": "白银区",
                "parent": 16204,
                "isLeaf": true
            }, {
                "value": 1620403,
                "label": "平川区",
                "parent": 16204,
                "isLeaf": true
            }, {
                "value": 1620421,
                "label": "靖远县",
                "parent": 16204,
                "isLeaf": true
            }, {
                "value": 1620422,
                "label": "会宁县",
                "parent": 16204,
                "isLeaf": true
            }, {
                "value": 1620423,
                "label": "景泰县",
                "parent": 16204,
                "isLeaf": true
            }],
            "value": 16204,
            "label": "白银市",
            "parent": 162
        }, {
            "children": [{
                "value": 1620502,
                "label": "秦州区",
                "parent": 16205,
                "isLeaf": true
            }, {
                "value": 1620503,
                "label": "麦积区",
                "parent": 16205,
                "isLeaf": true
            }, {
                "value": 1620521,
                "label": "清水县",
                "parent": 16205,
                "isLeaf": true
            }, {
                "value": 1620522,
                "label": "秦安县",
                "parent": 16205,
                "isLeaf": true
            }, {
                "value": 1620523,
                "label": "甘谷县",
                "parent": 16205,
                "isLeaf": true
            }, {
                "value": 1620524,
                "label": "武山县",
                "parent": 16205,
                "isLeaf": true
            }, {
                "value": 1620525,
                "label": "张家川回族自治县",
                "parent": 16205,
                "isLeaf": true
            }],
            "value": 16205,
            "label": "天水市",
            "parent": 162
        }, {
            "children": [{
                "value": 1620602,
                "label": "凉州区",
                "parent": 16206,
                "isLeaf": true
            }, {
                "value": 1620621,
                "label": "民勤县",
                "parent": 16206,
                "isLeaf": true
            }, {
                "value": 1620622,
                "label": "古浪县",
                "parent": 16206,
                "isLeaf": true
            }, {
                "value": 1620623,
                "label": "天祝藏族自治县",
                "parent": 16206,
                "isLeaf": true
            }],
            "value": 16206,
            "label": "武威市",
            "parent": 162
        }, {
            "children": [{
                "value": 1620702,
                "label": "甘州区",
                "parent": 16207,
                "isLeaf": true
            }, {
                "value": 1620721,
                "label": "肃南裕固族自治县",
                "parent": 16207,
                "isLeaf": true
            }, {
                "value": 1620722,
                "label": "民乐县",
                "parent": 16207,
                "isLeaf": true
            }, {
                "value": 1620723,
                "label": "临泽县",
                "parent": 16207,
                "isLeaf": true
            }, {
                "value": 1620724,
                "label": "高台县",
                "parent": 16207,
                "isLeaf": true
            }, {
                "value": 1620725,
                "label": "山丹县",
                "parent": 16207,
                "isLeaf": true
            }],
            "value": 16207,
            "label": "张掖市",
            "parent": 162
        }, {
            "children": [{
                "value": 1620802,
                "label": "崆峒区",
                "parent": 16208,
                "isLeaf": true
            }, {
                "value": 1620821,
                "label": "泾川县",
                "parent": 16208,
                "isLeaf": true
            }, {
                "value": 1620822,
                "label": "灵台县",
                "parent": 16208,
                "isLeaf": true
            }, {
                "value": 1620823,
                "label": "崇信县",
                "parent": 16208,
                "isLeaf": true
            }, {
                "value": 1620824,
                "label": "华亭县",
                "parent": 16208,
                "isLeaf": true
            }, {
                "value": 1620825,
                "label": "庄浪县",
                "parent": 16208,
                "isLeaf": true
            }, {
                "value": 1620826,
                "label": "静宁县",
                "parent": 16208,
                "isLeaf": true
            }],
            "value": 16208,
            "label": "平凉市",
            "parent": 162
        }, {
            "children": [{
                "value": 1620902,
                "label": "肃州区",
                "parent": 16209,
                "isLeaf": true
            }, {
                "value": 1620921,
                "label": "金塔县",
                "parent": 16209,
                "isLeaf": true
            }, {
                "value": 1620922,
                "label": "瓜州县",
                "parent": 16209,
                "isLeaf": true
            }, {
                "value": 1620923,
                "label": "肃北蒙古族自治县",
                "parent": 16209,
                "isLeaf": true
            }, {
                "value": 1620924,
                "label": "阿克塞哈萨克族自治县",
                "parent": 16209,
                "isLeaf": true
            }, {
                "value": 1620981,
                "label": "玉门市",
                "parent": 16209,
                "isLeaf": true
            }, {
                "value": 1620982,
                "label": "敦煌市",
                "parent": 16209,
                "isLeaf": true
            }],
            "value": 16209,
            "label": "酒泉市",
            "parent": 162
        }, {
            "children": [{
                "value": 1621002,
                "label": "西峰区",
                "parent": 16210,
                "isLeaf": true
            }, {
                "value": 1621021,
                "label": "庆城县",
                "parent": 16210,
                "isLeaf": true
            }, {
                "value": 1621022,
                "label": "环县",
                "parent": 16210,
                "isLeaf": true
            }, {
                "value": 1621023,
                "label": "华池县",
                "parent": 16210,
                "isLeaf": true
            }, {
                "value": 1621024,
                "label": "合水县",
                "parent": 16210,
                "isLeaf": true
            }, {
                "value": 1621025,
                "label": "正宁县",
                "parent": 16210,
                "isLeaf": true
            }, {
                "value": 1621026,
                "label": "宁县",
                "parent": 16210,
                "isLeaf": true
            }, {
                "value": 1621027,
                "label": "镇原县",
                "parent": 16210,
                "isLeaf": true
            }],
            "value": 16210,
            "label": "庆阳市",
            "parent": 162
        }, {
            "children": [{
                "value": 1621102,
                "label": "安定区",
                "parent": 16211,
                "isLeaf": true
            }, {
                "value": 1621121,
                "label": "通渭县",
                "parent": 16211,
                "isLeaf": true
            }, {
                "value": 1621122,
                "label": "陇西县",
                "parent": 16211,
                "isLeaf": true
            }, {
                "value": 1621123,
                "label": "渭源县",
                "parent": 16211,
                "isLeaf": true
            }, {
                "value": 1621124,
                "label": "临洮县",
                "parent": 16211,
                "isLeaf": true
            }, {
                "value": 1621125,
                "label": "漳县",
                "parent": 16211,
                "isLeaf": true
            }, {
                "value": 1621126,
                "label": "岷县",
                "parent": 16211,
                "isLeaf": true
            }],
            "value": 16211,
            "label": "定西市",
            "parent": 162
        }, {
            "children": [{
                "value": 1621202,
                "label": "武都区",
                "parent": 16212,
                "isLeaf": true
            }, {
                "value": 1621221,
                "label": "成县",
                "parent": 16212,
                "isLeaf": true
            }, {
                "value": 1621222,
                "label": "文县",
                "parent": 16212,
                "isLeaf": true
            }, {
                "value": 1621223,
                "label": "宕昌县",
                "parent": 16212,
                "isLeaf": true
            }, {
                "value": 1621224,
                "label": "康县",
                "parent": 16212,
                "isLeaf": true
            }, {
                "value": 1621225,
                "label": "西和县",
                "parent": 16212,
                "isLeaf": true
            }, {
                "value": 1621226,
                "label": "礼县",
                "parent": 16212,
                "isLeaf": true
            }, {
                "value": 1621227,
                "label": "徽县",
                "parent": 16212,
                "isLeaf": true
            }, {
                "value": 1621228,
                "label": "两当县",
                "parent": 16212,
                "isLeaf": true
            }],
            "value": 16212,
            "label": "陇南市",
            "parent": 162
        }, {
            "children": [{
                "value": 1622901,
                "label": "临夏市",
                "parent": 16229,
                "isLeaf": true
            }, {
                "value": 1622921,
                "label": "临夏县",
                "parent": 16229,
                "isLeaf": true
            }, {
                "value": 1622922,
                "label": "康乐县",
                "parent": 16229,
                "isLeaf": true
            }, {
                "value": 1622923,
                "label": "永靖县",
                "parent": 16229,
                "isLeaf": true
            }, {
                "value": 1622924,
                "label": "广河县",
                "parent": 16229,
                "isLeaf": true
            }, {
                "value": 1622925,
                "label": "和政县",
                "parent": 16229,
                "isLeaf": true
            }, {
                "value": 1622926,
                "label": "东乡族自治县",
                "parent": 16229,
                "isLeaf": true
            }, {
                "value": 1622927,
                "label": "积石山保安族东乡族撒拉族自治县",
                "parent": 16229,
                "isLeaf": true
            }],
            "value": 16229,
            "label": "临夏回族自治州",
            "parent": 162
        }, {
            "children": [{
                "value": 1623001,
                "label": "合作市",
                "parent": 16230,
                "isLeaf": true
            }, {
                "value": 1623021,
                "label": "临潭县",
                "parent": 16230,
                "isLeaf": true
            }, {
                "value": 1623022,
                "label": "卓尼县",
                "parent": 16230,
                "isLeaf": true
            }, {
                "value": 1623023,
                "label": "舟曲县",
                "parent": 16230,
                "isLeaf": true
            }, {
                "value": 1623024,
                "label": "迭部县",
                "parent": 16230,
                "isLeaf": true
            }, {
                "value": 1623025,
                "label": "玛曲县",
                "parent": 16230,
                "isLeaf": true
            }, {
                "value": 1623026,
                "label": "碌曲县",
                "parent": 16230,
                "isLeaf": true
            }, {
                "value": 1623027,
                "label": "夏河县",
                "parent": 16230,
                "isLeaf": true
            }],
            "value": 16230,
            "label": "甘南藏族自治州",
            "parent": 162
        }],
        "value": 162,
        "label": "甘肃省",
        "parent": 0
    }, {
        "children": [{
            "children": [{
                "value": 1630102,
                "label": "城东区",
                "parent": 16301,
                "isLeaf": true
            }, {
                "value": 1630103,
                "label": "城中区",
                "parent": 16301,
                "isLeaf": true
            }, {
                "value": 1630104,
                "label": "城西区",
                "parent": 16301,
                "isLeaf": true
            }, {
                "value": 1630105,
                "label": "城北区",
                "parent": 16301,
                "isLeaf": true
            }, {
                "value": 1630121,
                "label": "大通回族土族自治县",
                "parent": 16301,
                "isLeaf": true
            }, {
                "value": 1630122,
                "label": "湟中县",
                "parent": 16301,
                "isLeaf": true
            }, {
                "value": 1630123,
                "label": "湟源县",
                "parent": 16301,
                "isLeaf": true
            }],
            "value": 16301,
            "label": "西宁市",
            "parent": 163
        }, {
            "children": [{
                "value": 1630202,
                "label": "乐都区",
                "parent": 16302,
                "isLeaf": true
            }, {
                "value": 1630203,
                "label": "平安区",
                "parent": 16302,
                "isLeaf": true
            }, {
                "value": 1630222,
                "label": "民和回族土族自治县",
                "parent": 16302,
                "isLeaf": true
            }, {
                "value": 1630223,
                "label": "互助土族自治县",
                "parent": 16302,
                "isLeaf": true
            }, {
                "value": 1630224,
                "label": "化隆回族自治县",
                "parent": 16302,
                "isLeaf": true
            }, {
                "value": 1630225,
                "label": "循化撒拉族自治县",
                "parent": 16302,
                "isLeaf": true
            }],
            "value": 16302,
            "label": "海东市",
            "parent": 163
        }, {
            "children": [{
                "value": 1632221,
                "label": "门源回族自治县",
                "parent": 16322,
                "isLeaf": true
            }, {
                "value": 1632222,
                "label": "祁连县",
                "parent": 16322,
                "isLeaf": true
            }, {
                "value": 1632223,
                "label": "海晏县",
                "parent": 16322,
                "isLeaf": true
            }, {
                "value": 1632224,
                "label": "刚察县",
                "parent": 16322,
                "isLeaf": true
            }],
            "value": 16322,
            "label": "海北藏族自治州",
            "parent": 163
        }, {
            "children": [{
                "value": 1632321,
                "label": "同仁县",
                "parent": 16323,
                "isLeaf": true
            }, {
                "value": 1632322,
                "label": "尖扎县",
                "parent": 16323,
                "isLeaf": true
            }, {
                "value": 1632323,
                "label": "泽库县",
                "parent": 16323,
                "isLeaf": true
            }, {
                "value": 1632324,
                "label": "河南蒙古族自治县",
                "parent": 16323,
                "isLeaf": true
            }],
            "value": 16323,
            "label": "黄南藏族自治州",
            "parent": 163
        }, {
            "children": [{
                "value": 1632521,
                "label": "共和县",
                "parent": 16325,
                "isLeaf": true
            }, {
                "value": 1632522,
                "label": "同德县",
                "parent": 16325,
                "isLeaf": true
            }, {
                "value": 1632523,
                "label": "贵德县",
                "parent": 16325,
                "isLeaf": true
            }, {
                "value": 1632524,
                "label": "兴海县",
                "parent": 16325,
                "isLeaf": true
            }, {
                "value": 1632525,
                "label": "贵南县",
                "parent": 16325,
                "isLeaf": true
            }],
            "value": 16325,
            "label": "海南藏族自治州",
            "parent": 163
        }, {
            "children": [{
                "value": 1632621,
                "label": "玛沁县",
                "parent": 16326,
                "isLeaf": true
            }, {
                "value": 1632622,
                "label": "班玛县",
                "parent": 16326,
                "isLeaf": true
            }, {
                "value": 1632623,
                "label": "甘德县",
                "parent": 16326,
                "isLeaf": true
            }, {
                "value": 1632624,
                "label": "达日县",
                "parent": 16326,
                "isLeaf": true
            }, {
                "value": 1632625,
                "label": "久治县",
                "parent": 16326,
                "isLeaf": true
            }, {
                "value": 1632626,
                "label": "玛多县",
                "parent": 16326,
                "isLeaf": true
            }],
            "value": 16326,
            "label": "果洛藏族自治州",
            "parent": 163
        }, {
            "children": [{
                "value": 1632701,
                "label": "玉树市",
                "parent": 16327,
                "isLeaf": true
            }, {
                "value": 1632722,
                "label": "杂多县",
                "parent": 16327,
                "isLeaf": true
            }, {
                "value": 1632723,
                "label": "称多县",
                "parent": 16327,
                "isLeaf": true
            }, {
                "value": 1632724,
                "label": "治多县",
                "parent": 16327,
                "isLeaf": true
            }, {
                "value": 1632725,
                "label": "囊谦县",
                "parent": 16327,
                "isLeaf": true
            }, {
                "value": 1632726,
                "label": "曲麻莱县",
                "parent": 16327,
                "isLeaf": true
            }],
            "value": 16327,
            "label": "玉树藏族自治州",
            "parent": 163
        }, {
            "children": [{
                "value": 1632801,
                "label": "格尔木市",
                "parent": 16328,
                "isLeaf": true
            }, {
                "value": 1632802,
                "label": "德令哈市",
                "parent": 16328,
                "isLeaf": true
            }, {
                "value": 1632821,
                "label": "乌兰县",
                "parent": 16328,
                "isLeaf": true
            }, {
                "value": 1632822,
                "label": "都兰县",
                "parent": 16328,
                "isLeaf": true
            }, {
                "value": 1632823,
                "label": "天峻县",
                "parent": 16328,
                "isLeaf": true
            }],
            "value": 16328,
            "label": "海西蒙古族藏族自治州",
            "parent": 163
        }],
        "value": 163,
        "label": "青海省",
        "parent": 0
    }, {
        "children": [{
            "children": [{
                "value": 1640104,
                "label": "兴庆区",
                "parent": 16401,
                "isLeaf": true
            }, {
                "value": 1640105,
                "label": "西夏区",
                "parent": 16401,
                "isLeaf": true
            }, {
                "value": 1640106,
                "label": "金凤区",
                "parent": 16401,
                "isLeaf": true
            }, {
                "value": 1640121,
                "label": "永宁县",
                "parent": 16401,
                "isLeaf": true
            }, {
                "value": 1640122,
                "label": "贺兰县",
                "parent": 16401,
                "isLeaf": true
            }, {
                "value": 1640181,
                "label": "灵武市",
                "parent": 16401,
                "isLeaf": true
            }],
            "value": 16401,
            "label": "银川市",
            "parent": 164
        }, {
            "children": [{
                "value": 1640202,
                "label": "大武口区",
                "parent": 16402,
                "isLeaf": true
            }, {
                "value": 1640205,
                "label": "惠农区",
                "parent": 16402,
                "isLeaf": true
            }, {
                "value": 1640221,
                "label": "平罗县",
                "parent": 16402,
                "isLeaf": true
            }],
            "value": 16402,
            "label": "石嘴山市",
            "parent": 164
        }, {
            "children": [{
                "value": 1640302,
                "label": "利通区",
                "parent": 16403,
                "isLeaf": true
            }, {
                "value": 1640303,
                "label": "红寺堡区",
                "parent": 16403,
                "isLeaf": true
            }, {
                "value": 1640323,
                "label": "盐池县",
                "parent": 16403,
                "isLeaf": true
            }, {
                "value": 1640324,
                "label": "同心县",
                "parent": 16403,
                "isLeaf": true
            }, {
                "value": 1640381,
                "label": "青铜峡市",
                "parent": 16403,
                "isLeaf": true
            }],
            "value": 16403,
            "label": "吴忠市",
            "parent": 164
        }, {
            "children": [{
                "value": 1640402,
                "label": "原州区",
                "parent": 16404,
                "isLeaf": true
            }, {
                "value": 1640422,
                "label": "西吉县",
                "parent": 16404,
                "isLeaf": true
            }, {
                "value": 1640423,
                "label": "隆德县",
                "parent": 16404,
                "isLeaf": true
            }, {
                "value": 1640424,
                "label": "泾源县",
                "parent": 16404,
                "isLeaf": true
            }, {
                "value": 1640425,
                "label": "彭阳县",
                "parent": 16404,
                "isLeaf": true
            }],
            "value": 16404,
            "label": "固原市",
            "parent": 164
        }, {
            "children": [{
                "value": 1640502,
                "label": "沙坡头区",
                "parent": 16405,
                "isLeaf": true
            }, {
                "value": 1640521,
                "label": "中宁县",
                "parent": 16405,
                "isLeaf": true
            }, {
                "value": 1640522,
                "label": "海原县",
                "parent": 16405,
                "isLeaf": true
            }],
            "value": 16405,
            "label": "中卫市",
            "parent": 164
        }],
        "value": 164,
        "label": "宁夏回族自治区",
        "parent": 0
    }, {
        "children": [{
            "children": [{
                "value": 1650102,
                "label": "天山区",
                "parent": 16501,
                "isLeaf": true
            }, {
                "value": 1650103,
                "label": "沙依巴克区",
                "parent": 16501,
                "isLeaf": true
            }, {
                "value": 1650104,
                "label": "新市区",
                "parent": 16501,
                "isLeaf": true
            }, {
                "value": 1650105,
                "label": "水磨沟区",
                "parent": 16501,
                "isLeaf": true
            }, {
                "value": 1650106,
                "label": "头屯河区",
                "parent": 16501,
                "isLeaf": true
            }, {
                "value": 1650107,
                "label": "达坂城区",
                "parent": 16501,
                "isLeaf": true
            }, {
                "value": 1650109,
                "label": "米东区",
                "parent": 16501,
                "isLeaf": true
            }, {
                "value": 1650121,
                "label": "乌鲁木齐县",
                "parent": 16501,
                "isLeaf": true
            }],
            "value": 16501,
            "label": "乌鲁木齐市",
            "parent": 165
        }, {
            "children": [{
                "value": 1650202,
                "label": "独山子区",
                "parent": 16502,
                "isLeaf": true
            }, {
                "value": 1650203,
                "label": "克拉玛依区",
                "parent": 16502,
                "isLeaf": true
            }, {
                "value": 1650204,
                "label": "白碱滩区",
                "parent": 16502,
                "isLeaf": true
            }, {
                "value": 1650205,
                "label": "乌尔禾区",
                "parent": 16502,
                "isLeaf": true
            }],
            "value": 16502,
            "label": "克拉玛依市",
            "parent": 165
        }, {
            "children": [{
                "value": 1650402,
                "label": "高昌区",
                "parent": 16504,
                "isLeaf": true
            }, {
                "value": 1650421,
                "label": "鄯善县",
                "parent": 16504,
                "isLeaf": true
            }, {
                "value": 1650422,
                "label": "托克逊县",
                "parent": 16504,
                "isLeaf": true
            }],
            "value": 16504,
            "label": "吐鲁番市",
            "parent": 165
        }, {
            "children": [{
                "value": 1652201,
                "label": "哈密市",
                "parent": 16522,
                "isLeaf": true
            }, {
                "value": 1652222,
                "label": "巴里坤哈萨克自治县",
                "parent": 16522,
                "isLeaf": true
            }, {
                "value": 1652223,
                "label": "伊吾县",
                "parent": 16522,
                "isLeaf": true
            }],
            "value": 16522,
            "label": "哈密地区",
            "parent": 165
        }, {
            "children": [{
                "value": 1652301,
                "label": "昌吉市",
                "parent": 16523,
                "isLeaf": true
            }, {
                "value": 1652302,
                "label": "阜康市",
                "parent": 16523,
                "isLeaf": true
            }, {
                "value": 1652323,
                "label": "呼图壁县",
                "parent": 16523,
                "isLeaf": true
            }, {
                "value": 1652324,
                "label": "玛纳斯县",
                "parent": 16523,
                "isLeaf": true
            }, {
                "value": 1652325,
                "label": "奇台县",
                "parent": 16523,
                "isLeaf": true
            }, {
                "value": 1652327,
                "label": "吉木萨尔县",
                "parent": 16523,
                "isLeaf": true
            }, {
                "value": 1652328,
                "label": "木垒哈萨克自治县",
                "parent": 16523,
                "isLeaf": true
            }],
            "value": 16523,
            "label": "昌吉回族自治州",
            "parent": 165
        }, {
            "children": [{
                "value": 1652701,
                "label": "博乐市",
                "parent": 16527,
                "isLeaf": true
            }, {
                "value": 1652702,
                "label": "阿拉山口市",
                "parent": 16527,
                "isLeaf": true
            }, {
                "value": 1652722,
                "label": "精河县",
                "parent": 16527,
                "isLeaf": true
            }, {
                "value": 1652723,
                "label": "温泉县",
                "parent": 16527,
                "isLeaf": true
            }],
            "value": 16527,
            "label": "博尔塔拉蒙古自治州",
            "parent": 165
        }, {
            "children": [{
                "value": 1652801,
                "label": "库尔勒市",
                "parent": 16528,
                "isLeaf": true
            }, {
                "value": 1652822,
                "label": "轮台县",
                "parent": 16528,
                "isLeaf": true
            }, {
                "value": 1652823,
                "label": "尉犁县",
                "parent": 16528,
                "isLeaf": true
            }, {
                "value": 1652824,
                "label": "若羌县",
                "parent": 16528,
                "isLeaf": true
            }, {
                "value": 1652825,
                "label": "且末县",
                "parent": 16528,
                "isLeaf": true
            }, {
                "value": 1652826,
                "label": "焉耆回族自治县",
                "parent": 16528,
                "isLeaf": true
            }, {
                "value": 1652827,
                "label": "和静县",
                "parent": 16528,
                "isLeaf": true
            }, {
                "value": 1652828,
                "label": "和硕县",
                "parent": 16528,
                "isLeaf": true
            }, {
                "value": 1652829,
                "label": "博湖县",
                "parent": 16528,
                "isLeaf": true
            }],
            "value": 16528,
            "label": "巴音郭楞蒙古自治州",
            "parent": 165
        }, {
            "children": [{
                "value": 1652901,
                "label": "阿克苏市",
                "parent": 16529,
                "isLeaf": true
            }, {
                "value": 1652922,
                "label": "温宿县",
                "parent": 16529,
                "isLeaf": true
            }, {
                "value": 1652923,
                "label": "库车县",
                "parent": 16529,
                "isLeaf": true
            }, {
                "value": 1652924,
                "label": "沙雅县",
                "parent": 16529,
                "isLeaf": true
            }, {
                "value": 1652925,
                "label": "新和县",
                "parent": 16529,
                "isLeaf": true
            }, {
                "value": 1652926,
                "label": "拜城县",
                "parent": 16529,
                "isLeaf": true
            }, {
                "value": 1652927,
                "label": "乌什县",
                "parent": 16529,
                "isLeaf": true
            }, {
                "value": 1652928,
                "label": "阿瓦提县",
                "parent": 16529,
                "isLeaf": true
            }, {
                "value": 1652929,
                "label": "柯坪县",
                "parent": 16529,
                "isLeaf": true
            }],
            "value": 16529,
            "label": "阿克苏地区",
            "parent": 165
        }, {
            "children": [{
                "value": 1653001,
                "label": "阿图什市",
                "parent": 16530,
                "isLeaf": true
            }, {
                "value": 1653022,
                "label": "阿克陶县",
                "parent": 16530,
                "isLeaf": true
            }, {
                "value": 1653023,
                "label": "阿合奇县",
                "parent": 16530,
                "isLeaf": true
            }, {
                "value": 1653024,
                "label": "乌恰县",
                "parent": 16530,
                "isLeaf": true
            }],
            "value": 16530,
            "label": "克孜勒苏柯尔克孜自治州",
            "parent": 165
        }, {
            "children": [{
                "value": 1653101,
                "label": "喀什市",
                "parent": 16531,
                "isLeaf": true
            }, {
                "value": 1653121,
                "label": "疏附县",
                "parent": 16531,
                "isLeaf": true
            }, {
                "value": 1653122,
                "label": "疏勒县",
                "parent": 16531,
                "isLeaf": true
            }, {
                "value": 1653123,
                "label": "英吉沙县",
                "parent": 16531,
                "isLeaf": true
            }, {
                "value": 1653124,
                "label": "泽普县",
                "parent": 16531,
                "isLeaf": true
            }, {
                "value": 1653125,
                "label": "莎车县",
                "parent": 16531,
                "isLeaf": true
            }, {
                "value": 1653126,
                "label": "叶城县",
                "parent": 16531,
                "isLeaf": true
            }, {
                "value": 1653127,
                "label": "麦盖提县",
                "parent": 16531,
                "isLeaf": true
            }, {
                "value": 1653128,
                "label": "岳普湖县",
                "parent": 16531,
                "isLeaf": true
            }, {
                "value": 1653129,
                "label": "伽师县",
                "parent": 16531,
                "isLeaf": true
            }, {
                "value": 1653130,
                "label": "巴楚县",
                "parent": 16531,
                "isLeaf": true
            }, {
                "value": 1653131,
                "label": "塔什库尔干塔吉克自治县",
                "parent": 16531,
                "isLeaf": true
            }],
            "value": 16531,
            "label": "喀什地区",
            "parent": 165
        }, {
            "children": [{
                "value": 1653201,
                "label": "和田市",
                "parent": 16532,
                "isLeaf": true
            }, {
                "value": 1653221,
                "label": "和田县",
                "parent": 16532,
                "isLeaf": true
            }, {
                "value": 1653222,
                "label": "墨玉县",
                "parent": 16532,
                "isLeaf": true
            }, {
                "value": 1653223,
                "label": "皮山县",
                "parent": 16532,
                "isLeaf": true
            }, {
                "value": 1653224,
                "label": "洛浦县",
                "parent": 16532,
                "isLeaf": true
            }, {
                "value": 1653225,
                "label": "策勒县",
                "parent": 16532,
                "isLeaf": true
            }, {
                "value": 1653226,
                "label": "于田县",
                "parent": 16532,
                "isLeaf": true
            }, {
                "value": 1653227,
                "label": "民丰县",
                "parent": 16532,
                "isLeaf": true
            }],
            "value": 16532,
            "label": "和田地区",
            "parent": 165
        }, {
            "children": [{
                "value": 1654002,
                "label": "伊宁市",
                "parent": 16540,
                "isLeaf": true
            }, {
                "value": 1654003,
                "label": "奎屯市",
                "parent": 16540,
                "isLeaf": true
            }, {
                "value": 1654004,
                "label": "霍尔果斯市",
                "parent": 16540,
                "isLeaf": true
            }, {
                "value": 1654021,
                "label": "伊宁县",
                "parent": 16540,
                "isLeaf": true
            }, {
                "value": 1654022,
                "label": "察布查尔锡伯自治县",
                "parent": 16540,
                "isLeaf": true
            }, {
                "value": 1654023,
                "label": "霍城县",
                "parent": 16540,
                "isLeaf": true
            }, {
                "value": 1654024,
                "label": "巩留县",
                "parent": 16540,
                "isLeaf": true
            }, {
                "value": 1654025,
                "label": "新源县",
                "parent": 16540,
                "isLeaf": true
            }, {
                "value": 1654026,
                "label": "昭苏县",
                "parent": 16540,
                "isLeaf": true
            }, {
                "value": 1654027,
                "label": "特克斯县",
                "parent": 16540,
                "isLeaf": true
            }, {
                "value": 1654028,
                "label": "尼勒克县",
                "parent": 16540,
                "isLeaf": true
            }],
            "value": 16540,
            "label": "伊犁哈萨克自治州",
            "parent": 165
        }, {
            "children": [{
                "value": 1654201,
                "label": "塔城市",
                "parent": 16542,
                "isLeaf": true
            }, {
                "value": 1654202,
                "label": "乌苏市",
                "parent": 16542,
                "isLeaf": true
            }, {
                "value": 1654221,
                "label": "额敏县",
                "parent": 16542,
                "isLeaf": true
            }, {
                "value": 1654223,
                "label": "沙湾县",
                "parent": 16542,
                "isLeaf": true
            }, {
                "value": 1654224,
                "label": "托里县",
                "parent": 16542,
                "isLeaf": true
            }, {
                "value": 1654225,
                "label": "裕民县",
                "parent": 16542,
                "isLeaf": true
            }, {
                "value": 1654226,
                "label": "和布克赛尔蒙古自治县",
                "parent": 16542,
                "isLeaf": true
            }],
            "value": 16542,
            "label": "塔城地区",
            "parent": 165
        }, {
            "children": [{
                "value": 1654301,
                "label": "阿勒泰市",
                "parent": 16543,
                "isLeaf": true
            }, {
                "value": 1654321,
                "label": "布尔津县",
                "parent": 16543,
                "isLeaf": true
            }, {
                "value": 1654322,
                "label": "富蕴县",
                "parent": 16543,
                "isLeaf": true
            }, {
                "value": 1654323,
                "label": "福海县",
                "parent": 16543,
                "isLeaf": true
            }, {
                "value": 1654324,
                "label": "哈巴河县",
                "parent": 16543,
                "isLeaf": true
            }, {
                "value": 1654325,
                "label": "青河县",
                "parent": 16543,
                "isLeaf": true
            }, {
                "value": 1654326,
                "label": "吉木乃县",
                "parent": 16543,
                "isLeaf": true
            }],
            "value": 16543,
            "label": "阿勒泰地区",
            "parent": 165
        }, {
            "children": [{
                "value": 1659001,
                "label": "石河子市",
                "parent": 16590,
                "isLeaf": true
            }, {
                "value": 1659002,
                "label": "阿拉尔市",
                "parent": 16590,
                "isLeaf": true
            }, {
                "value": 1659003,
                "label": "图木舒克市",
                "parent": 16590,
                "isLeaf": true
            }, {
                "value": 1659004,
                "label": "五家渠市",
                "parent": 16590,
                "isLeaf": true
            }, {
                "value": 1659005,
                "label": "北屯市",
                "parent": 16590,
                "isLeaf": true
            }, {
                "value": 1659006,
                "label": "铁门关市",
                "parent": 16590,
                "isLeaf": true
            }, {
                "value": 1659007,
                "label": "双河市",
                "parent": 16590,
                "isLeaf": true
            }, {
                "value": 1659008,
                "label": "可克达拉市",
                "parent": 16590,
                "isLeaf": true
            }],
            "value": 16590,
            "label": "自治区直辖县级行政区划",
            "parent": 165
        }],
        "value": 165,
        "label": "新疆维吾尔自治区",
        "parent": 0
    }, {
        "children": [{
            "children": [{
                "value": 9330101,
                "label": "中西区",
                "parent": 93301,
                "isLeaf": true
            }, {
                "value": 9330102,
                "label": "东区",
                "parent": 93301,
                "isLeaf": true
            }, {
                "value": 9330103,
                "label": "九龙城区",
                "parent": 93301,
                "isLeaf": true
            }, {
                "value": 9330104,
                "label": "观塘区",
                "parent": 93301,
                "isLeaf": true
            }, {
                "value": 9330105,
                "label": "南区",
                "parent": 93301,
                "isLeaf": true
            }, {
                "value": 9330106,
                "label": "深水埗区",
                "parent": 93301,
                "isLeaf": true
            }, {
                "value": 9330107,
                "label": "湾仔区",
                "parent": 93301,
                "isLeaf": true
            }, {
                "value": 9330108,
                "label": "黄大仙区",
                "parent": 93301,
                "isLeaf": true
            }, {
                "value": 9330109,
                "label": "油尖旺区",
                "parent": 93301,
                "isLeaf": true
            }, {
                "value": 9330110,
                "label": "离岛区",
                "parent": 93301,
                "isLeaf": true
            }, {
                "value": 9330111,
                "label": "葵青区",
                "parent": 93301,
                "isLeaf": true
            }, {
                "value": 9330112,
                "label": "北区",
                "parent": 93301,
                "isLeaf": true
            }, {
                "value": 9330113,
                "label": "西贡区",
                "parent": 93301,
                "isLeaf": true
            }, {
                "value": 9330114,
                "label": "沙田区",
                "parent": 93301,
                "isLeaf": true
            }, {
                "value": 9330115,
                "label": "屯门区",
                "parent": 93301,
                "isLeaf": true
            }, {
                "value": 9330116,
                "label": "大浦区",
                "parent": 93301,
                "isLeaf": true
            }, {
                "value": 9330117,
                "label": "荃湾区",
                "parent": 93301,
                "isLeaf": true
            }, {
                "value": 9330118,
                "label": "元朗区",
                "parent": 93301,
                "isLeaf": true
            }],
            "value": 93301,
            "label": "香港",
            "parent": 933
        }],
        "value": 933,
        "label": "香港特区",
        "parent": 0
    }, {
        "children": [{
            "children": [{
                "value": 9340101,
                "label": "澳门",
                "parent": 93401,
                "isLeaf": true
            }],
            "value": 93401,
            "label": "澳门",
            "parent": 934
        }],
        "value": 934,
        "label": "澳门",
        "parent": 0
    }];