import { Component, OnInit, ViewChild,ChangeDetectorRef } from '@angular/core';
import { NzMessageService, NzModalService, NzDrawerService } from 'ng-zorro-antd';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { STColumn, STComponent } from '@delon/abc';
import { SFSchema } from '@delon/form';
import { StoreStorelistEditComponent } from "./edit/edit.component";
import { environment } from '@env/environment';


@Component({
  selector: 'app-store-storelist',
  templateUrl: './storelist.component.html',
})
export class StoreStorelistComponent implements OnInit {
  dataList = [{
    id: 1,
    name: "雨花台222号",
    address: "江苏省南京市雨花台南京南站",
    phone: "12222222222",
    time: "星期一-星期五",
    order:2,
  }]
  content=""
  @ViewChild('st') st: STComponent;
  columns: STColumn[] = [
    { title: '编号', index: 'id' },
    { title: '自提点名称',index: 'name' },
    { title: '自提点地址',index: 'address' },
    { title: '时间', type: 'date', index: 'time' },
    { title: '排序', index:"order"},
    {
      title: '操作',
      buttons: [
        { text: '编辑', click: (item: any) => this.edit(item) },
        { text: '删除', click:(item:any)=>this.delete(item.id) },
      ]
    }
  ];

  constructor(
    private http: _HttpClient,
    private modal: ModalHelper,
    private drawerService: NzDrawerService,
    private message:NzModalService,
    private cdr:ChangeDetectorRef,
  ) { }

  ngOnInit() { }

  add() {
    this.drawerService
    .create<StoreStorelistEditComponent, { editObj: any }, string>({
      nzTitle: '添加自提点',
      nzContent: StoreStorelistEditComponent,
      nzWidth: 800,
      nzContentParams: {
        editObj: {item:1},
      },
    })
    .afterClose.subscribe(() => {
      this.getData();
      this.cdr.detectChanges();
    });
  }
  edit(item) {
    this.drawerService
      .create<StoreStorelistEditComponent, { editObj: any }, string>({
        nzTitle: '修改自提点',
        nzContent: StoreStorelistEditComponent,
        nzWidth: 800,
        nzContentParams: {
          editObj: {item:item},
        },
      })
      .afterClose.subscribe(() => {
        this.getData();
        this.cdr.detectChanges();
      });
  }
  delete(id) {
    this.message.confirm({
      nzTitle: '是否确认删除该信息',
      nzContent: '删除以后无法恢复，删除前请慎重考虑!',
      nzOnOk: () => {
        this.http.delete(environment.SPAPPS_URL + "").subscribe(res => {
          console.log(res)
        })
      }
    });
  }
  getData() {
    this.http.get(environment.SPAPPS_URL + "").subscribe(res => {
      console.log(res)
    })
  }
  getRest() {
    this.content = "";
    this.getData();
  }
}
