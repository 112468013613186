import { Component, OnInit, ViewChild } from '@angular/core';
import { NzMessageService, UploadFile } from 'ng-zorro-antd';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { CookieService } from 'ngx-cookie-service';
import { ContentImagesComponent } from '../../../content/images/images.component';



@Component({
  selector: 'app-order-deliverylist-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.less'],
})
export class OrderDeliverylistViewComponent implements OnInit {
  imgList = [];
  get sid() {
    return this.cookes.get('sid');
  }
  constructor(
    public http: _HttpClient,
    private cookes: CookieService,
    private modal: ModalHelper,
    private msg: NzMessageService,
  ) {}

  ngOnInit(): void {}

  //挑选图片
  checkImages() {
    this.modal.createStatic(ContentImagesComponent, { size: 'md' }).subscribe((res: any) => {
      console.log(res);
      this.imgList.push({
        imgUrl: res.url,
        media_id: res.media_id,
      });
      console.log(this.imgList);
    });
  }

  save(value: any) {}

  close() {
    // this.modal.destroy();
  }
  editorConfig:any = {};
  content=""
}
