import { Component, OnInit, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { ActivatedRoute } from '@angular/router';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { environment } from '@env/environment';
import { CookieService } from 'ngx-cookie-service';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzTableModule } from 'ng-zorro-antd/table';

@Component({
  selector: 'app-step-userinfo-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class StepUserinfoNotificationComponent implements OnInit {
  isVisible = false;
  content = '';
  data1: any[] = [];
  data2: any[] = [];
  data: any = [];
  total1: any;
  total2: any;
  pageNumber1 = 1;
  pageNumber2 = 1;
  pageSize = 10;

  isAllDisplayDataChecked = false; //全选状态
  isIndeterminate = false; //单选的状态
  numberOfChecked = 0;
  listOfDisplayData = []; //全选数据
  mapOfCheckedId = {}; //被选中的id
  // checkData = [];
  checkAllData = [];
  checkAllRead = [];

  constructor(
    private http: _HttpClient,
    private modal: ModalHelper,
    private router: ActivatedRoute,
    private message: NzMessageService,
    private cdr: ChangeDetectorRef,
    private modalMessage: NzModalService,
    private cookies: CookieService,
  ) {}

  ngOnInit() {
    this.getInfo();
    this.getInfo2();
    console.log('sid', this.sid);
  }
  get sid() {
    return this.cookies.get('sid');
  }
  // 初始化数据
  getInfo() {
    this.http
      .get(
        environment.SPAPPS_URL +
          '/backend/web/v1/notice/notice-select?sid=' +
          this.sid +
          '&type=1' +
          '&page=' +
          this.pageNumber1 +
          '&per-page=10',
      )
      .subscribe(res => {
        this.data1 = res.data.list;
        this.total1 = res.data.pagination.total;
        console.log(this.total1);
        this.cdr.detectChanges();
      });
  }
  getInfo2() {
    this.http
      .get(
        environment.SPAPPS_URL +
          '/backend/web/v1/notice/notice-select?sid=' +
          this.sid +
          '&type=2' +
          '&page=' +
          this.pageNumber2 +
          '&per-page=10',
      )
      .subscribe(res => {
        this.data2 = res.data.list;
        this.total2 = res.data.pagination.total;
        this.cdr.detectChanges();
      });
  }

  // 查看
  look(item) {
    console.log(item);
    this.isVisible = true;
    this.content = item.content;
    this.http
      .get(environment.SPAPPS_URL + '/backend/web/v1/notice/notice-read?notice_id=' + item.notice_id)
      .subscribe(res => {
        this.getInfo();
      });
  }

  delete(id) {
    console.log(id);
    this.modalMessage.confirm({
      nzTitle: '<i>是否确定删除该信息?</i>',
      nzContent: '<b>删除以后无法恢复，删除前请慎重考虑!</b>',
      nzOnOk: () => {
        this.http
          .post(environment.SPAPPS_URL + '/backend/web/v1/notice/notice-del', {
            notice_id: [id],
          })
          .subscribe(res => {
            this.getInfo();
            this.getInfo2();
            if (res.code == 1) {
              this.message.success(res.message);
            } else {
              this.message.error(res.message);
            }
          });
      },
    });
  }

  // 页面数据改变时的回调 五
  currentPageDataChange($event): void {
    this.listOfDisplayData = $event;
    this.refreshStatus();
  }
  // 全选与单选的状态
  refreshStatus(): void {
    this.isAllDisplayDataChecked = this.listOfDisplayData.every(item => this.mapOfCheckedId[item.notice_id]);
    this.isIndeterminate =
      this.listOfDisplayData.some(item => this.mapOfCheckedId[item.notice_id]) && !this.isAllDisplayDataChecked;
  }
  // 全选选中的回调
  checkAll(value: boolean) {
    console.log(value);
    this.listOfDisplayData.forEach(item => {
      this.mapOfCheckedId[item.notice_id] = value;
    });
    console.log(this.mapOfCheckedId);
    this.refreshStatus();
  }
  checkbox() {
    console.log(this.mapOfCheckedId);
    this.refreshStatus();
  }
  daleteAll() {
    // 把所有选中的数据放入该数组
    for (let key in this.mapOfCheckedId) {
      // console.log(key + "----" + this.mapOfCheckedId[key])
      if (this.mapOfCheckedId[key] == true) {
        this.checkAllData.push(key);
      }
    }
    console.log(this.checkAllData);
    if (this.checkAllData.length !== 0) {
      this.modalMessage.confirm({
        nzTitle: '<i>是否确定删除该信息?</i>',
        nzContent: '<b>删除以后无法恢复，删除前请慎重考虑!</b>',
        nzOnOk: () => {
          this.http
            .post(environment.SPAPPS_URL + '/backend/web/v1/notice/notice-del', {
              notice_id: this.checkAllData,
            })
            .subscribe(res => {
              if (res.code == 1) {
                this.message.success(res.message);
                this.data1.forEach(item => (this.mapOfCheckedId[item.notice_id] = false));
                this.data2.forEach(item => (this.mapOfCheckedId[item.notice_id] = false));
                this.refreshStatus();
                this.getInfo();
                this.getInfo2();
              } else {
                this.message.error(res.message);
              }
            });
        },
      });
    } else {
      this.message.error('请选择要删除的数据');
      return;
    }
  }

  //已读设置
  readAll() {
    for (let key in this.mapOfCheckedId) {
      // console.log(key + "----" + this.mapOfCheckedId[key])
      if (this.mapOfCheckedId[key] == true) {
        this.checkAllRead.push(key);
      }
    }
    // console.log("checkAllRead",this.checkAllRead)
    if (this.checkAllRead.length !== 0) {
      this.http
        .post(environment.SPAPPS_URL + '/backend/web/v1/notice/notice-read-batch', {
          notice_id: this.checkAllRead,
        })
        .subscribe(res => {
          if (res.code == 1) {
            this.message.success(res.message);
            this.data1.forEach(item => (this.mapOfCheckedId[item.notice_id] = false));
            this.data2.forEach(item => (this.mapOfCheckedId[item.notice_id] = false));
            this.refreshStatus();
            this.getInfo();
            this.getInfo2();
          } else {
            this.message.error(res.message);
          }
        });
    } else {
      this.message.error('请选择要数据');
      return;
    }
  }
}
