import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ProductProductcommentEditComponent } from './edit/edit.component';
import { NzModalService } from 'ng-zorro-antd';
import { environment } from '@env/environment';
import { NzMessageService } from 'ng-zorro-antd/message';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-product-productcomment',
  templateUrl: './productcomment.component.html',
})
export class ProductProductcommentComponent implements OnInit {
  validateForm: FormGroup;
  userId = '';
  params = {
    content: '',
    searchKey: '',
  };
  pageNumber = 1;
  pageSize = 10;
  total: any;
  order: any;
  dataList = [
    {
      sort: '1',
      user: '18895693367',
      comment: '就是看看',
      product: '卓诗尼',
      display: '是',
      time: '2019-10-10',
      ipAddress: '168.68.34.21',
    },
  ];

  constructor(
    private http: _HttpClient,
    private modal: ModalHelper,
    private fb: FormBuilder,
    private modalService: NzModalService,
    private cdr: ChangeDetectorRef,
    private message: NzMessageService,
    private cookies: CookieService,
  ) {}
  get sid() {
    return this.cookies.get('sid');
  }
  ngOnInit() {
    this.getData();
    this.validateForm = this.fb.group({
      search: [null],
    });
  }
  // sort(order, e){
  //   console.log(order, e)
  //   this.http.post(environment.SPAPPS_URL + "/backend/web/v1/sp-goods-comment/list-order", {
  //     "list_orders":,
  //     "ids":
  //   }).subscribe(res => {

  //   })
  //  }
  look(item) {
    this.modal.createStatic(ProductProductcommentEditComponent, { view: item }).subscribe((res: any) => {
      this.getData();
      this.cdr.detectChanges();
    });
  }
  delete(id) {
    console.log(id);
    this.modalService.confirm({
      nzTitle: '是否确认删除该信息',
      nzContent: '删除以后无法恢复，删除前请慎重考虑!',
      nzOnOk: () => {
        this.http.get(environment.SPAPPS_URL + '/backend/web/v1/sp-goods-comment/del?ids=' + id).subscribe(res => {
          console.log(res);
          if (res.code == 1) {
            this.message.success('删除成功');
            this.getData();
          } else {
            this.message.error(res.message);
          }
        });
      },
    });
  }
  chagePage(e) {
    console.log(e);
    console.log(this.pageNumber);
  }
  getRest() {
    this.params.content = '';
    this.params.searchKey = '';
    this.getData();
  }
  getData() {
    this.http
      .get(
        environment.SPAPPS_URL +
          '/backend/web/v1/sp-goods-comment?sid=' +
          this.sid +
          '&nickname=' +
          this.params.searchKey +
          '&content=' +
          this.params.content +
          '&page=' +
          this.pageNumber +
          '&per-page=' +
          this.pageSize,
      )
      .subscribe(res => {
        console.log(res);
        if (res.code == 1) {
          this.dataList = res.data.list;
          this.total = res.data.pagination;
        }
      });
  }
}
