import { Component, OnInit, ViewChild,ChangeDetectorRef } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { STColumn, STComponent } from '@delon/abc';
import { NzDrawerService } from 'ng-zorro-antd';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';

import { ProductProductmodelEditComponent } from './edit/edit.component';
import { environment } from '@env/environment';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-product-productmodel',
  templateUrl: './productmodel.component.html',
})
export class ProductProductmodelComponent implements OnInit {
  islogin = true;
  searchkey: string="";
  dataList = [];
  total: number;
  @ViewChild('st') st: STComponent;
  columns: STColumn[] = [
    { title: 'ID', index: 'id',width:'40%'},
    { title: '模型名称', index: 'name'},
    {
      title: '操作',
      buttons: [
        { text: '编辑', click: (item: any) => this.edit(item) },
        { text: '删除', click:(item:any)=>this.delete(item.id) },
      ]
    }
  ];

  constructor(
    private http: _HttpClient,
    private modal: ModalHelper,
    private drawerService: NzDrawerService,
    private modalSerice: NzModalService,
    private cookes: CookieService,
    private message: NzMessageService,
    private cdr:ChangeDetectorRef,
  ) { }
  get sid() {
  return this.cookes.get("sid")
}

  ngOnInit() {
    this.getData()
  }

  add() {
    this.drawerService.create<ProductProductmodelEditComponent, { addObj: any }, string>({
      nzTitle: "添加商品模板",
      nzContent: ProductProductmodelEditComponent,
      nzWidth:"70%",
      nzContentParams: {
        addObj:{value:1}
      }
    }).afterClose.subscribe(res => {
      this.getData();
      this.cdr.detectChanges();
    })
  }
  edit(item) {
    this.drawerService.create<ProductProductmodelEditComponent, { editObj: any }, string>({
      nzTitle: "编辑商品模板",
      nzContent: ProductProductmodelEditComponent,
      nzWidth:"70%",
      nzContentParams: {
        editObj:{item:item}
      }
    }).afterClose.subscribe(res => {
      this.getData();
      this.cdr.detectChanges();
    })
  }
  delete(id) {
    this.modalSerice.confirm({
      nzTitle: '是否确认删除该信息',
      nzContent: '删除以后无法恢复，删除前请慎重考虑!',
      nzOnOk: () => {
        this.http.get(environment.SPAPPS_URL + "/backend/web/v1/sp-goods-type/goods-type-del?type_id=" + id).subscribe(res => {
          if (res.code == 1) {
            this.message.success("删除成功")
            this.getData()
          } else {
            this.message.error(res.message)
          }
        })
      }
    })
  }

  getData() {
    this.islogin = false;
    this.http.get(environment.SPAPPS_URL + "/backend/web/v1/sp-goods-type?sid="+this.sid+"&keyword="+this.searchkey).subscribe(res => {
      console.log(res)
      this.dataList = res.data.list;
      this.total = res.data.pagination;
    })
  }
  getRest() {
    this.searchkey = "";
    this.getData()
  }

}
