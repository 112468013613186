import { Component, OnInit, ViewChild,ChangeDetectorRef } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { STColumn, STComponent } from '@delon/abc';
import { MemberMemberlevelEditComponent } from "./edit/edit.component";
import { NzModalService, NzDrawerService } from 'ng-zorro-antd';
import { environment } from '@env/environment';

@Component({
  selector: 'app-member-memberlevel',
  templateUrl: './memberlevel.component.html',
})
export class MemberMemberlevelComponent implements OnInit {
  isLogin = false;
  datalist = [{
    id:1,
    name: "普通会员",
    discount: "100",
    consumption: "100",
    des:"若如初相见，若如初相恋",
    }]
  @ViewChild('st') st: STComponent;
  columns: STColumn[] = [
    { title: '编号', index: 'id' },
    { title: '等级名称', index: 'name' },
    { title: '等级折扣', index: 'discount' },
    { title: '消费额度', index: 'consumption' },
    { title: '描述', index: 'des' },
    {
      title: '操作',
      buttons: [
        { text: '编辑', click: (item: any) => this.edit(item) },
        { text: '删除', click: (item:any)=>this.delete(item.id) },
      ]
    }
  ];

  constructor(
    private http: _HttpClient,
    private modal: ModalHelper,
    private drawerService: NzDrawerService,
    private cdr:ChangeDetectorRef,
  ) { }

  ngOnInit() { }

  add() {
    this.drawerService
      .create<MemberMemberlevelEditComponent, { addObj: any }, string>({
        nzTitle: '添加商品分类',
        nzContent: MemberMemberlevelEditComponent,
        nzWidth: '60%',
        nzContentParams: {
          addObj: {add:1},
        },
      })
      .afterClose.subscribe(() => {
        this.getData();
        this.cdr.detectChanges()
      });
  }
  edit(item) {
    this.drawerService
      .create<MemberMemberlevelEditComponent, { addObj: any }, string>({
        nzTitle: '添加商品分类',
        nzContent: MemberMemberlevelEditComponent,
        nzWidth: '60%',
        nzContentParams: {
          addObj: {item:item},
        },
      })
      .afterClose.subscribe(() => {
        this.getData();
        this.cdr.detectChanges()
      });
  }
  delete(id) {

  }
  getData() {
    this.http.get(environment.SPAPPS_URL + "").subscribe(res => {
      console.log(res)
    })
  }
}
