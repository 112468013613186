import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SimpleGuard } from '@delon/auth';
import { environment } from '@env/environment';


// layout
import { LayoutDefaultComponent } from '../layout/default/default.component';
import { LayoutFullScreenComponent } from '../layout/fullscreen/fullscreen.component';
import { LayoutPassportComponent } from '../layout/passport/passport.component';
// dashboard pages
import { DashboardV1Component } from './dashboard/v1/v1.component';
import { DashboardAnalysisComponent } from './dashboard/analysis/analysis.component';
import { DashboardMonitorComponent } from './dashboard/monitor/monitor.component';
import { DashboardWorkplaceComponent } from './dashboard/workplace/workplace.component';
// passport pages
import { UserLoginComponent } from './passport/login/login.component';
import { UserRegisterComponent } from './passport/register/register.component';
import { UserRegisterResultComponent } from './passport/register-result/register-result.component';

// single pages
import { CallbackComponent } from './callback/callback.component';
import { UserLockComponent } from './passport/lock/lock.component';
import { SystemBaseconfigComponent } from './system/baseconfig/baseconfig.component';
import { AppletsListComponent } from './applets/list/list.component';
import { AppletsModule } from './applets/applets.module';
//demo
import { DemoListComponent } from './demo/list/list.component';
import { DemoDemoComponent } from './demo/demo/demo.component';
import { DemoDemoEditComponent } from './demo/demo/edit/edit.component';
import { DemoAuthComponent } from './demo/auth/auth.component';
import { DemoAuthEditComponent } from './demo/auth/edit/edit.component';

// sites
import { StepDefaultComponent } from './step/default/default.component';
import {StepAuthorizeresultComponent} from './step/authorizeresult/authorizeresult.component';
import { StepTemplatelistComponent } from './step/templatelist/templatelist.component';
import { StepTemplatelistBindsiteComponent } from './step/templatelist/bindsite/bindsite.component';
import { StepSitelistComponent } from './step/sitelist/sitelist.component';
import { StepSitelistEditComponent } from './step/sitelist/edit/edit.component';
import { StepSitelistAddComponent } from './step/sitelist/add/add.component';
import { StepResultComponent } from './step/result/result.component';
import { StepUserinfoComponent } from './step/userinfo/userinfo.component';
import { StepUserinfoBaseComponent } from './step/userinfo/base/base.component';
import { StepUserinfoSecurityComponent } from './step/userinfo/security/security.component';
import { StepVerifyComponent } from './step/verify/verify.component';
import { StepFeedbackComponent } from "./step/feedback/feedback.component"
import {StepTemplatesComponent} from './step/templates/templates.component';
import { StepUserinfoNotificationComponent } from './step/userinfo/notification/notification.component';

//content
import { ContentProductgroupComponent } from './content/productgroup/productgroup.component';

import { ContentCategorylistComponent } from './content/categorylist/categorylist.component';
import { ContentCategorylistAddComponent } from './content/categorylist/add/add.component';
import { ContentCategorylistEditComponent } from './content/categorylist/edit/edit.component';

import { ContentNewslistComponent } from './content/newslist/newslist.component';
import { ContentNewslistEditComponent } from './content/newslist/edit/edit.component';

import { ContentProlistComponent } from './content/prolist/prolist.component';
import { ContentProlistEditComponent } from './content/prolist/edit/edit.component';
import { ContentImagesComponent } from './content/images/images.component';
import { ContentParamslistComponent} from './content/paramslist/paramslist.component';
import { ContentParamslistEditComponent} from './content/paramslist/edit/edit.component';
import { ContentImglistComponent } from './content/imglist/imglist.component';
import { ContentImglistEditComponent } from './content/imglist/edit/edit.component';
import { ContentFilelistComponent } from './content/filelist/filelist.component';
import { ContentImglistCategorylistComponent } from './content/imglist/categorylist/categorylist.component';
import { ContentImglistCategorylistEditComponent } from './content/imglist/categorylist/edit/edit.component';
import { ContentProlistCategorylistComponent } from './content/prolist/categorylist/categorylist.component';
import { ContentProlistCategorylistEditComponent } from './content/prolist/categorylist/edit/edit.component';
import { ContentProparamsComponent} from './content/proparams/proparams.component';
import { ContentProparamsEditComponent} from './content/proparams/edit/edit.component';
import {ContentBaseconfigComponent} from './content/baseconfig/baseconfig.component';

//feedback
import { FeedbackMessagelistComponent } from './feedback/messagelist/messagelist.component';
import {FeedbackMessagelistViewComponent} from './feedback/messagelist/view/view.component';
import {FeedbackCommentlistComponent}  from './feedback/commentlist/commentlist.component';
import {FeedbackCommentlistViewComponent} from './feedback/commentlist/view/view.component';

// Product

import { ProductGoodsgroupComponent } from "./product/goodsgroup/goodsgroup.component";

import { ProductProductlistComponent } from "./product/productlist/productlist.component";
import { ProductProductlistEditComponent } from "./product/productlist/edit/edit.component";
import { ProductProductcateComponent } from "./product/productcate/productcate.component";
import { ProductProductcateEditComponent } from "./product/productcate/edit/edit.component";
import { ProductProductbrandComponent } from "./product/productbrand/productbrand.component";
import { ProductProductbrandEditComponent } from "./product/productbrand/edit/edit.component";
import { ProductProductcommentComponent } from "./product/productcomment/productcomment.component";
import { ProductProductcommentEditComponent } from "./product/productcomment/edit/edit.component";
import { ProductStocklistComponent } from "./product/stocklist/stocklist.component";
import { ProductProductmodelComponent } from "./product/productmodel/productmodel.component";
import { ProductProductmodelEditComponent} from "./product/productmodel/edit/edit.component"
import { ProductProductasklistComponent } from "./product/productasklist/productasklist.component";
import { ProductProductasklistViewComponent } from "./product/productasklist/view/view.component";
// order
import { OrderOrderlistComponent } from "./order/orderlist/orderlist.component";
import { OrderOrderlistViewComponent } from "./order/orderlist/view/view.component";
import { OrderOrderlistEditComponent } from "./order/orderlist/edit/edit.component";
import { OrderDeliverylistComponent } from "./order/deliverylist/deliverylist.component";
import { OrderDeliverylistViewComponent } from "./order/deliverylist/view/view.component";
import { OrderRefundorderlistComponent } from "./order/refundorderlist/refundorderlist.component";
import { OrderRefundorderlistViewComponent } from "./order/refundorderlist/view/view.component";

//member
import { MemberMemberlistComponent } from "./member/memberlist/memberlist.component";
import { MemberMemberlistEditComponent } from "./member/memberlist/edit/edit.component";
import { MemberMemberlevelComponent } from "./member/memberlevel/memberlevel.component";
import { MemberMemberlevelEditComponent } from "./member/memberlevel/edit/edit.component";

//store
import { StoreStorelistComponent } from "./store/storelist/storelist.component";
import { StoreStorelistEditComponent } from "./store/storelist/edit/edit.component";

//tongji
import {StatisticsStatisbaseComponent} from './statistics/statisbase/statisbase.component';

//首页
import { AdminBaseComponent } from './admin/base/base.component';
import { Exception404Component } from './exception/404.component';
import { Exception403Component } from './exception/403.component';
import { Exception500Component } from './exception/500.component';
import { ProductModule } from './product/product.module';

//test

const routes: Routes = [
  {
    path: '',
    component: StepDefaultComponent,
    canActivate: [SimpleGuard],
    canActivateChild: [SimpleGuard],
    children: [
      { path: 'sites/list', component: StepSitelistComponent },

      { path: '', redirectTo: 'sites/list', pathMatch: 'full' },
      // { path: '', redirectTo: 'admin/base', pathMatch: 'full' }, //admin/base   商户基础版 首页路由
    ],
  },

  {
    path: '',
    component: LayoutDefaultComponent,
     
    children: [
      { path: 'admin/base', component: AdminBaseComponent },
      {
        path: 'applets',
        loadChildren: './applets/applets.module#AppletsModule',
      },
      { path: 'system', loadChildren: './system/system.module#SystemModule' },
      { path: 'platform', loadChildren: './platform/platform.module#PlatformModule' },
      // { path: '', redirectTo: 'dashboard/v1', pathMatch: 'full' },
      // { path: '', redirectTo: 'admin/base', pathMatch: 'full' }, //admin/base   商户基础版 首页路由
      { path: 'dashboard', redirectTo: 'dashboard/v1', pathMatch: 'full' },
      { path: 'dashboard/v1', component: DashboardV1Component },
      { path: 'dashboard/analysis', component: DashboardAnalysisComponent },
      { path: 'dashboard/monitor', component: DashboardMonitorComponent },
      { path: 'dashboard/workplace', component: DashboardWorkplaceComponent },
      {
        path: 'widgets',
        loadChildren: './widgets/widgets.module#WidgetsModule',
      },
      { path: 'style', loadChildren: './style/style.module#StyleModule' },
      { path: 'delon', loadChildren: './delon/delon.module#DelonModule' },
      { path: 'extras', loadChildren: './extras/extras.module#ExtrasModule' },
      { path: 'pro', loadChildren: './pro/pro.module#ProModule' },
      // Exception
      //{ path: 'exception', loadChildren: './exception/exception.module#ExceptionModule' },
      //demo
      { path: 'demo/list', component: DemoListComponent },
      { path: 'demo/demo', component: DemoDemoComponent },
      { path: 'demo/demo/edit', component: DemoDemoEditComponent },
      { path: 'demo/auth', component: DemoAuthComponent },
      { path: 'demo/auth/edit', component: DemoAuthEditComponent },
      { path:'content/baseconfig', component:ContentBaseconfigComponent},
      { path: 'content/imglist', component: ContentImglistComponent,
        children: [
          { path: 'content/imglist/edit',component: ContentImglistEditComponent},
        ],
      },
      { path: 'content/productgroup',component: ContentProductgroupComponent},
      { path: 'content/images',component: ContentImagesComponent},
      { path: 'content/imglist/categorylist',component: ContentImglistCategorylistComponent,
        children: [
          {path: 'content/imglist/categorylist/edit',component: ContentImglistCategorylistEditComponent},
        ],
      },
      //
      { path: 'orders', loadChildren: './orders/orders.module#OrdersModule' },
      //
      {
        path:'content/newsparams',
        component:ContentParamslistComponent,
        children:[
          {
            path:'content/newsparams/edit',
            component:ContentParamslistEditComponent,
          }
        ]
      },
      {
         path:'content/proparams',
         component:ContentProparamsComponent,
         children:[
           {
             path:'content/proparams/edit',
             component:ContentProparamsEditComponent,
           }
         ]
      },
      {
        path: 'content/newslist',
        component: ContentNewslistComponent,
      },
      { path: 'content/newslist/edit', component: ContentNewslistEditComponent },
      {
        path: 'content/categorylist',
        component: ContentCategorylistComponent,
      },
      { path: 'content/categorylist/add', component: ContentCategorylistAddComponent },
      { path: 'content/categorylist/edit/:id', component: ContentCategorylistEditComponent },
      {
        path: 'content/prolist',
        component: ContentProlistComponent,
      },
      {
        path: 'content/pro-categorylist',
        component: ContentProlistCategorylistComponent,
      },
      {
        path: 'content/pro-categorylist/edit',
        component: ContentProlistCategorylistEditComponent,
      },
      {
        path: 'content/prolist/edit',
        component: ContentProlistEditComponent,
      },
      {
         path: 'statistics/statisbase',
         component:StatisticsStatisbaseComponent,
      },
      {
        path: 'feedback/messagelist',
        component: FeedbackMessagelistComponent,
        children:[
          {
            path :'feedback/messagelist/view',
            component:FeedbackMessagelistViewComponent,
          },
        ]
      },
      {
        path: 'feedback/commentlist',
        component: FeedbackCommentlistComponent,
        children:[
          {
            path :'feedback/commentlist/view',
            component:FeedbackCommentlistViewComponent,
          },
        ]
      },
      // product
      {
        path: "product/goodsgroup",
        component: ProductGoodsgroupComponent,
      },
      // {
      //   path: "product/goodsgroup",
      //   component: ProductGoodsgroupComponent,
      //   children: [
      //     {path:"product/goodsgroup/list",component:ProductGoodsgroupComponent}
      //   ]
      // },
      {
        path: "product/productlist",
        component: ProductProductlistComponent,
        children: [
          {path:"product/productlist/edit",component:ProductProductlistEditComponent}
        ]
      },
      {
        path: "product/productcate",
        component: ProductProductcateComponent,
        children:[{
          path:"product/productcate/edit",component:ProductProductcateEditComponent
        }]
      },
      {
        path: "product/productbrand",
        component: ProductProductbrandComponent,
        children: [{
          path:"product/productbrand/edit",component:ProductProductbrandEditComponent
        }]
      },
      {
        path: "product/productcomment",
        component: ProductProductcommentComponent,
        children: [{
          path:"product/productcomment/edit",component:ProductProductcommentEditComponent
        }]
      },
      {
        path: "product/stocklist",
        component: ProductStocklistComponent,
      },
      {
        path: "product/productmodel",
        component: ProductProductmodelComponent,
        children: [{
          path:"product/productmodel/edit",component:ProductProductmodelEditComponent
        }]
      },
      {
        path:"product/productasklist",
        component:ProductProductasklistComponent,
        children: [{
          path:"product/productasklist/view",component:ProductProductasklistViewComponent
        }]
      },
      //order
      {
        path: "order/orderlist",
        component: OrderOrderlistComponent,
        children: [
          { path: "order/orderlist/view", component: OrderOrderlistViewComponent },
          { path : "order/orderlist/edit",component: OrderOrderlistEditComponent }
        ]
      },
      {
        path: "order/deliverylist",
        component: OrderDeliverylistComponent,
        children: [
          { path: "order/deliverylist/view", component: OrderDeliverylistViewComponent }
        ]
      },
      {
        path: "order/refundorderlist",
        component: OrderRefundorderlistComponent,
        children: [
          {path:"order/refundorderlist/view",component:OrderRefundorderlistViewComponent}
        ]
      },
      //member
      {
        path: "member/memberlist",
        component: MemberMemberlistComponent,
        children: [
          {path:"member/memberlist/edit",component:MemberMemberlistEditComponent}
        ]
      },
      {
        path: "member/memberlevel",
        component: MemberMemberlevelComponent,
        children: [
          {path:"member/memberlevel/edit",component:MemberMemberlevelEditComponent}
        ]
      },
      //store
      {
        path: "store/storelist",
        component: StoreStorelistComponent,
        children: [
          {path:"store/storelist/edit",component:StoreStorelistEditComponent}
        ]
      }
    ],
  },
  // 全屏布局
  {
    path: 'data-v',
    component: LayoutFullScreenComponent,
    children: [{ path: '', loadChildren: './data-v/data-v.module#DataVModule' }],
  },
  // passport
  {
    path: 'passport',
    component: LayoutPassportComponent,
    children: [
      {
        path: 'login',
        component: UserLoginComponent,
        data: { title: '登录', titleI18n: '首屏智能小程序平台' },
      },
      {
        path: 'register',
        component: UserRegisterComponent,
        data: { title: '注册', titleI18n: '首屏智能小程序平台' },
      },
      {
        path: 'register-result',
        component: UserRegisterResultComponent,
        data: { title: '注册结果', titleI18n: '首屏智能小程序平台' },
      },
      {
        path: 'lock',
        component: UserLockComponent,
        data: { title: '锁屏', titleI18n: '首屏智能小程序平台' },
      },
    ],
  },

  {
    path: 'sites',
    component: StepDefaultComponent,
    children: [
      {
        path: 'setting',
        component: StepUserinfoComponent,
        children: [
          { path: '', redirectTo: 'base', pathMatch: 'full' },
          {
            path: 'base',
            component: StepUserinfoBaseComponent,
          },
          {
            path: 'security',
            component: StepUserinfoSecurityComponent,
          },
          {
            path: 'notification',
            component: StepUserinfoNotificationComponent,
          },
          {
            path: 'notification/:id',
            component: StepUserinfoNotificationComponent,
          },
        ],
      },
      {
        path: "sites/feedback",
        component:StepFeedbackComponent
      },
      {
        path: 'list',
        component: StepSitelistComponent,
      },
      {
        path: 'create',
        component: StepSitelistAddComponent,
      },
      {
        path: 'edit/:id',
        component: StepSitelistEditComponent,
      },
      {
        path: 'templatelist/:id',
        component: StepTemplatelistComponent,
      },
      {
        path:'templates',
        component:StepTemplatesComponent,
      },
      {
        path: 'templatelist',
        component: StepTemplatelistComponent,
        children: [
          {
            path: 'bindsite',
            component: StepTemplatelistBindsiteComponent,
          },
        ],
      },
      {
        path: 'result/:id',
        component: StepResultComponent,
      },
      {
        path: 'result',
        component: StepResultComponent,
      },
      {
        path: 'authorizeresult/:id',
        component: StepAuthorizeresultComponent,
      },
      {
        path: 'authorizeresult',
        component: StepAuthorizeresultComponent,
      },
      {
          path:'verify/:id',
          component:StepVerifyComponent,
      },
      {
        path:'verify',
        component:StepVerifyComponent,
      },
      {
        path:'exception/403',
        component:Exception403Component,
      },
      {
        path:'exception/404',
        component:Exception404Component,
      },
      {
        path:'exception/500',
        component:Exception500Component,
      },

    ],
  },


  // 单页不包裹Layout
  { path: 'callback/:type', component: CallbackComponent },
  { path: '**', redirectTo: '' + '/sites/exception/404' },
  // { path: 'list', component: RoutesPassportListComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: environment.useHash,
      // NOTICE: If you use `reuse-tab` component and turn on keepingScroll you can set to `disabled`
      // Pls refer to https://ng-alain.com/components/reuse-tab
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
})
export class RouteRoutingModule {}
