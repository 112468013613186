import { Component } from '@angular/core';

@Component({
  selector: 'layout-passport',
  templateUrl: './passport.component.html',
  styleUrls: ['./passport.component.less'],
})
export class LayoutPassportComponent {
  links = [
    {
      title: '关于我们',
      href: '',
    },
    {
      title: '联系我们',
      href: '',
    },
    {
      title: '隐私政策',
      href: '',
    },
    {
      title: '服务条款',
      href: '',
    }
  ];
}
