import { Component, OnInit, ViewChild } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { STColumn, STComponent } from '@delon/abc';
import { OrderRefundorderlistViewComponent } from "./view/view.component";
@Component({
  selector: 'app-order-refundorderlist',
  templateUrl: './refundorderlist.component.html',
})
export class OrderRefundorderlistComponent implements OnInit {
  isLogin = true;
  params = {
    account : "",
    nickName : "",
  }

  datalist = [{
    orderNumber: "201804121012019579",
    consignee: "肖战",
    totalAmount: "777",
    amountPayable: "790",
    orderStatus: "已完成",
    logisticsStatus: "未发货",
    time:"2018-04-12 10:12:01"
  }]

  @ViewChild('st') st: STComponent;
  columns: STColumn[] = [
    { title: '订单号', index: 'orderNumber',width:50,exported:true },
    { title: '收货人', index: 'consignee',width: 50 },
    { title: '总金额', type: 'currency', index: 'totalAmount' ,width: '50px'},
    { title: '应付金额', type: 'currency', index: 'amountPayable',width: '50px'},
    { title: '订单状态', index: 'orderStatus',width: '50px'},
    { title: '物流状态', index: 'logisticsStatus' ,width: '50px'},
    { title: '下单时间', type: 'date', index: 'time' ,width: '50px'},
    {
      title: '操作',
      buttons: [
        { text: '查看', click: (item: any) => this.view(item) }
      ],
      width: '50px'
    }
  ];

  constructor(private http: _HttpClient, private modal: ModalHelper) { }

  ngOnInit() {

   }

  view(item) {
    this.modal
      .createStatic(OrderRefundorderlistViewComponent, { view:item })
      .subscribe(() => this.st.reload());
  }
  getData() {
    this.isLogin = false;
  }
}
