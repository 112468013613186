import { Component, OnInit, ViewChild } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { STColumn, STComponent } from '@delon/abc';
import { SFSchema } from '@delon/form';
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'app-product-stocklist',
  templateUrl: './stocklist.component.html',
})
export class ProductStocklistComponent implements OnInit {
  selectedValue:any;
  listOfData = [
    {
      id: "1221",
      name: "圣罗兰巴黎香水",
      specs: "规格原装",
      order: "12545658",
      stock: 90,
      type: "货品库存",
      operator: "小红",
      operatorType: "商品录入",
      time:"2019-07-23 15:03",
    }
  ]

  constructor(
    private http: _HttpClient,
    private modal: ModalHelper,
    private cookes:CookieService,
  ) { }
  get sid() {
    return this.cookes.get("sid");
  }
  ngOnInit() { }

  search() {

  }


}
