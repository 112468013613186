import {Component, OnInit} from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd';
import { SFSchema } from '@delon/form';
import { _HttpClient, ModalHelper } from '@delon/theme';
import {  SFSchemaEnumType } from '@delon/form';
import { map } from 'rxjs/operators';
import {environment} from "@env/environment";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";


@Component({
  selector: 'app-step-step1-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.less'],
})
export class StepStep1EditComponent implements OnInit {
  validateForm: FormGroup;
  submitting = false;
  record: any = {};
  type = 1;
  DefaultValue = this.record.parent;

  //行业选择
  nzOptions: any[] | null = null;
  values: any[] | null = null;


  //获取行业类型
  getCompangType() {
    let that = this;
    this.http.get(environment.SPAPPS_URL + '/backend/web/v1/instances/com-type')
      .subscribe(res => {
        console.log('行业类型', res.data);
        that.nzOptions = res.data;
      })
  }
  constructor(
    private msgSrv: NzMessageService,
    private http: _HttpClient,
    private fb: FormBuilder
  ) {}


  ngOnInit(): void {
    console.log('record', this.record);
    this.validateForm = this.fb.group({
      app_id: [0,[Validators.required]],
      com_name: [null, [Validators.required]],
      com_type: [null, [Validators.required]],
      name:[null,[Validators.required]]
    });
    this.getCompangType();
  }

  submitForm():void{

  }



  close() {
    //this.modal.destroy();
  }
}

