import {Component, OnInit} from '@angular/core';
import { NzMessageService, NzModalRef } from 'ng-zorro-antd';
import { SFSchema } from '@delon/form';
import { _HttpClient } from '@delon/theme';
import {  SFSchemaEnumType } from '@delon/form';
import { map } from 'rxjs/operators';
import {environment} from "@env/environment";
import { areasList } from '../../../../../assets/plugins/area-china';
import { log, isArray } from 'util';
import { FormBuilder, FormArray, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-demo-demo-edit',
  templateUrl: './edit.component.html',
})
export class DemoDemoEditComponent implements OnInit{

  areasList = areasList; // 地区总数据
  provinceList: any = [];
  cityList = [];
  districtList = [];
  city: any;
  area: any;
  selectProvinceObject;
  selectCityObject;
  profileForm: FormGroup;
  editMapBol = false;
  submitting = false;
  record: any = {};
  saving = false;
  type = 1;
  DefaultValue = this.record.parent;

  constructor(
    private fb: FormBuilder,
    private modal: NzModalRef,
    private msgSrv: NzMessageService,
    private http: _HttpClient,
    private activatedRoute: ActivatedRoute,
    private message: NzMessageService,
  ) {}


  ngOnInit(): void {

  //   this.profileForm = new FormGroup({
      
  //  });
   this.profileForm = this.fb.group({

    address_pro: ['', Validators.required],
    address_city: ['', Validators.required],
    address_area: ['', Validators.required],
   });



   this.activatedRoute.queryParams.subscribe((params: Params) => {
    if (params.id) {
      this.http.get<any>(`https://api.spapps.cn/backend/web/v1/demo/1`).subscribe(res => {
        this.profileForm.controls['address_pro'].setValue(
          res.data.address_pro,
        );
        this.profileForm.controls['address_city'].setValue(
          res.data.address_city,
        );
        this.profileForm.controls['address_area'].setValue(
          res.data.address_area,
        );

  });
    } else {
      this.editMapBol = true;
    }
  });

  this.areasList.map((pro, index) => {
      this.provinceList.push({
        value: pro.value,
        label: pro.label,
      });
    });

    // console.log('record', this.record);
  }

  // 选择省
  changeProvince($event, e = 0) {


    console.log(1);

    if ($event) {
      this.areasList.map((pro, index) => {
        if (pro.label === $event) {
          this.selectProvinceObject = pro.children;
          this.cityList = [];
          this.districtList = [];
          this.city = null;
          this.area = null;
          if (pro.children.length > 0) {
            pro.children.map(city => {
              this.cityList.push({
                value: city.value,
                label: city.label,
              });
            });
          }
          return;
        }
      });
    }
  }
  // 选择市
  changeCity($event, e = 0) {
    if ($event) {
      this.selectProvinceObject.map((city, index) => {
        if (city.label === $event) {
          this.selectCityObject = city.children;
          this.districtList = [];
          this.area = null;
          if (city.children.length > 0) {
            city.children.map(dis => {
              this.districtList.push({
                value: dis.value,
                label: dis.label,
              });
            });
          }
          return;
        }
      });
    }
  }

  // 选择区县
  changeDistinct($event, e = 0) {
    if ($event) {
      if (isArray(this.selectCityObject)) {
        this.selectCityObject.map((dis, index) => {
          if (dis.label === $event) {
            this.selectCityObject = dis;
            return;
          }
        });
      }
    }
  }


  //编辑修改
  edit(value: any) {
  }

  close() {
    this.modal.destroy();
  }


  getCaptcha() {
     this.http.post(environment.SPAPPS_URL+'/backend/web/v1/auth/captcha',{
       phone:'18001580537'
     }).subscribe((res:any)=>{
      console.log(res);
        // if(res.code ===1)
        // {
        //   this.count = 59;
        //   this.interval$ = setInterval(() => {
        //     this.count -= 1;
        //     if (this.count <= 0) clearInterval(this.interval$);
        //   }, 1000);
        // }
        // else
        // {
        //   this.msg.error(res.message);
        // }
     });
   
  }






  submitForm() {
    const value = this.profileForm.value;
    this.saving = true;

    console.log(value);
    if (!value.address_pro) {
      this.message.error('请选择省份');
      return;
    }
    if (!value.address_city) {
      this.message.error('请选择城市');
      return;
    }
    if (!value.address_area) {
      this.message.error('请选择区域');
      return;
    }
  }




}

