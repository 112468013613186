import { Component, OnInit, ViewChild,ChangeDetectionStrategy,ChangeDetectorRef } from '@angular/core';
import { NzMessageService, UploadFile } from 'ng-zorro-antd';
import { _HttpClient } from '@delon/theme';
import { SFSchema, SFUISchema } from '@delon/form';
import { environment } from '@env/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Observer } from 'rxjs/index';
import { areasList } from '../../../../../assets/plugins/area-china';
import { NzModalRef } from 'ng-zorro-antd';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { log, isArray } from 'util';

@Component({
  selector: 'app-step-userinfo-base',
  templateUrl: './base.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepUserinfoBaseComponent implements OnInit {
  avatarUrl: string;
  picUrl: string;
  loading = false;
  media_id = 0;

  //用户信息
  userinfo: any = {};

  // 地区总数据
  areasList = areasList; // 地区总数据
  provinceList: any = [];
  cityList = [];
  districtList = [];
  city: any;
  area: any;
  selectProvinceObject;
  selectCityObject;
  profileForm: FormGroup;
  editMapBol = false;
  submitting = false;
  record: any = {};
  saving = false;
  type = 1;
  DefaultValue = this.record.parent;

  constructor(
    // private modal: NzModalRef,
    private msgSrv: NzMessageService,
    public http: _HttpClient,
    private fb: FormBuilder,
    private msg: NzMessageService,
    // private modal: NzModalRef,
    private activatedRoute: ActivatedRoute,
    private message: NzMessageService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.areasList.map((pro, index) => {
      this.provinceList.push({
        value: pro.value,
        label: pro.label,
      });
    });

    this.picUrl = environment.SPAPPS_URL + '/backend/web/v1/commons/upload-pic';
    this.profileForm = this.fb.group({
      nickname: [null, [Validators.required]],
      email: [null, Validators.required],
      com_brief: [null],
      office_phone: [null],
      address: [null, Validators.required],
      address_pro: ['', Validators.required],
      address_city: ['', Validators.required],
      address_area: ['', Validators.required],
      media_id: [0],
    });
    this.getBaseInfo();
  }

  getBaseInfo() {
    this.http.get(environment.SPAPPS_URL + '/backend/web/v1/users/profile').subscribe((res: any) => {
      if (res.code !== 1) {
        this.msg.error(res.message);
        return;
      }
      console.log(res.data);
      this.userinfo = res.data;
      this.avatarUrl = res.data.avatarUrl;
      this.media_id = res.data.avatar;
      this.cdr.detectChanges();
    });
  }

  //图片上传前处理
  beforeUpload = (file: File) => {
    return new Observable((observer: Observer<boolean>) => {
      // const isJPG = file.type === 'image/jpeg';
      const isJPG =
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/jpg' ||
        file.type === 'image/bmp';
      if (!isJPG) {
        this.msg.error('仅支持上传jpg、jpeg、png、bmp 格式图片文件!');
        observer.complete();
        return;
      }
      const isLt2M = file.size / 1024 / 1024 / 1024 < 120;
      if (!isLt2M) {
        this.msg.error('上传图标文件必须小于20K，请压缩以后再上传 !');
        observer.complete();
        return;
      }
      // check height
      this.checkImageDimension(file).then(dimensionRes => {
        if (!dimensionRes) {
          this.msg.error('上传的图标文件必须宽高等比，且小于256像素 !');
          observer.complete();
          return;
        }

        observer.next(isJPG && isLt2M && dimensionRes);
        observer.complete();
      });
    });
  };

  private getBase64(img: File, callback: (img: string) => void): void {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result!.toString()));
    reader.readAsDataURL(img);
  }

  private checkImageDimension(file: File): Promise<boolean> {
    return new Promise(resolve => {
      const img = new Image(); // create image
      img.src = window.URL.createObjectURL(file);
      img.onload = () => {
        const width = img.naturalWidth;
        const height = img.naturalHeight;
        window.URL.revokeObjectURL(img.src!);
        resolve(width === height && width <= 256);
      };
    });
  }

  //
  handleChange(info: { file: UploadFile }): void {
    console.log(info);
    switch (info.file.status) {
      case 'uploading':
        this.loading = true;
        break;
      case 'done':
        this.getBase64(info.file!.originFileObj!, (img: string) => {
          this.loading = false;
          this.avatarUrl = img;
          this.media_id = info.file.response.data.media_id;
        });
        break;
      case 'error':
        this.msg.error('图片上传失败，系统未知错误，请联系客服进行反馈!');
        this.loading = false;
        break;
    }
  }

  handleData = (file: UploadFile) => {
    return {
      media_id: this.media_id,
    };
  };

  // 选择省
  changeProvince($event, e = 0) {
    if ($event) {
      this.areasList.map((pro, index) => {
        if (pro.label === $event) {
          this.selectProvinceObject = pro.children;
          this.cityList = [];
          this.districtList = [];
          this.city = null;
          this.area = null;
          if (pro.children.length > 0) {
            pro.children.map(city => {
              this.cityList.push({
                value: city.value,
                label: city.label,
              });
            });
          }
          return;
        }
      });
    }
  }
  // 选择市
  changeCity($event, e = 0) {
    if ($event) {
      this.selectProvinceObject.map((city, index) => {
        if (city.label === $event) {
          this.selectCityObject = city.children;
          this.districtList = [];
          this.area = null;
          if (city.children.length > 0) {
            city.children.map(dis => {
              this.districtList.push({
                value: dis.value,
                label: dis.label,
              });
            });
          }
          return;
        }
      });
    }
  }

  // 选择区县
  changeDistinct($event, e = 0) {
    if ($event) {
      if (isArray(this.selectCityObject)) {
        this.selectCityObject.map((dis, index) => {
          if (dis.label === $event) {
            this.selectCityObject = dis;
            return;
          }
        });
      }
    }
  }

  submitForm() {
    this.saving = true;
    const value = this.profileForm.value;
    if (!this.profileForm.valid) {
      for (const i in this.profileForm.controls) {
        this.profileForm.controls[i].markAsDirty();
        this.profileForm.controls[i].updateValueAndValidity();
      }
    } else {
      value.media_id = this.media_id;
      console.log(value);
      //提交
      this.http
        .put(environment.SPAPPS_URL + '/backend/web/v1/users/' + this.userinfo.id, {
          update_type: 'profile', //修改个人信息
          nickname: value.nickname, //昵称
          avatar: value.media_id, //头像
          email: value.email, //常用邮箱
          com_brief: value.com_brief, //简介
          province: value.address_pro,
          city: value.address_city,
          area: value.address_area,
          address: value.address,
          office_phone: value.office_phone,
        })
        .subscribe((res: any) => {
          if (res.code !== 1) {
            this.msg.error(res.message);
          } else {
            this.msg.success('操作成功，已成功保存个人信息.');
          }
        });
    }
    this.saving = false;
    this.cdr.detectChanges();
  }
}
