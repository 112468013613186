import { Component, OnInit, ViewChild } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { STColumn, STComponent,STColumnBadge } from '@delon/abc';
import { SFSchema } from '@delon/form';
import { environment} from "@env/environment";
import { NzMessageService,NzModalService  } from 'ng-zorro-antd';
import { ActivatedRoute,Router } from '@angular/router';

const BADGE: STColumnBadge = {
  "0": { text: '待审核', color: 'default' },
  "1": { text: '运营中', color: 'success' },
  "2": { text: '制作中', color: 'processing' },
  "-1": { text: '已过期', color: 'default' },
  "-2": { text: '已删除', color: 'error' },
};

@Component({
  selector: 'app-step-templatelist-bindsite',
  templateUrl: './bindsite.component.html',
  styleUrls:['./bindsite.component.less'],
})
export class StepTemplatelistBindsiteComponent implements OnInit {
  url = environment.SPAPPS_URL + '/backend/web/v1/instances?status=0';
  item:any = {};
  title:string;
  searchSchema: SFSchema = {
    properties: {
      name:{
        type:'string',
        title:'小程序名称',
      },
    }
  };
  @ViewChild('st') st: STComponent;
  columns: STColumn[] = [
    { title: '编号', index: 'instance_id',className:'text-center' },
    { title: '小程序名称', index: 'name' },
    { title: '订单类型', index: 'app_type_name' },
    { title: '申请时间', index: 'apply_at' },
    { title: '状态', type: 'badge', index: 'status',badge: BADGE,className:'text-center' },
    {
      title: '操作',
      buttons: [
        { text: '选取', click: (item: any) => this.bindTemplate(item) }
      ]
    }
  ];

  constructor(private http: _HttpClient, private modal: ModalHelper,     private router: Router,   public msg: NzMessageService,) { }

  ngOnInit() {
      this.title = "[" + this.item.number +"-"+this.item.title +"]";
   }

  ///绑定小程序
  bindTemplate(i:any){
    this.http.post(environment.SPAPPS_URL+'/backend/web/v1/instances/update-tpl',{guid:i.guid,tpl_id:this.item.id}).subscribe(
      (res:any)=>{
        if (res.code !== 1) {
          this.msg.error(res.message);
          return;
        }
        this.router.navigateByUrl('/sites/result/'+ i.guid);
      }
    );
  }
}
