import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import {StepStep1AddComponent} from './add/add.component';
import {StepStep1EditComponent} from './edit/edit.component'
import { STColumn, STComponent } from '@delon/abc';
import { SFSchema } from '@delon/form';
import {environment} from "@env/environment";
import {Router} from "@angular/router";



@Component({
  selector: 'app-step-step1',
  templateUrl: './step1.component.html',
  styleUrls: ['./step1.component.less'],
})
export class StepStep1Component implements OnInit {
  q: any = {
    status: 'all',
  };
  loading = false;
  data: any[] = [];
  getData() {
    this.loading = true;

    this.http.get(environment.SPAPPS_URL + '/backend/web/v1/instances').subscribe((res: any) => {
      console.log(res);
      this.data = res.data.list;
      //this.data = res;
      this.loading = false;
      //this.cdr.detectChanges();
    });
  }

  constructor(
    private http: _HttpClient,
    private modal: ModalHelper,
    private cdr: ChangeDetectorRef,  //检测组件变化
    private router: Router,
  ) { }

  ngOnInit() {
    this.getData();
  }

  //新建
  add() {
    this.modal
      .createStatic(StepStep1AddComponent, { i: { id: 0 } }, )
      .subscribe(res => {
        //this.st.reload()
      });

  }

  //配置
  openConfig(item: any){
    console.log('配置小程序');
    console.log(item);
  }



  //删除
  delete(id:any){
    console.log('删除小程序');
    console.log(id);
  }

}
