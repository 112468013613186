import {Component, OnInit} from '@angular/core';
import { NzMessageService,UploadFile } from 'ng-zorro-antd';
import { _HttpClient, ModalHelper } from '@delon/theme';
import {environment} from "@env/environment";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import { ActivatedRoute } from '@angular/router';
import {Observable, Observer} from "rxjs/index";
import { Router } from '@angular/router';

@Component({
  selector: 'app-step-sitelist-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.less'],
})
export class StepSitelistEditComponent implements OnInit {
  id:any = 0;
  guid:any = '';
  validateForm: FormGroup;
  submitting = false;
  avatarUrl:string;
  //行业选择
  nzOptions: any[] | null = null;
  media_id = 0;

  //获取行业类型
  getCompangType() {
    let that = this;
    this.http.get(environment.SPAPPS_URL + '/backend/web/v1/instances/com-type')
      .subscribe(res => {
        console.log('行业类型', res.data);
        that.nzOptions = res.data;
      })
  }
  constructor(
    private msgSrv: NzMessageService,
    private http: _HttpClient,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private msg: NzMessageService,
    private router: Router,
  ) {}

  loading = false;
  data: any = {};
  picUrl = '';
  get app_type() {
    return this.validateForm.controls.app_type;
  }
  get com_name() {
    return this.validateForm.controls.com_name;
  }
  get com_type() {
    return this.validateForm.controls.com_type;
  }
  get name() {
    return this.validateForm.controls.name;
  }


  ngOnInit(): void {

    // this.guid = this.activatedRoute.params['_value']['id'] == null ? "":this.activatedRoute.params['_value']['id'];

    this.picUrl = environment.SPAPPS_URL + '/backend/web/v1/commons/upload-pic';
    this.id = this.activatedRoute.snapshot.params['id'];
    this.getCompangType();
    //获取单个实例详情
    this.http.get(environment.SPAPPS_URL + '/backend/web/v1/instances/viewGu?key='+this.id,{
}).subscribe((res: any) => {
      console.log(res);
      this.data = res.data;
      this.avatarUrl = this.data.logo.url;
      this.media_id = this.data.logo.media_id;
      //this.data = res;
      this.loading = false;
      //this.cdr.detectChanges();
      // console.log('data:'+ JSON.stringify(this.data) );
    });
    this.validateForm = this.fb.group({
      app_type: [null,[Validators.required]],
      com_name: [null, [Validators.required,Validators.pattern(/^[^\s]*$/)]],
      com_type: [null, [Validators.required]],
      name:[null,[Validators.required,Validators.pattern(/^[^\s]*$/),Validators.maxLength(10)]],
    });

  }

  submitForm():void{
    console.log('状态：'+ this.validateForm.valid);
    if(!this.validateForm.valid)
    {
      for (const i in this.validateForm.controls) {
        this.validateForm.controls[i].markAsDirty();
        this.validateForm.controls[i].updateValueAndValidity();
      }
    }
    else {
      this.http
        .post(environment.SPAPPS_URL+'/backend/web/v1/instances', {
          // type: this.type,
          app_id: this.app_type.value,
          com_name: this.com_name.value,
          com_type: this.com_type.value,
          name: this.name.value,
          media_id: this.media_id,
          guid:this.id,
        })
        .subscribe((res: any) => {
          if (res.code !== 1) {
            this.msg.error(res.message);
            return;
          }

          let url = 'sites/result';
          console.log(res.data);
          this.router.navigateByUrl(url);
        });
      }
  }

  //图片上传前处理
  beforeUpload = (file: File) => {
    return new Observable((observer: Observer<boolean>) => {
      // const isJPG = file.type === 'image/jpeg';
      const isJPG = (file.type === 'image/jpeg' || file.type === 'image/png' ||file.type === 'image/jpg' || file.type === 'image/bmp')
      if (!isJPG) {
        this.msg.error('仅支持上传jpg、jpeg、png、bmp 格式图片文件!');
        observer.complete();
        return;
      }
      const isLt2M = file.size / 1024 / 1024/1024 < 120;
      if (!isLt2M) {
        this.msg.error('上传图标文件必须小于20K，请压缩以后再上传 !');
        observer.complete();
        return;
      }
      // check height
      this.checkImageDimension(file).then(dimensionRes => {
        if (!dimensionRes) {
          this.msg.error('上传的图标文件必须宽高等比，且小于256像素 !');
          observer.complete();
          return;
        }

        observer.next(isJPG && isLt2M && dimensionRes);
        observer.complete();
      });
    });
  };

  private getBase64(img: File, callback: (img: string) => void): void {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result!.toString()));
    reader.readAsDataURL(img);
  }

  private checkImageDimension(file: File): Promise<boolean> {
    return new Promise(resolve => {
      const img = new Image(); // create image
      img.src = window.URL.createObjectURL(file);
      img.onload = () => {
        const width = img.naturalWidth;
        const height = img.naturalHeight;
        window.URL.revokeObjectURL(img.src!);
        resolve(width === height && width <= 256);
      };
    });
  }

  //
  handleChange(info: { file: UploadFile }): void {
    console.log(info);
    switch (info.file.status) {
      case 'uploading':
        this.loading = true;
        break;
      case 'done':
        this.getBase64(info.file!.originFileObj!, (img: string) => {
          this.loading = false;
          this.avatarUrl = img;
          this.media_id = info.file.response.data.media_id;
        });
        break;
      case 'error':
        this.msg.error('图片上传失败，系统未知错误，请联系客服进行反馈!');
        this.loading = false;
        break;
    }
  }

  handleData = (file: UploadFile) => {
    return {
      'media_id':this.media_id
    }
  };



}
