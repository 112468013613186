import { Component, OnInit } from '@angular/core';
import { NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { _HttpClient } from '@delon/theme';
import { environment } from '@env/environment';

@Component({
  selector: 'app-feedback-messagelist-view',
  templateUrl: './view.component.html',
})
export class FeedbackMessagelistViewComponent implements OnInit {
  record: any = {};
  constructor(private modal: NzModalRef, public msgSrv: NzMessageService, public http: _HttpClient) {}

  ngOnInit(): void {
    console.log(this.record);
  }
  choose() {
    console.log(this.record.status);
    this.http
      .post(environment.SPAPPS_URL + '/backend/web/v1/form/editform', {
        id: this.record.id,
        status: this.record.status,
      })
      .subscribe((res: any) => {
        console.log(res);
      });
  }
  close() {
    this.modal.destroy();
  }
}
