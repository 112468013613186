import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzMessageService, NzDrawerRef } from 'ng-zorro-antd';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { areasList } from '../../../../../assets/plugins/area-china';
import { log, isArray } from 'util';
import { environment } from '@env/environment';
import { OrderOrderlistViewComponent } from '../view/view.component';

@Component({
  selector: 'app-order-orderlist-edit',
  templateUrl: './edit.component.html',
})
export class OrderOrderlistEditComponent implements OnInit {
  validateForm: FormGroup;
  loading = false;
  form = {
    name: '',
    phone: '',
    province: '',
    city: '',
    area: '',
    zcode: '',
    invoice: '',
    taxpayer: '',
    content: '',
  };
  areasList = areasList; // 地区总数据
  provinceList: any = [];
  cityList = [];
  areaList = [];
  city: any;
  area: any;
  selectProvinceObject;
  selectCityObject;

  /////////////////////////////////

  constructor(
    private msgSrv: NzMessageService,
    public http: _HttpClient,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private modal: ModalHelper,
    private drawerRef: NzDrawerRef,
  ) {}

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      name: [null, [Validators.required]],
      phone: [null, [Validators.required]],
      province: [null, [Validators.required]],
      city: [null, [Validators.required]],
      area: [null, [Validators.required]],
      zcode: [null, [Validators.required]],
      invoice: [null],
      taxpayer: [null],
      content: [null],
    });
    //获取所有的省
    this.areasList.map((pro, index) => {
      this.provinceList.push({
        value: pro.value,
        label: pro.label,
      });
    });
  }

  addGood() {
    this.modal.createStatic(OrderOrderlistViewComponent, { view: 1 }).subscribe();
  }
  save(value: any) {
    this.loading = true;
    this.http.post(environment.SPAPPS_URL + '', {}).subscribe(res => {
      if (res.code !== 1) {
        this.msgSrv.error(res.message);
        return;
      }
      this.msgSrv.success(res.message);
      this.drawerRef.close();
    });
  }

  // 选择省
  changeProvince($event, e = 0) {
    this.form.province = $event;
    console.log(this.form.province);
    if ($event) {
      this.areasList.map((pro, index) => {
        if (pro.label === $event) {
          this.selectProvinceObject = pro.children;
          this.cityList = [];
          this.areaList = [];
          this.city = null;
          this.area = null;
          if (pro.children.length > 0) {
            pro.children.map(city => {
              this.cityList.push({
                value: city.value,
                label: city.label,
              });
            });
          }
          return;
        }
      });
    }
  }
  // 选择市
  changeCity($event, e = 0) {
    console.log(this.form.city);
    if ($event) {
      this.selectProvinceObject.map((city, index) => {
        if (city.label === $event) {
          this.selectCityObject = city.children;
          this.areaList = [];
          this.area = null;
          if (city.children.length > 0) {
            city.children.map(dis => {
              this.areaList.push({
                value: dis.value,
                label: dis.label,
              });
            });
          }
          return;
        }
      });
    }
  }

  // 选择区县
  changeDistinct($event, e = 0) {
    console.log(this.form.area);
    if ($event) {
      if (isArray(this.selectCityObject)) {
        this.selectCityObject.map((dis, index) => {
          if (dis.label === $event) {
            this.selectCityObject = dis;
            return;
          }
        });
      }
    }
  }
}
