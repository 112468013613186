import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { NzMessageService,NzModalService,NzDrawerRef  } from 'ng-zorro-antd';
import { _HttpClient,ModalHelper } from '@delon/theme';
import { environment} from "@env/environment";
import {map} from "rxjs/operators";
import {SFSchemaEnumType} from "@delon/form";
import { ActivatedRoute,Router } from '@angular/router';

@Component({
  selector: 'app-step-templates',
  templateUrl: './templates.component.html',
  styleUrls:['./templates.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepTemplatesComponent implements OnInit {
  q: any = {
    ps: 16,
    categories: [],
  };

  list: any[] = [];
  total = 0; 
  pageSize = 4;

  loading = true;

  // region: cateogry
  categories = [];

  //选中
  checkedCategory= [];

  checkedColors= [];

  colors = [
    {text: '全部', value: false,name:'' },
    {text:'红色',value:false,name:'#ff0000'},
    {text:'橙色',value:false,name:'#FFAA33'},
    {text:'黄色',value:false,name:'#FFFF00'},
    {text:'绿色',value:false,name:'#008000'},
    {text:'青色',value:false,name:'#00FF00'},
    {text:'蓝色',value:false,name:'#0000FF'},
    {text:'紫色',value:false,name:'#800080'},
    {text:'黑色',value:false,name:'#000000'},
    {text:'白色',value:false,name:'#ffffff'},
    {text:'灰色',value:false,name:'#cccccc'},
  ];

  changeCategory(status: boolean, idx: number) {
    // console.log(idx)
    this.categories[idx].value = status;
    this.checkedCategory = [];
    this.categories.forEach(item=>{
       if(item.value)
       {
         this.checkedCategory.push(item.id);
       }
    });
    console.log(this.checkedCategory);
    this.getData(1);
  }

  changeColor(status:boolean, idx: number) {
    this.checkedColors = [];
    if (idx === 0) {
      this.colors.map(i => (i.value = status));
    } else {
      this.colors[idx].value = status;
    }
    this.colors.forEach(item=>{
       if(item.value)
       { 
         this.checkedColors.push(item.name);
       }
    });
    this.getData(1);
  }
  // endregion

  constructor(
    private http: _HttpClient,
    public msg: NzMessageService,
    private activatedRoute: ActivatedRoute,
    private modalService: NzModalService,
    private router: Router,
    private modal: ModalHelper,
    private cdr: ChangeDetectorRef,
    private drawerRef: NzDrawerRef<string>
  ) {}

  ngOnInit() {
    this.getData(1);
    this.getType();
  }

  //获取行业类型
  getType() {
    this.http.get(environment.SPAPPS_URL+'/backend/web/v1/instances/com-type?level=1').subscribe((res: any) => {
       console.log(res);
      // this.categories = res.data;
      res.data.forEach(item=>{
        this.categories.push({ id: item.id, value: false,name: item.name });
      })
    });
  }

  getData(page:number) {
    this.loading = true;
    this.http.get(environment.SPAPPS_URL+'/backend/web/v1/templates?tpl=1&page='+ page +'&per-page='+this.pageSize,{ category: JSON.stringify(this.checkedCategory),color:JSON.stringify(this.checkedColors) })
      .subscribe((res: any) => {
        this.list = res.data.list;
        this.total = res.data.pagination.total;
       this.loading = false;
       this.cdr.detectChanges();
    });
  }



  ///选择模板
  selecttemplate(item:any) {
      this.drawerRef.close(item);
  }

}
