import { Component, OnInit, ViewChild } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { STColumn, STComponent } from '@delon/abc';
import { OrderDeliverylistViewComponent } from './view/view.component';
@Component({
  selector: 'app-order-deliverylist',
  templateUrl: './deliverylist.component.html',
})
export class OrderDeliverylistComponent implements OnInit {
  isLogin = true;
  params = {
    account: '',
    nickName: '',
  };
  listOfAllData = [
    {
      id: 1,
      orderNumber: '1',
      people: '肖战1',
      phone: '18895693367',
      money: '10',
      time: '2018-04-12 10:12:01',
    },
    {
      id: 2,
      orderNumber: '2',
      people: '肖战2',
      phone: '18895693367',
      money: '10',
      time: '2018-04-12 10:12:01',
    },
    {
      id: 3,
      orderNumber: '3',
      people: '肖战3',
      phone: '18895693367',
      money: '10',
      time: '2018-04-12 10:12:01',
    },
  ];
  isAllDisplayDataChecked = false;
  isIndeterminate = false;
  listOfDisplayData: any[] = [];
  mapOfCheckedId: { [key: string]: boolean } = {};

  checkAll(value) {
    console.log(value);
    this.listOfDisplayData.forEach(item => {
      this.mapOfCheckedId[item.id] = value;
      console.log(this.mapOfCheckedId);
    });

    this.refreshStatus();
  }
  refreshStatus(): void {
    console.log(this.mapOfCheckedId);
    //半选
    this.isAllDisplayDataChecked = this.listOfDisplayData.every(item => this.mapOfCheckedId[item.id]);
    console.log(this.isAllDisplayDataChecked);
    //单选
    this.isIndeterminate =
      this.listOfDisplayData.some(item => this.mapOfCheckedId[item.id]) && !this.isAllDisplayDataChecked;
    console.log(this.isIndeterminate);
    // console.log('listOfDisplayData', this.listOfDisplayData);
    // console.log('all', this.isAllDisplayDataChecked);
    // console.log(this.isIndeterminate);
  }
  // @ViewChild('st') st: STComponent;
  // columns: STColumn[] = [
  //   { title: '订单号', index: 'orderNumber' },
  //   { title: '收货人', index: 'people' },
  //   { title: '手机号', index: 'phone' },
  //   { title: '时间', type: 'date', index: 'time' },
  //   {
  //     title: '操作',
  //     buttons: [{ text: '查看', click: (item: any) => this.view(item) }],
  //   },
  // ];

  constructor(private http: _HttpClient, private modal: ModalHelper) {}

  ngOnInit() {
    this.isLogin = false;
    this.listOfDisplayData = this.listOfAllData;
  }
  // add() {
  //   this.modal.createStatic(OrderDeliverylistViewComponent, { item: 1 }).subscribe(() => this.st.reload());
  // }
  // view(item) {
  //   this.modal.createStatic(OrderDeliverylistViewComponent, { item: item }).subscribe(() => this.st.reload());
  // }
}
