import { Component, OnInit, ViewChild, TemplateRef, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { tap, map } from 'rxjs/operators';
import {STComponent, STColumn, STData, STChange, STPage} from '@delon/abc';
import {environment} from "@env/environment";
import {DemoAuthEditComponent} from "./edit/edit.component";

@Component({
  selector: 'app-demo-auth',
  templateUrl: './auth.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DemoAuthComponent implements OnInit {
  q: any = {
    title: ''
  };
  data: any[] = [];
  loading = false;
  @ViewChild('st')
  st: STComponent;
  columns: STColumn[] = [
    { title: '角色名称', index: 'name',width:'25%'},
    { title: '描述', index: 'description',width:'25%'},
    { title: '员工数量', index: 'count', className:'text-center',width:'20%'},
    {
      title: '操作',
      buttons: [
        {
          text: '查看',
          click: (item: any) => this.detail(item.key),
        },
        {
          text: '修改',
          click: (item: any) => this.update(item),
        },
        {
          text: '删除',
          click: (item: any) => this.delete(item.key)
        }

      ]
    }
  ];
  selectedRows: STData[] = [];
  description = '';
  totalCallNo = 0;
  expandForm = false;

  constructor(
    private http: _HttpClient,
    private modal: ModalHelper,
    public msg: NzMessageService,
    private modalSrv: NzModalService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.loading = true;
    //this.http.get(environment.SPAPPS_URL + '/backend/web/sp/authorities', this.q)  //查询
    this.http.get(environment.SPAPPS_URL + '/backend/web/sp/authorities')
      .subscribe(res => {
        this.data = res.data.list;
        this.loading = false;
        this.cdr.detectChanges();
      });
  }

  stChange(e: STChange) {
    switch (e.type) {
      case 'checkbox':
        this.selectedRows = e.checkbox!;
        this.totalCallNo = this.selectedRows.reduce((total, cv) => total + cv.callNo, 0);
        this.cdr.detectChanges();
        break;
      case 'filter':
        this.getData();
        break;
    }
  }

  remove() {
    this.http.delete('/rule', { nos: this.selectedRows.map(i => i.no).join(',') }).subscribe(() => {
      this.getData();
      this.st.clearCheck();
    });
  }

  approval() {
    this.msg.success(`审批了 ${this.selectedRows.length} 笔`);
  }

  add(tpl: TemplateRef<{}>) {
    let that = this;
    that.modal.create(DemoAuthEditComponent, {},{ size: 'md' }).subscribe(res => {
      console.log(res)
      //that.st.reload();
    });
  }

  reset() {
    // wait form reset updated finished
    setTimeout(() => this.getData());
  }


  //me
  //查看
  detail(id: any) {
    console.log('跳转到详情', id);
  }

  //修改
  update(item: any) {
    console.log('修改', item)
  }

  //删除
  delete(id: any) {
    console.log('删除', id)
  }

}
