import { Component, OnInit, ViewChild } from '@angular/core';
import { NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { _HttpClient } from '@delon/theme';
import * as $ from 'jquery';
import { DOCUMENT } from '@angular/common';
import { environment } from '@env/environment';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-product-productcomment-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.less'],
})
export class ProductProductcommentEditComponent implements OnInit {
  component = [];
  view: any;
  message: any;
  userList: any = {};
  messageList = [];
  user = "";
  constructor(
    private modal: NzModalRef,
    private msgSrv: NzMessageService,
    public http: _HttpClient,
    private cookes:CookieService,
  ) { }
  get sid() {
      return this.cookes.get("sid")
    }
  ngOnInit(): void {
    console.log(this.view);
    this.getComment();
    let user = window.localStorage.getItem('user');
    this.user = JSON.parse(user);
  }

  clickSend() {
    let time;
    var content = $('textarea').val(); // 表单元素
    if (content === '') {
      return;
    } else {
      this.component.push(this.message);
      console.log(this.component);
      time = this.getNowFormatDate();
      this.send();
    }
    // 动态创建 div
    var $div = $('<div></div>')
      .addClass('box-manger')
      .html(
        " <div class='box-manger'><div class='box'><div>" +
          time +
          "</div><div>管理员</div></div><div class='manger'><div class='manger-left'>" +
          content +
          "</div><div class='manger-right'></div></div></div>",
      );
    $('.cont').append($div);
    $div[0].scrollIntoView(); // 让容器进入可视区
    $('textarea').val(''); // 清空内容
    $('.box-manger').css('margin', '10px 0');
    $('.box').css({
      display: 'flex',
      'justify-content': 'space-between',
    });
    $('.manger').css({
      height: '40px',
      display: 'flex',
      'justify-content': 'flex-end',
    });
    $('.manger-left').css({
      height: '40px',
      padding: '0 10px',
      'background-color': 'pink',
      'line-height': '40px',
      'border-radius': '5px',
      flex: '1',
    });
    $('.manger-right').css({
      width: '40px',
      height: '40px',
      'background-color': 'red',
      'border-radius': '50%',
      'margin-left': '20px',
    });
  }
  getNowFormatDate() {
    //获取当前时间
    var date = new Date();
    var seperator1 = '-';
    var seperator2 = ':';
    var month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
    var strDate = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    var currentdate =
      date.getFullYear() +
      seperator1 +
      month +
      seperator1 +
      strDate +
      ' ' +
      date.getHours() +
      seperator2 +
      date.getMinutes() +
      seperator2 +
      date.getSeconds();
    return currentdate;
  }
  // 发送
  send() {
    this.http.post(environment.SPAPPS_URL + "/backend/web/v1/sp-goods-comment/reply", {
      "sid":this.sid,
      "id": this.view.comment_id,
      "goods_id": this.userList.goods_id,
      "content": this.message,
      "user_id":this.user['user_id'],
    }).subscribe(res=>{
      console.log(res);
      if (res.code == 1) {
        this.msgSrv.success("回复成功");
      } else {
        this.msgSrv.error(res.message);
      }
    })
  }
  // 查询评论
  getComment() {
    this.http
      .get(environment.SPAPPS_URL + '/backend/web/v1/sp-goods-comment/detail?id=' + this.view.comment_id)
      .subscribe(res => {
        console.log(res);
        if (res.code == 1) {
          this.userList = res.data.comment;
          this.messageList = res.data.reply;
          console.log(this.userList);
        }
      });
  }
}
